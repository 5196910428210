import { withTranslation } from 'react-i18next';
// Chakra imports
import {
  Flex,Box,Spacer,
  Button,Text,SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment"; 
// Custom components
import Card from "components/card/Card.js";

import React from "react";

import 'rsuite/dist/rsuite.min.css';

import AnimationBattery from "../../../../overview/components/animation/detail/battery";
import AnimationHome from "../../../../overview/components/animation/detail/home";
import AnimationSolar from "../../../../overview/components/animation/detail/solar";
import AnimationSolarThermal from "../../../../overview/components/animation/detail/solar_thermal";
import AnimationHeatPump from "../../../../overview/components/animation/detail/heat_pump";
import AnimationBoiler from "../../../../overview/components/animation/detail/boiler";
import AnimationGrid from "./grid";
import AnimationHeating from "./heating";

import PaneRight from "./PaneRight";


function AnimationDetail(props) {
  const { ...rest } = props;
  const {t,onUpdateDate,dates,data,onBack,type} = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const graphBgColor = useColorModeValue("primary.main","secondary.main");

  var [circleData, setCircleData] = React.useState({
    innerSub:60,
    outnerSub_2:60,
    inner:40,
    outner:15,
    outnerSub_1:25
  });


  React.useEffect(() => {  
    let total
    if (type==="battery"){
      setCircleData({
        innerSub:0,
        outnerSub_2:0,
        inner:0,
        outner:0,
        outnerSub_1:0
      })
    }
    else if (type==="home"){
      if(data.hasOwnProperty("PuissanceDecharge")){
        total=Number(data["PuissanceDecharge"])+Number(data["GCP2"])+Number(data["PVexporte"])
        setCircleData({
          innerSub:Number((data["GCP2"]/total)*100).toFixed(1),
          outnerSub_2:Number((data["GCP2"]/total)*100).toFixed(1),
          inner:(Number((data["PuissanceDecharge"]/total)*100) + Number((data["PVexporte"]/total)*100)).toFixed(1),
          outner:Number((data["PVexporte"]/total)*100).toFixed(1),
          outnerSub_1:Number((data["PuissanceDecharge"]/total)*100).toFixed(1)
        })
      }
    }
    else if (type==="solar_panel"){
      if(data.hasOwnProperty("ConsDirectPV")){
        total=Number(data["ConsDirectPV"])+Number(data["PVexporte"])+Number(data["PVSTORED"])
        setCircleData({
          innerSub:Number((data["PVexporte"]/total)*100).toFixed(1),
          outnerSub_2:Number((data["PVexporte"]/total)*100).toFixed(1),
          inner:(Number((data["PVSTORED"]/total)*100) + Number((data["ConsDirectPV"]/total)*100)).toFixed(1),
          outner:Number((data["PVSTORED"]/total)*100).toFixed(1),
          outnerSub_1:Number((data["ConsDirectPV"]/total)*100).toFixed(1)
        })
      }
    }

    return () => {}
  },[data]);
  
  const getRequestAnimation=()=>{
    if (type==="battery")return <AnimationBattery data={data}/>
    else if (type==="home")return <AnimationHome data={data}/>
    else if (type==="solar_panel") return <AnimationSolar data={data}/>
    else if (type==="heat_pump")return <AnimationHeatPump/>
    else if (type==="solar_thermal")return <AnimationSolarThermal/>
    else if (type==="boiler")return <AnimationBoiler/> 
    else if (type==="grid")return <AnimationGrid data={data}/> 
    else if (type==="heating")return <AnimationHeating/> 
  }

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      {...rest}>
      <Box>
        <SimpleGrid columns={{base:1,md:1,xl:2}} gap='20px' zIndex='0'>
          <Flex flexDirection={ "column"}  
            align='center' justifyContent={"space-between"}>
            <Flex ps='0px' pe='4px' flexDirection={{ base: "column", lg: "row" }} 
              align='center' w='100%' zIndex='0'>
                <Button  
                  width='90px' 
                  colorScheme='whatsapp' 
                  variant={"ghost"} 
                  onClick={onBack}
                >
                  {t("back")}
                </Button>
                <Spacer/>
                <DateRangePicker 
                  isoWeek={true}
                  value={dates}
                  placeholder={t("from_midnight")}
                  onOk={(value) => {
                    value[0]=new Date(new Date(value[0]).setHours(0,0,0));
                    value[1]=new Date(new Date(value[1]).setHours(23,59,58))
                    onUpdateDate(value)
                  }}
                  ranges={[
                    {
                      label: t("yesterday"),
                      value:[
                        moment().subtract(1, 'days').startOf('day').toDate(),
                        moment().subtract(1, 'days').endOf('day').toDate()
                      ]
                    },
                    {
                      label: t("last_week"),
                      value: [
                        moment().subtract(1, 'week').startOf('week').toDate(),
                        moment().subtract(1, 'week').endOf('week').toDate()
                      ]
                    },
                    {
                      label: t("last_momth"),
                      value: [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate()
                      ]
                    },
                    {
                      label: t("last_year"),
                      value: [
                        moment().subtract(1, 'year').startOf('year').toDate(),
                        moment().subtract(1, 'year').endOf('year').toDate()
                      ]
                    }
                  ]}
                />
            </Flex>
              {data?getRequestAnimation():<></>}
              <Text
                w="100%"
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                bg={graphBgColor}>
                {t(type)}
              </Text>
          </Flex>
          <PaneRight
            type={type}
            data={circleData}
          />
        </SimpleGrid>}
      </Box>
    </Card>
  );
}

export default withTranslation()(AnimationDetail);