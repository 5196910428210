
import { withTranslation } from 'react-i18next';
// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAuth.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
//import { SidebarContext } from "contexts/SidebarContext";
import React, { useState} from "react";
import {Route, Switch } from "react-router-dom";
import {routes} from "routes.js";

// Admin Imports
import MainDashboard from "views/admin/default";
import Billing from "views/admin/billing";
import Monitoring from "views/admin/monitoring_control";
import Devices from "views/admin/devices";
import Sensors from "views/admin/sensors";
import Profile from "views/admin/profile";
import Configurations from "views/admin/configurations";
import Users from "views/admin/users";
import Demo from "views/admin/demo";
import Overview from "views/admin/overview";

// Custom Chakra theme
function Dashboard(props) {
  const { ...rest } = props;
  const {t} = props;
  //const [toggleSidebar, setToggleSidebar] = useState(true);
  // functions for changing the states from components
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      /*if(i===1) return
      if(i===2) return
      if(i===3) return*/
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else 
        if(window.location.href.indexOf(routes[i].path) !== -1)
          return routes[i].showSelectDevice;
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <Sidebar routes={routes(t)} display='none' {...rest} />
      <Box
        float='right'
        minHeight='100vh'
        height='100%'
        overflow='auto'
        position='relative'
        maxHeight='100%'
        w={{ base: "100%", xl: "calc( 100% - 5px )" }}
        maxWidth={{ base: "100%", xl: "calc( 100% - 240px )" }}
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease'>
        <Portal >
          <Box>
            <Navbar
              onOpen={onOpen}
              brandText={getActiveRoute(routes(t))}
              showSelectDevice={getActiveNavbar(routes(t))}
              message={getActiveNavbarText(routes(t))}
              {...rest}
            />
          </Box>
        </Portal>

        <Box
          mx='auto'
          p={{ base: "20px", md: "30px" }}
          pe='20px'
          minH='100vh'
          mt={5}
          pt='50px'>
          <Switch> 
            <Route path="/dashboard" component={MainDashboard} />
            <Route path="/overview" component={Overview} />
            <Route path="/monitoring_control" component={Monitoring} />
            <Route path="/billing" component={Billing} />
            <Route path="/users" component={Users} />
            <Route path="/devices" component={Devices} />
            <Route path="/sensors" component={Sensors} />
            <Route path="/profile" component={Profile} />
            <Route path="/configurations" component={Configurations} />
            <Route path="/demo" component={Demo} />
          </Switch>
        </Box>
        <Box>
          <Footer auth={true}/>
        </Box>
      </Box>
    </Box>
  );
}
export default withTranslation()(Dashboard);
