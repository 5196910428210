import mexp from "utils/math-expression-evaluator";

export const ipv4=(message = 'Invalid IP address')=>{
    return  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(message);
}
export const getConvertVal= (input,inMin,inMax,outMin,outMax) => {
    var percentage=((Number(input) - Number(inMin)) * 100) / (Number(inMax) - Number(inMin))
    return (Number(percentage) * (Number(outMax) - Number(outMin)) / 100) + Number(outMin)
}

export const parseExpresion=(expression,list)=>{
    var text=expression, _var,_value
    if(expression)
      if(expression.includes("$")){
        var newTxt = text.split('$');
        for (var i = 1; i < newTxt.length; i++) {
          _var=newTxt[i].split(' ')[0];
          list.forEach(element => {
            if(element.id===_var){
              _value=element.value?element.value:"0"
              text=text.replace('$'+_var,''+_value)
            }
          });
        }
      }

    try{ return [text+" = "+mexp.eval(text),true,mexp.eval(text)]}
    catch(e){return [text+" = "+e.message,false,0] }
  }