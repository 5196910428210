import {Grid,GridItem,
    useColorModeValue,
    Button,FormControl,InputRightElement,
    FormLabel,InputGroup,
    Input} from '@chakra-ui/react'
  import Select from 'react-select';
  import '../../../../assets/css/react-autocomplete-input.css';
  import {Utils} from "utils";
  
  
  import * as R from 'ramda';
  import { withTranslation } from 'react-i18next';
  import React from "react";
  
  
  function TabCommads(props) {
    const {t,onTypeCommand,data}=props;
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
  
    const [command, setCommand] = React.useState({
      protocol: "undefined",
      topic: "",
      list:[{_id:(new Date()).getTime()}]
    })
    let _command={}
  
  
    React.useEffect(() => { 
      if(data.full_id!=="")
        if(data.commands)
          setCommand(data.commands)
      return () => {}
    },[data]);
  
    const handleChangeCommand = (prop) => (event) => {
      _command=command
        setCommand({ ...command, [prop]: event.target.value });
        _command[prop]=event.target.value
        onTypeCommand(_command);
    };
  
    const showMQTTview=()=>{
        return (<Grid
            templateColumns='repeat(4, 1fr)'
            gap={3}
            mb='10px'
        >
            <GridItem colSpan={4}>
              <FormControl >
                <FormLabel
                display='flex'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>Topic
                </FormLabel>
                <Input
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  mb='9px'
                  fontWeight='500'
                  value={command.topic}
                  onChange={handleChangeCommand('topic')}
                  size='lg'
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              {show_command_list()}
            </GridItem>
        </Grid>)   
    }
  
    const show_command_list=()=>{
      return<>{command.list.map((_command, index) => (
        <Grid
          key={index}
            templateColumns='repeat(9, 1fr)'
            gap={3}
            mb='10px'
        >
          <GridItem colSpan={2}>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              mb='9px'
              fontWeight='500'
              placeholder={t("title")}
              value={_command.title}
              onChange={(item)=>{
                let _commandsList=command.list
                for (let i = 0; i < _commandsList.length; i++) {
                  if(_commandsList[i]._id===_command._id){
                    _commandsList[i].title=item.target.value
                    break
                  }
                }
                _command=command
                setCommand({...command,list:_commandsList})
                _command["list"]=_commandsList
                onTypeCommand(_command);
              }}
              size='md'
            />
          </GridItem>
          <GridItem colSpan={6}>
            
            <InputGroup size='md'>
              <Input
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                mb='9px'
                fontWeight='500'
                placeholder={t("command")}
                value={_command.command}
                onChange={(item)=>{
                  let _commandsList=command.list
                  for (let i = 0; i < _commandsList.length; i++) {
                    if(_commandsList[i]._id===_command._id){
                      _commandsList[i].command=item.target.value
                      break
                    }
                  }
                  _command=command
                  setCommand({...command,list:_commandsList})
                  _command["list"]=_commandsList
                  onTypeCommand(_command);
                }}
                size='md'
              />
              {_command.command?<InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={()=>{
                   Utils.requestPost("sendMqttCommand",{topic:command.topic,command:_command.command})
                  }}>Test</Button>
              </InputRightElement>:null}
            </InputGroup>
          </GridItem>
          <GridItem colSpan={1}>
            {index===command.list.length-1?<Button colorScheme='green' size={"sm"}
              onClick={()=>{
                setCommand({...command,list:[...command.list,{_id:(new Date()).getTime()}]})
              }}> + </Button>:<Button colorScheme='red' size={"sm"}
              onClick={()=>{
                setCommand({...command,list:R.filter(o => o._id !== _command._id, command.list)})
              }}> x </Button>}
          </GridItem>
        </Grid>
      ))}</>
    }

    return (
        <Grid
            templateColumns='repeat(4, 1fr)'
            gap={3}
            mb='10px'
        >
          <GridItem colSpan={4}>
            <FormControl>
              <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='0px'>
                  {t("Communication protocol")}
              </FormLabel>
              <Select 
                ms={{ base: "0px", md: "0px" }}
                value={command.protocol}
                defaultValue={command.protocol}
                onChange={(itm)=>{
                  _command=command
                  //_command.list=[]
                  setCommand({...command,"protocol":itm.value });
                  _command["protocol"]=itm.value
                  onTypeCommand(_command);
                }}
                options={[
                  {value:'undefined',label:t("undefined")},
                  {value:'mqtt',label:"MQTT"}
                ]}
                placeholder={t("Communication protocol")}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
              {command.protocol==="mqtt"?showMQTTview():null}
          </GridItem>
        </Grid>
    );
  }
  
  export default withTranslation()(TabCommads);