
// Chakra imports
import { Box, Grid,Flex} from '@chakra-ui/react'

import {useDisclosure} from "@chakra-ui/hooks";
import ComplexTable from "./components/ComplexTable";
import React from "react";
import { useHistory } from "react-router-dom";
import * as R from 'ramda';
// Redux
import { useSelector,useDispatch } from 'react-redux';
import {Post} from './queries'

import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { withTranslation } from 'react-i18next';
import ModalForm from './components/ModalForm';
import ModalConfig from './components/ModalConfig';
import Constant from 'utils/Constant';

function Users(props) {
  const {t}=props;
  let history = useHistory();
  var response;

  const [values, setValues] = React.useState({
    idUser: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    role: {value:"user",label:t("user")},
    showPassword: false,
    isReadOnly:false,
    isLoading:false,
    isLoadingProfile:false,
    myDevicesList:[]
  });
  const [users, setUsers] = React.useState([]);
  const [usersAll, setUsersAll] = React.useState([]);
  const [configData, setConfigData] = React.useState({});
  
  const [listProfile, setListProfile] = React.useState([]);
  const [listProfileAll, setListProfileAll] = React.useState([]);
  const [devices, setDevices] = React.useState([]);
  const myInfos = useSelector(state => state.user.infos);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOpenConfig, setIsOpenConfig] = React.useState(false);
  

  const columnsData = [
    {
      Header: "Email",
      accessor: "email",
    },
    /*{
      Header: t("firstname"),
      accessor: "firstname",
    },*/
    {
      Header: t("lastname"),
      accessor: "lastname",
    },
    {
      Header: t("last_visit"),
      accessor: "lastVisit",
    },
    {
      Header: "Action",
      accessor: "_id",
    },
  ];
  const columnsProfilData=[
    {
      Header: "Action",
      accessor: "_id",
    },
    {
      Header: t("title"),
      accessor: "title",
    }]

  React.useEffect(() => {  
    (async function() {
      document.title = t("user_list")+" - NeoLec";
      try{ 
        if(myInfos.token){ 
          getUsers()
          getProfile()
          getDevices()
        }else history.push('/')
      }catch (e) {
        console.error(e);
      }
    })();
    return () => {}
  },[]);

  const getUsers= async ()=>{
    setValues({ ...values,"isLoading":true})
    response=await Post.getUsers(myInfos.token)
    setValues({ ...values,"isLoading":false})
    if(response.hasOwnProperty("error")) NotificationManager.error(response.error, 'Message');
    else{
      if(myInfos.role==="admin"){
        setUsers(response)
        setUsersAll(response)
      }
      else{
        var _responseData=[]
        response.forEach(elt => {
          if(elt.email===myInfos.email){
            _responseData.push(elt)
            setUsers(_responseData)
            setUsersAll(_responseData)
          }
        });
      }
    }
  }
  const getProfile= async ()=>{
    setValues({ ...values,"isLoadingProfile":true})
    response=await Post.requestPost("listProfile",{token:myInfos.token})
    setValues({ ...values,"isLoadingProfile":false})
    if(response.hasOwnProperty("error")) NotificationManager.error(response.error, 'Message');
    else{
      if(myInfos.role==="admin"){
        for (let i = 0; i < response.length; i++) {
          response[i].value=response[i]._id;
          response[i].label=response[i].title;
        }
        setListProfile(response)
        setListProfileAll(response)
      }
    }
  }
  const getDevices= async ()=>{
    setValues({ ...values,"isLoading":true})
    response=await Post.getDevices(myInfos.token)
    setValues({ ...values,"isLoading":false})
    if(response.hasOwnProperty("error")) NotificationManager.error(response.error, 'Message');
    else{
      var _devices=[]
      response.forEach(dvc => {
        _devices.push({label:dvc.title,value:dvc.id})
      });
      setDevices(_devices)
    }
  }
  const deleteRow =(row)=> {
    if(myInfos._id!==row._id){
      fetch(Constant.SERVER_URL+'/deleteUser', {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          token:myInfos.token,
          id:row._id,
          lng:t("names")
        })
      })
      .then(response => response.json())
      .then(responseData=>{
        if(responseData.hasOwnProperty("error")){
          if(responseData.error===200){
            NotificationManager.success(t("successful_operation"), 'Message');
            setUsers(R.filter(o => o._id !== row._id, users)) 
            setUsersAll(R.filter(o => o._id !== row._id, usersAll)) 
            if(myInfos.email===row.email){
              localStorage.removeItem("myInfos");
              dispatch({type:'SET_INFOS',payload:{}});
              history.push("/login");
            }
          }else NotificationManager.error(responseData.msg, 'Message');
        }else  NotificationManager.error(t("error_occurred"), 'Message'); 
      });
    }else  NotificationManager.error(t("unable_delete"), 'Message');
  };

  const editRow =(row)=> {
    var _devices=[]
    if(row.hasOwnProperty("devices")){ 
      row.devices.forEach(device => {
        _devices.push({
          label: device.name,
          value: device.id
        })
      });
    }
    setValues({ ...values, 
      role: {value:row.role,label:t(row.role)}, 
      lastname: row.lastname,
      firstname: row.firstname,
      email: row.email,
      idUser: row._id,
      phone: row.phone,
      profile: row.profile,
      isReadOnly: row.isReadOnly,
      myDevicesList: _devices
    });

    onOpen()
  }
  const profileDuplicate =(row={})=> {
    row._id=""
    row.title=row.title+" COPY"
    setConfigData(row)
    setIsOpenConfig(true)
  }
  const profileManagement =(row={})=> {
    setConfigData(row)
    setIsOpenConfig(true)
  }
  const onConfig =(row)=> {
    
  }

  const deleteConfigRow =(row)=> {
    if(myInfos._id!==row._id){
      fetch(Constant.SERVER_URL+'/deleteConfig', {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          token:myInfos.token,
          id:row._id
        })
      })
      .then(response => response.json())
      .then(responseData=>{
        if(responseData.hasOwnProperty("error")){
          if(responseData.error===200){
            NotificationManager.success(t("successful_operation"), 'Message');
            setListProfile(R.filter(o => o._id !== row._id, listProfile)) 
            setListProfileAll(R.filter(o => o._id !== row._id, listProfileAll)) 
          }else NotificationManager.error(responseData.msg, 'Message');
        }else  NotificationManager.error(t("error_occurred"), 'Message'); 
      });
    }else  NotificationManager.error(t("unable_delete"), 'Message');
  };
  
  return (
    
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Grid
          mb='20px'
          gridTemplateColumns={myInfos.role==="admin"?{ sm: "repeat(2, 1fr)",xl: "repeat(2, 1fr)" }:{}}
          gap={{ base: "20px", xl: "20px" }}
          display={{ base: "block", xl: "grid" }}>
          <Flex>
              <LoadingOverlay
                active={values.isLoading}
                spinner
                text={t("loading")}
              >
                <ComplexTable
                  title={t("user_list")}
                  columnsData={columnsData}
                  tableData={users}
                  onSearch={(text)=>{
                    if(text.length===0) setUsers(usersAll)
                    else setUsers(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), usersAll)) 
                  }}
                  onDetele={(row)=>{
                    NotificationManager.error(t("click_to_confirm"), t("deleting"), 5000, () => {
                      deleteRow(row)
                    });
                  }}
                  onModify={(row)=>{ editRow(row) }}
                  onConfig={(row)=>{ onConfig(row) }}
                  onOpen={() => {
                    setValues({...values,
                      idUser: "",
                      firstname: "",
                      lastname: "",
                      phone: "",
                      email: "",
                      password: "",
                      role: {value:"user",label:t("user")},
                      showPassword: false,
                      isLoading:false,
                      isReadOnly: false,
                      myDevicesList:[]
                    });
                    onOpen()
                  }}
                />
              </LoadingOverlay>
          </Flex> 
          <LoadingOverlay
            active={values.isLoadingProfile}
            spinner
            text={t("loading")}
          >
            {myInfos.role==="admin"?<ComplexTable
              title={t("profile_management")}
              columnsData={columnsProfilData}
              tableData={listProfile}
              /*onSearch={(text)=>{
                if(text.length===0) setListProfile(listProfileAll)
                else setListProfile(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), listProfileAll)) 
              }}*/
              onDetele={(row)=>{
                NotificationManager.error(t("click_to_confirm"), t("deleting"), 5000, () => {
                  deleteConfigRow(row)
                });
              }}
              onModify={(row)=>{ profileManagement(row) }}
              onDuplicate={(row)=>{ profileDuplicate(row) }}
              onOpen={() => {profileManagement()}}
            />:null}
          </LoadingOverlay>
        </Grid> 

        {isOpen?<ModalForm
          isAdmin={myInfos.role==="admin"}
          isOpen={isOpen} 
          onClose={onClose}
          data={values}
          users={users}
          token={myInfos.token}
          devices={devices}
          profiles={listProfile}
          setUsers={(_userList)=>{
            setUsers(_userList)
            setUsersAll(_userList)
          }}
          showMsg={(msg)=>{
            NotificationManager.success(msg, 'Message');
          }}
        />:null}
        {isOpenConfig?<ModalConfig
            isOpen={true}
            data={configData}
            token={myInfos.token}
            onClose={()=>setIsOpenConfig(false)}
            devices={devices}
            showMsg={(msg,state)=>{
              if(state==="error") NotificationManager.error(msg, 'Message');
              else NotificationManager.success(msg, 'Message');
            }}
          />:null}
        <NotificationContainer/>
      </Box>
  );
}
export default withTranslation()(Users);