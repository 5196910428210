// Chakra imports
import {
  Box,
  FormLabel,
  Text,
  Tooltip,
  useColorModeValue,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark,
  Checkbox,Stack,HStack,
  NumberInput,NumberInputField,NumberInputStepper,
  NumberIncrementStepper,NumberDecrementStepper
} from "@chakra-ui/react";
import {MdSensors} from "react-icons/md";
import { withTranslation } from 'react-i18next';
import PlaningLine from "views/admin/monitoring_control/components/PlaningLine";
// Custom components
import React,{useEffect,useState} from "react";

function Default(props) {
  const {
    t,
    id,
    sensor,
    isSubChecked,
    values,
    onChangeField,
    onChangeRange,
    onChangeIsInRange,
    desc,
    textWidth,
    reversed,
    ...rest
  } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const labelStyles = {mt: '2', ml: '-2.5', fontSize: 'sm'}
  const MIN_ALERT=sensor.alert_min?sensor.alert_min:(sensor.val_min+Number((sensor.val_max-sensor.val_min)*(1/4)))
  const MAX_ALERT=sensor.alert_max?sensor.alert_min:(sensor.val_min+Number((sensor.val_max-sensor.val_min)*(3/4)))

  const [showLeftTooltip, setShowLeftTooltip] = useState(false)
  const [showRightTooltip, setShowRightTooltip] = useState(false)

  const [isInRange, setIsInRange] = useState(values.isInRange)
  
  useEffect(async () => { 
    if(!values.alert_min)onChangeRange([MIN_ALERT,MAX_ALERT])

    return () => {}
  },[]);

  return (
    <Box w='100%' fontWeight='500' {...rest}>
        <Stack>
          <FormLabel
            ms='15px'
            htmlFor={id}
            _hover={{ cursor: "pointer" }}
            direction='column'
            mb='0px'
            w="75%">
              <Text
                color={textColorPrimary}
                mt={-0.1}
                fontSize='sm'
                textColor='secondaryGray.900'
                fontWeight='500'>
                {desc}
              </Text>
          </FormLabel>
        </Stack>

        {sensor.type==="digital_sensor"?null:<Stack ms='15px'>
            {values.hasOwnProperty("alert_min")?<RangeSlider 
              mt='2' 
              mb='15'
              aria-label={['min', 'max']} 
              value={[values.alert_min ,values.alert_max]}
              min={sensor.val_min} 
              max={sensor.val_max}
              onChange={(val) => {
                setShowRightTooltip(true);
                setShowLeftTooltip(true)
                onChangeRange(val)
              }}
              onChangeEnd={() => {
                setShowRightTooltip(false);
                setShowLeftTooltip(false)
              }}
            >
              <RangeSliderMark value={sensor.val_min}   {...labelStyles}>{sensor.val_min}</RangeSliderMark>
              <RangeSliderMark value={sensor.val_min+Number((sensor.val_max-sensor.val_min)*(1/4))}  {...labelStyles}>{sensor.val_min+Number((sensor.val_max-sensor.val_min)*(1/4))}</RangeSliderMark>
              <RangeSliderMark value={sensor.val_min+Number((sensor.val_max-sensor.val_min)*(1/2))}  {...labelStyles}>{sensor.val_min+Number((sensor.val_max-sensor.val_min)*(1/2))}</RangeSliderMark>
              <RangeSliderMark value={sensor.val_min+Number((sensor.val_max-sensor.val_min)*(3/4))}  {...labelStyles}>{sensor.val_min+Number((sensor.val_max-sensor.val_min)*(3/4))}</RangeSliderMark>
              <RangeSliderMark value={sensor.val_max} {...labelStyles}>{sensor.val_max}</RangeSliderMark>
              
              <RangeSliderTrack bg={isInRange?'red.100':'primary.main'}>
                <RangeSliderFilledTrack bg={isInRange?'primary.main':'red.100'} />
              </RangeSliderTrack>

              <Tooltip
                hasArrow
                bg='teal.500'
                color='white'
                placement='top'
                isOpen={showLeftTooltip}
                label={values.alert_min}
              >
                <RangeSliderThumb boxSize={6} index={0}>
                  <Box color='secondary.main' as={MdSensors} />
                </RangeSliderThumb>
              </Tooltip>

              <Tooltip
                hasArrow
                bg='teal.500'
                color='white'
                placement='top'
                isOpen={showRightTooltip}
                label={values.alert_max}
              >
                <RangeSliderThumb boxSize={6} index={1}>
                  <Box color='secondary.main' as={MdSensors} />
                </RangeSliderThumb>
              </Tooltip>
            </RangeSlider>:null}
          
            <Checkbox 
              size='sm' 
              id="idCheckbox"
              name="name"
              color='secondaryGray.600'
              colorScheme='green'
              isChecked={isInRange}
              onChange={(state)=>{
                setIsInRange(state.target.checked)
                onChangeIsInRange(state.target.checked)
              }}
            >
              {isInRange?t("value_in_range"):t("value_out_range")}
            </Checkbox>
          </Stack>
        }
        {values.alertType.value==="type_A2"||values.alertType.value==="type_D2"?<HStack 
          mt='4'
        >
          {values.alertType.value==="type_D2"?<>
            <Text
              color={textColorPrimary}
              fontSize='sm'
              textColor='secondaryGray.900'
              fontWeight='500'>
              {t("value")}
            </Text>
            <NumberInput 
              min={0}
              max={1}
              maxW='60px'
              onChange={(val) => onChangeField("alertValue",Number(val))}
              value={values.alertValue}
            >
              <NumberInputField />
            </NumberInput>
          </>:null}
          <Text
            color={textColorPrimary}
            fontSize='sm'
            textColor='secondaryGray.900'
            fontWeight='500'>
            {t("duration")}
          </Text>
          <NumberInput 
            min={1}
            onChange={(val) => onChangeField("alertDuration",Number(val))}
            value={values.alertDuration}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text
            color={textColorPrimary}
            mt={-0.1}
            fontSize='sm'
            textColor='secondaryGray.900'
            fontWeight='500'>
            {"min"}
          </Text>
        </HStack>:null}

        {values.alertType.value==="type_A3"||values.alertType.value==="type_D3"?<Box 
          mt='4'
        >
          {values.alertType.value==="type_D3"?<HStack>
            <Text
              color={textColorPrimary}
              fontSize='sm'
              textColor='secondaryGray.900'
              fontWeight='500'>
              {t("value")}
            </Text>
            <NumberInput 
              min={0}
              max={1}
              maxW='60px'
              onChange={(val) => onChangeField("alertValue",Number(val))}
              value={values.alertValue}
            >
              <NumberInputField />
            </NumberInput>
          </HStack>:null}
          <PlaningLine
            title={"time slot"}
            programList={values.program?values.program:{}}
            mainSensors={[sensor]}
            secondSensors={[]}
            onUpdateProgramList={(list)=>{onChangeField("program",list) }}
            errorMsg={(text)=>{console.log(text)}}
          />
        </Box>:null}
    </Box>
  );
}
export default withTranslation()(Default);