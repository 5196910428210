
import Global from "utils/Constant";

export const listDevices = (token) => {
  return fetch(Global.SERVER_URL+'/listDevices', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token})
    })
    .then(response => response.json())
    .then(listUsers=>{
      return listUsers
    });
};
export const getDevices= (token) => {
  return fetch(Global.SERVER_URL+'/getDevices', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token})
    })
    .then(response => response.json())
    .then(listUsers=>{
      return listUsers
    });
};
export const requestPost= (endPoint,data) => {
  return fetch(Global.SERVER_URL+'/'+endPoint, {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(datas=>{return datas });
};