import {
  Flex,Box,Select,Text,
  useColorModeValue,Input
} from '@chakra-ui/react'

import { withTranslation } from 'react-i18next';
import moment from "moment"; 
import React from "react";

function PeriodLine(props) {
  const {t,onChange,period}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");

  const [startDate, setStartDate] = React.useState( moment().add(-1, 'years').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [type, setType] = React.useState("hourly");

  
  React.useEffect(() => { 
    if(period.hasOwnProperty("type")) setType(period.type)
    if(period.hasOwnProperty("startDate")) setStartDate( moment(period.startDate).format('YYYY-MM-DD'))
    if(period.hasOwnProperty("endDate")) setEndDate( moment(period.endDate).format('YYYY-MM-DD'))
    
    return () => {}
  },[]);
  
  return (
    <Flex
      justifyContent={"space-between"}
      align={"center"}
      pb={3}
    >
      <Flex>
        <Box>
          <Text color={textColor}>{t("Start Date")}</Text>
          <Input
            placeholder="Start Date"
            size="md"
            type="date"
            color={textColor}
            value={startDate}
            onChange={(val)=>{
              onChange(moment(val.target.value, 'YYYY-MM-DD').toDate(),endDate,type)
              setStartDate(val.target.value)
            }}
          />
        </Box>
        <Box ml={4}>
          <Text color={textColor}>{t("End Date")}</Text>
          <Input
            placeholder="End Date"
            size="md"
            type="date"
            color={textColor}
            value={endDate}
            onChange={(val)=>{
              onChange(startDate,moment(val.target.value, 'YYYY-MM-DD').toDate(),type)
              setEndDate(val.target.value)
            }}
          />
        </Box>
      </Flex>
      <Box>
        <Text color={textColor}>{t("Type")}</Text>
        <Select
            w={150}
            value={type} 
            onChange={(val)=>{
              onChange(startDate,endDate,val.target.value)
              setType(val.target.value)
            }}
        >
            <option value='hourly'>{t("hourly")}</option>
            <option value='power'>{t("power")}</option>
        </Select>
      </Box>
  </Flex>
  );
}

export default withTranslation()(PeriodLine);