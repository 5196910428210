
import Card from "components/card/Card.js";
import Lottie from 'react-lottie';
import React, {  useEffect } from 'react';
import smartHomeLottie  from 'assets/lotties/smart_home.json'

function AnimationLoading(props) {
    const { ...rest } = props;

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: props.lottie,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return (
        <Card
            justifyContent='center'
            align='center'
            direction='column'
            w='100%'
            mb='0px'
            {...rest}>
                
            <Lottie options={defaultOptions}
              height={200}
              width={200} 
              />
        </Card>
    );}
export default AnimationLoading;