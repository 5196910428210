
import { withTranslation } from 'react-i18next';
import React from "react";
import {
    Button,
    Text,Input,
    FormControl,FormLabel,
    Modal,
    useColorModeValue,
    FormErrorMessage,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton} from '@chakra-ui/react'
import {validateEmail} from "utils/Validators";
import {Post} from 'utils/queries'
import {NotificationContainer, NotificationManager} from 'react-notifications';

import "./styles.css"

function Index(props) {
  const {t,isOpen,onClose} = props;
  var [infos, setInfos] =  React.useState({
    email:"",
    isLoading:false
  });
  var [errorEmail, setErrorEmail] =  React.useState("");

  const textColor = useColorModeValue("navy.700", "white");

  const Overlay = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='blur(10px)'
    />
  )
  

  const handleChange = (prop) => (event) => {
    setInfos({ ...infos, [prop]: event.target.value });
  };
  const handleSubmit = async (e) => { 
    let error,noFound=true;
    error= await validateEmail(infos.email)
    if(error.email)setErrorEmail("")
    else {
      setErrorEmail(error);
      noFound=false;
    }
    if(noFound){
        setInfos({ ...infos, isLoading:true});
        var responseData=await Post.requestPost("resetPassword",{email:infos.email,"lng":t("names")})
        setInfos({ ...infos, isLoading:false});
        if(responseData.hasOwnProperty("error")){
          if(responseData.error===200){ 
              setInfos({ ...infos, email:""});
              onClose();
              NotificationManager.success(t("successful_reset"), 'Message');
          }else if(responseData.error===404) NotificationManager.error(t("account_not_found"), 'Message');
        }else NotificationManager.error(t("unknown_error"), 'Message');
        
    };
    e.preventDefault();
  }
  
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <Overlay/>
      <ModalContent>
        <ModalHeader>{t("forgot_password")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{t("new_pwd_mail")}</Text>
          <FormControl isRequired isInvalid={errorEmail!==""}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              {t("email")}
            </FormLabel>
            <FormErrorMessage>{errorEmail}</FormErrorMessage>
            <Input
              id="email"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='user@neolec.ch'
              mb='24px'
              fontWeight='500'
              value={infos.email}
              onChange={handleChange('email')}
              size='lg'
              isRequired={true}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='whatsapp' variant='outline' 
          isLoading={infos.isLoading}
          onClick={handleSubmit}
          >{t("reset")}</Button>
        </ModalFooter>
      </ModalContent>
            <NotificationContainer/>
    </Modal>
  );
}
export default withTranslation()(Index);