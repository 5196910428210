import { withTranslation } from 'react-i18next';
import React from "react";
// Chakra imports
import {Box,
  Text,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "../../../../components/card/MiniStatistics";

import 'rsuite/dist/rsuite.min.css';


function Pane24(props) {
  const {t,myInfos,lastStateMsg} = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textBgColor = useColorModeValue("#ffffff", "navy.800");

  
  const showAllPane=()=>{
    for (let i = 0; i < myInfos.myViews.dashboard.monitoring_24h.length; i++) {

      if(myInfos.myViews.dashboard.monitoring_24h[i].hasOwnProperty("dataLast24h")){
        if(myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.hasOwnProperty("y")){
          if(lastStateMsg.message){
            if(lastStateMsg.message.hasOwnProperty(myInfos.myViews.dashboard.monitoring_24h[i].id)){
              if(((new Date(lastStateMsg.last_message)).getTime()-(new Date(myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.x[myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.x.length-1])).getTime())>45*1000){
                myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.x.shift();
                myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.y.shift();
                
                myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.y.push(lastStateMsg.message[myInfos.myViews.dashboard.monitoring_24h[i].id])
                myInfos.myViews.dashboard.monitoring_24h[i].dataLast24h.x.push(lastStateMsg.last_message)
              }
            }
          }
        }
      }

    }

      return myInfos.myViews.dashboard.monitoring_24h.map((item)=>
       <MiniStatistics
          key={item.id}
          value={lastStateMsg?lastStateMsg.hasOwnProperty("message")?lastStateMsg.message[item.id]?lastStateMsg.message[item.id]:0:"--":"---"}
          info={item}
          data={item.hasOwnProperty("dataLast24h")?item.dataLast24h:[] }
        />
      )
  }

  const show24Pane=()=>{
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard")){
        if(myInfos.myViews.dashboard.hasOwnProperty("monitoring_24h")){
          return(
            <Box>
              <Text
                w="100%"
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                borderRadius= "20px"
                mb= "15px"
                p="5px"
                bg={textBgColor}>
                {t("data24")}
              </Text>
              <SimpleGrid columns={{base:1,md:hasAnimationPane()?2:3,xl:hasAnimationPane()?2:3}} gap='10px'mb='10px'>
                {showAllPane()}
              </SimpleGrid>
            </Box>
          )
        }
      }
    return null
  }

  const hasAnimationPane=()=>{
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard"))
        if(myInfos.myViews.dashboard.svg==="main_animation")return true
    return false
  }

  return (show24Pane());
}

export default withTranslation()(Pane24);