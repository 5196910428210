import React from "react";

// Chakra imports
import { Flex, useColorModeValue,Image } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import Dark from "assets/img/logo/dark.png";
import Light from "assets/img/logo/light.png";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Image 
        src={logoColor==="navy.700"?Dark:Light}
        h={{ base: "60px", xl: "60px", "2xl": "98px" }}
        w={{ base: "165px", xl: "165px", "2xl": "165px" }}
        my='12px'
        me='10px' />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
