import React from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';



function MultiSelect(props) {
    const {t,returnSelectedValues,listGraph} = props;
    var [defaultSerie, setDefaultSerie] = React.useState([]);
    
    React.useEffect(() => {  
      var _defaultSerie=[]
      for (let i = 0; i < listGraph.length; i++) {
        props.series.forEach(serie => {
          if(listGraph[i].value===serie){
            _defaultSerie.push(listGraph[i])
          }
        });
      }
      setDefaultSerie(_defaultSerie)
      props.returnSelectedValues(_defaultSerie);
      
      return () => {}
    },[]);


    return(
      <div>
        <Select
          defaultValue={defaultSerie}
          isMulti
          isSearchable={true}
          closeMenuOnSelect={false}
          options={listGraph}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={t("select_parameter")}
          onChange={(val)=>{
            returnSelectedValues(val);
          }}
        />
        </div>
    );
}
export default withTranslation()(MultiSelect);