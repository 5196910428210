// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/hooks";
import ComplexTable from "../users/components/ComplexTable";
import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useSelector} from 'react-redux';

import {Post} from './queries'
import ModalForm from './components/ModalForm';

import * as R from 'ramda';
import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { withTranslation } from 'react-i18next';

function Device(props) {
  const {t}=props;
  let history = useHistory();
  var response;

  var [devicesList, setDevicesList] = React.useState([]);
  var [devicesListAll, setDevicesListAll] = React.useState([]);

  const [values, setValues] = React.useState({
    id: "",
    title:"",
    idDevice:"",
    all_devices:[],
    isLoading:false
  });
  const myInfos = useSelector(state => state.user.infos);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const columnsData = [
    {
      Header: t("title"),
      accessor: "title",
    },
    {
      Header: t("last_message"),
      accessor: "last_message",
    },
    {
      Header: "Action",
      accessor: "id",
    },
  ];

  React.useEffect(() => {  
    (async function() {
      document.title = t("device")+"s - NeoLec";
      try{ 
        if(myInfos.token){
          listDevices()
          getDevices()
        }else history.push('/')
      }catch (e) {console.error(e);}
    })();
    return () => {}
  },[]);

  const listDevices= async ()=>{
    setValues({ ...values,"isLoading":true})
    response=await Post.listDevices(myInfos.token)
    setValues({ ...values,"isLoading":false})

    if(response.hasOwnProperty("error")){
      NotificationManager.error(response.error, 'Message');
    }else{
      response.sort(function compare(a, b) {return new Date(a.last_message)-new Date(b.last_message);}).reverse();
      if(myInfos.role==="admin"){ 
        setDevicesList(response)
        setDevicesListAll(response)
      }
      else{
        var _responseData=[]
        response.forEach(elt => {
            _responseData.push(elt)
        });
        setDevicesList(_responseData)
        setDevicesListAll(_responseData)
      }
    }
  }

  const getDevices= async ()=>{
    setValues({ ...values,"isLoading":true})
    response=await Post.requestPost("getDevices",{token:myInfos.token})
    setValues({ ...values,"isLoading":false})
    
    if(Array.isArray(response)){
      var all_devices=[]
      response.forEach(elt => {
        all_devices.push({
          label:elt.deviceId+" ("+elt.connectionState+")",
          value:elt.deviceId
        })
      });
      setValues({ ...values,all_devices})
    }
  }
  
  const deleteRow =async (row)=> {
    response=await Post.requestPost("deleteDevice",{token:myInfos.token,id:row._id})
    if(response.hasOwnProperty("error")){
      if(response.error===200){
        setDevicesList(R.filter(o => o._id !== row._id, devicesList))  
        setDevicesListAll(R.filter(o => o._id !== row._id, devicesListAll)) 
      }else NotificationManager.error(response.msg, 'Message');
    }else  NotificationManager.error(t("error_occurred"), 'Message'); 
  };

  const editRow =(row)=> {
    setValues({ ...values, 
      idDevice: row._id, 
      id: row.id,
      device:{label:row.id, value: row.id},
      title: row.title,
      message: row.message,
      last_message: row.last_message,
      forecast_id:row.hasOwnProperty("forecast_id")?row.forecast_id :"",
      rate:row.hasOwnProperty("rate")?row.rate :""
    });
    onOpen()
  }

  // Chakra Color Mode
  return (
    <LoadingOverlay
      active={values.isLoading}
      spinner
      text={t("loading")}
    >
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "220px", xl: "220px" }}>
        <ComplexTable
          title={''}
          columnsData={columnsData}
          tableData={devicesList}
          onSearch={(text)=>{
            if(text.length===0) setDevicesList(devicesListAll)
            else setDevicesList(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), devicesListAll)) 
          }}
          onDetele={(row)=>{
            NotificationManager.error(t("click_to_confirm"), t("deleting"), 5000, () => {
              deleteRow(row)
            });
          }}
          onModify={(row)=>{ 
            editRow(row) 
          }}
          onOpen={() => {
            setValues({...values,
              id: "",
              title:"",
              idDevice:"",
              device:"",
              forecast_id:"",
              rate:"",
              message: "",
              last_message: "",
              //all_devices:[],
              isLoading:false
            })
            onOpen()
          }}
        />
      </SimpleGrid>
        {values.all_devices.length>0?<ModalForm
          isOpen={isOpen} 
          onClose={onClose}
          token={myInfos.token}
          role={myInfos.role}
          data={values}
          devices={values.all_devices}
          addDevice={(itm)=>{
            response=devicesListAll
            if(R.filter(o => o._id === itm._id, response).length>0){
              for (let i = 0; i < response.length; i++) 
                if(response[i]._id === itm._id){
                  response[i]=itm
                  break
                }
            }else response.unshift(itm)

            setDevicesList(response)
            setDevicesListAll(response)
          }}
          showMsg={(msg)=>{NotificationManager.success(msg, 'Message'); }}
          showErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message'); }}
        />:null}
      <NotificationContainer/>
    </Box>
    </LoadingOverlay>
  );
}
export default withTranslation()(Device);