import {
  Flex,Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,

  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { withTranslation } from 'react-i18next';
import React from "react";

function ModalTitle(props) {
  const {t,isOpen, onClose,getTitle,label}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const [title, setTitle] = React.useState("");

  
  React.useEffect(() => { 
    setTitle(label)
    return () => {}
  },[label]);

  const handleSubmit = async (e) => { 
    e.preventDefault();
    getTitle(title)
    onClose()
  }
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent size='md'>
        <ModalHeader>{t("Modification")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired ml='10px' >
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("title")}
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={t("title")}
              mb='9px'
              fontWeight='500'
              value={title}
              onChange={(val)=>{setTitle(val.target.value)}}
              size='md'
            />
          </FormControl>
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            {title!==""?<Button  
              width='140px' 
              colorScheme='green'  
              onClick={handleSubmit}
            >
              {t("update")}
            </Button>:<div/>}
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                setTitle("")
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalTitle);