import {
  Flex,Button,Box,
  Modal,SimpleGrid,
  ModalOverlay,FormControl,FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,Divider,AbsoluteCenter,
  ModalBody,Input,
  ModalCloseButton,NumberInputField,NumberInput,
  useColorModeValue,Text
} from '@chakra-ui/react'
import Switch from "react-switch";
import Select from 'react-select';

// Redux
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React from "react";
import SwitchField from "./SwitchField";

function ModalAlertConfig(props) {
  const {t,sensorsList,isOpen, onClose,onSubmit,showErrorMsg,alert}=props;
  var _selectSensor
  // Chakra color mode
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const myInfos = useSelector(state => state.user.infos);

  const [values, setValues] = React.useState({
    alertTitle:"",
    alertType:{value:"type_A1",label:t("type_A1")},
    alertValue:1,
    alertDuration:15,
    daily_max:3,
    isEnable:true,
    delay:15,
    address_mail:myInfos.email
  });
  const [selectSensor, setSelectSensor] = React.useState({});
  
  const typeAlertDigital=[
    {value:"type_D1",label:t("type_D1")},
    {value:"type_D2",label:t("type_D2")},
    {value:"type_D3",label:t("type_D3")}
  ]
  const typeAlertAnalog=[
    {value:"type_A1",label:t("type_A1")},
    {value:"type_A2",label:t("type_A2")},
    {value:"type_A3",label:t("type_A3")}
  ]

  React.useEffect(async () => { 
    if(alert)
      if(alert.hasOwnProperty("_id")){
        setSelectSensor(alert)
        setValues(alert.alert)
      }
    return () => {}
  },[props]);


  const handleSubmit = async (e) => { 
    e.preventDefault();
    _selectSensor=selectSensor
    _selectSensor.alert=values

    if(_selectSensor.alert.alertTitle!==""){
      if(_selectSensor.id){
        let error=null
        if(_selectSensor.alert.alertType.value==="type_A3" || _selectSensor.alert.alertType.value==="type_D3")
          if(!_selectSensor.alert.program) error=t("time slot")
        
        if(error)showErrorMsg(error)
        else  onSubmit(_selectSensor)
      }else showErrorMsg(t("sensor"))
    }else showErrorMsg(t("title"))
  }

  return (
    <Modal  closeOnOverlayClick={false} blockScrollOnMount={false} isCentered isOpen={isOpen} 
      size={"3xl"} 
      onClose={()=>{
        onClose()
      }}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent>
        <ModalHeader>{t("alert")}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Box pb={4}>
            <Box>
              <Text fontSize='md'>{t("title")}</Text>
              <Input
                size="md"
                type="text"
                onChange={(alertTitle) => 
                  setValues({...values,"alertTitle":alertTitle.target.value})
                }
                value={values.alertTitle}
              />
            </Box>

            <Flex w={"100%"}
              direction={{ md: "row", sm: "column" }}
              justifyContent={"space-between"}
              align={"center"}
              mt='3'
              mb='9px'
            >
              <Box>
                <Text fontSize='md'>{t("sensor")}</Text>
                <Select 
                  styles={{
                    control: (provided, state) => ({...provided,width: 300}),
                    menu: base => ({ ...base, zIndex: 99 }) 
                  }}  
                  ms={{ base: "0px", md: "0px" }}
                  defaultValue={selectSensor}
                  onChange={(itm)=>{
                    if(alert)
                      if(alert.hasOwnProperty("_id"))itm._id=alert._id

                    setSelectSensor(itm);
                    if(itm.type==="digital_sensor")setValues({...values,alertType:{value:"type_D1",label:t("type_D1")}})
                    else setValues({...values,alertType:{value:"type_A1",label:t("type_A1")}})
                  }}
                  getOptionLabel ={(option)=>option.title}
                  getOptionValue ={(option)=>option.id}
                  options={sensorsList}
                  placeholder={t("sensor")}
                />
              </Box>
              <Box>
                <Text fontSize='md'>Type</Text>
                <Select 
                  styles={{
                    control: (provided, state) => ({...provided,width: 300}),
                    menu: base => ({ ...base, zIndex: 99 }) 
                  }} 
                  ms={{ base: "0px", md: "0px" }}
                  value={values.alertType}
                  onChange={(itm)=>{
                    setValues({...values,alertType:itm});
                  }}
                  options={selectSensor.type==="digital_sensor"?typeAlertDigital:typeAlertAnalog}
                  placeholder={"Type"}
                />
              </Box>
              <Box>
                <Text fontSize='md'>{t("enabled")}</Text>
                <Switch
                  checked={values.isEnable}
                  onChange={(state)=>{
                    setValues({...values,"isEnable":state})
                  }}
                  height={20}
                  width={40}
                />
              </Box>
            </Flex>

            <Box mt={5}>
              {selectSensor.type==="analog_sensor"?<SwitchField
                key={selectSensor.id}
                mb='20px'
                sensor={selectSensor}
                values={values}
                desc={t(selectSensor.type) +(selectSensor.subtitle!==""?"("+selectSensor.subtitle+")":"")}
                onChangeRange={(range)=>{setValues({...values,alert_min:range[0],alert_max:range[1]})}}
                onChangeField={(field,value)=>{
                  setValues({...values,[field]:value})
                }}
                onChangeIsInRange={(state)=>{
                  setValues({...values,"isInRange":state})
                }}
              />:
              selectSensor.type==="digital_sensor"?<SwitchField
                key={selectSensor.id}
                mb='20px'
                sensor={selectSensor}
                values={values}
                desc={t(selectSensor.type) +(selectSensor.subtitle!==""?"("+selectSensor.subtitle+")":"")}
                onChangeRange={(range)=>{setValues({...values,alert_min:range[0],alert_max:range[1]})}}
                onChangeField={(field,value)=>{
                  setValues({...values,[field]:value})
                }}
                onChangeIsInRange={(state)=>{
                  setValues({...values,"isInRange":state})
                }}
              />:null}
            </Box>
          </Box>
          <Box position='relative' padding='2'>
            <Divider />
            <AbsoluteCenter bg='white' px='4'>
              Notification
            </AbsoluteCenter>
          </Box>
          <SimpleGrid columns={{base:1,md:2,xl:2}} gap='20px'>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                mb='0px'>
                {t("delay_alert")}
              </FormLabel>
              <NumberInput  min={1}
                value={values.delay}
                onChange={(val)=>setValues({...values,"delay":Number(val)})}
              >
                <NumberInputField/>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                mb='0px'>
                {t("daily_max")}
              </FormLabel>
              <NumberInput  min={1} 
                max={100}
                value={values.daily_max}
                onChange={(val)=>setValues({...values,"daily_max":Number(val)})}
              >
                <NumberInputField/>
              </NumberInput>
            </FormControl>
          </SimpleGrid>
          <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                mb='0px'>
                {t("email_addresses_notify")}
              </FormLabel>
              <Input 
                width={"100%"}
                size="md"
                type="text"
                value={values.address_mail}
                onChange={(val)=>setValues({...values,"address_mail":val.target.value})}
              />
            </FormControl>
          
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            <Button  
              width='140px' 
              colorScheme='green'  
              onClick={handleSubmit}
            >{alert.hasOwnProperty("_id")?t("modify"):t("valid")}
            </Button>
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalAlertConfig);