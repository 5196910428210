/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { withTranslation } from 'react-i18next';
import i18n from '../../assets/locales/i18n';

function Footer(props) {
  const {t}=props;
  var [language, setLanguage] = React.useState("Français");

  let textColor = useColorModeValue("gray.400", "white");
  let linkColor = useColorModeValue({ base: "gray.400", lg: props.auth?"gray.400":"primary.main" }, "white");

  const changeLanguage = (lng) => {
    if(lng==="fr")setLanguage("Français")
    else setLanguage("English")
    i18n.changeLanguage(lng);
  }

  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "center",
      }}
      justifyContent='space-around'
      px={{ base: "30px", md: "0px" }}
      pb='0px'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", lg: "0px" }}>
        {" "}
        © Neolec Copyright {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
        . Made with love by
          <Link
            mx='3px'
            color={textColor}
            href='https://www.Neolec.ch'
            target='_blank'
            fontWeight='700'>
            Neolec
          </Link>
        </Text>
      </Text>
      <List display='flex'>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            to='#'
            fontWeight='500'
            color={linkColor}
            onClick={() => language==="Français"?changeLanguage('en'):changeLanguage('fr')}
          >
            {language==="Français"?"English":"Français"}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={linkColor}
            href='https://www.neolec.ch/a-propos'>
            {t("about_us")}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={linkColor}
            href='https://www.neolec.ch/faq'>
            FAQ
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={linkColor}
            href='https://www.neolec.ch/contact'>
            Contact
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight='500'
            color={linkColor}
            href='https://www.neolec.ch/actualites'>
            {t("news")}
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
export default withTranslation()(Footer)
