import { withTranslation } from 'react-i18next';
// Chakra imports
import {Box,Flex
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import AnimationLoading from "./animation/Loading";
import MultiSelect from "./MultiSelect.js";
import { Utils } from "utils";
import Constant from "utils/Constant";

import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment"; 

import 'rsuite/dist/rsuite.min.css';
import { subDays } from 'date-fns'
import DateRangePicker from 'rsuite/DateRangePicker';
import { Post } from 'utils/queries/index.js';

import smartHomeLottie from '../../../../assets/lotties/smart_home.json'


function CustomCharts(props) {
  const {t,cur_device,myInfos} = props;
  var response,data;
  var [isLoading, setIsLoading] = React.useState(false);
  var [chartData, setChartData] = React.useState([]);
  var [listGraph, setListGraph] = React.useState([]);
  
  const _date= new Date()
  var [startChartDate, setStartChartDate] = React.useState(new Date((_date.getTime()-24*60*60*1000)));
  var [endChartDate, setEndChartDate] = React.useState(new Date(((new Date()).getTime()+(10*60*60*1000))));
  var [selectedGraph, setSelectedGraph] = React.useState([]);

  React.useEffect(async() => { 
    await updateChart(startChartDate,endChartDate)
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard"))
        if(myInfos.myViews.dashboard.hasOwnProperty("monitoring_24h")){
          response=[]
          var color='#0052CC'
          var dd=2
          var ts="."
          var ds=","
          myInfos.myViews.dashboard.monitoring_24h.forEach(element => {
            if(element.hasOwnProperty("graph")){
              if(element.graph.hasOwnProperty("cc"))color=element.graph.cc
              if(element.graph.hasOwnProperty("dd"))dd=element.graph.dd
              if(element.graph.hasOwnProperty("ts"))ts=element.graph.ts
              if(element.graph.hasOwnProperty("ds"))ds=element.graph.ds
            }
            response.push({value:element.id, label:element.title/*+" ("+element.device_id+")"*/, color,dd,ts,ds,unit:element.unit,device_id:element.device_id})
          });
          setListGraph(response)
        }
    return () => {}
  },[cur_device]);

  
  const updateChart=async (startDate,endDate)=>{
    startDate=moment(startDate).toDate()
    endDate=moment(endDate).toDate()
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard")){
        if(myInfos.myViews.dashboard.hasOwnProperty("graphs")){

          setIsLoading(true)
          setStartChartDate(startDate);
          setEndChartDate(endDate);

          let data_={
            token:myInfos.token,
            graphs:myInfos.myViews.dashboard.graphs,
            startDate,endDate
          }
          if(cur_device.hasOwnProperty("device_id")){
            data_.deviceList=cur_device.device_id
          }
          response=await Post.requestPost("getChartDatas",data_)
          setIsLoading(false)
          if(response.hasOwnProperty("error"))
            if(response.error===200){
              data=[]
              for (const chart of response.charts)
                try {
                   data.push(await getGraphInfos(chart))
                } catch (error) { } 
              setChartData(data)
            }
        }else if(myInfos.myViews.dashboard.hasOwnProperty("monitoring_24h")){
          setIsLoading(true)
          setStartChartDate(startDate);
          setEndChartDate(endDate);
          data=[{items:[]}]
          for (let i = 0; i < myInfos.myViews.dashboard.monitoring_24h.length; i++) 
            data[0].items.push(myInfos.myViews.dashboard.monitoring_24h[i]);
            
          
          for (let i = 0; i < data[0].items.length; i++) {
            data[0].items[i]={
              device_id:data[0].items[i].device_id,
              id:data[0].items[i].id,
              formula:data[0].items[i].formula
            }
          }
          response=await Post.requestPost("getChartDatas",{
            token:myInfos.token,
            graphs:data,
            startDate,endDate
          })
          
          if(response.hasOwnProperty("error")){
            if(response.error===200){
              data=[]
              for (const chart of response.charts)
                data.push(await getGraphInfos(chart))
              setChartData(data)
            }
          }
          setIsLoading(false)
        }
      }
  }

  const getGraphInfos=(_datas)=>{
    let items=_datas.items
    let titles="",dashArray=[],series=[],type='line',
    fill={ type: 'solid',opacity:1},startForecast=null,forecast_y,
    rsv=false,decimal=0,hasBound=false,val_min=0, val_max=10
    var colors=[]//["#0068b6","#75cb0f","#ffeb45","#e6e7e7","#dff2ff"]

    if(items){
      for (let element of items){
        titles+=(element.title+" ("+element.unit.trim()+"), ");
        hasBound=false

        if(element.diff_index)element.y=element.y_indx
        
        if(element.hasOwnProperty("graph")){
          if(element.graph.hasOwnProperty("cc")) colors.push(element.graph.cc)
          else colors.push("#8bdac4")

          if(element.graph.hasOwnProperty("gph_ct")){
            if(element.graph.gph_ct.value==="smooth") dashArray.push(0)
            else dashArray.push(2)
          }else dashArray.push(0)

          if(element.graph.hasOwnProperty("gph_gt")){
            type=element.graph.gph_gt.value
            if(element.graph.gph_gt.value=="win_loss"){
              type="rangeArea"
              colors.push(Constant.WIN_LOSS_2_COLOR)
            }
          }else{
            if(element.graph.hasOwnProperty("gt")) {
              type=element.graph.gt.value
              if(element.graph.gt.value=="win_loss"){
                type="rangeArea"
                colors.push(Constant.WIN_LOSS_2_COLOR)
              }
            }else type="area"
          }
          if(items.length===1)fill={type: 'solid',opacity:type==="line"?1:.5}
          
          if(element.graph.hasOwnProperty("gph_bound")){
            if(element.graph.gph_bound){
              val_min=element.val_min 
              val_max=element.val_max
              hasBound=true
            }
          }

          if(element.graph.hasOwnProperty("gph_rvs")){ 
            rsv=element.graph.gph_rvs.value
          }else rsv=false

          if(element.graph.dd)
            if(Number(decimal)<Number(element.graph.dd))
              decimal=Number(element.graph.dd)
        }else{
          colors.push("#8bdac4")
          dashArray.push(0)
        }

        //Config forecast datas
        if(element.f_y){
          if(element.hasOwnProperty("graph")){
            if(element.graph.gph_gt.value==="bar")dashArray.push(1)
            else dashArray.push(3)

            if(element.graph.hasOwnProperty("cc")) colors.push(element.graph.cc)
            else colors.push("#8bdac4")
          }else colors.push("#8bdac4")
          
          forecast_y=[]
          if(startForecast==null)startForecast=element.x[element.x.length-1]
          
          for (let i = 0; i < element.y.length; i++) 
            forecast_y.push(null)
           

          element.x.push(moment(new Date(element.x[element.x.length-1].split(".0")[0])).add(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss")+".000Z")
          
          element.y.push(0)
          forecast_y.push(0)
          forecast_y[forecast_y.length-1]=element.y[element.y.length-2]

          for (const x of element.f_x) 
            if(new Date(element.x[element.x.length-1]) < new Date(x))
              element.x.push(x)


          let percentError=element.f_y[0]/element.y[element.y.length-1>-1?element.y.length-1:0]
          if(element.f_y[0]>element.y[element.y.length-1>-1?element.y.length-1:0])
            percentError=element.y[element.y.length-1>-1?element.y.length-1:0]/element.f_y[0]
        
          //if(isFinite(percentError)) element.f_y.map(function(x) { return x*percentError}) 

          
          for (const elt of element.f_y) {
            forecast_y.push(elt)
            if(element.graph.gph_gt.value==="bar"){
              //forecast_y.push(elt*0.9)
              //forecast_y.push(elt*1.2)
              //forecast_y.push(elt*0.75)
            }
          }

          element.f_y={
            name:element.title.split(" (")[0]+" (P)",
            type,
            data: element.f_y?forecast_y.map(function(x) { return x===null?0:(Utils.getConvertVal(
              x,
              element.input_val_min,
              element.input_val_max,
              element.val_min,
              element.val_max
            ) * (rsv?-1:1))+(rsv?-0.0001:0); }):[0]
          }

          for (let i = element.y.length; i < element.x.length; i++) 
            element.y.push(null)
          
        }
        
        if(type==="rangeArea"){
          let _Y_Win_Lost_min=[],_Y_Win_Lost_max=[],i=0
          for (const elt of element.y) {
            if(elt<=Number(element.graph.th)){
              _Y_Win_Lost_min.push({
                x:element.x[i],
                y:[Number(element.graph.th),Number(Constant.FAKE_MIN===elt?element.graph.th:elt)]}
              )
            }else _Y_Win_Lost_max.push({
              x:element.x[i],
              y:[Number(element.graph.th),Number(elt)]}
            )
            i++
          }
          if(_Y_Win_Lost_max.length===0)_Y_Win_Lost_max.push({
            x:_Y_Win_Lost_min[0].x,
            y:[null,null]}
          )
          series=[
            {data:_Y_Win_Lost_min,type,name:"Down",th:Number(element.graph.th)},
            {data:_Y_Win_Lost_max,type,name:"Up",th:Number(element.graph.th)},
          ]
        }else{
          series.push({
            name: element.title.split(" (")[0]+""+(element.hasOwnProperty("forecast")?" (R)":""),
            type,
            data: element.y?element.y.map(function(x) { return x===Constant.FAKE_MIN?null:(Utils.getConvertVal(
              x,
              element.input_val_min,
              element.input_val_max,
              element.val_min,
              element.val_max
            ) * (rsv?-1:1))+(rsv?-0.0001:null); }):[0]
          })
        }
        if(element.f_y)series.push(element.f_y)
      }
      titles=titles.slice(0, -2)

      return{
        titles:_datas.hasOwnProperty("title")?(_datas.title.length>0?_datas.title:titles):titles,
        series,
        x:items.length!==0?items[0].x:[new Date()],
        fill,
        val_min,
        val_max,
        hasBound,
        dashArray,
        colors:items.length!==0?colors:[],
        stacked:_datas.hasOwnProperty("stacked")?_datas.stacked:false,
        decimal,
        startForecast
      }
    }
    return{
      titles:_datas.hasOwnProperty("title")?(_datas.title.length>0?_datas.title:titles):titles,
      series:[{name: "", data:[],type:"line"}],
      x:[new Date()],
      fill,
      val_min,
      val_max,
      hasBound,
      dashArray:[],
      colors:["black"],
      stacked:_datas.hasOwnProperty("stacked")?_datas.stacked:false,
      decimal,
      startForecast
    }
  }
  const getMin=(values)=>{
    let min=999999
    values.forEach(value => {
      if(value.data)value.data.forEach(elt => {
        try {
          if(elt)
            if(elt.hasOwnProperty("y")){
              if(elt.y[1])elt=elt.y[1]
              else elt=999999
            }
        } catch (error) {
          console.log("error",error);
        }
        if(elt<min && Number(elt).toFixed(3)!==Number(Constant.FAKE_MIN).toFixed(3))min=Number(elt)
      });
    });
    return min
  }
  const getMax=(values)=>{
    let max=0
    values.forEach(value => {
      if(value.data)
        value.data.forEach(elt => {
            try {
              if(elt)
                if(elt.hasOwnProperty("y")){
                  if(elt.y[1])elt=elt.y[1]
                  else elt=0
                }
            } catch (error) {
              console.log("error2",error);
            }
          if(elt>max)max=Number(elt)
        });
    });
    return max
  }
  const getDatas=(device_id,id)=>{
    if(chartData){
      for (let i = 0; i < chartData.length; i++) {
        for (let j = 0; j < chartData[i].items.length; j++) {
          if(chartData[i].items[j].device_id===device_id && chartData[i].items[j].id===id ){
            let datas={
              x:new Date(chartData[i].items[j].x),
              y:chartData[i].items[j].y
            }
            if(chartData[i].items[j].f_y){
              datas.f_y=chartData[i].items[j].f_y
              datas.f_x=chartData[i].items[j].f_x
            }
            return datas
          }
        };
        
      }
      return {x:[new Date()],y:[0]}
    }
  }

  const showGraph=()=>{
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard")){
        if(myInfos.myViews.dashboard.hasOwnProperty("graphs"))
          return (
            <Card
              justifyContent='center'
              align='center'
              direction='column'
              w='100%'
              mb='0px'>

              <Flex justify='space-between' ps='0px' pe='20px' pt='5px' align='center' w='100%' zIndex={1}>
                <DateRangePicker 
                  isoWeek={true}
                  placeholder={t("Last 24 hours")}
                  onOk={(value) => {
                    value[0]=new Date(new Date(value[0]).setHours(0,0,0));
                    value[1]=new Date(new Date(value[1]).setHours(23,59,58))
                    updateChart (value[0],value[1])
                  }}
                  ranges={[
                    {
                      label: t("yesterday"),
                      value:[
                        moment().subtract(1, 'days').startOf('day').toDate(),
                        moment().subtract(1, 'days').endOf('day').toDate()
                      ]
                    },
                    {
                      label: t("last_week"),
                      value: [
                        moment().subtract(1, 'week').startOf('week').toDate(),
                        moment().subtract(1, 'week').endOf('week').toDate()
                      ]
                    },
                    {
                      label: t("last_momth"),
                      value: [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate()
                      ]
                    },
                    {
                      label: t("last_year"),
                      value: [
                        moment().subtract(1, 'year').startOf('year').toDate(),
                        moment().subtract(1, 'year').endOf('year').toDate()
                      ]
                    }
                  ]}
                />
              </Flex>
              <br/>
              <Box mt='auto' w='100%' zIndex='0'>
                {isLoading?<AnimationLoading  lottie={smartHomeLottie}/>:
                  chartData.map((item) => (
                    <ReactApexChart
                      key= {item.titles}
                      type={item.series[0].type}
                      options={{
                        chart: {
                          id: item.titles,
                          //group: 'sync',
                          zoom: {enabled: false},
                          stacked: item.series[0]?item.series[0].type==="bar"?false:item.stacked:item.stacked,
                          toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                              download: true,
                              selection: true,
                              zoom: true,
                              zoomin: true,
                              zoomout: true,
                              pan: true,
                              customIcons: []
                            },
                            export: {
                              csv: {
                                filename:  "neolec",
                                columnDelimiter: ',',
                                headerCategory: t("datetime"),
                                dateFormatter(timestamp) {
                                  return moment(timestamp).format("DD/MM/YYYY HH:mm")
                                }
                              },
                              png: { filename: "neolec"}
                            }
                          },
                          animations: { enabled: false }
                        },
                        stroke: {
                          width:2,
                          curve:"straight",
                          dashArray:item.dashArray
                        },
                        colors:item.colors,
                        title: {text: item.titles},
                        yaxis:{
                          labels: {
                            style: { fontSize:"10px"},
                            formatter: function (value) {
                              return (Number(value).toFixed(item.decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\''))
                            }
                          },
                          axisBorder: {show:false},
                          axisTicks: { show: false},
                          forceNiceScale: true,
                          max:item.hasOwnProperty("hasBound")?item.hasBound===true?item.val_max:getMax(item.series):getMax(item.series),
                          min:item.hasOwnProperty("hasBound")?item.hasBound===true?item.val_min:getMin(item.series):getMin(item.series)
                        },
                        xaxis: {
                          type: 'datetime',
                          categories:item.series[0]?item.series[0].type==="rangeArea"?[]:item.x:item.x,
                          labels: {
                            style: {colors:"#000",fontSize: "9px"},
                            datetimeUTC: false,
                            datetimeFormatter: {
                              year: 'yyyy',
                              month: 'MMM \'yy',
                              day: 'dd MMM HH:00',
                              hour: 'HH:mm'
                            }
                          }
                        },
                        annotations: {
                          yaxis: [{
                            y: item.series[0]?item.series[0].type==="rangeArea"?item.series[0].th:0:0,
                            borderColor:"#000",
                            label: {
                              style: { color: '#fff'},
                              text: ''
                            }
                          }],
                          xaxis: [{
                            x: new Date(item.startForecast).getTime(),
                            borderColor:"#000",
                            label: {
                              style: {
                                color: '#fff',
                                background: "#085a97",
                                fontSize:"10px",
                                fontWeight:"bold"
                              },
                              text: t("forecast")
                            }
                          }]
                        },
                        tooltip: {
                          enabled: true,
                          fillSeriesColor: false,
                          theme: "light",
                          x: {
                              show: false,
                              format: 'dd MMM, HH:mm',
                                
                          },
                          y: {title: {formatter: (seriesName) => ""}},
                        },
                        plotOptions:{
                          bar:  /*item.series[0]?item.series[0].type==="rangeArea"?{
                            horizontal: false
                          }:{}:*/{}
                        },
                        dataLabels: {enabled: false },
                        grid: {
                          borderColor: '#e7e7e7',
                          row: {
                            colors: ['#f3f3f3', 'transparent'], 
                            opacity: 0.5
                          },
                        },
                        fill:item.fill,
                      }}
                      series={item.series}
                      width={'100%'}
                      height={200}
                    />
                    ))}
              </Box>
            </Card>)
        else if(myInfos.myViews.dashboard.hasOwnProperty("monitoring_24h"))
          return (
            <Card
              justifyContent='center'
              align='center'
              direction='column'
              w='100%'
              mb='0px'>

              <Flex justify='space-between' ps='0px' pe='20px' pt='5px' align='center' w='100%' zIndex={1}>
                <DateRangePicker 
                  isoWeek={true}
                  placeholder={t("from_midnight")}
                  onOk={(value) => {
                    updateChart (value[0],value[1])
                  }}
                  ranges={[
                    {
                      label: t("yesterday"),
                      value: [subDays(new Date(), 1), new Date()]
                    },
                    {
                      label: t("last_week"),
                      value: [subDays(new Date(), 6), new Date()]
                    },
                    {
                      label: t("last_momth"),
                      value: [subDays(new Date(), 30), new Date()]
                    },
                    {
                      label: t("last_year"),
                      value: [subDays(new Date(), 354), new Date()]
                    }
                  ]}
                />
                <MultiSelect
                  series={selectedGraph}
                  listGraph={listGraph}
                  returnSelectedValues={(val)=>{
                    for (let i = 0; i < val.length; i++) val[i].id= val[i].value;
                    setSelectedGraph(val)
                  }}
                />
              </Flex>
              <Box mt='auto' w='100%' zIndex='0'>
              {isLoading?<AnimationLoading  lottie={smartHomeLottie}/>:
                selectedGraph.map((item) => (
                  <ReactApexChart
                    key= {item.id}
                    options={{
                      chart: {
                        id: item.id,
                        //group: 'sync',
                        zoom: {enabled: false},
                        toolbar: {
                          show: true,
                          offsetX: 0,
                          offsetY: 0,
                          tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            customIcons: []
                          },
                          export: {
                            csv: {
                              filename:  "neolec",
                              columnDelimiter: ',',
                              headerCategory: t("datetime"),
                              dateFormatter(timestamp) {
                                return moment(timestamp).format("DD/MM/YYYY HH:mm")
                              }
                            },
                            png: { filename: "neolec"}
                          }
                        }
                      },
                      stroke: {
                        width: 2,
                        curve: ['smooth'],
                        dashArray: [0]
                      },
                      colors:[item.color],
                      title: {text: item.label},
                      yaxis: {
                        tickAmount: 4,
                        forceNiceScale: true,
                        floating: true,
                        labels: {
                          style: { fontSize:"10px"},
                          formatter: function (value) {
                            return (Number(value).toFixed(item.dd).toString().replace('.',item.ds).replace(/\B(?=(\d{3})+(?!\d))/g, item.ts)) //Math.abs(value)>999?Math.round(value/1000)+"k":Math.round(value);
                          }
                        },
                        axisBorder: {show: false},
                        axisTicks: { show: false}
                      },
                      xaxis: {
                        type: 'datetime',
                        categories:getDatas(item.device_id,item.id).x,
                        labels: {
                          style: {colors:"#000",fontSize: "9px"},
                          datetimeUTC: false,
                          datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM HH:00',
                            hour: 'HH:mm'
                          }
                        },
                        axisBorder: {
                          show: true,
                          color: '#ff2c00',
                          height: 20,
                          width: '100%',
                          offsetX: 0,
                          offsetY: 0
                        }
                      },
                      tooltip: {
                        enabled: true,
                        fillSeriesColor: false,
                        theme: "light",
                        x: {
                            show: false,
                            format: 'dd MMM, HH:mm',
                              
                        },
                        y: {
                            title: {
                                formatter: (seriesName) => ""
                            },
                        },
                      },
                      dataLabels: {enabled: false },
                      grid: {
                        borderColor: '#e7e7e7',
                        row: {
                          colors: ['#f3f3f3', 'transparent'], 
                          opacity: 0.5
                        },
                      },
                    }}
                    series={[{
                        name: item.label+" "+item.unit,
                        data: getDatas(item.device_id,item.id).y?getDatas(item.device_id,item.id).y.map(function(x) { return Utils.getConvertVal(
                          x,
                          item.input_val_min,
                          item.input_val_max,
                          item.val_min,
                          item.val_max
                        ) }):[]
                      }
                    ]}
                    type='line'
                    width={'100%'}
                    height={200}
                  />
                  ))}
              </Box>
            </Card>)
      
      }
    return null
  }
  return (showGraph());
}

export default withTranslation()(CustomCharts);