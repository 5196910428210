import {useEffect,useState} from "react";
import { Chart } from "react-google-charts";
import Boiler from './boiler_dark.js';
import {Box,Text} from '@chakra-ui/react';

function BoilerDark(props) {
    const max=100,min=0
    const [state, setState] = useState(props.status);

    useEffect(() => { 
        //setInterval(() => {setTemp(Math.floor(Math.random() * max));}, 4000);
        //setInterval(() => { setPower(Math.floor(Math.random() * 2000));}, 5500);
    },[]);

    return (
        <div style={{height: "300px",marginLeft:30}}>
            <div style={{position: "absolute"}}>
                <Boiler 
                    power={props.power}
                    tempExt={props.tempExt}
                    humidity={props.humidity}
                    state={state}
                    title={props.title}
                    onChange={()=>{
                        props.onChange(!state)
                        setState(!state)
                    }}
                />
            </div>
            <Box ml={30} mt={70}>
                <Chart
                    chartType="Gauge"
                    data={[["Label", "Value"],["˚C", props.temp==="--˚C"?0:parseInt(props.temp)]]}
                    options={{
                        width: 70, height: 70,
                        redFrom: max-(max*.1),
                        redTo: max,
                        yellowFrom: max-(max*.25),
                        yellowTo: max-(max*.1),
                        minorTicks: 5,
                        max,
                        min,
                        colors:["#fff","#fff"]
                    }}
                />
            </Box>
            <div style={{position: "absolute",marginTop:-23,marginLeft:57}}>
                <Text
                    bg="white"
                    fontSize='10px'
                    fontWeight='700'
                    lineHeight='100%'>
                {props.temp}
                </Text>
            </div>
        </div>
    );}
export default BoilerDark;