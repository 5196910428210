import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,Button,IconButton,
  useColorModeValue,
} from "@chakra-ui/react";

import moment from "moment"; 
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import MainMenu from "components/menu/MainMenu";
// Custom icons
import { IoEllipsisVertical } from "react-icons/io5";


// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";

import { withTranslation } from 'react-i18next';
function ColumnsTable(props) {
  const { columnsData, tableData,title,t } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;


  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandText = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  var duration;
  
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, indx) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={indx}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, indx) => {
                  let data = "";
                  if (cell.column.id === "title") {
                    data = (
                      <Text color={textColor} key={indx} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  else if (cell.column.id === "date") {
                    duration = moment.duration(moment(new Date()).diff(moment(cell.value)));
                    
                    data = (
                      <Flex align='center'>
                        <Icon
                          w='24px'
                          h='24px'
                          me='5px'
                          color={
                            duration.asDays() < 7 ? "green.500"
                              : duration.asDays() > 30 ? "red.500"
                              : duration.asDays() > 6 ? "orange.500" : null
                          }
                          as={
                            duration.asDays() < 7 ? MdCheckCircle
                              : duration.asDays() > 30 ? MdCancel
                              : duration.asDays() > 6 ? MdOutlineError
                              : null
                          }
                        />
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {moment(cell.value).format("DD MMM YY, HH:mm:ss")}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.id === "id") {
                    data = (
                      <MainMenu
                        ms='auto'
                        mb='0px'
                        icon={
                          <Icon as={IoEllipsisVertical} w='24px' h='24px' color={textColor} />
                        }
                        onDetele={()=>{props.onDetele(cell.row.original)}}
                        onModify={()=>{props.onModify(cell.row.original)}}
                      />
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Flex mt='5px' justify='center' mb='2px' align='center'>
        <Button colorScheme='teal' size='xs' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        {" "}
        <Button colorScheme='teal' size='xs' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"Previous"}
        </Button>
        {" "}
        <span>
          {" Page "}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>{" "}
        </span>
       {/*<span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px" }}
          />
          </span>*/}
        {" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <Button colorScheme='teal' size='xs' onClick={() => nextPage()} disabled={!canNextPage}>
          {"Next"}
        </Button>
        {" "}
        <Button colorScheme='teal' size='xs' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {">>"}
        </Button>
      </Flex>
    </Card>
  );
}
export default withTranslation()(ColumnsTable);