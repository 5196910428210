

import {MdPowerSettingsNew} from "react-icons/md";
import {useColorModeValue, Icon,IconButton } from "@chakra-ui/react";

export default function PowerIcon(props) {
  const {state,onChange}=props
  const ethBox = useColorModeValue("white", "navy.800");
  const redBorderColor = useColorModeValue("red.900", "red.300");
  const greenBorderColor = useColorModeValue("green.900", "green.300");

    return(
      <IconButton
        isRound={true}
        variant='solid'
        colorScheme={state?"green":"red"}
        fontSize='40px'
        borderRadius='35px'
        borderColor={state?greenBorderColor:redBorderColor}
        borderWidth='3px'
        borderStyle='solid'
        icon={<Icon color={ethBox} w='25px'  as={MdPowerSettingsNew} />}
        onClick={() => onChange()}
      />)
}