// Chakra imports
import {
  Spacer,
  Button,
  Box,
  Text,
  Select,
  Input,
  Stack
} from "@chakra-ui/react";
import { withTranslation } from 'react-i18next';
import TextInput from 'react-autocomplete-input';
// Custom components
import React,{useEffect,useState} from "react";
import { parseExpresion } from "constants/function";
//import Select from 'react-select';

function Index(props) {
  const {
    t,btnAdd,
    condition,
    sensors,
    updateField,
    btnAction,
    titleAction,
    type="",
    variables,...rest
  } = props;
  var [formula, setFormula] = useState(null);
  var [duration, setDuration] = useState(0);
  
  useEffect(async () => { 
    if(condition.formula){
      setFormula(condition.formula)
      setDuration(condition.duration)
    }
    return () => {}
  },[props]);

  return (
    <Stack direction="row" w='100%' align="center" spacing={2}>
        {type=="formula"?<Box w={"70%"}>
          <TextInput 
            trigger="$"
            className={"react-autocomplete-box"}
            maxOptions={1000}
            value={formula}
            onChange={ (value)=>{
              setFormula(value);
              updateField("formula",value)
            }}
            options={variables}
            style={{ width: "100%" }}
            aria-label={t("formula")}
            placeholder={t("see_list_available")}
          />
          <b>{!formula?"":parseExpresion(formula,sensors)[0]}</b>
        </Box>:
        <Box w={"70%"}>
          <Text fontSize='sm'>{t("sensor")}</Text>
          <Select
            w='100%'
            placeholder={t("sensor")}
            value={formula}
            onChange={(itm)=>{
              setFormula(itm.target.value);
              updateField("formula",itm.target.value)
            }}
          >
            {sensors.map((sensor)=>{
              return <option 
                value={sensor.id}
              >{sensor.title}</option>
              })
            }
          </Select>
        </Box>}
        <Box>
          <Text fontSize='sm'>{titleAction}</Text>
          <Input  
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            maxW='190px'
            fontWeight='500'
            value={duration}
            onChange={(val)=>{
              setDuration(val.target.valuel);
              updateField("duration",val.target.value)
            }}/>
        </Box>
        <Spacer />
        {btnAdd?<Button colorScheme='green' size={"sm"}
          onClick={()=>{
            btnAction(condition)
            // setValues({ ...values,sensorsPlotted:R.filter(o => o._id !== plotter._id, values.sensorsPlotted)  })
          }}> + </Button>:
          <Button colorScheme='red' size={"sm"}
            onClick={()=>{
              btnAction(condition)
              // setValues({ ...values,sensorsPlotted:R.filter(o => o._id !== plotter._id, values.sensorsPlotted)  })
            }}> x </Button>}
    </Stack>
  );
}
export default withTranslation()(Index);