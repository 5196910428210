import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import React from "react";
import { withTranslation } from 'react-i18next';

function SidebarCard(props) {
  const {t} = props;
  const bgColor = "linear-gradient(135deg, #75cb0f 0%, #3d690a 100%)";
  const borderColor = useColorModeValue("white", "navy.800");

  const tips=[
    "Brevet primé à plusieurs reprises en Suisse",
    "Consommer intelligemment pour optimiser vos coûts énergétiques",
    "UNE OPTIMISATION DE 40%* SUR LES ÉQUIPEMENTS ÉNERGIVORES",
    "Surveillance du fonctionnement de votre installation en toute simplicité et en temps réel, 24h/24h",
    "Détection et notification des anomalies de fonctionnement"
  ]

  React.useEffect(() => { 
    return () => {}
  },[]);

  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      me='20px'
      position='relative'>
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='linear-gradient(135deg, #3d690a 0%, #75cb0f 100%)'
        borderRadius='50%'
        w='94px'
        h='94px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='50%'
        top='-47px'
        transform='translate(-50%, 0%)'>
        <Image src={logoWhite} w='40px' h='40px' />
      </Flex>
      <Flex
        direction='column'
        mb='12px'
        align='center'
        justify='center'
        px='15px'
        pt='55px'>
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'
          mb='6px'>
          Neolec
        </Text>
        <Text
          fontSize='14px'
          color={"white"}
          px='10px'
          mb='5px'
          textAlign='center'>
          {tips[Math.floor(Math.random() * ((tips.length-1) - 0) + 0)]}
        </Text>
      </Flex>
      <Link mb={{ sm: "8px", xl: "12px" }} isExternal href='https://www.neolec.ch'>
        <Button
          bg='whiteAlpha.300'
          _hover={{ bg: "whiteAlpha.200" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "24px" }}
          color={"white"}
          fontWeight='regular'
          fontSize='sm'
          minW='185px'
          mx='auto'>
          {t("Learn more")}
        </Button>
      </Link>
    </Flex>
  );
}
export default withTranslation()(SidebarCard);
