// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import { withTranslation } from 'react-i18next';
// Redux
import { useSelector} from 'react-redux';
import {routes} from "routes.js";

// FUNCTIONS

function SidebarContent(props) {
  const myInfos = useSelector(state => state.user.infos);

  var routes_=[],allRoutes=routes(props.t);
  
  if(myInfos.role!=="admin"){
    if(Array.isArray(allRoutes))
      allRoutes.forEach(route => {
        if(route.path==="/users" || route.path==="/demo"|| route.path==="/overview"){
          
        }else routes_.push(route)
      });
  }else routes_=allRoutes
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
         <Links rtes={routes_} />
        </Box>
      </Stack>

      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard />
      </Box>
    </Flex>
  );
}
export default withTranslation()(SidebarContent);
