import Global from "../Constant";

export const requestPost= (endPoint,data) => {
  return fetch(Global.SERVER_URL+'/'+endPoint, {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(datas=>{return datas });
};
export const listDevices = (token) => {
  return fetch(Global.SERVER_URL+'/listDevices', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token})
    })
    .then(response => response.json())
    .then(devicesList=>{
      return devicesList
    });
};
export const listSensors = (token,device_id) => {
return fetch(Global.SERVER_URL+'/listSensors', {
    method: 'post',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({token,"device_id":device_id})
  })
  .then(response => response.json())
  .then(message=>{
    if(message){
      if(message.hasOwnProperty("error"))return {error:message.msg}
      else return message;
    }
  });
};
export const sendToDevice = (token,device_id,sensor_id,val,cmd="OFF") => {
return fetch(Global.SERVER_URL+'/sendToDevice', {
    method: 'post',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      token,
      device_id,
      val,
      sensor_id,
      cmd
      //message:val?sensor_id+":1":sensor_id+":0"
    })
  })
  .then(response => response.json())
  .then(message=>{
    if(message){
      if(message.hasOwnProperty("error")){
        if(message.error===200)return 200
        return {error:message.msg}
      }else return {error:"Unknow"};
    }
  });
};