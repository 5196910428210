import { withTranslation } from 'react-i18next';
// Chakra imports
import {Box,Flex} from "@chakra-ui/react";
// Custom components
import AnimationLoading from "./animation/Loading";
import Card from "components/card/Card.js";
import MultiSelect from "./MultiSelect.js";

import React from "react";
import ReactApexChart from "react-apexcharts";

import 'rsuite/dist/rsuite.min.css';
import { subDays } from 'date-fns'
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment"; 
import { Utils } from "utils";
import Constant from "utils/Constant";
import { Post } from 'utils/queries/index.js';

import smartHomeLottie from '../../../../assets/lotties/smart_home.json'


function TrioCharts(props) {
  const {t,listSensors,token} = props;
  var response,data=[];
  
  var [isLoading, setIsLoading] = React.useState(false);
  var [listGraph, setListGraph] = React.useState([]);
  var [startChartDate, setStartChartDate] = React.useState(new Date(((new Date()).getTime()-24*60*60*1000)));
  var [endChartDate, setEndChartDate] = React.useState(new Date());
  var [selectedGraph, setSelectedGraph] = React.useState([]);
  var [chartData, setChartData] = React.useState([]);

  React.useEffect(async () => { 
    await updateChart(startChartDate,endChartDate)
      
    response=[]
    var color='#0052CC',dd=1,ts="'",ds="."
    listSensors.forEach(element => {
      if(element.hasOwnProperty("graph")){
        if(element.graph.hasOwnProperty("cc"))color=element.graph.cc
        if(element.graph.hasOwnProperty("dd"))dd=element.graph.dd
        if(element.graph.hasOwnProperty("ts"))ts=element.graph.ts
        if(element.graph.hasOwnProperty("ds"))ds=element.graph.ds
      }
      response.push({value:element.id, label:element.title/*+" ("+element.device_id+")"*/, color,dd,ts,ds,unit:element.unit,device_id:element.device_id})
    });
    setListGraph(response)
    /*if(props.data["date_forecast"]){
      _futurDatas=[]
      if(props.series[0]==="SOLAR_PV_Production"){
        _futurDatas=props.data["value_forecast"]
      }
      setFuturDatas(_futurDatas)
    }*/
    
    return () => {}
  },[listSensors]);

  const updateChart=async (startDate,endDate)=>{

    setIsLoading(true)
    setStartChartDate(startDate);
    setEndChartDate(endDate);
    data=[{items:[]}]
    for (let i = 0; i < listSensors.length; i++) 
      data[0].items.push(listSensors[i]);
      
    
    for (let i = 0; i < data[0].items.length; i++) {
      data[0].items[i]={
        device_id:data[0].items[i].device_id,
        id:data[0].items[i].id,
        formula:data[0].items[i].formula
      }
    }
    
    response=await Post.requestPost("getChartDatas",{
      token,
      graphs:data,
      startDate,endDate
    })
    
    setIsLoading(false)
    if(response.hasOwnProperty("error")){
      if(response.error===200)setChartData(response.charts)
    }
      
  }
  const getDatas=(device_id,id)=>{
    if(chartData){
      for (let i = 0; i < chartData.length; i++) {
        for (let j = 0; j < chartData[i].items.length; j++) {
          if(chartData[i].items[j].device_id===device_id && chartData[i].items[j].id===id ){
            return {x:chartData[i].items[j].x,y:chartData[i].items[j].y}
          }
        };
        
      }
      return {x:[new Date()],y:[0]}
    }
  }
  const getMin=(values)=>{
    var _min=99999
    values.forEach(element => {if(element<_min && element!==Constant.FAKE_MIN)_min=element});
    return _min
  }

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'>

      <Flex justify='space-between' ps='0px' pe='20px' pt='5px' align='center' w='100%' zIndex={1}>
        <DateRangePicker 
          isoWeek={true}
          placeholder={t("Last 24 hours")}
          onOk={(value) => {
            value[0]=new Date(new Date(value[0]).setHours(0,0,0));
            value[1]=new Date(new Date(value[1]).setHours(23,59,58))
            updateChart (value[0],value[1])
          }}
          ranges={[
            {
              label: t("yesterday"),
              value:[
                moment().subtract(1, 'days').startOf('day').toDate(),
                moment().subtract(1, 'days').endOf('day').toDate()
              ]
            },
            {
              label: t("last_week"),
              value: [
                moment().subtract(1, 'week').startOf('week').toDate(),
                moment().subtract(1, 'week').endOf('week').toDate()
              ]
            },
            {
              label: t("last_momth"),
              value: [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate()
              ]
            },
            {
              label: t("last_year"),
              value: [
                moment().subtract(1, 'year').startOf('year').toDate(),
                moment().subtract(1, 'year').endOf('year').toDate()
              ]
            }
          ]}
        />
        <MultiSelect
          series={selectedGraph}
          listGraph={listGraph}
          returnSelectedValues={(val)=>{
            for (let i = 0; i < val.length; i++) val[i].id= val[i].value;
            setSelectedGraph(val)
          }}
        />
      </Flex>
      <Box mt='auto' w='100%' zIndex='0'>
      {isLoading?<AnimationLoading  lottie={smartHomeLottie}/>:
        selectedGraph.map((item) => (
          <ReactApexChart
            key= {item.id}
            options={{
              chart: {
                id: item.id,
                //group: 'sync',
                zoom: {enabled: false},
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    customIcons: []
                  },
                  export: {
                    csv: {
                      filename:  "neolec",
                      columnDelimiter: ',',
                      headerCategory: t("datetime"),
                      dateFormatter(timestamp) {
                        return moment(timestamp).format("DD/MM/YYYY HH:mm")
                      }
                    },
                    png: { filename: "neolec"}
                  }
                }
              },
              stroke: {
                width: 2,
                curve:"smooth",// 'smooth' straight
              },
              colors:[item.color],
              title: {text: item.label},
              yaxis: {
                tickAmount: 4,
                floating: false,
                labels: {
                  style: { fontSize:"10px"},
                  formatter: function (value) {
                    return (Number(value).toFixed(item.dd).toString().replace('.',item.ds).replace(/\B(?=(\d{3})+(?!\d))/g, item.ts)) //Math.abs(value)>999?Math.round(value/1000)+"k":Math.round(value);
                  }
                },
                min:getMin(getDatas(item.device_id,item.id).y?getDatas(item.device_id,item.id).y.map(function(x) { return Utils.getConvertVal(
                  x,
                  item.input_val_min,
                  item.input_val_max,
                  item.val_min,
                  item.val_max
                ) }):[]),
                axisBorder: {show: false},
                axisTicks: { show: false}
              },
              xaxis: {
                type: 'datetime',
                categories:getDatas(item.device_id,item.id).x,
                labels: {
                  datetimeUTC: false,
                  style: {colors:"#000",fontSize: "9px"},
                  datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM HH:00',
                    hour: 'HH:mm'
                  }
                },
                axisBorder: {
                  show: true,
                  color: '#232729',
                  height: 2,
                  width: '100%',
                  offsetX: 0,
                  offsetY: 0
                }
              },
              tooltip: {
                enabled: true,
                fillSeriesColor: false,
                theme: "light",
                x: {
                    show: false,
                    format: 'dd MMM, HH:mm',
                      
                },
                y: {
                    title: {
                        formatter: (seriesName) => ""
                    },
                },
              },
              dataLabels: {enabled: false },
              grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#f3f3f3', 'transparent'], 
                  opacity: 0.5
                },
              },
            }}
            series={[{
                name: item.label+""+item.unit,
                data: getDatas(item.device_id,item.id).y?getDatas(item.device_id,item.id).y.map(function(x) { return Utils.getConvertVal(
                  x,
                  item.input_val_min,
                  item.input_val_max,
                  item.val_min,
                  item.val_max
                ) }):[]
              }
            ]}



            
            type='line'
            width={'100%'}
            height={200}
          />
          ))}
      </Box>
    </Card>
  );
}

export default withTranslation()(TrioCharts);