import React from "react";
import { Tabs, TabList, TabPanels, 
    useTab,
    TabPanel,
    useMultiStyleConfig,
    Box,
    Button } from '@chakra-ui/react'

import AnimationNeolec from "./neolec"
import AnimationFactory from "./factory";
import AnimationNeoClim from "./neoClim";
import AnimationNeoClim2 from "./neoClim/index_2";
import AnimationBoiler from "./boiler";

function DemoTab(props) {
  
    const CustomTab = React.forwardRef((props, ref) => {
        // 1. Reuse the `useTab` hook
        const tabProps = useTab({ ...props, ref })
        const isSelected = !!tabProps['aria-selected']

        // 2. Hook into the Tabs `size`, `variant`, props
        const styles = useMultiStyleConfig('Tabs', tabProps)

        return (
            <Button __css={styles.tab} {...tabProps}>
            <Box as='span' mr='2'>{/*isSelected ? '🎥' : */props.icon}</Box>
            {tabProps.children}
            </Button>
        )
        })
  
    return (
    <Tabs bg="white">
        <TabList>
        <CustomTab icon='🏠'> Neolec</CustomTab>
        <CustomTab icon='🏭'> Factory</CustomTab>
        <CustomTab icon='☃️'> Neo Clim</CustomTab>
        <CustomTab icon='☃️'> Neo Clim 2</CustomTab>
        <CustomTab icon='♨️'> Boiler</CustomTab>
        </TabList>
        <TabPanels w='100%'>
            <TabPanel w='67%' align="center" justify="center">
                <AnimationNeolec/>
            </TabPanel>
            <TabPanel  w='77%' align="center" justify="center">
                <AnimationFactory/>
            </TabPanel>
            <TabPanel  w='77%'>
                <AnimationNeoClim/>
            </TabPanel>
            <TabPanel  w='77%'>
                <AnimationNeoClim2/>
            </TabPanel>
            <TabPanel  w='77%'>
                <AnimationBoiler/>
            </TabPanel>
        </TabPanels>
    </Tabs>
    );
}
export default DemoTab;