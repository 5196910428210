import window from "global";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
//import "../fonts";
import useIsServer from "./hooks/useIsServer";
import Knob from "./Knob";
import Labels from "./Labels";
import reducer from "./redux/reducer";
import Svg from "./Svg";



function CircularSlider(props) {
  let direction = 1
  let limit = 360
  let offsetAngle = 0
  let knobColor = "#0076BA"
  let knobSize = 100
  let knobPosition = "top"
  let hideKnob = false
  let knobDraggable = true
  let knobEl = null
  let labelTop = "_"
  let labelColor = "#75cb10"
  let secondaryLabelColor = "#c0c0c0"
  let roundLabelFontSize = ".0rem"
  let roundLabelColor = "#272b77"
  let labelFontFamily = `"Segoe UI" "Arial"`
  let labelFontSize = ".0rem"
  let labelOffset = 3
  let activeLabelColor = "#75cb10"
  let valueFontSize = "5rem"
  let hideLabelValue = false
  let progressColorFrom = "#54BFFD"
  let progressColorTo = "#0378BC"
  let progressSize = 30
  let trackColor = "#CFE0F4"
  let trackSize = 40
  let doubleLineColor = null
  let doubleLineType = "progress"
  let data = []
  let magentTolerance = 10
  let progressLineCap = "round"
  let renderLabelValue = null

  const onChange = (value) => {props.onChange(value)}

  const contentWidth = props.width - (2 * labelOffset);
  const initialState = {
    mounted: false,
    isDragging: false,
    contentWidth: contentWidth,
    radius: contentWidth / 2,
    knobPosition: knobPosition,
    label: 0,
    data: data,
    radians: 0,
    offset: 0,
    knob: {
      x: 0,
      y: 0,
    },
    dashFullArray: 0,
    dashFullOffset: 0,
  };

  const circularSlider = useRef(null);
  const knobRef = useRef(null);
  const svgFullPath = useRef(null);
  const lastAngle = useRef(0);
  const dragable = useRef(null);
  const isServer = useIsServer();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activedItem, setActived] = React.useState(null);
  const [updatedKey, updateState] = React.useState(null);

  const spreadDegrees = 360;
  const knobOffset = {
    top: Math.PI / 2,
    right: 0,
    bottom: -Math.PI / 2,
    left: -Math.PI,
  };

  const getOffsetRideans = (knobPosition, offsetAngle) => {
    return knobOffset[knobPosition] + (offsetAngle / 180) * Math.PI;
  };

  const getSliderRotation = (number) => {
    if (number < 0) return -1;
    return 1;
  };

  const getRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  };

  const generateRange = () => {
    const data= [];
    for (let num = props.min; +num.toFixed(2) <= props.max; num += props.step) {
      const key = +num.toFixed(2);
      data.push({ key: key.toString(), value: key, showLabel: key % props.labelStep === 0 });
    }

    return data;
  };

  const setKnobPosition = useCallback(
    (radians) => {
      if (!state.dashFullArray) {
        updateState(Math.random());
        return;
      }
      const offsetRadians = radians + getOffsetRideans(knobPosition, offsetAngle);
      let degrees = (offsetRadians > 0 ? offsetRadians : 2 * Math.PI + offsetRadians) * (spreadDegrees / (2 * Math.PI));
      if (!activedItem && degrees > limit) {
        degrees = 0;
        if (dragable.current === undefined) {
          dragable.current = false;
        } else {
          return;
        }
      } else if (knobPosition === "bottom" && degrees > limit) {
        degrees = limit;
        return;
      } else if (knobPosition === "top" && degrees < 360 - limit) {
        degrees = 360 - limit;
        return;
      }

      let newDegree = degrees + limit - offsetAngle;
      newDegree = newDegree > 360 ? newDegree - 360 : newDegree;
      let pt = svgFullPath.current.getPointAtLength((newDegree * state.dashFullArray) / 360);
      pt.x = Math.round(pt.x);
      pt.y = Math.round(pt.y);

      // change direction
      let dashOffset = (degrees / spreadDegrees) * state.dashFullArray;
      degrees = getSliderRotation(direction) === -1 ? spreadDegrees - degrees : degrees;
      lastAngle.current = degrees;
      const pointsInCircle = state.data.length / limit;
      const currentPosition = degrees * pointsInCircle;
      let currentPoint = Math.ceil(currentPosition);

      const closestPointDegrees = Math.max(currentPoint / pointsInCircle - 1 / pointsInCircle / 2, 0);
      if (Math.abs(closestPointDegrees - degrees) <= magentTolerance && state.data[currentPoint - 1]?.showLabel) {
        newDegree = closestPointDegrees + limit - offsetAngle;
        newDegree = newDegree > 360 ? newDegree - 360 : newDegree;
        pt = svgFullPath.current.getPointAtLength((newDegree * state.dashFullArray) / 360);
        pt.x = Math.round(pt.x);
        pt.y = Math.round(pt.y);
        dashOffset = (closestPointDegrees / spreadDegrees) * state.dashFullArray;
        degrees = getSliderRotation(direction) === -1 ? spreadDegrees - closestPointDegrees : closestPointDegrees;
        setActived(currentPoint);
      } else {
        setActived(0);
      }
      knobRef.current.style = `transform: rotate(${degrees + offsetAngle}deg);`;

      if (state.data[currentPoint - 1]?.key !== state.label?.key) {
        onChange(state.data[currentPoint - 1]);
      }

      dispatch({
        type: "setKnobPosition",
        payload: {
          dashFullOffset:
            getSliderRotation(direction) === -1 ? dashOffset : (state.dashFullArray || svgFullPath.current.getTotalLength()) - dashOffset,
          label: state.data[currentPoint - 1],
          knob: {
            x: pt.x,
            y: pt.y,
          },
        },
      });
    },
    // eslint-disable-next-line
    [offsetAngle, limit, state.dashFullArray, state.radius, state.data, state.label, knobPosition, trackSize, direction, onChange],
  );

  const onMouseDown = () => {
    dragable.current = true;
    dispatch({
      type: "onMouseDown",
      payload: {
        isDragging: true,
      },
    });
  };

  const onMouseUp = (event) => {
    dragable.current && onMouseMove(event);
    dragable.current = false;
    dispatch({
      type: "onMouseUp",
      payload: {
        isDragging: false,
      },
    });
  };

  const onMouseMove = useCallback(
    (event, passive = false) => {
      if (!dragable.current) return;

      if (passive) event.preventDefault();

      const touch = event.changedTouches?.[0];

      const offsetRelativeToDocument = (ref) => {
        const rect = ref.current.getBoundingClientRect();
        const scrollLeft = !isServer && ((window?.pageXOffset ?? 0) || (document?.documentElement?.scrollLeft ?? 0));
        const scrollTop = !isServer && ((window?.pageYOffset ?? 0) || (document?.documentElement?.scrollTop ?? 0));
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
      };

      const mouseXFromCenter = (touch ? touch.pageX : event.pageX) - (offsetRelativeToDocument(circularSlider).left + state.radius);
      const mouseYFromCenter = (touch ? touch.pageY : event.pageY) - (offsetRelativeToDocument(circularSlider).top + state.radius);

      const radians = Math.atan2(mouseYFromCenter, mouseXFromCenter);

      setKnobPosition(radians);
    },
    [state.radius, setKnobPosition, isServer],
  );

  // Get svg path length onmount
  useEffect(() => {
    const data = state.data.length ? [...state.data] : [...generateRange()];
    const [firstItem] = data;
    onChange(firstItem);
    dispatch({
      type: "init",
      payload: {
        mounted: true,
        data: data,
        label: firstItem,
        dashFullArray: svgFullPath.current.getTotalLength ? svgFullPath.current.getTotalLength() : 0,
      },
    });

    // eslint-disable-next-line
  }, [props.max, props.min]);

  useEffect(() => {
    setActived(props.dataIndex);
    updateState(Math.random())
  }, [props.dataIndex]);

  // Set knob position
  useEffect(() => {
    let updatedIndex = activedItem === null ? props.dataIndex : activedItem;
    const dataArrayLength = state.data.length;
    const knobPositionIndex = updatedIndex > dataArrayLength ? dataArrayLength : updatedIndex;

    if (!!dataArrayLength) {
      const pointsInCircle = limit / dataArrayLength;
      const offset = getRadians(pointsInCircle) / 2;

      dispatch({
        type: "setInitialKnobPosition",
        payload: {
          radians: Math.PI / 2 - getOffsetRideans(state.knobPosition, offsetAngle),
          offset,
        },
      });

      const degrees = getSliderRotation(direction) * knobPositionIndex * pointsInCircle;
      const radians = getRadians(degrees) - getOffsetRideans(state.knobPosition, offsetAngle);

      if (!knobPositionIndex) {
        return setKnobPosition(radians);
      }
      return setKnobPosition(radians - offset * getSliderRotation(direction));
    }

    // eslint-disable-next-line
  }, [state.dashFullArray, state.knobPosition, state.data.length, direction, updatedKey]);

  const sanitizedLabel = labelTop.replace(/[\W_]/g, "_");

  const styles=  {
    circularSlider: {
      position: "relative",
      display: "inline-block",
      opacity: 0,
      transition: "opacity 0.2s ease-in",
      width: `${props.width}px`,
    },
    value: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      zIndex: 3,
    },
    mounted: {
      opacity: 1,
      touchAction: "none",
    }
  };

  return (
    <div
      style={{ ...styles.circularSlider, ...(state.mounted && styles.mounted) }}
      ref={circularSlider}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseUp}
      onTouchMove={onMouseMove}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
    >
      <Svg
        width={contentWidth}
        limit={limit}
        label={sanitizedLabel}
        labelOffset={labelOffset}
        direction={direction}
        strokeDasharray={state.dashFullArray}
        strokeDashoffset={state.dashFullOffset || 100}
        svgFullPath={svgFullPath}
        progressSize={progressSize}
        progressColorFrom={progressColorFrom}
        progressColorTo={progressColorTo}
        progressLineCap={progressLineCap}
        doubleLineColor={doubleLineColor}
        doubleLineType={doubleLineType}
        trackColor={trackColor}
        trackSize={trackSize}
        radiansOffset={state.radians}
        offsetAngle={offsetAngle}
        labelColor={labelColor}
        roundLabelColor={roundLabelColor}
        roundLabelFontSize={roundLabelFontSize}
        labelFontSize={labelFontSize}
        activeLabelColor={activeLabelColor}
        data={state.data}
        activedItem={activedItem}
        onLableClick={(id) => {
          setActived(id);
          updateState(Math.random());
        }}
      />
      {knobDraggable && (
        <Knob
          knobRef={knobRef}
          isDragging={state.isDragging}
          knobPosition={{ x: state.knob.x, y: state.knob.y }}
          knobSize={knobSize > trackSize ? knobSize : trackSize}
          knobColor={knobColor}
          trackSize={trackSize}
          hideKnob={hideKnob}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
          {knobEl}
        </Knob>
      )}
      {renderLabelValue ? (
        <div style={styles.value}>{renderLabelValue}</div>
      ) : (
        <Labels
          power={props.power}
          humidity={props.humidity}
          temperature={props.temperature}

          labelColor={labelColor}
          secondaryLabelColor={secondaryLabelColor}
          labelFontFamily={labelFontFamily}
          labelFontSize={labelFontSize}
          valueFontSize={valueFontSize}
          appendToValue={props.appendToValue}
          hideLabelValue={hideLabelValue}
          selected={state.label}
        />
      )}
    </div>
  );
};

export default CircularSlider;
