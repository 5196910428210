// Chakra imports
import {
  Box,
  SimpleGrid
} from "@chakra-ui/react";

// Custom components
import Animation from "./components/animation/main";
import AnimationLoading from "./components/animation/Loading";

import { withTranslation } from 'react-i18next';
import React from "react";
import moment from "moment"; 

import { useHistory,useLocation } from "react-router-dom";
// Redux
import { useSelector} from 'react-redux';

import {Post} from './queries'
import {Utils} from "utils";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
  
import TrioCharts from "./components/TrioCharts";
import AnimationDetail from "./components/animation/detail";

import 'rsuite/dist/rsuite.min.css';

import smartHomeLottie  from '../../../assets/lotties/smart_home.json'
import CustomCharts from "./components/CustomCharts";
import Pane24 from "./components/Pane24";
import mexp from "utils/math-expression-evaluator";

function Dashboard(props) {
  let history = useHistory(),response;
  const series=[]

  var _intervalId,data;
  
  var [currentDeviceID, setCurrentDeviceID] = React.useState("");
  var [typeAnimation, setTypeAnimation] = React.useState("");
  
  var [showAnimDetail, setShowAnimDetail] = React.useState(false);
  var [isLoading, setIsLoading] = React.useState(false);
  var [list_sensors, setListSensors] = React.useState([]);
  var [dateAnimDetail, setDateAnimDetail] = React.useState([new Date(new Date().getTime()-24*60*60*1000), new Date()]);
  var [datasAnimDetail, setDatasAnimDetail] = React.useState({});
  //var [chartData, setChartData] = React.useState({date:[]});
  var listSensors=[];
  
  var [startChartDate, setStartChartDate] = React.useState((new Date()).setHours(0,0,0,0));
  var [endChartDate, setEndChartDate] = React.useState(moment(new Date()).set({ hour:   23,minute: 59, second: 59}));
  var [lastStateMsg, setLastStateMsg] = React.useState({});
  var [curInfos, setCurInfos] = React.useState({myViews:{}});
  
  const myInfos = useSelector(state => state.user.infos);
  const cur_device = useSelector(state => state.device.cur_device);

  const location = useLocation();

  React.useEffect(() => { 
    (async function() {
      document.title ="Dashboard - NeoLec";
      clearInterval(_intervalId);
      if(cur_device.value){
        if(myInfos.token){
          manageListDevice(cur_device.value)
          await calculingOverviewData([cur_device.value],dateAnimDetail)
        }
        else history.push('/')
      }
    })();
    setCurInfos(myInfos)
    return () => {clearInterval(_intervalId);}
  },[cur_device]);

  React.useEffect(() => { 
    setShowAnimDetail(false)
    return () => {}
  },[location]);

  const manageListDevice= async (_currentDeviceID)=>{
    setIsLoading(true)
    
    let views=[],json
    if(myInfos.myViews){
      if(myInfos.myViews.dashboard){
        if(myInfos.myViews.dashboard.monitoring_24h){
          myInfos.myViews.dashboard.monitoring_24h.forEach(elt => {
            if(elt.formula) json={id:elt.id,formula:elt.formula }
            else json={id:elt.id}
            if(elt.device_id!==cur_device.value)json.device_id=elt.device_id
            views.push(json)
          });
        }
      }
    }
    
    if(cur_device.hasOwnProperty("device_id"))
      listSensors=await Utils.requestPost("listSensors",{token:myInfos.token,device_id:cur_device.device_id})
    else listSensors=await Utils.requestPost("listSensors",{token:myInfos.token,device_id:_currentDeviceID})
    
    data=myInfos
    if(data.hasOwnProperty("role"))
      if(data.role==="admin"){
        setListSensors(listSensors)
        response=await Post.getDataLast24h(myInfos.token,_currentDeviceID,listSensors)
        data.myViews={dashboard:{monitoring_24h:[]}}
        for (var elt in response){
          await listSensors.forEach(elt2 => {
            if(elt===elt2.id){
              elt2.dataLast24h=response[elt]
              data.myViews.dashboard.monitoring_24h.push(elt2)
            }
          });
        }
      }else{
        if(cur_device.hasOwnProperty("device_id"))
          response=await Post.getDataLast24h(myInfos.token,cur_device.device_id,views)
        else response=await Post.getDataLast24h(myInfos.token,_currentDeviceID,views)
        if(data.hasOwnProperty("myViews"))
          if(data.myViews.hasOwnProperty("dashboard"))
            if(data.myViews.dashboard.hasOwnProperty("monitoring_24h")){
              for (let i = 0; i < data.myViews.dashboard.monitoring_24h.length; i++) {
                for (var elt_ in response){
                  if(data.myViews.dashboard.monitoring_24h[i].id===elt_){
                    data.myViews.dashboard.monitoring_24h[i].dataLast24h=response[elt_]
                  }
                }
              }
            }
      }
    setCurInfos(data)
    getLastState(_currentDeviceID)
    setCurrentDeviceID(_currentDeviceID)
    manageState(_currentDeviceID)
    setIsLoading(false)
    updateChart(_currentDeviceID,startChartDate,endChartDate)

  }
  
  const manageState=async (device_id)=>{
    clearInterval(_intervalId);
    _intervalId = setInterval(async function() {
      await getLastState(device_id)
    }, 30*1000);
  }

  const getLastState=async (device_id)=>{
    if(myInfos.myViews){
      if(myInfos.myViews.dashboard){
        if(myInfos.myViews.dashboard.monitoring_24h){
          device_id=[]
          myInfos.myViews.dashboard.monitoring_24h.forEach(elt => {
            if (!device_id.includes(elt.device_id)){
              device_id.push(elt.device_id);
            }
          });
        }
      }
    }
    if(cur_device.hasOwnProperty("device_id"))
      response=await Post.getLastState(myInfos.token,cur_device.device_id)
    else response=await Post.getLastState(myInfos.token,device_id)

    if(response.message){
      listSensors.forEach(elt => {
        if(elt.type==="calculated_analog_sensor"){
          response.message[elt.id]=parseExpresion(elt.formula,response.message)
        }
      });
    }
    setLastStateMsg(response)
  }

  const updateChart=async (deviceID,startDate,endDate)=>{
    setIsLoading(true)

    setStartChartDate(startDate);
    setEndChartDate(endDate);
    response=await Post.getChartDatas(myInfos.token,deviceID,startDate,endDate)
    setIsLoading(false)
    if(response.hasOwnProperty("error")){
      NotificationManager.error(response.msg, 'Message');
    }//else setChartData(response)
  }

  const showDashboard=()=>{
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard")){
        if(myInfos.myViews.dashboard.svg==="main_animation"){
          if(isLoading)return(<AnimationLoading  lottie={smartHomeLottie}/>)
          else return(<Animation 
            lastStateMsg={lastStateMsg.message?lastStateMsg.message:{}}
            itemClick={(elt)=>{
              setTypeAnimation(elt);
              setShowAnimDetail(true);
            }}
          />)
        }/*
        else if(myInfos.myViews.dashboard.svg==="monitoring_ac"){
          if(isLoading)return(<AnimationLoading  lottie={smartHomeLottie}/>)
          else return(<MonitoringAC 
            lastStateMsg={{}}
          />)
        }*/
      }
    return null;
  }

  const hasAnimationPane=()=>{
    if(myInfos.hasOwnProperty("myViews"))
      if(myInfos.myViews.hasOwnProperty("dashboard"))
        if(myInfos.myViews.dashboard.svg==="main_animation")return true
        //else if(myInfos.myViews.dashboard.svg==="monitoring_ac")return true
    return false
  }

  const calculingOverviewData=async (_deviceId,_date)=>{
    response=await Post.calculingOverviewData(myInfos.token,_deviceId,_date);
    if(response.hasOwnProperty("GPC"))setDatasAnimDetail(response)
  }


  const customView=()=>{
    return(<Box align={"center"} justify={"center"} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!showAnimDetail?
      <SimpleGrid columns={{base:1,md:hasAnimationPane()?2:1,xl:hasAnimationPane()?2:1}} gap='10px' mb='20px'>
        {showDashboard()}
        {curInfos.hasOwnProperty("myViews")?curInfos.myViews.hasOwnProperty("dashboard")?
          <Pane24 
            myInfos={curInfos}
            lastStateMsg={lastStateMsg}
          />:null:null}
      </SimpleGrid>:
      <SimpleGrid gap='10px' mb='20px'>
        <AnimationDetail
          type={typeAnimation}
          dates={dateAnimDetail}
          data={datasAnimDetail}
          lastStateMsg={lastStateMsg.message?lastStateMsg.message:{}}
          onUpdateDate={async (date)=>{
            setDateAnimDetail(date)
            await calculingOverviewData([cur_device.value],date)
          }}
          onUpdateDatas={(datas)=>{setDatasAnimDetail(datas)}}
          onBack={()=>{setShowAnimDetail(false) }}
        />
      </SimpleGrid>}
      {!showAnimDetail?<SimpleGrid gap='10px' mb='10px'>
        <CustomCharts 
          myInfos={myInfos}
          cur_device={cur_device}
        />
      </SimpleGrid>:null}
    </Box>)
  }

  const defaultView=()=>{
    return(<Box align={"center"} justify={"center"} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!showAnimDetail?
      <SimpleGrid columns={{base:1,md:hasAnimationPane()?2:1,xl:hasAnimationPane()?2:1}} gap='10px' mb='20px'>
        {showDashboard()}
        <Pane24
          myInfos={curInfos}
          lastStateMsg={lastStateMsg}
        />
      </SimpleGrid>:<SimpleGrid gap='10px' mb='20px'>
        <AnimationDetail
          type={typeAnimation}
          lastStateMsg={lastStateMsg.message?lastStateMsg.message:{}}
          onRefresh={(date)=>{updateChart(currentDeviceID,date[0],date[1])}}
          onBack={()=>{setShowAnimDetail(false) }}
        />
      </SimpleGrid>}

      <SimpleGrid gap='10px' mb='10px'>
        <TrioCharts 
          token={myInfos.token}
          listSensors={list_sensors} 
          series={series}
          onRefresh={(date)=>{
            updateChart(currentDeviceID,date[0],date[1])
          }}
        />
      </SimpleGrid>
      <NotificationContainer/>
    </Box>)
  }

  const parseExpresion=(expression,obj)=>{
    if(expression){
      var text=expression, _var
      if(expression)
        if(expression.includes("$")){
          var newTxt = text.split('$');
          for (var i = 1; i < newTxt.length; i++) {
            _var=newTxt[i].split(' ')[0];
            text=text.replace('$'+_var,''+obj[_var])
          }
        }
      try{ return mexp.eval(text)}
      catch(e){return 0 }
    }
    return ["Empty",false,0]
  }

  return (
    <div>
      {myInfos.role==="admin"?defaultView():customView()}
    </div>
  );
}

export default withTranslation()(Dashboard);