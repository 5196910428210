
import React from "react";
import {Buffer} from 'buffer';
// Chakra imports
import { Text, useColorModeValue,
  Icon,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,Flex,Button } from "@chakra-ui/react";
  
// Redux
import { useSelector} from 'react-redux';
// Custom components
import Card from "components/card/Card.js";

import { withTranslation } from 'react-i18next';

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import {Post} from '../../sensors/queries'
import {validateEmail,validatePassword,validateField} from "utils/Validators";


function MyInfos(props) {
  const {setErrorMsg,showMsg,setSuccessMsg,sendOTPDatas,t} = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const textColor = useColorModeValue("navy.700", "white");
  
  var response;
  const [show, setShow] = React.useState(false);
  const [showNew, setShowNew] = React.useState(false);
  const [values, setValues] = React.useState({
    idUser: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    new_password: "",
    old_password: "",
    role:"user",
    myDevicesList:[]
  });
  var [errorEmail, setErrorEmail] =  React.useState("");
  var [errorFirstname, setErrorFirstname] =  React.useState("");
  var [errorPwd, setErrorPwd] =  React.useState("");
  var [errorPwdNew, setErrorPwdNew] =  React.useState("");

  const myInfos = useSelector(state => state.user.infos);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();

    let error,noFound=true;
    
    error= await validateEmail(values.email)
    if(error.email)setErrorEmail("")
    else {
      setErrorEmail(error);
      noFound=false;
    }
    ///////
    error= await validatePassword(values.new_password)
    if(error.password)setErrorPwdNew("")
    else{
      setErrorPwdNew(error);
      noFound=false;
    }
    ///////
    error= await validatePassword(values.old_password)
    if(error.password)setErrorPwd("")
    else{
      setErrorPwd(error);
      noFound=false;
    }
    //////////
    error= await validateField(values.firstname)
    if(error.field)setErrorFirstname("")
    else{
      setErrorFirstname(error);
      noFound=false;
    }
    if(noFound) {
      setValues({ ...values,"isLoading":true})
      console.log("values",values);
      var _user={ 
        idUser:values.idUser,
        email:values.email, 
        firstname:values.firstname,
        lastname:values.lastname,
        new_password:values.new_password!==""?Buffer.from(values.new_password).toString("base64"):"",
        old_password:values.old_password!==""?Buffer.from(values.old_password).toString("base64"):"",
        token:myInfos.token,
        phone:values.phone, 
        lastVisit:new Date()
      }
      if(values.role) _user.role=values.role.value
      
      response=await Post.requestPost("addUser",_user)
      console.log("response",response);
      if(response.hasOwnProperty("error")){
        if(response.error===200){
          showMsg(t("successful_modification"))
        }
        else if(response.error===402){
          setErrorMsg(t("email_used"))
        }else{
          setErrorMsg(response.msg)
        }
      }else  {
        setErrorMsg(t("error_occurred"))
      }
      setValues({ ...values,"isLoading":false})
    }
  }

  const generateQrCode = async (id,email) => {
    try {
      setValues({ ...values,"isLoadingOTP":true})

      response=await Post.requestPost("generateOTP",{id, email })
      
      if(response.hasOwnProperty("error")){
        if(response.error===200){
          sendOTPDatas({
            base32: response.base32,
            otpauth_url: response.otpauth_url,
          });
        }else setErrorMsg(response.msg)
      }else setErrorMsg(t("error_occurred"))
      setValues({ ...values,"isLoadingOTP":false})

    } catch (error) {
      setValues({ ...values,"isLoadingOTP":false})
      setErrorMsg(error.toString())
    }
  };
  
  const disableOTP = async () => {
    const response = await Post.requestPost( "disableOTP", {id:values.idUser});
    if(response.hasOwnProperty("error")){
      if(response.error===200){
        setSuccessMsg("Two Factor Authentication Disabled")
      }else setErrorMsg(response.msg)
    }else setErrorMsg(t("error_occurred"))
  }

  React.useEffect(() => { 
    (async function() {
      setValues({
        idUser: myInfos._id,
        firstname: myInfos.firstname,
        lastname:myInfos.lastname,
        phone:myInfos.phone,
        email: myInfos.email,
        old_password:"",
        new_password:"",
        role:myInfos.role,
      })
    })();
    return () => {}
  },[]);
  
  return (
    <Card>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='25px'>
        {t("my_infos")}
      </Text>

      <FormControl isRequired isInvalid={errorFirstname!==""}>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='0px'>
          {t("firstname")}
        </FormLabel>
        <FormErrorMessage>{errorFirstname}</FormErrorMessage>
        <Input
          id="firstname"
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          placeholder='John'
          mb='9px'
          fontWeight='500'
          value={values.firstname}
          onChange={handleChange('firstname')}
          size='lg'
          isRequired={true}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='0px'>
          {t("lastname")}
        </FormLabel>
        <Input
          id="lastname"
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          placeholder='Doe'
          mb='9px'
          fontWeight='500'
          value={values.lastname}
          onChange={handleChange('lastname')}
          size='lg'
        />
      </FormControl>
        <FormControl isRequired isInvalid={errorEmail!==""}>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='0px'>
            {t("email")}
          </FormLabel>
          <FormErrorMessage>{errorEmail}</FormErrorMessage>
          <Flex w="100%" justify="space-between" alignItems={"c"} mt='1px'>
            <Input
              id="email"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='user@neolec.ch'
              mb='9px'
              fontWeight='500'
              value={values.email}
              onChange={handleChange('email')}
              width='70%' 
              isRequired={true}
            />
            <Button  
              fontSize='sm'
              colorScheme={myInfos.otp_enabled?'red':'green'}
              isLoading={values.isLoadingOTP}
              onClick={()=>{
                if(myInfos.otp_enabled)disableOTP()
                else generateQrCode(values.idUser,values.email)
              }}
            >
              {t(myInfos.otp_enabled?"Disable 2FA":"Enable 2FA")}
            </Button>
          </Flex>
        </FormControl>
      <FormControl>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='0px'>
          {t("phone")}
        </FormLabel>
        <Input
          id="phone"
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='tel'
          placeholder='+41 xx xxx xx xx'
          mb='9px'
          fontWeight='500'
          value={values.phone}
          onChange={handleChange('phone')}
          size='lg'
        />
      </FormControl>
      <FormControl  isInvalid={errorPwd!==""}>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='0px'>
          {t("old_password")}
        </FormLabel>

        <FormErrorMessage>{errorPwd}</FormErrorMessage>
        <InputGroup size='md'>
          <Input
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            placeholder='**********'
            type={show? 'text' : 'password'}
            mb='9px'
            fontWeight='500'
            value={values.old_password}
            onChange={handleChange('old_password')}
            size='lg'
          />
          <InputRightElement display='flex' alignItems='center' mt='4px'>
            <Icon
              color={textColorSecondary}
              _hover={{ cursor: "pointer" }}
              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={()=>{setShow(!show)}}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
        
      <FormControl  isInvalid={errorPwdNew!==""}>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='0px'>
          {t("new_password")}
        </FormLabel>
        <FormErrorMessage>{errorPwdNew}</FormErrorMessage>
        <InputGroup size='md'>
          <Input
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            placeholder='**********'
            type={showNew? 'text' : 'password'}
            mb='9px'
            fontWeight='500'
            value={values.new_password}
            onChange={handleChange('new_password')}
            size='lg'
          />
          <InputRightElement display='flex' alignItems='center' mt='4px'>
            <Icon
              color={textColorSecondary}
              _hover={{ cursor: "pointer" }}
              as={showNew ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={()=>{setShowNew(!showNew)}}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Flex w="100%" justify="space-between" mt='25px'>
          <Button  
            width='140px' 
            colorScheme='green'  
            isLoading={values.isLoading}
            onClick={handleSubmit}
          >
            {t("update")}
          </Button>
          <Button colorScheme='red' variant='outline' 
            onClick={()=>{
              setValues({...values,
                idUser: "",
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                new_password: "",
                old_password: "",
                role:"user",
                myDevicesList:[]
              })
            }}>{t("cancel")}</Button>
        </Flex>
    </Card>
  );
}
export default withTranslation()(MyInfos);
