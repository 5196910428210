import React,{useEffect} from "react";
import { withTranslation } from 'react-i18next';
import * as R from 'ramda';
import 'moment/locale/fr' 
import { useSelector } from 'react-redux';
import {Flex,Box,Checkbox} from "@chakra-ui/react";
import Row from "./components/row";

function SectionPower(props) {
    var {t, onUpdateProgram,program,errorMsg,currency } = props;
    var _program={}
    
    const [listRange, setListRange] =  React.useState([]);
    const [isVisibleAll, setIsVisibleAll] =  React.useState(false);
    const myInfos = useSelector(state => state.user.infos);

    useEffect(async () => { 
        if(program.hasOwnProperty("isVisibleAll"))setIsVisibleAll(program.isVisibleAll)
        setListRange(program)
    },[])

    return <Box justify="center">
        <Flex w={"100%"}
            justifyContent={"space-between"}
            align={"center"}
            mt={isVisibleAll?3:0}
        >
            <Box/>
            {myInfos.role==="admin"?<Checkbox 
                ml={2}
                size='sm' 
                color='secondaryGray.600'
                isChecked={isVisibleAll}
                onChange={(state)=>{
                    setIsVisibleAll(state.target.checked)
                    _program=program
                    _program.isVisibleAll=state.target.checked
                    onUpdateProgram(_program)
                }}
            >{t("Visible to all users")}</Checkbox>:null}
        </Flex>
        {listRange.map((data,index)=>{
            return(<Row 
                data={data}
                index={index} 
                key={index} 
                currency={currency}
                onAdd={(min)=>{setListRange([...listRange,{
                    id:listRange.length,
                    min,
                    color:"#"+Math.floor(Math.random()*16777215).toString(16)
                }])}}
                onRemove={(id)=>{
                    onUpdateProgram(R.filter(o => o.id !== id, listRange))
                    setListRange(R.filter(o => o.id !== id, listRange))
                }}
                errorMsg={(text)=>{errorMsg(text)}}
                isFirst={0===index}
                isLast={(listRange.length-1)===index}
                updateData={(info)=>{ 
                    let _listRange=listRange
                    for (let i = 0; i < _listRange.length; i++) {
                        if(_listRange[i].id===info.id){
                            _listRange[i]=info
                            break
                        }
                    }
                    setListRange(_listRange)
                    onUpdateProgram(_listRange)
                }}
            />)
        })}
    </Box>;
}
export default withTranslation()(SectionPower);