
import Constant from "./Constant";
import Global from "./Constant";
import {Buffer} from 'buffer';
// Redux
import { useDispatch } from 'react-redux';

export const requestPost= (endPoint,data) => {
  return fetch(Global.SERVER_URL+'/'+endPoint, {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(datas=>{return datas });
};
export const getConvertVal= (input,inMin,inMax,outMin,outMax) => {
  if(input === null)return null
  if(typeof input === 'undefined')return 0
  if(typeof inMin === 'undefined')return input
  if(typeof outMin === 'undefined')return input
  if(!inMax)inMax=100
  if(!outMax)outMax=100

  var percentage=((Number(input) - Number(inMin)) * 100) / (Number(inMax) - Number(inMin))
  return (Number(percentage) * (Number(outMax) - Number(outMin)) / 100) + Number(outMin)
};
export const onLogin=async(email,password_)=>{
  let password= Buffer.from(password_).toString('base64')
  await fetch(Constant.SERVER_URL+'/login', {
    method: 'post',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({email,password})
  })
  .then(response => response.json())
  .then(responseData=>{
    if(responseData.hasOwnProperty("error")){
      if(responseData.error===200){
        responseData.user.password=password_
        const dispatch = useDispatch();
        dispatch({type:'SET_INFOS',payload:responseData.user});
        return true
      }else return false
    }else return false
  });
}
export const onRefresh=async(email,password_)=>{
  let password= Buffer.from(password_).toString('base64')
  return await fetch(Constant.SERVER_URL+'/login', {
    method: 'post',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({email,password})
  })
  .then(response => response.json())
  .then( responseData=>{
    if(responseData.hasOwnProperty("error")){
      if(responseData.error===200){
        responseData.user.password=password_
        return responseData.user
      }else return false
    }else return false
  });
}
export const onLogout=(token)=>{
  fetch(Constant.SERVER_URL+'/logout', {
    method: 'post',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({token})
  })
  .then(response => response.json())
  .then(responseData=>{
    if(responseData.hasOwnProperty("error")){
      if(responseData.error===200){
        console.log(responseData.msg);
      }
    }
  });
}