import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "assets/css/App.css";
import 'assets/locales/i18n';

import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";

import { Provider } from 'react-redux';
import App from './App'
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <App/>
        </ChakraProvider>
      </PersistGate>
    </Provider>,
  document.getElementById("root")
);
//serviceWorker.register();
