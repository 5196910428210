import * as Yup from 'yup';

let schemaEmail = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Email is required")
});
let schemaPassword = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(8, "The password must be at least 8 characters long")
});
let schemaField = Yup.object().shape({
    field: Yup.string()
        .required("This field is required")
});

export const validateField=(field)=> {
    return schemaField.validate({field}).catch((err) => {
        return err.errors[0]
    });
}

export const validatePassword=(password)=> {
    return schemaPassword.validate({password}).catch((err) => {
        return err.errors[0]
    });
}
export const validateEmail=(email)=> {
    return schemaEmail.validate({email}).catch((err) => {
        return err.errors[0]
    });
}