import {useEffect} from "react";
import anime from "animejs";

function Animation(props) {

  const animationSmoke=() => {
    anime({
        targets: '#smoke_1',
        strokeWidth: .2,
        easing: 'linear',
        duration: 3700,
        loop: true
      });
    anime({
        targets: '#smoke_2',
        strokeWidth: .1,
        easing: 'linear',
        duration: 4500,
        loop: true
    });
    anime({
        targets: '#smoke_3',
        strokeWidth: .3,
        easing: 'linear',
        duration: 2500,
        loop: true
    });
  }

  useEffect(() => { 
    animationSmoke()
  },[]);
  
  return (
    <svg version="1.1" id="idstation" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="-30 -1 124 64" >
       <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(8.000000, 54.000000)" fill="#FFFFFF" stroke="#012038" strokeWidth="1.5">
            <rect x="0" y="0" width="47" height="4" rx="1"></rect>
        </g>
        <rect  stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="8" y="26" width="47" height="4" rx="1"></rect>
        <rect stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="33" y="20" width="7" height="42" rx="3.5"></rect>
        <rect stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="11" y="20" width="7" height="42" rx="3.5"></rect>
        <rect stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="22" y="20" width="7" height="42" rx="3.5"></rect>
        <rect stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="44" y="20" width="7" height="42" rx="3.5"></rect>
        <polygon id="Path-34" stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" points="55 24 55 32 59 30.0326238 59 26.3890344"></polygon>
        <path id="smoke_1" d="M28.9689705,4 C27.7602016,4.62844728 27.1121763,5.40878396 27.0248944,6.34101006 C26.8939717,7.7393492 27.2964901,8.10690323 27.8326136,9.09544916 C28.3687371,10.0839951 29.1675465,10.722949 28.9689705,12.4222898 C28.8365865,13.5551836 28.1885612,14.4144204 27.0248944,15" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path id="smoke_2" d="M33.9689705,4 C32.7602016,4.62844728 32.1121763,5.40878396 32.0248944,6.34101006 C31.8939717,7.7393492 32.2964901,8.10690323 32.8326136,9.09544916 C33.3687371,10.0839951 34.1675465,10.722949 33.9689705,12.4222898 C33.8365865,13.5551836 33.1885612,14.4144204 32.0248944,15" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path id="smoke_3" d="M38.9689705,4 C37.7602016,4.62844728 37.1121763,5.40878396 37.0248944,6.34101006 C36.8939717,7.7393492 37.2964901,8.10690323 37.8326136,9.09544916 C38.3687371,10.0839951 39.1675465,10.722949 38.9689705,12.4222898 C38.8365865,13.5551836 38.1885612,14.4144204 37.0248944,15" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </g>
    </svg>
  );
}
export default Animation;