/*!
=====================================================
* Horizon UI - v1.1.0
=========================================================

*/

import { withTranslation } from 'react-i18next';
// Chakra imports
import {Flex,Box,SimpleGrid,useColorModeValue,FormControl,FormLabel,Button} from '@chakra-ui/react';
import { BsLightning } from 'react-icons/bs';
import { FaMoneyBillWave } from "react-icons/fa"
import * as R from 'ramda';

import DateRangePicker from 'rsuite/DateRangePicker';
import Select from 'react-select';

import moment from "moment"; 

// Redux
import { useSelector} from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import React from "react";

import Card from "components/card/Card";
import ComplexTable from "./components/ComplexTable";
import StatsCard from './components/StatsCard';

import {Post} from 'utils/queries/index.js';
import BillingGraph from './components/BillingGraph';



function Index(props) {
  const {t}=props;
  const myInfos = useSelector(state => state.user.infos);
  
  var [billingDatas, setBillingDatas] = React.useState([]);
  

  var [modelList, setModelList] = React.useState([]);
  var [selectModelList, setSelectModelList] = React.useState([]);

  var [summaryConsump, setSummaryConsump] = React.useState({month_sum:0,this_month_sum:0,week_sum:0});
  
  var [selectDates, setSelectDates] = React.useState([new Date((new Date()).setDate((new Date()).getDate()-30)),new Date()]);
  var [isLoading, setIsLoading] = React.useState(false);
  var [listRow, setListRow] = React.useState([]);
  var [t_cost, setTCost] = React.useState(0);
  var [t_comsumption, setTComsumption] = React.useState(0);
  var [listAllRow, setListAllRow] = React.useState([]);
  
  const bgColor = useColorModeValue("#ffffff", "navy.800");
  const textColor = useColorModeValue("navy.700", "white");
  

  const columnsData = [
    {
      Header: t("Billing models"),
      accessor: "billing_title",
    },
    {
      Header: t("Energy sources"),
      accessor: "sensor_title",
    },
    {
      Header: t("Serie"),
      accessor: "serie",
    },
    {
      Header: t("comsumption"),
      accessor: "comsumption",
    },
    {
      Header: t("cost"),
      accessor: "cost",
    },
  ];
  var _modelList=[],response,listTable,cost=0,comsumption

  React.useEffect(() => {  
    (async function() {
      document.title = t("billing")+" - NeoLec";

      setIsLoading(true)
      response=await Post.requestPost("managePairing",{token:myInfos.token,type:"read"})
      setIsLoading(false)
      if(response.hasOwnProperty("error")){
        if(response.error===200) {
          _modelList=[]
          response.items.forEach(async model => {
            _modelList.push({value:model._id,
              label:model.model_title+" / "+model.sensor_title
            })
          });
          setModelList(_modelList)
        }else if(response.hasOwnProperty("msg")) NotificationManager.error(response.msg, 'Message');
        else NotificationManager.error(t("error_occurred"), 'Message');
      }else NotificationManager.error(t("error_occurred"), 'Message');
      
    })();
    return () => {};
  },[]);
  
  const updateChart=async (dates)=>{
    setIsLoading(true)
    response=[]
    selectModelList.forEach(item => {response.push(item.value)});
    response=await Post.requestPost("getBillingDatas",{
      token:myInfos.token,
      sources:response,
      dates
    })
    if(response.hasOwnProperty("graphs")){
      setListRow([])
      setListAllRow([])
      setBillingDatas(response.graphs)
      listTable=[]
      let T_cost=0
      let T_comsumption=0
      for await(const graph of response.graphs) {
        for await(const serie of graph.series) {

          cost=0
          comsumption=0
          serie.y_cost.forEach((itm,idx) => {
            cost+=itm
            comsumption+=serie.y_consumption[idx]
          });
          
          listTable.push({
            billing_title:graph.billing_title,sensor_title:graph.sensors_title,
            serie:serie.title,
            color:serie.color,cost,comsumption
          });
          T_cost+=cost
          T_comsumption+=comsumption
        }
      }
      setTComsumption(T_comsumption)
      setTCost(T_cost)

      setListAllRow(listTable)
      setListRow(listTable)
    }
    setIsLoading(false)
  }
  const getSummaryBillingDatas=async ()=>{
    response=[]
    selectModelList.forEach(item => {response.push(item.value)});
    setIsLoading(true)
    response=await Post.requestPost("getSummaryBillingDatas",{token:myInfos.token,sources:response })
    setIsLoading(false)
    if(response.hasOwnProperty("error")){
      if(response.error===200) {
        setSummaryConsump(response.infos)
      }else if(response.hasOwnProperty("msg")) NotificationManager.error(response.msg, 'Message');
      else NotificationManager.error(t("error_occurred"), 'Message');
    }else NotificationManager.error(t("error_occurred"), 'Message');

    await updateChart(selectDates)
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Neolec ⚡⚡'
    >
      <Box
        pt={{ base: "100px", md: "100px", xl: "110px" }}
        px={{ base: 2, sm: 12, md: 17 }}
        spacing={{ base: 5, lg: 8 }}
      >
        <SimpleGrid borderRadius={10}
          border={'1px solid'}
          borderColor={useColorModeValue('gray.800', 'white')}
          bg={bgColor} 
          alignItems={"center"}
          p={5} mb={5} columns={{ base: 1, md: 2 }} 
          spacing={{ base: 5, lg: 8 }}
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
        >
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("Model")+" - "+t("Energy sources")}
            </FormLabel>
            <Select 
              isMulti
              options={modelList}
              placeholder={t("Pairing")}
              defaultValue={selectModelList}
              onChange={(items)=>{
                setSelectModelList(items)
              }}
            />
          </FormControl>


          {selectModelList.length==0?null:<Button mt={13} colorScheme='facebook' leftIcon={<FaMoneyBillWave />} size='md' 
              onClick={() => {getSummaryBillingDatas() }}
            >
              {t("Financial estimates")}
          </Button>}
        </SimpleGrid>

        <SimpleGrid mb={5} columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
            title={t("last_momth")}
            cost={summaryConsump.hasOwnProperty("last_month_data")?summaryConsump.last_month_data.cost:"--"}
            consumption={summaryConsump.hasOwnProperty("last_month_data")?summaryConsump.last_month_data.consumption:"--"}
            currency={summaryConsump.hasOwnProperty("last_month_data")?summaryConsump.currency:"--"}
            icon={<BsLightning size={'3em'} />}
          />
          <StatsCard
            title={t("last_week")}
            cost={summaryConsump.hasOwnProperty("last_week_data")?summaryConsump.last_week_data.cost:"--"}
            consumption={summaryConsump.hasOwnProperty("last_week_data")?summaryConsump.last_week_data.consumption:"--"}
            currency={summaryConsump.hasOwnProperty("last_week_data")?summaryConsump.currency:"--"}
            icon={<BsLightning size={'3em'} />}
          />
          <StatsCard
            title={t("this_month")}
            cost={summaryConsump.hasOwnProperty("this_month_data")?summaryConsump.this_month_data.cost:"--"}
            consumption={summaryConsump.hasOwnProperty("this_month_data")?summaryConsump.this_month_data.consumption:"--"}
            currency={summaryConsump.hasOwnProperty("this_month_data")?summaryConsump.currency:"--"}
            icon={<BsLightning size={'3em'} />}
          />
        </SimpleGrid>

        <Card
          direction='column'
          w='100%'
          px='0px'
          mb={4}
          overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex justify='space-between' pl='20px' pr='20px' pt='5px' align='center' w='100%' zIndex={1}>
              <DateRangePicker 
                isoWeek={true}
                placeholder={t("Last 30 days")}
                onOk={async (value) => {
                  value[0]=new Date(new Date(value[0]).setHours(0,0,0));
                  value[1]=new Date(new Date(value[1]).setHours(23,59,58))
                  setSelectDates(value)
                  await updateChart(value)
                }}
                ranges={[
                  {
                    label: t("yesterday"),
                    value:[
                      moment().subtract(1, 'days').startOf('day').toDate(),
                      moment().subtract(1, 'days').endOf('day').toDate()
                    ]
                  },
                  {
                    label: t("last_week"),
                    value: [
                      moment().subtract(1, 'week').startOf('week').toDate(),
                      moment().subtract(1, 'week').endOf('week').toDate()
                    ]
                  },
                  {
                    label: t("last_momth"),
                    value: [
                      moment().subtract(1, 'month').startOf('month').toDate(),
                      moment().subtract(1, 'month').endOf('month').toDate()
                    ]
                  },
                  {
                    label: t("last_year"),
                    value: [
                      moment().subtract(1, 'year').startOf('year').toDate(),
                      moment().subtract(1, 'year').endOf('year').toDate()
                    ]
                  }
                ]}
              />
            </Flex>
            {<BillingGraph
              billingGraph={billingDatas}
              unit=" W"
            />}
        </Card>

        <ComplexTable
          title={t("Summary")}
          columnsData={columnsData}
          tableData={listRow}
          total={{t_comsumption,t_cost}}
          onSearch={(text)=>{
            let T_cost=0
            let T_comsumption=0
            let _listTable=R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), listAllRow)
            for (const item of _listTable) {
              T_cost+=item.cost
              T_comsumption+=item.comsumption
            }
            setTComsumption(T_comsumption)
            setTCost(T_cost)
            setListRow(_listTable) 

          }}
        />
      </Box>
      <NotificationContainer/>
    </LoadingOverlay>
  );
}

export default withTranslation()(Index);