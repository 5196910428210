/*!
=====================================================
* Horizon UI - v1.1.0
=========================================================*/

// Chakra importsm
import { Box} from '@chakra-ui/react'
//
import DemoTab from './components/DemoTab'
// Assets
import React from "react";

export default function Demo() {
  return (
    <Box  pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <DemoTab/>
    </Box>
  );
}
