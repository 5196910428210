import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,Input,
  Thead,Tr,Tfoot,Button,
  useColorModeValue,
} from "@chakra-ui/react";

import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";

import { withTranslation } from 'react-i18next';

function ColumnsTable(props) {
  const { columnsData, tableData,title,t,total,onSearch} = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
  React.useEffect(() => {  
    return () => {}
  },[]);

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          {title}
        </Text>
        <Input
          variant='auth'
          fontSize='sm'
          width={200}
          mb='9px'
          fontWeight='500'
          placeholder={t("search")}
          onChange={(item)=>{onSearch(item.target.value);}}
          size='md'
        />
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.id === "billing_title" || cell.column.id === "sensor_title")
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {t(cell.value)}
                      </Text>
                    );
                  
                  if (cell.column.id === "cost" || cell.column.id === "comsumption") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        { Number(cell.value).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}
                      </Text>
                    );
                  }
                  if (cell.column.id === "serie") {
                    data = (
                      <Text color={row.original.color} fontSize='sm' fontWeight='bold'>
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th>TOTAL</Th>
            <Th fontSize='sm' fontWeight='bold'>{Number(total.t_comsumption).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}</Th>
            <Th fontSize='sm' fontWeight='bold'>{Number(total.t_cost).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}</Th>
          </Tr>
        </Tfoot>
      </Table>

      <Flex mt='5px' justify='center' mb='2px' align='center'>
        <Button colorScheme='teal' size='xs' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        {" "}
        <Button colorScheme='teal' size='xs' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"Previous"}
        </Button>
        {" "}
        <span>
          {" Page "}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>{" "}
        </span>
       {/*<span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px" }}
          />
          </span>*/}
        {" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <Button colorScheme='teal' size='xs' onClick={() => nextPage()} disabled={!canNextPage}>
          {"Next"}
        </Button>
        {" "}
        <Button colorScheme='teal' size='xs' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {">>"}
        </Button>
      </Flex>
    </Card>
  );
}
export default withTranslation()(ColumnsTable);