import React from "react";
import {Stack} from "@chakra-ui/react";
import Day from "./Day";

export default function WeekLine(props) {
  const { weekSelect,weekPress } = props;

  return (
    <Stack spacing={2} direction='row'>
        <Day 
            day={"L"}
            isPress={weekSelect[0]}
            onPress={()=>weekPress(0)}
        />
        <Day 
            day={"M"}
            isPress={weekSelect[1]}
            onPress={()=>weekPress(1)}
        />
        <Day 
            day={"M"}
            isPress={weekSelect[2]}
            onPress={()=>weekPress(2)}
        />
        <Day 
            day={"J"}
            isPress={weekSelect[3]}
            onPress={()=>weekPress(3)}
        />
        <Day 
            day={"V"}
            isPress={weekSelect[4]}
            onPress={()=>weekPress(4)}
        />
        <Day 
            day={"S"}
            isPress={weekSelect[5]}
            onPress={()=>weekPress(5)}
        />
        <Day 
            day={"D"}
            isPress={weekSelect[6]}
            onPress={()=>weekPress(6)}
        />
    </Stack>
  );
}
