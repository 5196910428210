import React from "react";

// Chakra imports
import {Flex,useColorModeValue } from "@chakra-ui/react";
import { withTranslation } from 'react-i18next';

import Donut from "views/admin/overview/components/animation/detail/Donut";
import ReactApexChart from "react-apexcharts";

function PaneRight(props) {
  const bgColor = useColorModeValue("#fff", "navy.800");
  const textColor = useColorModeValue("navy.800","#ffffff");
  const {type,t}=props

  const getOption=(color="#08f547",index=2)=>{
    return {
      chart: {
        type: 'radialBar',
        sparkline: { enabled: true }
      },
      colors:[color],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#7babdb",
            strokeWidth: '100%',
            margin: 0
          },
          dataLabels: {
            name: {
              color:textColor,
              fontSize: '16px',
              offsetY: 25,
              fontWeight: 'bold'
            },
            value: {
              offsetY: -40,
              fontSize: '25px',
              fontWeight: 'bold',
              color:textColor
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 100]
        },
      },
      labels: [getTitle(index)],
    }
  }
  
  const getOptionDDonut=(labels,colors,title="")=>{
    return {
      chart: {
        type: 'donut',
      },
      colors,
      labels,
      title: {
        text: title,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          color:  '#263238'
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]+" kWh"
        }
      }
    
    }
  }
  const getTitle=(index)=>{
    if(type==="battery"){
      if (index===1) return t("roundcycle_losses")
      else if (index===2) return "SOH"
    }
    else if(type==="home"){
      if (index===1) return t('consumption_break_down')
      else if (index===2) return t('self_sufficiency')
    }
    else if(type==="grid"){
      if (index===1) return ""
      else if (index===2) return ""
    }
    else if(type==="solar_panel"){
      if (index===1) return t("production_break_down")
      else if (index===2) return t("self_consumption_rate")
    }
    else if(type==="solar_thermal"){
      if (index===1) return "EAU CHAUDE SANITAIRE, COP 3.87"
      else if (index===2) return "CHAUFFAGE, COP 4.11"
    }
    else if(type==="boiler"){
      if (index===1) return ""
      else if (index===2) return ""
    }
    else if(type==="heat_pump"){
      if (index===1) return "EAU CHAUDE SANITAIRE, COP 3.87"
      else if (index===2) return "CHAUFFAGE, COP 4.11"
    }
    else if( type==="heating"){
      if (index===1) return ""
      else if (index===2) return ""
    }
  }
  const getItems=(index)=>{
    if(type==="battery"){
      if (index===1) return <ReactApexChart
        options={getOption("#fbae09",1)}
        series={[props.data.innerSub]}
        width={'100%'}
        type={'radialBar'}
        height={330}
      />
      else if (index===2) return <ReactApexChart
        options={getOption()}
        series={[props.data.inner]}
        width={'100%'}
        type={'radialBar'}
        height={330}
      />
    }
    else if(type==="home"){
      if (index===1) return <Donut
        title={getTitle(1)}
        data={props.data}
      />
      else if (index===2) return <ReactApexChart
        options={getOption()}
        series={[props.data.inner]}
        width={'100%'}
        type={'radialBar'}
        height={330}
      />
    }
    else if(type==="grid"){
      if (index===1) return  null
      else if (index===2)return null
    }
    else if(type==="solar_panel"){
      if (index===1) return  <Donut
        title={getTitle(1)}
        data={props.data}
      />
      else if (index===2) return <ReactApexChart
        options={getOption()}
        series={[props.data.inner]}
        width={'100%'}
        type={'radialBar'}
        height={330}
      />
    }
    else if(type==="solar_thermal"){
      if (index===1) return <ReactApexChart 
          options={getOptionDDonut(
            [
              "ECS Solaire T.", 
              "ECS PAC",
              "Puissance PAC"],
            ['#E99952', '#8BDAC4','#012038'],
            getTitle(index))}
          series={[595, 19, 72]} 
          type="donut"
          height= {350}
          width= {350}
        />
      else if (index===2) return <ReactApexChart 
        options={getOptionDDonut([
          "Accu Solaire T.",
          "Chauffage PAC", 
          "Puissance PAC"],
          ['#E99952', '#8BDAC4', '#012038'],
          getTitle(index))}
        series={[1690, 538, 412]} 
        type="donut"
        height= {370}
        width= {370}
      />
    }
    else if(type==="boiler"){
      if (index===1) return null
      else if (index===2) return null
    }
    else if(type==="heat_pump"){
      if (index===1) return <ReactApexChart 
          options={getOptionDDonut([
            "ECS Solaire T.", 
            "ECS PAC",
            "Puissance PAC"],
            ['#E99952', '#8BDAC4', '#012038'],
            getTitle(index))}
          series={[595, 19, 72]} 
          type="donut"
          height= {350}
          width= {350}
          
        />
      else if (index===2) return <ReactApexChart 
        options={getOptionDDonut([
          "Accu Solaire T.",
          "Chauffage PAC", 
          "Puissance PAC"],
          ['#E99952', '#8BDAC4', '#012038'],
          getTitle(index))}
        series={[538, 1690,412]} 
        type="donut"
        height= {370}
        width= {370}
      />
    }
    /*
          "Qté chaleur CHAUFFAGE PAS", 
          "Qté chaleur ACCU RESOL",
          "Puiss. absorbée CHAUFFAGE PAS" */
    else if(type==="heating"){
      if (index===1) return null
      else if (index===2) return null
    }
  }
  const getUnit=(index)=>{
    if(type==="battery"){
      if (index===1) return "A"
      else if (index===2) return "A"
      else if (index===3) return "%"
      else if (index===4) return "V"
    }
    else if(type==="home"){
      if (index===1) return "W"
      else if (index===2) return "kWh"
      else if (index===3) return "°C"
      else if (index===4) return ""
    }
    else if(type==="grid"){
      if (index===1) return "W"
      else if (index===2) return "kWh"
      else if (index===3) return "kWh"
      else if (index===4) return ""
    }
    else if(type==="solar_panel"){
      if (index===1) return "kWh"
      else if (index===2) return "kWh"
      else if (index===3) return "kWh"
      else if (index===4) return "%"
    }
    else if(type==="solar_thermal"){
      if (index===1) return "W/m²"
      else if (index===2) return "L/h"
      else if (index===3) return "°C"
      else if (index===4) return "kWh"
    }
    else if(type==="boiler"){
      if (index===1) return "°C"
      else if (index===2) return "°C"
      else if (index===3) return "kWh"
      else if (index===4) return "kWh"
    }
    else if(type==="heat_pump"){
      if (index===1) return ""
      else if (index===2) return "Hz"
      else if (index===3) return ""
      else if (index===4) return ""
    }
    else if(type==="heating"){
      if (index===1) return "°C"
      else if (index===2) return "°C"
      else if (index===3) return "kWh"
      else if (index===4) return "kWh"
    }
  }
  // Chakra Color Mode
  return (
    <Flex
      direction='column'
      bg={bgColor}
      pb={5}
      align="center"
      justifyContent="center"
      borderRadius='30px'>
        {getItems(1)}
        {getItems(2)}
    </Flex>
  );
}
export default withTranslation()(PaneRight);