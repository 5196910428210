import {
  Flex,Button,Box,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,Text,
  ModalCloseButton,
  useColorModeValue
} from '@chakra-ui/react'

// Redux
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React from "react";
import Select from 'react-select';

function ModalPairing(props) {
  const {t,isOpen, onClose,sensorsList,onSubmit,datas}=props;
  // Chakra color mode
  const bgColor = useColorModeValue("secondary.dark","navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  var [pairing, setPairing] = React.useState({model:"",sensor:""});

  const myInfos = useSelector(state => state.user.infos);
  
  React.useEffect(async() => { 
    if(datas.hasOwnProperty("id")){
      setPairing(datas)
    }
    return () => {}
  },[]);

  const handleChange = (prop) => (event) => { setPairing({ ...pairing, [prop]: event.value });};
  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(pairing)
  }

  return (
    <Modal blockScrollOnMount={false} isCentered isOpen={isOpen} 
      size={"sm"} 
      onClose={()=>{onClose()}}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent>
        <ModalHeader>{t("Billing models")}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
            <Box>
                <Text align="left" color={textColor} fontSize='sm' fontWeight='700'>
                    {t("Billing models")}
                </Text>
                <Select
                  defaultValue={pairing.model}
                  onChange={handleChange('model')}
                  options={props.billingList}
                  placeholder={t("Billing models")}
                />
            </Box>
            <Box mt={"10px"}>
                <Text align="left" color={textColor} fontSize='sm' fontWeight='700'>
                    {t("Energy sources")}
                </Text>
                <Select
                  defaultValue={pairing.sensor}
                  onChange={handleChange('sensor')}
                  options={sensorsList}
                  placeholder={t("sensor")}
                />
            </Box>
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            {pairing.model!=""&&pairing.sensor!=""?<Button  
              width='140px' 
              colorScheme='green'  
              onClick={handleSubmit}
            >{datas.hasOwnProperty("id") ?t("modify"):t("Join")}
            </Button>:<p/>}
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalPairing);