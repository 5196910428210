import {
  Flex,Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,

  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { withTranslation } from 'react-i18next';
import React from "react";

function ModalTitle(props) {
  const {t,isOpen, onClose,getInfos,data}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const [title, setTitle] = React.useState("");
  const [color, setColor] = React.useState("#1977c8");
  const [cost, setCost] = React.useState(0);

  
  React.useEffect(() => { 
    setTitle(data.title)
    setColor(data.color)
    setCost(data.cost)
    return () => {}
  },[data]);

  const handleSubmit = async (e) => { 
    e.preventDefault();
    getInfos(title,color,cost)
    onClose()
  }
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
   
    <ModalContent size='md'>
      <ModalHeader>{t("Modification")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='0px'>
            {t("title")}
          </FormLabel>
          <Input
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            placeholder={t("title")}
            mb='9px'
            fontWeight='500'
            value={title}
            onChange={(val)=>{setTitle(val.target.value)}}
            size='md'
          />
          </FormControl>
          <br/>
          <Flex>
            <FormControl isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("color_curve")}
              </FormLabel>
              <Input
                type={"color"}
                value={color}
                onChange={(val)=>{setColor(val.target.value)}}
              />
            </FormControl>
            <FormControl isRequired ml='10px'>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("Cost per kWh")}
              </FormLabel>
              <Input
                type={"number"}
                value={cost}
                onChange={(val)=>{setCost(val.target.value)}}
              />
            </FormControl>
          </Flex>
      </ModalBody>
      <ModalFooter bg={bgColor}>
        <Flex w="100%" justify="space-between">
          {title!==""?<Button  
            width='140px' 
            colorScheme='green'  
            onClick={handleSubmit}
          >
            {t("update")}
          </Button>:<div/>}
          <Button colorScheme='red' variant='outline' 
            onClick={()=>{
              setTitle("")
              setColor("#1977c8")
              onClose();
            }}>{t("cancel")}</Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
  );
}

export default withTranslation()(ModalTitle);