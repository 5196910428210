const initialState = {
    cur_device: {},
    isConnected: {state:0,last_message:"1970-01-01T00:00:00.088Z"},
    exportedDevices:[]
}


const Device = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CUR_DEVICE': 
            return {
                ...state,
                cur_device:action.payload
            }
        case 'DEVICE_IS_CONNECTED': 
            return {
                ...state,
                isConnected:action.payload
            }
        case 'EXPORTED_DEVICED': 
            return {
                ...state,
                exportedDevices:action.payload
            }
        default: return state
    }
}

export default Device;