import {useEffect} from "react";
import anime from "animejs";
import "./styles.css"

function Animation(props) {
    const {data}=props
  
  const animationElecFlux=() => {
    anime({
      targets: '#lineLeft',
      strokeDashoffset: 100,
      easing: 'linear',
      duration: 5000,
      loop: true
    });
    anime({
      targets: '.lineLeft',
      easing: 'linear',
      translateX:[-30,30],
      duration: 4000,
      loop: true
    });

    anime({
        targets: '#grid_flux',
        strokeDashoffset: 100,
        easing: 'linear',
        duration: 5000,
        loop: true
      });
    anime({
        targets: '.grid_flux',
        easing: 'linear',
        translateX:[-40,45],
        duration: 4000,
        loop: true
    });
  }
  
  const animationVerticalFlux=() => {
    anime({
      targets: '#lineCenterLeft',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 5500,
      loop: true
    });
    anime({
      targets: '.lineCenterLeft',
      easing: 'linear',
      translateX:40,
      duration: 3500,
      loop: true
    });
    
    anime({
        targets: '#lineCenterRight',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: 5500,
        loop: true
    });
    anime({
        targets: '.lineCenterRight',
        easing: 'linear',
        translateX:40,
        duration: 3500,
        loop: true
    });
  }
  
  useEffect(() => { 
    animationElecFlux();
    animationVerticalFlux();
  },[]);
    
  const getPercent=(val)=>{
    if(data.hasOwnProperty("PuissanceDecharge")){
      var total=Number(data["PuissanceDecharge"])+Number(data["puissanceBat"])
      return "--"//Number((val/total)*100).toFixed(1)
    }
    return "--"
  }
  
  return (
    <svg version="1.1" id="idstation" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-50 0 900 490" >
        <g  strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="illustration" transform="translate(0.000000, 7.000000)">               
                <g transform="translate(350, 19)" fillRule="evenodd">
                    <g id="battery" transform="translate(170, -5) scale(2.3) rotate(90)" stroke="#012038">
                        <path d="M15.0533813,41.3298829 L80.8281427,41.3298829 C82.1536261,41.3298829 83.2281427,42.4043995 83.2281427,43.7298829 L83.2281427,70.3910079 C83.2281427,71.7164913 82.1536261,72.7910079 80.8281427,72.7910079 L15.0533813,72.7910079 C13.7278979,72.7910079 12.6533813,71.7164913 12.6533813,70.3910079 L12.6533813,43.7298829 C12.6533813,42.4043995 13.7278979,41.3298829 15.0533813,41.3298829 Z" id="Rectangle" strokeWidth="1.8" transform="translate(47.940762, 57.060445) rotate(-90.000000) translate(-47.940762, -57.060445) "></path>
                        <g id="Group" transform="translate(43.446316, 43.546129)" fill="#012038" strokeLinejoin="round" strokeWidth="0.72">
                            <polygon points="5.6771955 0 0 12.9367811 4.1591425 12.9367811"></polygon>
                            <polygon transform="translate(6.150295, 17.557060) scale(-1, -1) translate(-6.150295, -17.557060) " points="8.98889287 11.0886695 3.31169737 24.0254507 7.47083988 24.0254507"></polygon>
                        </g>
                        <path d="M47.6374504,10.5317858 L49.7422224,10.5317858 C50.4049641,10.5317858 50.9422224,11.0690441 50.9422224,11.7317858 L50.9422224,27.3095716 C50.9422224,27.9723133 50.4049641,28.5095716 49.7422224,28.5095716 L47.6374504,28.5095716 C46.9747087,28.5095716 46.4374504,27.9723133 46.4374504,27.3095716 L46.4374504,11.7317858 C46.4374504,11.0690441 46.9747087,10.5317858 47.6374504,10.5317858 Z" id="Rectangle" strokeWidth="1.8" transform="translate(48.689836, 19.520679) scale(-1, 1) rotate(90.000000) translate(-48.689836, -19.520679) "></path>
                    </g>
                    <g id="boule_batery" transform="translate(-105, 83)">
                        <ellipse stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                        <text transform="translate(7,29)" fontSize="18" fontWeight="bold" fill="#012038">
                            <tspan>{data?getPercent(data.hasOwnProperty("PuissanceDecharge")?data["PuissanceDecharge"]:"--"):"--"}%</tspan>
                        </text>
                    </g>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="19" fontWeight="normal" fill="#012038">
                        <tspan x="150" y="94">{data?data["puissanceBat"]:"--"} kWh</tspan>
                    </text>
                </g>

                <g id="rightZone" transform="translate(720, 69)" strokeWidth="2.079">
                    <g transform="translate(0, 0) scale(1)" stroke="#012038">
                        <path d="M14.5567723,18.7638954 L3.60983836,77.4737971 C3.32921991,78.9787922 4.32177327,80.426318 5.82676833,80.7069364 C5.9917477,80.7376981 6.15917979,80.7534141 6.32700187,80.7538906 L89.7091643,80.9906556 C91.2400914,80.9950027 92.4846771,79.7574651 92.4890242,78.2265379 C92.4894724,78.0686781 92.4764352,77.9110686 92.4500546,77.755428 L82.4587672,18.8087613 C82.2328001,17.4755985 81.0779256,16.5 79.7257481,16.5 L17.2818069,16.5 C15.9468272,16.5 14.8014726,17.4515339 14.5567723,18.7638954 Z" id="Path-19" strokeLinecap="round" strokeLinejoin="round"></path>
                        <line x1="38.55" y1="17.8125" x2="30" y2="79.5" id="Line-16" strokeLinecap="square"></line>
                        <line x1="59.7857143" y1="17.8125" x2="69.8571429" y2="79.28125" id="Line-16" strokeLinecap="square"></line>
                        <line x1="13.0714286" y1="37.3125" x2="83.3571429" y2="37.3125" id="Line-16" strokeLinecap="square"></line>
                        <line x1="6.79245283" y1="60.75" x2="89.2075472" y2="60.75" id="Line-16" strokeLinecap="square"></line>
                        <line x1="76.5" y1="93" x2="22.5" y2="93" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="37.5" y1="81" x2="37.5" y2="93" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="61.5" y1="81" x2="61.5" y2="93" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                    </g>

                    <g id="boule_PV_solar" transform="translate(-50, 35)">
                        <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                        <text  transform="translate(8,27)" fontSize="18" fontWeight="bold" fill="#012038">
                            <tspan>{data?getPercent(data["puissanceBat"]):"--"}%</tspan>
                        </text>
                    </g>
                </g>

                <g id="grid" transform="translate(365, 345) scale(0.5)" strokeWidth="2" stroke="#012038">
                    <path d="M44.7983254,212.678053 L12.3988585,259.288062 L40.8028448,166.550144 L53.205626,75.9512563 L70.5107744,4.65591276 C70.6670992,4.01187152 71.3159241,3.61649921 71.9599653,3.772824 C72.390269,3.87726937 72.7280871,4.21019378 72.8387999,4.63892767 L91.2539313,75.9512563 L91.2539313,75.9512563 L103.57604,167.487169 L130.882901,259.288062 L100.31951,212.678053 L44.7983254,212.678053 Z" id="Path-32" strokeLinejoin="round"></path>
                    <polygon id="Path-33" strokeLinejoin="round" points="8.23981846 79.5314955 58.5063544 54.7552494 85.1408364 54.7552494 131.837095 79.5314955"></polygon>
                    <polygon id="Path-33" strokeLinejoin="round" points="8.23981846 141.472111 48.3152992 116.695864 96.3673747 116.695864 131.837095 141.472111"></polygon>
                    <line x1="53.6245523" y1="80.6672833" x2="96.2560611" y2="115.945069" id="Line" strokeLinecap="square"></line>
                    <line x1="48.3152992" y1="115.945069" x2="91.7616147" y2="79.9068931" id="Line-2" strokeLinecap="square"></line>
                    <line x1="27.3412158" y1="212.046872" x2="102.248656" y2="168.500743" id="Line-3" strokeLinecap="square"></line>
                    <line x1="115.731996" y1="210.545281" x2="40.8245551" y2="166.999152" id="Line-4" strokeLinecap="square"></line>
                    <line x1="42.3227039" y1="166.999152" x2="102.248656" y2="166.999152" id="Line-5" strokeLinecap="square"></line>
                    <line x1="27.3412158" y1="212.647508" x2="115.731996" y2="212.647508" id="Line-6" strokeLinecap="square"></line>
                    <line x1="8.23981846" y1="79.5314955" x2="8.23981846" y2="87.7902441" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="2.05995462" y1="88.3533406" x2="14.4196823" y2="88.3533406" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="125.657232" y1="88.3533406" x2="138.016959" y2="88.3533406" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="131.837095" y1="79.5314955" x2="131.837095" y2="87.7902441" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="8.23981846" y1="141.472111" x2="8.23981846" y2="149.730859" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="2.05995462" y1="150.293956" x2="14.4196823" y2="150.293956" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="125.657232" y1="150.293956" x2="138.016959" y2="150.293956" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="131.837095" y1="141.472111" x2="131.837095" y2="149.730859" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                </g>

                <g id="leftZone" transform="translate(-5, 60) rotate(0)" strokeWidth="2">
                    <g id="home" transform="translate(-50, -15) scale(1.8)" strokeWidth="1.1">
                        <path d="M10,33 C12.209139,33 14,34.790861 14,37 C14,37.5692141 13.881104,38.1106586 13.666792,38.6008535 C14.4938857,39.492169 15,40.6869798 15,42 C15,42.4861559 14.9306164,42.9561064 14.8012113,43.4004894 C15.5536801,44.4028314 16,45.6492939 16,47 C16,50.3137085 13.3137085,53 10,53 C6.6862915,53 4,50.3137085 4,47 C4,45.6492939 4.4463199,44.4028314 5.19952542,43.4000468 C5.06938362,42.9561064 5,42.4861559 5,42 C5,40.6869798 5.50611426,39.492169 6.33389914,38.6000111 C6.11889597,38.1106586 6,37.5692141 6,37 C6,34.790861 7.790861,33 10,33 Z" id="Combined-Shape" stroke="#012038"></path>
                        <line x1="10" y1="53" x2="10" y2="61" stroke="#012038" strokeLinecap="square"></line>
                        <line x1="3.5" y1="61.5" x2="60.5" y2="61.5" stroke="#012038" strokeLinecap="round"></line>
                        <path d="M16.5,27.5 L36.5,13.5 M56.5,27.5 L36.5,13.5 M19.5,25.5 L19.5,61.5 M53.5,25.5 L53.5,61.5 M19.5,61.5 L53.5,61.5" stroke="#012038" strokeLinecap="square"></path>
                        <path d="M24.6428571,38.5 L32.3571429,38.5 M24.5,38.5 L24.5,30.5 M24.6428571,30.5 L32.3571429,30.5 M32.5,38.5 L32.5,30.5" stroke="#012038" strokeLinecap="square"></path>
                        <path d="M24.6428571,54.5 L32.3571429,54.5 M24.5,54.5 L24.5,46.5 M24.6428571,46.5 L32.3571429,46.5 M32.5,54.5 L32.5,46.5" stroke="#012038" strokeLinecap="square"></path>
                        <path d="M39.6428571,38.5 L47.3571429,38.5 M39.5,38.5 L39.5,30.5 M39.6428571,30.5 L47.3571429,30.5 M47.5,38.5 L47.5,30.5" stroke="#012038" strokeLinecap="square"></path>
                        <path d="M39.5,61.5 L39.5,46.5 M39.5,46.5 L47.5,46.5 M39.5,61.5 L47.5,61.5 M47.5,61.5 L47.5,46.5" stroke="#012038" strokeLinecap="square"></path>
                    </g>
                    <text fontSize="20" fontWeight="normal" fill="#012038">
                        <tspan x="80" y="55">{data?data["PuissanceDecharge"]:"--"} kWh</tspan>
                    </text>
                </g>

                <g transform="translate(53, 125)">
                    <line x1="15" y1="0" x2="190" y2="0" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <g id="lineLeft" transform="translate(20,-2)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="5" y1="2" x2="162" y2="2" strokeDasharray="3,7.5"></line>
                        <g transform="translate(160,4) rotate(-180.000000)">
                            <line x1="35.2064971" y1="2.252386" x2="33.7083483" y2="0.750795334" class="lineLeft"></line>
                            <line x1="35.2064971" y1="2.252386" x2="33.7083483" y2="3.75397667" class="lineLeft"></line>
                            <line x1="87.1546638" y1="2.252386" x2="85.656515" y2="0.750795334" class="lineLeft"></line>
                            <line x1="87.1546638" y1="2.252386" x2="85.656515" y2="3.75397667" class="lineLeft"></line>
                            <line x1="127.1546638" y1="2.252386" x2="125.656515" y2="0.750795334" class="lineLeft"></line>
                            <line x1="127.1546638" y1="2.252386" x2="125.656515" y2="3.75397667" class="lineLeft"></line>
                        </g>
                    </g>
                </g>

                <g transform="translate(493, 125)">
                    <line x1="0" y1="0" x2="175" y2="0" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <g id="grid_flux" transform="translate(10, -2)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line  x1="0.749074406" y1="2.252386" x2="155.058402" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <g transform="translate(180,4) rotate(180)">
                            <line x1="66.6676221" y1="2.252386" x2="65.1694733" y2="0.750795334" class="grid_flux"></line>
                            <line x1="66.6676221" y1="2.252386" x2="65.1694733" y2="3.75397667" class="grid_flux"></line>

                            <line x1="108.615789" y1="2.252386" x2="107.11764" y2="0.750795334" class="grid_flux"></line>
                            <line x1="108.615789" y1="2.252386" x2="107.11764" y2="3.75397667" class="grid_flux"></line>

                            <line x1="148.615789" y1="2.252386" x2="147.11764" y2="0.750795334" class="grid_flux"></line>
                            <line x1="148.615789" y1="2.252386" x2="147.11764" y2="3.75397667" class="grid_flux"></line>
                        </g>
                    </g>
                </g>
                <g transform="translate(330, 200)">
                    <line x1="0" y1="0" x2="0" y2="200" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <g id="lineCenterLeft" stroke="#012038" strokeLinecap="round" strokeWidth="1.5" transform="translate(-3, 170) rotate(270)">
                        <line x1="0.749074406" y1="2.252386" x2="170" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="lineCenterLeft"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="lineCenterLeft"></line>
                       
                        <line x1="45.7305625" y1="2.252386" x2="44.2324137" y2="0.750795334" class="lineCenterLeft"></line>
                        <line x1="45.7305625" y1="2.252386" x2="44.2324137" y2="3.75397667" class="lineCenterLeft"></line>
                       
                        <line x1="75.6935388" y1="0.750795334" x2="77.1916876" y2="2.252386" class="lineCenterLeft"></line>
                        <line x1="77.1916876" y1="2.252386" x2="75.6935388" y2="3.75397667" class="lineCenterLeft"></line>
                       
                        <line x1="105.6935388" y1="0.750795334" x2="107.1916876" y2="2.252386" class="lineCenterLeft"></line>
                        <line x1="107.1916876" y1="2.252386" x2="105.6935388" y2="3.75397667" class="lineCenterLeft"></line>
                    </g>
                    <g id="boule_grid_left" transform="translate(-20, 170)">
                        <ellipse stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                        <text transform="translate(9,31)" fontSize="18" fontWeight="bold" fill="#012038">
                            <tspan>--%</tspan>
                        </text>
                    </g>
                    <text fontSize="20" fontWeight="normal" fill="#012038">
                        <tspan x="-40" y="-10">-- kWh</tspan>
                    </text>
                </g>
                <g id="lineCenterRight" transform="translate(450, 200)">
                    <line x1="0" y1="0" x2="0" y2="200" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <g stroke="#012038" strokeLinecap="round" strokeWidth="1.5" transform="translate(2, 3) rotate(90)">
                        <line x1="0.749074406" y1="2.252386" x2="62.1731757" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="lineCenterRight"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="lineCenterRight"></line>
                        <line x1="45.6935388" y1="0.750795334" x2="47.1916876" y2="2.252386" class="lineCenterRight"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="3.75397667" class="lineCenterRight"></line>
                    </g>
                    <g stroke="#012038" strokeLinecap="round" strokeWidth="1.5" transform="translate(2, 70) rotate(90)">
                        <line x1="0.749074406" y1="2.252386" x2="62.1731757" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="lineCenterRight"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="lineCenterRight"></line>
                        <line x1="45.6935388" y1="0.750795334" x2="47.1916876" y2="2.252386" class="lineCenterRight"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="3.75397667" class="lineCenterRight"></line>
                    </g>
                    <g stroke="#012038" strokeLinecap="round" strokeWidth="1.5" transform="translate(2, 140) rotate(90)">
                        <line x1="0.749074406" y1="2.252386" x2="62.1731757" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="lineCenterRight"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="lineCenterRight"></line>
                    </g>
                    <g id="boule_grid_right" transform="translate(-20, -20)">
                        <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                       <text transform="translate(7,28)" fontSize="18" fontWeight="bold" fill="#012038">
                            <tspan>--%</tspan>
                        </text>
                    </g>
                    <text fontSize="20" fontWeight="normal" fill="#012038">
                        <tspan x="10" y="200">-- kWh</tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

  );
}
export default Animation;