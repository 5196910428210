import {useEffect} from "react";
import anime from "animejs";
import "./styles.css"

function Animation(props) {
    const {data}=props

    const animationElecFlux=() => {
        anime({
            targets: '#lineCenter',
            strokeDashoffset: 100,
            direction:"reverse",
            easing: 'linear',
            duration: 5000,
            loop: true
        });
        anime({
            targets: '.lineCenter',
            easing: 'linear',
            translateX:[-40,62],
            duration: 4000,
            loop: true
        });
    }
    
    const animHelice=() => {
        anime({
          targets: "#helice",
          rotate: 360, 
          easing: 'linear',
          loop: true,
          duration: 3000
        });
    
        anime({
            targets: "#group_helice",
            translateX: 15, 
            translateY: 35, 
            easing: 'linear',
            duration: 10
        });
    }

    const animationSmoke=() => {
        anime({
            targets: '#smoke_fan_1',
            strokeWidth: .1,
            easing: 'linear',
            duration: 2700,
            loop: true
            });
        anime({
            targets: '#smoke_fan_2',
            strokeWidth: .1,
            easing: 'linear',
            duration: 5000,
            loop: true
        });
        anime({
            targets: '#smoke_fan_3',
            strokeWidth: .1,
            easing: 'linear',
            duration: 4000,
            loop: true
        });
    }

    useEffect(() => { 
        animationElecFlux();
        animHelice()
        animationSmoke()

        anime({
            targets: '.water_flux',
            easing: 'linear',
            translateX:40,
            duration: 3500,
            loop: true
        });
    },[]);
    

    return (
        <svg version="1.1" id="idstation" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 520" >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="illustration" transform="translate(0.000000, 107.000000)">

                    
                    <g id="solar_thermal" transform="translate(30, 80)" strokeWidth="1.1" fillRule="evenodd">
                        <g transform="scale(1.5)">
                            <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="31" y="20.014855" width="3" height="38" rx="1.5"></rect>
                            <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="41" y="20.014855" width="3" height="38" rx="1.5"></rect>
                            <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="51" y="20.014855" width="3" height="38" rx="1.5"></rect>
                            <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="21" y="20.014855" width="3" height="38" rx="1.5"></rect>
                            <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.5" fill="#FFFFFF" x="11" y="20.014855" width="3" height="38" rx="1.5"></rect>
                            <path d="M5.5625376,3.57194738 C3.8541792,5.24702248 3,8.38970669 3,13 C3,17.6102933 3.8541792,20.7529775 5.5625376,22.4280526 C5.93664279,22.7948739 6.43975536,23.0002416 6.96369471,23 L58.3476009,23 C58.9943785,22.9993708 59.6009257,22.6860086 59.975587,22.1587983 C61.3251957,20.2608483 62,17.2079155 62,13 C62,8.79208446 61.3251957,5.73915169 59.975587,3.84120168 C59.6009257,3.31399136 58.9943785,3.00062918 58.3476009,3 L6.96369471,3 C6.43975536,2.99975839 5.93664279,3.20512615 5.5625376,3.57194738 Z" id="Path-36" stroke="#012038" strokeWidth="1.5"></path>
                            <path d="M7.10343361,58.5011344 C7.02744402,58.7960816 6.98944922,59.1290368 6.98944922,59.5 C6.98944922,59.8709632 7.02744402,60.2039184 7.10343361,60.4988656 C7.33104018,61.3826222 8.12803686,62.0002027 9.04063224,62 L55.9252872,62 C56.7923438,62.0011249 57.5613725,61.4434369 57.8296988,60.6189434 C57.9361991,60.2917097 57.9894492,59.9187286 57.9894492,59.5 C57.9894492,59.0812714 57.9361991,58.7082903 57.8296988,58.3810566 C57.5613725,57.5565631 56.7923438,56.9988751 55.9252872,57 L9.04063224,57 C8.12803686,56.9997973 7.33104018,57.6173778 7.10343361,58.5011344 Z" id="Path-36" stroke="#012038" strokeWidth="1.5"></path>
                            <line x1="12.5" y1="22.5" x2="12.5" y2="25.5" id="Line-24" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square"></line>
                            <line x1="32.5" y1="22.5" x2="32.5" y2="25.5" id="Line-24" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square"></line>
                            <line x1="22.5" y1="22.5" x2="22.5" y2="25.5" id="Line-24" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square"></line>
                            <line x1="42.5" y1="22.5" x2="42.5" y2="25.5" id="Line-24" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square"></line>
                            <line x1="52.5" y1="22.5" x2="52.5" y2="25.5" id="Line-24" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square"></line>
                            <line x1="11" y1="58.5" x2="54.5" y2="58.5" id="Line-23" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square"></line>
                            <path d="M31.9986274,6 C29.9033296,8.91102134 28.7623824,10.8759305 28.5757856,11.8947274 C28.0591391,14.7155583 30.2919601,16 31.9986274,16 C33.7201839,16 35.935284,14.6946038 35.4256233,11.8947274 C35.2410736,10.8808836 34.0987416,8.91597446 31.9986274,6 Z" id="Path-20" stroke="#012038" strokeWidth="1.5" strokeLinejoin="round"></path>
                            <path d="M30.5,13 C30.5036835,13.3268868 30.5879377,13.5752752 30.7527626,13.7451651 C30.9175875,13.915055 31.1666667,14 31.5,14" id="Path-21-Copy" stroke="#012038" strokeLinecap="round"></path>
                        </g>
                        <g id="boule_sT" transform="translate(115, 55)">
                            <ellipse stroke="#e89a52" strokeWidth="1.5" fill="#e89a52" cx="0" cy="0" rx="15" ry="15"></ellipse>
                            <g transform="translate(-12, -12)" stroke="#FFFFFF" strokeWidth="0.6">
                                <g>
                                    <path d="M12,1.875 C12.8284271,1.875 13.5,2.54657288 13.5,3.375 L13.4999708,14.93702 C14.824415,15.5156808 15.75,16.8372494 15.75,18.375 C15.75,20.4460678 14.0710678,22.125 12,22.125 C9.92893219,22.125 8.25,20.4460678 8.25,18.375 C8.25,16.8376334 9.17512285,15.5163408 10.4990372,14.9374536 L10.5,3.375 C10.5,2.54657288 11.1715729,1.875 12,1.875 Z" id="Combined-Shape"></path>
                                    <circle id="Oval" cx="12" cy="18.375" r="1.5"></circle>
                                    <line x1="12" y1="16.875" x2="12" y2="5.625" strokeLinecap="round" strokeLinejoin="round"></line>
                                    <line x1="14.625" y1="9" x2="17.625" y2="9" strokeLinecap="round" strokeLinejoin="round"></line>
                                    <line x1="14.625" y1="7.875" x2="16.125" y2="7.875" strokeLinecap="round" strokeLinejoin="round"></line>
                                    <line x1="14.625" y1="10.125" x2="16.125" y2="10.125" strokeLinecap="round" strokeLinejoin="round"></line>
                                    <line x1="14.625" y1="11.25" x2="17.625" y2="11.25" strokeLinecap="round" strokeLinejoin="round"></line>
                                    <line x1="14.625" y1="12.375" x2="16.125" y2="12.375" strokeLinecap="round" strokeLinejoin="round"></line>
                                </g>
                            </g>
                            <text transform="translate(-105, 65)" fontSize="20" fontWeight="normal" fill="#012038">
                                <tspan>37.6 kWh</tspan>
                            </text>
                        </g>

                        <g transform="translate(133, 55)">
                            <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="20" cy="0" rx="7" ry="7"></ellipse>
                            <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="60" cy="0" rx="7" ry="7"></ellipse>
                            <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="100" cy="0" rx="7" ry="7"></ellipse>
                            <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="140" cy="0" rx="7" ry="7"></ellipse>
                            <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="180" cy="0" rx="7" ry="7"></ellipse>
                            <line x1="0" y1="0" x2="230" y2="0" stroke="#E99952" strokeWidth="6.75" strokeLinecap="round"></line>
                        </g>
                    </g>

                    <g id="boiler" transform="translate(356, 66)" strokeWidth="2.079">
                        <g transform="translate(10, -30) scale(2.7) " stroke="#012038">
                            <path d="M19,15 C19,11.3347977 23.3631038,9.50219659 32.0893114,9.50219659 C40.8155189,9.50219659 45.1786227,11.3347977 45.1786227,15 L45.1786227,56.4182212 C45.3630639,58.2475103 40.9999601,59.1621549 32.0893114,59.1621549 C23.1786626,59.1621549 18.8155588,58.2475103 19,56.4182212 L19,15 Z" id="Path-14" stroke="#012038" strokeWidth="1.5" fill="#FFFFFF"></path>
                            <circle id="Oval" stroke="#012038" strokeWidth="1.5" cx="32" cy="19.5" r="5.5"></circle>
                            <line x1="32" y1="19.5" x2="34" y2="17.5" id="Line" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                            <path d="M31.9986274,39 C29.9033296,41.9110213 28.7623824,43.8759305 28.5757856,44.8947274 C28.0591391,47.7155583 30.2919601,49 31.9986274,49 C33.7201839,49 35.935284,47.6946038 35.4256233,44.8947274 C35.2410736,43.8808836 34.0987416,41.9159745 31.9986274,39 Z" id="Path-20" stroke="#012038" strokeWidth="1.5" strokeLinejoin="round"></path>
                            <path d="M30.5,46 C30.5036835,46.3268868 30.5879377,46.5752752 30.7527626,46.7451651 C30.9175875,46.915055 31.1666667,47 31.5,47" id="Path-21-Copy" stroke="#012038" strokeLinecap="round"></path>
                            <path d="M22.09,58.8940216 C22.09,59.4960144 22.09,60.0980072 22.09,60.7 C22.09,61.2522847 22.5377153,61.7 23.09,61.7 L26.09,61.7 C26.6422847,61.7 27.09,61.2522847 27.09,60.7 C27.09,60.291549 27.09,59.8830981 27.09,59.4746471 C27.09,59.0153861 27.09,59.1621549 24.7166169,59.0279193 C22.3432339,58.8936837 22.09,58.74899 22.09,58.8940216 Z" id="Path-35" stroke="#012038" strokeWidth="1.5"></path>
                            <path d="M37.08,59.0604196 C37.08,59.6624124 37.08,60.2644051 37.08,60.8663979 C37.08,61.4186827 37.5277153,61.8663979 38.08,61.8663979 L41.08,61.8663979 C41.6322847,61.8663979 42.08,61.4186827 42.08,60.8663979 C42.08,60.457947 42.08,60.049496 42.08,59.641045 C42.08,59.181784 42.08,59.3285528 39.7066169,59.1943172 C37.3332339,59.0600816 37.08,58.9153879 37.08,59.0604196 Z" id="Path-35" stroke="#012038" strokeWidth="1.5" transform="translate(39.580000, 60.433199) scale(-1, 1) translate(-39.580000, -60.433199) "></path>
                        </g>
                        <g transform="translate(60,165)">
                            <text id="textRight" fontSize="20" fontWeight="normal" fill="#012038">
                                <tspan x="0" y="0">122.7 kWh</tspan>
                            </text>
                        </g>
                    </g>

                    <g id="heat_pumpGroup" transform="translate(757, 66)" strokeWidth="2">
                        <g stroke="none" transform="scale(2.1) translate(-10,-10)" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect stroke="#012038" strokeWidth="1.5" x="7" y="24" width="51" height="35" rx="2"></rect>
                            <circle stroke="#012038" strokeWidth="1.5" cx="24.5" cy="41.5" r="13.5"></circle>
                            <g id="group_helice">
                                <g id="helice" style={{transformOrigin: "center",transformBox: "fill-box"}} stroke="#012038" strokeWidth="1.5">
                                    <path d="M11.1531783,5.49728993 C11.3055945,4.66774041 11.6892828,4.12604519 12.3042434,3.8722043 C12.919204,3.6183634 14.2098513,3.46893324 16.1761853,3.42391382 C13.837081,0.713410936 11.6201531,-0.387787594 9.52540178,0.120318225 C7.43065043,0.628424044 7.18084146,2.42074795 8.77597488,5.49728993 L11.1531783,5.49728993 Z" strokeLinejoin="round"></path>
                                    <path d="M14.1531783,14.4972899 C14.3055945,13.6677404 14.6892828,13.1260452 15.3042434,12.8722043 C15.919204,12.6183634 17.2098513,12.4689332 19.1761853,12.4239138 C16.837081,9.71341094 14.6201531,8.61221241 12.5254018,9.12031822 C10.4306504,9.62842404 10.1808415,11.4207479 11.7759749,14.4972899 L14.1531783,14.4972899 Z" strokeLinejoin="round" transform="translate(14.958899, 11.748645) rotate(103.000000) translate(-14.958899, -11.748645) "></path>
                                    <circle cx="10.2416131" cy="8.5" r="2.5"></circle>
                                    <path d="M4.15317832,13.4972899 C4.30559446,12.6677404 4.68928283,12.1260452 5.30424342,11.8722043 C5.919204,11.6183634 7.20985129,11.4689332 9.17618528,11.4239138 C6.83708097,8.71341094 4.62015313,7.61221241 2.52540178,8.12031822 C0.430650426,8.62842404 0.180841459,10.4207479 1.77597488,13.4972899 L4.15317832,13.4972899 Z" strokeLinejoin="round" transform="translate(4.958899, 10.748645) rotate(223.000000) translate(-4.958899, -10.748645) "></path>
                                </g>
                            </g>
                            <line x1="15.5" y1="59.25" x2="15.5" y2="62.5" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="47.5" y1="59.5" x2="47.5" y2="62.5" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="53" y1="33" x2="44" y2="33" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="53" y1="37" x2="44" y2="37" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="53" y1="41" x2="44" y2="41" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="53" y1="45" x2="44" y2="45" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                        </g>
                        <g id="lineCenter" transform="translate(-250, 68) rotate(-90)">
                            <line x1="0" y1="0" x2="0" y2="200" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                            <g stroke="#012038" strokeLinecap="round" strokeWidth="1.5" transform="translate(-2,200) rotate(270)">
                                <line x1="0.749074406" y1="2.252386" x2="190.1731757" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                            
                                <line x1="36.7305625" y1="2.252386" x2="35.2324137" y2="0.750795334" class="lineCenter"></line>
                                <line x1="36.7305625" y1="2.252386" x2="35.2324137" y2="3.75397667" class="lineCenter"></line>
                                
                                <line x1="85.6935388" y1="0.750795334" x2="87.1916876" y2="2.252386" class="lineCenter"></line>
                                <line x1="87.1916876" y1="2.252386" x2="85.6935388" y2="3.75397667" class="lineCenter"></line>
                                
                                <line x1="135.7305625" y1="2.252386" x2="134.2324137" y2="0.750795334" class="lineCenter"></line>
                                <line x1="135.7305625" y1="2.252386" x2="134.2324137" y2="3.75397667" class="lineCenter"></line>
                            </g>
                        </g>
                        <g id="boule_heat_pump" transform="translate(-40, 69)">
                            <ellipse stroke="#8bdac4" strokeWidth="1.5" fill="#8bdac4" cx="0" cy="0" rx="15" ry="15"></ellipse>
                            <g transform="translate(-47, -20) scale(1.4)" stroke="#FFFFFF" strokeWidth="0.5">
                                <path id="smoke_fan_1" d="M28.9689705,9 C27.7602016,9.62844728 27.1121763,10.408784 27.0248944,11.3410101 C26.8939717,12.7393492 27.2964901,13.1069032 27.8326136,14.0954492 C28.3687371,15.0839951 29.1675465,15.722949 28.9689705,17.4222898 C28.8365865,18.5551836 28.1885612,19.4144204 27.0248944,20"stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path id="smoke_fan_2" d="M33.9689705,9 C32.7602016,9.62844728 32.1121763,10.408784 32.0248944,11.3410101 C31.8939717,12.7393492 32.2964901,13.1069032 32.8326136,14.0954492 C33.3687371,15.0839951 34.1675465,15.722949 33.9689705,17.4222898 C33.8365865,18.5551836 33.1885612,19.4144204 32.0248944,20" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path id="smoke_fan_3" d="M38.9689705,9 C37.7602016,9.62844728 37.1121763,10.408784 37.0248944,11.3410101 C36.8939717,12.7393492 37.2964901,13.1069032 37.8326136,14.0954492 C38.3687371,15.0839951 39.1675465,15.722949 38.9689705,17.4222898 C38.8365865,18.5551836 38.1885612,19.4144204 37.0248944,20"stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                            <text transform="translate(55, 70)" fontSize="20" fontWeight="normal" fill="#012038">
                                <tspan>85.1 kWh</tspan>
                            </text>
                        </g>
                    </g>

                </g>
            </g>
        </svg>

    );
}
export default Animation;