
// Redux
import {useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import React from "react";


export default  function Logout() {

    let history = useHistory();
    const dispatch = useDispatch();

    React.useEffect(() => { 
        localStorage.clear();
        dispatch({type:'SET_INFOS',payload:{}});
        dispatch({type:'SET_CUR_DEVICE',payload:{}});
        dispatch({type:'DEVICE_IS_CONNECTED',payload:0});
        setTimeout(() => {history.push('/auth')}, 1000);
    })
    return (<div></div>);
}