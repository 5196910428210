import React,{useState,useEffect} from "react";
import { withTranslation } from 'react-i18next';
import Card from "components/card/Card.js";
import {Flex, Text, useColorModeValue,} from "@chakra-ui/react";
import PowerIcon from "components/icons/PowerIcon";

function DigitalInput(props) {
  var { title,subtitle, current,onAction } = props;
  const [value, setValue] = useState(0);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
 
  useEffect(() => { 
    setValue(current)
  },[])

  function handleChange(val){
    onAction(val?1:0,val?"ON":"OFF")
    setValue(val)
  }

  return (
    <Card>
    <Flex 
        align={{ sm: "flex-start", lg: "center" }}
        justify='space-between'
      >
      <Flex direction='column'>
        <Text
          color={textColorBid}
          fontSize={{ base: "sm"}}
          fontWeight='400'>
          {subtitle}
        </Text>
        <Text
          color={textColor}
          fontSize={{
            base: "xl",
            md: "lg",
            lg: "lg",
            xl: "lg",
            "2xl": "md",
            "3xl": "lg",
          }}
          fontWeight='bold'>
          {title}
        </Text>
      </Flex>
      <PowerIcon
        state={value} 
        onChange={()=>{
          console.log("val",!value?1:0);
          handleChange(!value?1:0);
          setValue(!value);
        }}
      />
    </Flex>
  </Card>
  );
}
export default withTranslation()(DigitalInput);