import { withTranslation } from 'react-i18next';
// Chakra imports
import {Flex,Box} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
// Custom components
import Card from "components/card/Card.js";

import React from "react";

import 'rsuite/dist/rsuite.min.css';

import AnimationBattery from "./battery/index2";
import AnimationHome from "./home/index2";
import AnimationSolar from "./solar/index2";
import Donut from "./Donut";

function AnimationDetail(props) {
  const { ...rest } = props;
  const {cur_device,type,t} = props;


  React.useEffect(() => {  

    return () => {}
  },[props.data,cur_device]);
  
  const getRequestAnimation=()=>{
    if (type==="battery") {
      return <AnimationBattery/>
    }
    else if (type==="home") {
      return <AnimationHome/>
    }
    else if (type==="solar_panel") {
      return <AnimationSolar/>
    }
  }

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}>
      <Box w='100%' align='center' >
        <ReactApexChart
          options={{
            chart: {
              type: 'radialBar',
              sparkline: { enabled: true }
            },
            colors:["#0068b6"],
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: "#e7e7e7",
                  strokeWidth: '97%',
                  margin: 5, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    color: '#999',
                    opacity: 1,
                    blur: 2
                  }
                },
                dataLabels: {
                  name: {
                    color: 'black',
                    fontSize: '15px',
                    fontWeight: 'light'
                  },
                  value: {
                    offsetY: -40,
                    fontSize: '25px',
                    fontWeight: 'bold',
                    color: '#0068b6'
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91]
              },
            },
            labels: [props.title2],
          }
        }
        series={[props.data.inner]}
        width={'100%'}
        type={'radialBar'}
          height={300}
        />
        {getRequestAnimation()}
        <Box mt={{ sm:"-30px", md:"-170px", lg:"-300px" }} w='100%' justify="flex-end" >
          {type==="battery"?
            <Flex mt={{ sm:"-30px", md:"-140px", lg:"130px" }}>
              <Box w={{ sm:"0%", md:'65%', lg:'70%' }} ></Box>
              <Box w={{ sm:"100%", md:'35%', lg:'30%' }}>
                <ReactApexChart
                  options={{
                    chart: {
                      type: 'radialBar',
                      sparkline: { enabled: true }
                    },
                    colors:["#75cb10"],
                    plotOptions: {
                      radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                          background: "#e7e7e7",
                          strokeWidth: '97%',
                          margin: 5, // margin is in pixels
                          dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                          }
                        },
                        dataLabels: {
                          name: {
                            color: 'black',
                            fontSize: '15px',
                            fontWeight: 'light'
                          },
                          value: {
                            offsetY: -40,
                            fontSize: '25px',
                            fontWeight: 'bold',
                            color: '#75cb10'
                          }
                        }
                      }
                    },
                    fill: {
                      type: 'gradient',
                      gradient: {
                        shade: 'light',
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91]
                      },
                    },
                    labels: [t("roundcycle_losses")]
                  }
                }
                series={[props.data.roundcycleVal]}
                width={300}
                type={'radialBar'}
                  height={300}
                />
              </Box>
            </Flex>
           :<Donut 
              title={props.title}
              data={props.data}
            />}
        </Box>
      </Box>
        
    </Card>
  );
}

export default withTranslation()(AnimationDetail);