// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import Select from 'react-select';
// Assets
import { MdLanguage } from "react-icons/md";
import routes from "routes.js";
// Redux
import { useSelector,useDispatch } from 'react-redux';

import chroma from 'chroma-js';

import { withTranslation } from 'react-i18next';
import i18n from 'assets/locales/i18n';
import moment from "moment"; 
import 'moment/locale/fr' 

import {Post} from '../../views/admin/devices/queries'
import {onRefresh,onLogout} from "utils/Function";



function HeaderLinks(props) {
  const { ...rest } = props;
  const { showSelectDevice,t} = props;
  let history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  const myInfos = useSelector(state => state.user.infos);
  const cur_device = useSelector(state => state.device.cur_device);
  const isConnected = useSelector(state => state.device.isConnected);

  const dispatch = useDispatch();
  //const [isRefresh,setIsRefresh]= React.useState(false);
  var [devicesList, setDevicesList] = React.useState({});
  var _intervalId;

  var response,[language, setLanguage] = React.useState(i18n.language==="fr"?"Français":"English");
  

  React.useEffect(() => { 
    (async function() {
      listDevices()
      i18n.changeLanguage(i18n.language);
      if(!myInfos.token){
        dispatch({type:'SET_INFOS',payload:{}});
        dispatch({type:'SET_CUR_DEVICE',payload:{}});
        dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:0,last_message:null}});
        history.push('/')
      }else{
        _intervalId =setInterval(async function() {
          if(cur_device.value){
            response=await Post.requestPost("getLastState",{token:myInfos.token,device_id:cur_device.value})
            if(response){
              if(response.message) dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:((new Date()).getTime()-(new Date(response.last_message)).getTime())<90*1000?1:0,last_message:response.last_message}});
              else dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:0,last_message:response.last_message}});
            }else dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:0,last_message:null}});
          }
        }, 30*1000);
      }
    })();
    return () => {clearInterval(_intervalId);}
  },[]);
  
  const listDevices= async ()=>{
    response=await Post.requestPost("listDevices",{token:myInfos.token})
    if(!response.hasOwnProperty("error")){
      var all_devices=[]
      response.forEach(elt => {
        all_devices.push({
          label:elt.title,
          value:elt.id, 
          color: ((new Date()).getTime()-(new Date(elt.last_message)).getTime())<90*1000?"green":"red",
          last_message:elt.last_message,
          isConnected:((new Date()).getTime()-(new Date(elt.last_message)).getTime())<90*1000?1:0
        })
      });
      all_devices.sort(function(a, b) { return (new Date(b.last_message)).getTime() - (new Date(a.last_message)).getTime(); });
      setDevicesList(all_devices)
      if(!cur_device.hasOwnProperty("label")){
        if(all_devices.length>0){
          dispatch({type:'SET_CUR_DEVICE',payload:all_devices[0]});
          response=await Post.requestPost("getLastState",{token:myInfos.token,device_id:all_devices[0].value})
          if(response){
            if(response.message) dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:((new Date()).getTime()-(new Date(response.last_message)).getTime())<90*1000?1:0,last_message:response.last_message}});
            else dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:0,last_message:null}});
          }else dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:0,last_message:null}});
        }
      }
    }
  } 

  const changeLanguage = (lng) => {
    if(lng==="fr")setLanguage("Français")
    else setLanguage("English")
    i18n.changeLanguage(lng);
  }

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={showSelectDevice ? { base: "wrap", md: "nowrap" } : "unset"}
      p='5px'
      borderRadius='30px'
      boxShadow={shadow}>
      <Flex
        bg={ethBg}
        display={showSelectDevice ? "flex" : "none"}
        borderRadius='30px'
        ms='auto'
        pt='5px'
        pl='20px'
        pr='16px'
        align='center'
        me='6px'>
        {/*<Flex
          align='center'
          justify='center'
          bg={isConnected.state===1?greenColor:redColor}
          h='29px'
          w='29px'
          borderRadius='30px'
          me='7px'>
          <Icon color={ethBox} w='70px'  as={MdPowerSettingsNew} />
        </Flex>*/}

        {cur_device.hasOwnProperty("label")?<div><Select 
          defaultValue={cur_device}
          isMulti
          onChange={async (itm)=>{ 
            if(itm.length===1){
              itm=itm[0]
              console.log("itm--",itm);
              dispatch({type:'SET_CUR_DEVICE',payload:itm});
              response=await Post.requestPost("getLastState",{token:myInfos.token,device_id:itm.value})
              if(response){
                if(response.message) dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:((new Date()).getTime()-(new Date(response.last_message)).getTime())<90*1000?1:0,last_message:response.last_message}});
                else dispatch({type:'DEVICE_IS_CONNECTED',payload:0});
              }else dispatch({type:'DEVICE_IS_CONNECTED',payload:0});
            }else if(itm.length>1){
              let device_id=[],item={label:"Multi Device",value:"multi_devices"}
              itm.forEach(async element => {
                device_id.push(element.value)
                item.label=element.label
                item.value=element.value
              });
              
              item.device_id=device_id
              dispatch({type:'SET_CUR_DEVICE',payload:item});
              response=await Post.requestPost("getLastState",{token:myInfos.token,device_id})
              if(response){
                if(response.message) dispatch({type:'DEVICE_IS_CONNECTED',payload:{state:((new Date()).getTime()-(new Date(response.last_message)).getTime())<90*1000?1:0,last_message:response.last_message}});
                else dispatch({type:'DEVICE_IS_CONNECTED',payload:0});
              }else dispatch({type:'DEVICE_IS_CONNECTED',payload:0});
            }
          }}
          options={devicesList}
          placeholder={t("select_device")}
          styles={{
            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = chroma(data.color);
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? data.color
                  : isFocused
                  ? color.alpha(0.1).css()
                  : undefined,
                color: isDisabled
                  ? '#ccc'
                  : isSelected
                  ? chroma.contrast(color, 'white') > 2
                    ? 'white'
                    : 'black'
                  : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
          
                ':active': {
                  ...styles[':active'],
                  backgroundColor: !isDisabled
                    ? isSelected
                      ? data.color
                      : color.alpha(0.3).css()
                    : undefined,
                },
              };
            },
            multiValue: (styles, { data }) => {
              const color = chroma(data.color);
              return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
              };
            },
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              color: data.color,
            }),
            multiValueRemove: (styles, { data }) => ({
              ...styles,
              color: data.color,
              ':hover': {
                backgroundColor: data.color,
                color: 'white',
              },
            }),
          }}
        />{/*isConnected.state==0*/true?<Text
        color={textColor}
        fontWeight='700'
        fontSize="9px">
         {t("last_message")+": "+moment(new Date(isConnected.last_message)).fromNow() }
      </Text>:null}</div>:null}
      </Flex>
      <SidebarResponsive routes={routes(t)}  {...rest}/>

      <Menu>
        <MenuButton p='0px'>
          <Icon
            mt='6px'
            as={MdLanguage}
            color={navbarIcon}
            w='18px'
            h='18px'
            me='10px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='20px'
          me={{ base: "30px", md: "unset" }}
          borderRadius='20px'
          bg={menuBg}
          border='none'
          mt='22px'
          minW={{ base: "unset" }}
          maxW={{ base: "360px", md: "unset" }}>
          <Flex flexDirection='column'>
            <Button w='100%' h='44px' mb='10px' border='1px solid' 
              variant={language==="Français"?'brand': ""}
              borderColor={borderButton}
              onClick={() => changeLanguage('fr')}>
              Français
            </Button>
            <Button  w='100%' h='44px'  mb='10px' border='1px solid'  
              variant={language==="English"?'brand': ""}
              borderColor={borderButton}
              onClick={() => changeLanguage('en')}>
              English
            </Button>
          </Flex>
        </MenuList>
      </Menu>

      {/*<Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        h='18px'
        w='max-content'
        onClick={toggleColorMode}>
        <Icon
          me='10px'
          h='18px'
          w='18px'
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button>*/}

      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: "pointer" }}
            color='white'
            name={myInfos.lastname===''?myInfos.firstname:myInfos.lastname+" "+myInfos.firstname}
            bg='secondary.dark'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              👋&nbsp; Hey, {myInfos.firstname}
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius='8px'
              px='14px'
              onClick={()=>{ history.push('/profile')}}
            >
              <Text fontSize='sm'>{t("profile")}</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius='8px'
              px='14px'
              onClick={async()=>{
                //setIsRefresh(true)
                let updateUserData=await onRefresh(myInfos.email,myInfos.password)
                if(updateUserData)dispatch({type:'SET_INFOS',payload:updateUserData});
                window.location.reload();
                //setIsRefresh(false)
            }}>
              <Flex alignItems='center' justifyContent='center'>
                {/*isRefresh?<Spinner size='xs'/>:null*/}
                <Text fontSize='sm'>{t("Refresh")}</Text>
              </Flex>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='red.400'
              borderRadius='8px'
              px='14px'
              onClick={()=>{
                localStorage.clear();
                dispatch({type:'SET_INFOS',payload:{}});
                dispatch({type:'SET_CUR_DEVICE',payload:{}});
                dispatch({type:'DEVICE_IS_CONNECTED',payload:0});
                onLogout(myInfos.token)
                setTimeout(() => {history.push('/auth')}, 1000);
              }}>
              <Text fontSize='sm'>{t("logOut")}</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
export default withTranslation()(HeaderLinks)

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  showSelectDevice: PropTypes.bool,
  onOpen: PropTypes.func,
};
