import {
  Flex,Button,Box,
  Modal,SimpleGrid,
  ModalOverlay,FormControl,FormLabel,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,Input,
  ModalCloseButton,NumberInputField,NumberInput,
  useColorModeValue,Text,Select,Spacer,Divider,

  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

import Switch from "react-switch";

// Redux
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React from "react";
import RowConfig from './RowConfig';

function ModalAlertConfig(props) {
  const {t,sensorsList,isOpen, onClose,onSubmit,showErrorMsg,alert}=props;
  
  // Chakra color mode
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const myInfos = useSelector(state => state.user.infos);

  const [values, setValues] = React.useState({
    title:"",
    hourStart:0,
    hourEnd:23,
    daily_max:3,
    isEnable:true,
    delay:15,
    address_mail:myInfos.email
  });
  const [variables, setVariables] = React.useState([]);
  const [listConditions, setListConditions] = React.useState([{id:0,formula:null,duration:0}]);
  const [listActions, setListActions] = React.useState([{id:0,formula:null,duration:0}]);
  

  React.useEffect(async () => { 
    if(alert)
      if(alert.hasOwnProperty("_id")){
        setValues(alert.info)
        setListConditions(alert.listConditions)
        if(alert.listActions.length>0)
          setListActions(alert.listActions)
      }
      if(sensorsList){
        var _variables=[]
        sensorsList.forEach(element => { _variables.push(element.id)});
        setVariables(_variables)
      }
    return () => {}
  },[props]);


  const handleSubmit = async (e) => { 
    e.preventDefault();
    let itm={}
    if(alert.hasOwnProperty("_id"))itm._id=alert._id
    itm.listConditions=listConditions
    itm.listActions=listActions
    itm.info=values

    if(itm.info.title!==""){
      if(itm.listActions[0].formula===null)itm.listActions=[]
      if(itm.listConditions[0].formula===null){
        showErrorMsg(t("conditions_check"))
      }else onSubmit(itm)
    }else showErrorMsg(t("title"))
  }

  return (
    <Modal  closeOnOverlayClick={false} blockScrollOnMount={false} isCentered isOpen={isOpen} 
      size={"3xl"} 
      onClose={()=>{
        onClose()
      }}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent>
        <ModalHeader>{t("programming")}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Box pb={4}>
            <Flex
              w="100%"
              justifyContent={"space-between"}
              align={"center"}
            >
              <Box w="50%">
                <Text fontSize='md'>{t("title")}</Text>
                <Input
                  size="md"
                  type="text"
                  onChange={(title) => 
                    setValues({...values,"title":title.target.value})
                  }
                  value={values.title}
                />
              </Box>

              <Flex w={"30%"} ms="15px" justify='center' align='center' zIndex={1}>
                <Box >
                  <Text fontSize='sm'>{t("start_time")}</Text>
                  <Select
                    w='100%'
                    placeholder={t("start_time")}
                    defaultValue={values.hourStart}
                    onChange={(itm)=>{
                      if(Number(itm.target.value) < Number(values.hourEnd))
                        setValues({...values,"hourStart":itm.target.value})
                    }}
                  >
                    {[0,1,2,3,4,5,6,7,8,
                      9,10,11,12,13,14,15,
                      16,17,18,19,20,21,22,23].map((hour)=>{
                      return <option 
                        value={hour}
                      >{hour}</option>
                      })
                    }
                  </Select>
                </Box>
                <Box w={"10%"} h="2px" mt="10px" me="5px" ml="5px" bg={"grey"}/>
                <Box >
                  <Text fontSize='sm'>{t("end_time")}</Text>
                  <Select
                    w='100%'
                    placeholder={t("end_time")}
                    defaultValue={values.hourEnd}
                    onChange={(itm)=>{
                      if(Number(itm.target.value) > Number(values.hourStart))
                        setValues({...values,"hourEnd":itm.target.value})
                    }}
                  >
                    {[0,1,2,3,4,5,6,7,8,
                      9,10,11,12,13,14,15,
                      16,17,18,19,20,21,22,23].map((hour)=>{
                      return <option 
                        value={hour}
                      >{hour}</option>
                      })
                    }
                  </Select>
                </Box>
              </Flex>
              <Spacer/>
              <Box>
                <Text fontSize='md'>{t("enabled")}</Text>
                <Switch
                  checked={values.isEnable}
                  onChange={(state)=>{
                    setValues({...values,"isEnable":state})
                  }}
                  height={20}
                  width={40}
                />
              </Box>
            </Flex>
          </Box>

          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton _expanded={{ bg:"#0064b5", color: 'white' }}>
                  <Box as="span" flex='1' textAlign='left'>
                    {t("conditions_check")}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={2} >
                  {listConditions.map((condition, index) => (
                    <RowConfig
                      titleAction={t("duration")}
                      type={"formula"}
                      variables={variables}
                      sensors={sensorsList}
                      condition={condition}
                      btnAdd={condition.id===0?true:false}
                      btnAction={(elt)=>{
                        if(elt.id===0){ 
                          setListConditions([...listConditions,{id:(new Date()).getTime(),duration:0}])
                        }else{
                          setListConditions(listConditions.filter(item => item.id !== elt.id))
                        }
                      }}
                      updateField={async (label,value)=>{
                        let _listConditions=listConditions
                        _listConditions[index][label]=value
                        await setListConditions(_listConditions)
                      }}
                    />
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton _expanded={{ bg:"#0064b5", color: 'white' }}>
                  <Box as="span" flex='1' textAlign='left'>
                  {t("actions_take")}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={2} >
                  {listActions.map((action, index) => (
                    <RowConfig
                      titleAction={t("value")}
                      variables={variables}
                      sensors={sensorsList}
                      condition={action}
                      btnAdd={action.id===0?true:false}
                      btnAction={(elt)=>{
                        if(elt.id===0){ 
                          setListActions([...listActions,{id:(new Date()).getTime()}])
                        }else{
                          setListActions(listActions.filter(item => item.id !== elt.id))
                        }
                      }}
                      updateField={async (label,value)=>{
                        let _listActions=listActions
                        _listActions[index][label]=value
                        await setListActions(_listActions)
                      }}
                    />
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton _expanded={{ bg:"#0064b5", color: 'white' }}>
                  <Box as="span" flex='1' textAlign='left'>Notification</Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={2}>
                  <SimpleGrid columns={{base:1,md:2,xl:2}} gap='20px'>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        mb='0px'>
                        {t("delay_alert")}
                      </FormLabel>
                      <NumberInput  min={1}
                        value={values.delay}
                        onChange={(val)=>setValues({...values,"delay":Number(val)})}
                      >
                        <NumberInputField/>
                      </NumberInput>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        mb='0px'>
                        {t("daily_max")}
                      </FormLabel>
                      <NumberInput  min={1} 
                        max={100}
                        value={values.daily_max}
                        onChange={(val)=>setValues({...values,"daily_max":Number(val)})}
                      >
                        <NumberInputField/>
                      </NumberInput>
                    </FormControl>
                  </SimpleGrid>
                  <FormControl>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      mb='0px'>
                      {t("email_addresses_notify")}
                    </FormLabel>
                    <Input 
                      width={"100%"}
                      size="md"
                      type="text"
                      value={values.address_mail}
                      onChange={(val)=>setValues({...values,"address_mail":val.target.value})}
                    />
                  </FormControl>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            <Button  
              width='140px' 
              colorScheme='green'  
              onClick={handleSubmit}
            >{alert.hasOwnProperty("_id")?t("modify"):t("valid")}
            </Button>
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalAlertConfig);