import {useState,useEffect} from "react";
import anime from "animejs";
import "./styles.css"

function Animation(props) {
    const [prodLine1, setProdLine1] = useState(87);
    const [prodLine2, setProdLine2] = useState(184);
    const [prodLine3, setProdLine3] = useState(382);
    const [prodLine4, setProdLine4] = useState(0);
    const [totalImput, setTotalImput] = useState(1134);
    const [gridProd, setGridProd] = useState(0);
    const [groupProd, setGroupProd] = useState(114);

  const max = 2000;
  const animationSmoke=() => {
    anime({
        targets: '.smoke_1',
        strokeWidth: .1,
        easing: 'linear',
        duration: 2700,
        scale: .9,
        loop: true
      });
    anime({
        targets: '.smoke_2',
        strokeWidth: .1,
        scale: 1.1,
        easing: 'linear',
        duration: 5000,
        loop: true
    });
    anime({
        targets: '.smoke_3',
        strokeWidth: .1,
        scale: .9,
        easing: 'linear',
        duration: 4000,
        loop: true
    });
  }
  const animation=() => {
  }
  const animHelice=() => {
    anime({
      targets: ".helice",
      rotate: 360, 
      easing: 'linear',
      loop: true,
      duration: 3000
    });
  }
  const animationElecFlux=() => {
    anime({
      targets: '#borne_charge_flux',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 6000,
      loop: true
    });
    anime({
      targets: '.borne_charge_flux',
      easing: 'easeInOutSine',
      translateX:[-20,25],
      duration: 6000,
      loop: true
    });

    anime({
        targets: '#grid_flux',
        strokeDashoffset: 150,
        direction: 'reverse',
        easing: 'linear',
        duration: 5000,
        loop: true
      });
    anime({
        targets: '.grid_flux',
        easing: 'linear',
        translateX:[-70,45],
        duration: 4000,
        loop: true
    });

    
  }

  const animationVerticalFlux=() => {
    anime({
      targets: '#flux_vertical_group',
      strokeDashoffset: 100,
      easing: 'linear',
      duration: 6000,
      loop: true
    });
    anime({
      targets: '.flux_vertical_group',
      easing: 'linear',
      translateX:40,
      duration: 6000,
      loop: true
    });

    anime({
        targets: '#flux_horizontal_group',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: 6000,
        loop: true
      });
    anime({
        targets: '.flux_horizontal_group',
        easing: 'linear',
        translateX:[-15,18],
        duration: 6000,
        loop: true
    });
     
  }
  const animationfluxToEngine=() => {
    anime({
      targets: '#fluxBottomEngine',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 4500,
      loop: true
    });
     
    anime({
        targets: '#fluxToEngine_1, #fluxToEngine_2',
        strokeDashoffset: 100,
        easing: 'linear',
        duration: 4500,
        loop: true
    });
    anime({
        targets: '.fluxToEngine_1, .fluxToEngine_3',
        easing: 'linear',
        direction: 'reverse',
        translateX:[-30,38],
        duration: 4500,
        loop: true
    });
    anime({
        targets: '#fluxToEngine_3',
        strokeDashoffset: 100,
        easing: 'linear',
        duration: 3900,
        loop: true
    });
    anime({
        targets: '.fluxToEngine_2',
        easing: 'linear',
        direction: 'reverse',
        translateX:[-30,38],
        duration: 3900,
        loop: true
    });



      anime({
        targets: '#fluxTopEngine',
        strokeDashoffset: 100,
        easing: 'linear',
        duration: 4500,
        loop: true
      });
      anime({
        targets: '.fluxTopEngine',
        easing: 'linear',
        translateX:[-7,10],
        duration: 4500,
        loop: true
      });
  }

  
  useEffect(() => { 
    animation();
    animHelice(); 
    animationSmoke();
    animationElecFlux();
    animationVerticalFlux();
    animationfluxToEngine();

    setInterval(() => {
        setProdLine1(Math.floor(Math.random() * max));
        setProdLine2(Math.floor(Math.random() * max));
        setProdLine3(Math.floor(Math.random() * max));
    }, 4000);
    setInterval(() => {
        //setProdLine4(Math.floor(Math.random() * max));
        //setGridProd(Math.floor(Math.random() * max));
        setTotalImput(Math.floor(Math.random() * max));
        setGroupProd(Math.floor(Math.random() * max));
    }, 5000);
  },[]);
  
  return (
    <svg version="1.1" id="idstation" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1010 910" >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="illustration" transform="translate(0.000000, 7.000000)">
            <g transform="translate(704.11234, 200) scale(0.4)" >
                <path fill="#fff" stroke="none" d="M0 2C16.4598 -1.91875 22.358 11.5199 28 25L29 25C29 19.3892 26.8369 8.09964 30.0278 3.3179C32.7461 -0.755692 41.3812 0.321587 42.6821 5.05864C45.3292 14.6979 43 28.0023 43 38C43 41.3894 43.9536 46.7129 41.9722 49.6821C34.6399 60.6703 24.3897 43.9812 21.5756 39C18.8159 34.115 16.1578 29.1827 14 24L13 24C13 29.911 17.3086 47.2177 11.6821 50.9722C8.71079 52.955 3.40753 52 0 52L0 63L6 63L4 67C7.04018 70.6566 3.78661 72.1401 0 72C3.702 76.2365 15.6272 74 21 74C41.6154 74 65.618 77.4492 85.8873 73.8519C91.7001 72.8202 88.7137 65.2698 97 65L96 74L101 72L99 65L103 67L107 65L103 74C119.807 74 141.902 77.7584 158 73C157.236 67.1809 159.229 65.189 165 65L165 74L202 74L216 72C210.805 70.7203 209.387 64.0009 216 65L216 51C209.074 51.8753 201.234 53.4442 195.001 49.2971C182.261 40.8212 183.525 22.5127 197 15.4421C203.223 12.1771 209.395 13.4787 216 14L216 0L144 0L144 52L130 52L130 0L33 0C25.8197 0 4.77184 -3.4608 0 2z"/>
                <path fill="#0169b7" stroke="none" d="M130 0L130 52L144 52L144 0L130 0M0 52C3.35721 52 9.61069 53.1787 12.3966 50.9722C17.3153 47.0765 13.0006 29.6743 13 24L14 24C16.104 31.3037 22.14 46.6234 28.6096 50.9722C31.2309 52.7342 39.8453 53.0821 41.9722 50.3966C44.1751 47.6153 43 41.3281 43 38C43 27.4596 44.6214 15.4445 42.8519 5.05864C42.0151 0.147614 31.7817 -1.10899 29.6034 3.3179C26.969 8.67169 29 19.0782 29 25C26.4965 20.7147 24.1739 16.3176 21.7191 12C19.2764 7.70346 4.01354 -8.29987 0.317901 4.14815C-3.71213 17.7225 0 37.8028 0 52z"/>
                <path fill="#75cb10" stroke="none" d="M99 53C100.255 42.8383 90.8633 34.6076 93.5733 25C97.4537 11.243 115.908 12.0295 120.142 25C122.797 33.1334 113.945 45.2036 113 54C135.583 44.5331 130.676 7.68383 104 11.3048C83.2075 14.1271 76.3302 47.6097 99 53z"/>
                <path fill="#0169b7" stroke="none" d="M82 39L61 39C66.7122 37.3116 74.4585 39.967 79.6821 37.3966C86.823 33.8828 79.1457 18.8574 74.9066 15.9946C57.4643 4.21522 38.0493 27.6067 48.7276 43.9961C53.3397 51.0749 74.4891 57.6031 80.9722 49.2724C82.8896 46.8086 82 41.9305 82 39z"/>
                <path fill="#75cb10" stroke="none" d="M169 38C162.132 42.5324 156.891 37.3878 150.318 39.7732C146.63 41.1114 147.427 48.6094 150.318 50.3472C155.508 53.4678 167.534 52.9953 173 50.8519C191.862 43.4544 184.561 13.079 165 13.0802C156.731 13.0808 139.982 29.0743 149.742 36.9722C153.51 40.0211 164.318 38 169 38z"/>
                <path fill="#0169b7" stroke="none" d="M216 51L215 39C211.379 39.6719 207.381 40.7204 204.109 38.3966C199.004 34.7708 200.115 27.716 206.015 25.6181C208.823 24.6196 212.15 25.6401 215 26L216 14C209.218 13.1429 202.365 12.0979 196 15.4421C183.5 22.0092 182.163 41.3505 194.004 49.2971C200.188 53.447 209.087 52.2834 216 51z"/>
                <path fill="#fff" stroke="none" d="M100 48L113 48C115.098 40.8688 121.638 34.8519 120.797 27C119.36 13.5874 100.448 9.5843 94.3179 22.0039C89.7481 31.2631 97.5635 39.5424 100 48M215 23C212.139 26.929 207.023 23.6744 203.228 26.6034C191.161 35.9178 210.121 39.6918 216 40L215 23M60 27L68 27C65.6377 23.3733 62.7934 24.1584 60 27M162 27L170 27C167.145 24.0871 164.536 23.5008 162 27z"/>
                <path fill="#75cb10" stroke="none" d="M99 27C96.0264 35.122 102.43 45.2424 111.981 40.6674C116.728 38.394 118.458 32.6257 120 28C114.726 28.0151 109.844 28.0066 107 33L99 27z"/>
                <path fill="#0169b7" stroke="none" d="M104.109 46.5872C96.674 48.767 101.598 62.1675 107.715 59.9174C115.668 56.9915 113.732 43.7659 104.109 46.5872z"/>
                <path fill="#0165b7" stroke="none" d="M100 48L101 49L100 48z"/>
                <path fill="#57a879" stroke="none" d="M99 49L99 54L100 54L99 49M113 49L113 52L114 52L113 49M112 52L113 53L112 52z"/>
                <path fill="#0169b7" stroke="none" d="M0 63C0.0511558 65.236 -0.693124 70.5564 1.62346 71.9028C5.18733 73.9741 7.32451 69.2133 4 67L6 63L0 63M41 63C38.9014 67.2804 39.1459 72.5922 45 72L43 70L45 67L45 66L41 63M215 73L216 72C215.058 58.7578 206.663 68.4468 215 73M9 65L10 73L11 73L12 68L13 68L14 73L15 73L16 68L17 68L18 73C23.6098 65.5541 14.3515 65.0006 9 65M29 73C33.0445 58.798 14.6093 71.3892 29 73M32 65L33 73L38 65L32 65M54.4444 66.0463C50.5995 68.6129 54.7646 74.3698 58.3997 71.777C61.8408 69.3226 58.206 63.5354 54.4444 66.0463M62 72L66 72L66 68L67 68L68 73C73.2273 66.0619 62.3406 61.6289 62 72M74.3071 66.0463C70.7319 68.3551 73.858 73.7772 77.5671 71.7122C81.4549 69.5477 78.3097 63.4615 74.3071 66.0463M82 65L83 73L84 73L88 67L82 65M89 74C95.9316 73.9178 96.9765 71.636 97 65C89.7743 65.0856 89.4808 67.4794 89 74M99 65L99 74C104.1 73.1701 105.646 69.6845 107 65L103 67L99 65M115 65L116 73L117 73L118 68L119 68L120 73L121 73L122 68L123 68L124 73C129.61 65.5541 120.351 65.0006 115 65M129 65C128.435 70.9905 130.01 72.5647 136 72C135.712 66.7963 134.223 65.1737 129 65M138 65L139 73L140 73L141 68L142 68L145 73C146.696 66.7738 143.873 65.07 138 65M148 66C148.373 70.6698 149.162 72.3087 154 73C155.531 67.6235 153.805 64.4542 148 66M157 74L165 74L165 65C158.024 65.0831 157.4 67.501 157 74M169.444 66.0463C165.599 68.6129 169.765 74.3698 173.4 71.777C176.841 69.3226 173.206 63.5354 169.444 66.0463M177 72L184 73L185 68L186 68L187 73C192.996 65.0419 177.371 60.6875 177 72M192.607 66.0463C189.456 68.397 193.097 73.6885 196.486 71.7122C200.505 69.3685 196.247 63.3318 192.607 66.0463M201 65L202 73L203 73L204 68L205 68L205 72L209 72C208.678 66.1964 206.584 65.0665 201 65z"/>
                <path fill="#fff" stroke="none" d="M0 68L1 69L0 68M92 68L92 70L94 68L92 68M160 68L162 70L162 68L160 68M181.333 68.6667L181.667 69.3333L181.333 68.6667M215.333 68.6667L215.667 69.3333L215.333 68.6667M2 69L3 70L2 69z"/>
            </g>
                
            <g id="maison" transform="translate(0.000000, 83.800636)" stroke="#012038">
                <line x1="553" y1="658" x2="940.299056" y2="658" strokeWidth="3.8" strokeLinecap="round"  strokeDasharray="4.49999988079071"></line>
                <line x1="553.776663" y1="173.433722" x2="941.797205" y2="173.433722" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                <path d="M514.824794,156.916225 L747.03786,0.750795334 M553.776663,131.389183 L553.027589,655 M979.250926,156.916225 L747.03786,0.750795334 M941.797205,132.890774 L941.797205,655" id="Combined-Shape" strokeWidth="1.8" strokeLinecap="round"></path>
            </g>

            <text id="grid_ouput" fontFamily="Montserrat-Bold, Montserrat" fontSize="18" fontWeight="bold" fill="#012038">
                <tspan x="170" y="400">{gridProd} kW</tspan>
            </text>   
            <g transform="translate(29, 220)" stroke="#012038" strokeWidth="1.8">
                <g id="grid">
                    <path d="M44.7983254,212.678053 L12.3988585,259.288062 L40.8028448,166.550144 L53.205626,75.9512563 L70.5107744,4.65591276 C70.6670992,4.01187152 71.3159241,3.61649921 71.9599653,3.772824 C72.390269,3.87726937 72.7280871,4.21019378 72.8387999,4.63892767 L91.2539313,75.9512563 L91.2539313,75.9512563 L103.57604,167.487169 L130.882901,259.288062 L100.31951,212.678053 L44.7983254,212.678053 Z" id="Path-32" strokeLinejoin="round"></path>
                    <polygon id="Path-33" strokeLinejoin="round" points="8.23981846 79.5314955 58.5063544 54.7552494 85.1408364 54.7552494 131.837095 79.5314955"></polygon>
                    <polygon id="Path-33" strokeLinejoin="round" points="8.23981846 141.472111 48.3152992 116.695864 96.3673747 116.695864 131.837095 141.472111"></polygon>
                    <line x1="53.6245523" y1="80.6672833" x2="96.2560611" y2="115.945069" id="Line" strokeLinecap="square"></line>
                    <line x1="48.3152992" y1="115.945069" x2="91.7616147" y2="79.9068931" id="Line-2" strokeLinecap="square"></line>
                    <line x1="27.3412158" y1="212.046872" x2="102.248656" y2="168.500743" id="Line-3" strokeLinecap="square"></line>
                    <line x1="115.731996" y1="210.545281" x2="40.8245551" y2="166.999152" id="Line-4" strokeLinecap="square"></line>
                    <line x1="42.3227039" y1="166.999152" x2="102.248656" y2="166.999152" id="Line-5" strokeLinecap="square"></line>
                    <line x1="27.3412158" y1="212.647508" x2="115.731996" y2="212.647508" id="Line-6" strokeLinecap="square"></line>
                    <line x1="8.23981846" y1="79.5314955" x2="8.23981846" y2="87.7902441" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="2.05995462" y1="88.3533406" x2="14.4196823" y2="88.3533406" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="125.657232" y1="88.3533406" x2="138.016959" y2="88.3533406" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="131.837095" y1="79.5314955" x2="131.837095" y2="87.7902441" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="8.23981846" y1="141.472111" x2="8.23981846" y2="149.730859" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="2.05995462" y1="150.293956" x2="14.4196823" y2="150.293956" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="125.657232" y1="150.293956" x2="138.016959" y2="150.293956" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="131.837095" y1="141.472111" x2="131.837095" y2="149.730859" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                </g>
            </g>

            <text id="group_ouput" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold" fontSize="18" fill="#012038">
                <tspan x="170" y="640">{groupProd} kW</tspan>
            </text>  
            <g id="elecGroup" transform="translate(11, 580) scale(0.4)" >
                <defs><clipPath id="id1">
                    <path d="M 97 57 L 370 57 L 370 305.675781 L 97 305.675781 Z M 97 57 " clip-rule="nonzero"/></clipPath>
                </defs>
                <rect x="-37.5" width="450" y="-37.499999" height="449.999989"/>
                <rect x="-37.5" width="450" y="-37.499999" height="449.999989"/>
                <rect x="-37.5" width="450" y="-37.499999" height="449.999989"/>
                <rect x="-37.5" width="450" y="-37.499999" height="449.999989"/>
                <g clip-path="url(#id1)">
                    <path fill="#012038" d="M 366.203125 118.996094 L 139.523438 118.996094 L 139.523438 75.183594 C 139.523438 73.320312 137.953125 71.796875 136.050781 71.796875 L 121.226562 71.796875 L 107.125 58.421875 C 105.769531 57.066406 103.570312 57.195312 102.300781 58.546875 C 101.027344 59.902344 101.070312 62.0625 102.382812 63.375 L 116.441406 76.667969 L 116.441406 118.996094 L 100.773438 118.996094 C 98.867188 118.996094 97.34375 120.519531 97.34375 122.425781 L 97.34375 281.84375 C 97.34375 283.746094 98.867188 285.269531 100.773438 285.269531 L 109.582031 285.269531 L 109.582031 304.914062 C 109.582031 306.816406 111.105469 308.339844 113.011719 308.339844 L 348.839844 308.339844 C 350.746094 308.339844 352.183594 306.816406 352.183594 304.914062 L 352.183594 285.269531 L 366.203125 285.269531 C 368.066406 285.269531 369.589844 283.746094 369.589844 281.84375 L 369.589844 122.425781 C 369.589844 120.519531 368.066406 118.996094 366.203125 118.996094 Z M 123.304688 78.613281 L 132.703125 78.613281 L 132.703125 118.996094 L 123.304688 118.996094 Z M 345.410156 301.484375 L 116.441406 301.484375 L 116.441406 285.269531 L 345.410156 285.269531 Z M 362.730469 278.457031 L 104.164062 278.457031 L 104.164062 125.8125 L 362.730469 125.8125 Z M 362.730469 278.457031 " fillRule="nonzero"/>
                </g>
                <path fill="#012038" d="M 254.875 195.359375 L 340.285156 195.359375 C 342.148438 195.359375 343.714844 193.878906 343.714844 191.972656 L 343.714844 141.601562 C 343.714844 139.738281 342.148438 138.171875 340.285156 138.171875 L 254.875 138.171875 C 252.882812 138.171875 251.402344 139.738281 251.402344 141.601562 L 251.402344 191.972656 C 251.402344 193.878906 252.882812 195.359375 254.875 195.359375 Z M 258.21875 144.988281 L 336.8125 144.988281 L 336.8125 188.503906 L 258.21875 188.503906 Z M 258.21875 144.988281 " fillRule="nonzero"/>
                <path fill="#012038" d="M 254.875 267.109375 L 340.285156 267.109375 C 342.148438 267.109375 343.714844 265.628906 343.714844 263.726562 L 343.714844 213.308594 C 343.714844 211.445312 342.148438 209.921875 340.285156 209.921875 L 254.875 209.921875 C 252.882812 209.921875 251.402344 211.445312 251.402344 213.308594 L 251.402344 263.726562 C 251.402344 265.628906 252.882812 267.109375 254.875 267.109375 Z M 258.21875 216.78125 L 336.8125 216.78125 L 336.8125 260.339844 L 258.21875 260.339844 Z M 258.21875 216.78125 " fillRule="nonzero"/>
                <path fill="#012038" d="M 127.539062 156.542969 L 127.539062 183 C 127.539062 184.90625 129.0625 186.429688 130.96875 186.429688 L 137.828125 186.429688 L 137.828125 218.898438 L 130.96875 218.898438 C 129.0625 218.898438 127.539062 220.421875 127.539062 222.324219 L 127.539062 248.738281 C 127.539062 250.6875 129.0625 252.167969 130.96875 252.167969 L 137.828125 252.167969 L 137.828125 263.726562 C 137.828125 265.628906 139.351562 267.109375 141.214844 267.109375 L 226.671875 267.109375 C 228.574219 267.109375 230.101562 265.628906 230.101562 263.726562 L 230.101562 141.558594 C 230.101562 139.695312 228.574219 138.128906 226.671875 138.128906 L 141.171875 138.128906 C 139.308594 138.128906 137.785156 139.695312 137.785156 141.558594 L 137.785156 153.074219 L 130.96875 153.074219 C 129.0625 153.113281 127.539062 154.640625 127.539062 156.542969 Z M 137.785156 245.394531 L 134.355469 245.394531 L 134.355469 225.710938 L 137.785156 225.710938 Z M 144.644531 248.738281 L 144.644531 144.988281 L 223.28125 144.988281 L 223.28125 260.339844 L 144.644531 260.339844 Z M 134.355469 159.972656 L 137.785156 159.972656 L 137.785156 179.613281 L 134.355469 179.613281 Z M 134.355469 159.972656 " fillRule="nonzero"/>
                <path fill="#012038" d="M 305.222656 218.898438 L 288.113281 218.898438 C 286.25 218.898438 284.726562 220.421875 284.726562 222.324219 C 284.726562 224.230469 286.25 225.710938 288.113281 225.710938 L 305.222656 225.710938 C 307.085938 225.710938 308.652344 224.230469 308.652344 222.324219 C 308.652344 220.421875 307.085938 218.898438 305.222656 218.898438 Z M 305.222656 218.898438 " fillRule="nonzero"/>
                <path fill="#012038" d="M 288.113281 153.113281 L 271.089844 153.113281 C 269.1875 153.113281 267.621094 154.640625 267.621094 156.542969 L 267.621094 175.085938 C 267.621094 176.988281 269.1875 178.511719 271.089844 178.511719 L 288.113281 178.511719 C 290.019531 178.511719 291.585938 176.988281 291.585938 175.085938 L 291.585938 156.542969 C 291.585938 154.640625 290.019531 153.113281 288.113281 153.113281 Z M 284.726562 171.699219 L 274.480469 171.699219 L 274.480469 159.972656 L 284.726562 159.972656 Z M 284.726562 171.699219 " fillRule="nonzero"/>
                <path fill="#012038" d="M 214.6875 188.546875 C 212.78125 188.546875 211.296875 190.113281 211.296875 191.972656 L 211.296875 209.246094 C 211.296875 211.148438 212.78125 212.714844 214.6875 212.714844 C 216.59375 212.714844 218.117188 211.148438 218.117188 209.246094 L 218.117188 191.972656 C 218.117188 190.113281 216.59375 188.546875 214.6875 188.546875 Z M 214.6875 188.546875 " fillRule="nonzero"/>
                <path fill="#012038" d="M 190.886719 193.117188 L 178.140625 195.953125 L 187.117188 170.894531 C 187.796875 169.160156 186.863281 167.210938 185.042969 166.578125 C 183.351562 165.941406 181.359375 166.871094 180.683594 168.566406 L 169.585938 199.46875 C 169.121094 200.566406 169.417969 201.878906 170.265625 202.851562 C 171.070312 203.785156 172.339844 204.207031 173.527344 203.953125 L 186.992188 200.992188 L 178.902344 234.726562 C 178.394531 236.546875 179.625 238.453125 181.445312 238.875 C 181.699219 238.960938 181.996094 238.960938 182.207031 238.960938 C 183.816406 238.960938 185.171875 237.945312 185.550781 236.378906 L 194.996094 197.265625 C 195.292969 196.121094 194.953125 194.9375 194.105469 194.046875 C 193.257812 193.203125 192.03125 192.863281 190.886719 193.117188 Z M 190.886719 193.117188 " fillRule="nonzero"/>
                <path class="smoke_3" fill="#012038" d="M 79.390625 166.617188 C 73.882812 169.03125 57.707031 173.773438 49.027344 167.042969 C 35.347656 156.542969 12.695312 166.152344 11.761719 166.617188 C 9.984375 167.421875 9.222656 169.371094 9.984375 171.105469 C 10.535156 172.375 11.761719 173.179688 13.117188 173.179688 C 13.582031 173.179688 14.007812 173.136719 14.472656 172.925781 C 19.933594 170.511719 36.152344 165.773438 44.917969 172.460938 C 49.621094 176.144531 55.507812 177.328125 61.179688 177.328125 C 71.726562 177.328125 81.507812 173.136719 82.140625 172.925781 C 83.878906 172.121094 84.683594 170.175781 83.921875 168.4375 C 83.160156 166.660156 81.125 165.855469 79.390625 166.617188 Z M 79.390625 166.617188 " fillRule="nonzero"/>
                <path class="smoke_1" fill="#012038" d="M 79.390625 143.039062 C 73.882812 145.410156 57.707031 150.195312 49.027344 143.464844 C 35.347656 132.964844 12.695312 142.617188 11.71875 143.039062 C 9.941406 143.84375 9.179688 145.835938 9.941406 147.570312 C 10.535156 148.839844 11.761719 149.601562 13.117188 149.601562 C 13.582031 149.601562 14.007812 149.476562 14.472656 149.304688 C 19.933594 146.890625 36.152344 142.195312 44.917969 148.882812 C 49.664062 152.566406 55.546875 153.75 61.222656 153.75 C 71.765625 153.75 81.550781 149.515625 82.183594 149.304688 C 83.921875 148.542969 84.726562 146.597656 83.964844 144.859375 C 83.160156 143.082031 81.125 142.277344 79.390625 143.039062 Z M 79.390625 143.039062 " fillRule="nonzero"/>
                <path class="smoke_2" fill="#012038" d="M 79.390625 190.238281 C 73.882812 192.652344 57.707031 197.351562 49.027344 190.664062 C 35.347656 180.164062 12.695312 189.816406 11.761719 190.238281 C 9.984375 190.957031 9.179688 192.949219 9.984375 194.683594 C 10.535156 196.039062 11.761719 196.757812 13.117188 196.757812 C 13.582031 196.757812 14.007812 196.671875 14.472656 196.503906 C 19.933594 194.089844 36.152344 189.351562 44.917969 196.039062 C 49.664062 199.722656 55.546875 200.90625 61.222656 200.90625 C 71.765625 200.90625 81.550781 196.714844 82.183594 196.503906 C 83.921875 195.699219 84.726562 193.753906 83.964844 191.972656 C 83.160156 190.238281 81.125 189.476562 79.390625 190.238281 Z M 79.390625 190.238281 " fillRule="nonzero"/>
                <path class="smoke_1" fill="#012038" d="M 79.390625 235.363281 C 73.882812 237.777344 57.707031 242.476562 49.027344 235.785156 C 35.347656 225.289062 12.695312 234.941406 11.761719 235.363281 C 9.984375 236.125 9.222656 238.15625 9.984375 239.851562 C 10.535156 241.164062 11.761719 241.96875 13.117188 241.96875 C 13.582031 241.96875 14.007812 241.839844 14.472656 241.671875 C 19.933594 239.257812 36.113281 234.558594 44.878906 241.203125 C 49.621094 244.886719 55.507812 246.074219 61.179688 246.074219 C 71.726562 246.074219 81.507812 241.882812 82.140625 241.671875 C 83.878906 240.910156 84.683594 238.875 83.921875 237.183594 C 83.160156 235.449219 81.125 234.644531 79.390625 235.363281 Z M 79.390625 235.363281 " fillRule="nonzero"/>
                <path class="smoke_3" fill="#012038" d="M 79.390625 211.785156 C 73.882812 214.199219 57.707031 218.9375 49.027344 212.207031 C 35.347656 201.710938 12.695312 211.363281 11.761719 211.742188 C 9.984375 212.546875 9.179688 214.535156 9.984375 216.273438 C 10.535156 217.585938 11.761719 218.347656 13.117188 218.347656 C 13.582031 218.347656 14.007812 218.21875 14.472656 218.007812 C 19.933594 215.679688 36.152344 210.894531 44.917969 217.585938 C 49.664062 221.308594 55.546875 222.539062 61.222656 222.539062 C 71.765625 222.539062 81.550781 218.304688 82.183594 218.007812 C 83.921875 217.289062 84.726562 215.296875 83.964844 213.5625 C 83.160156 211.828125 81.125 211.066406 79.390625 211.785156 Z M 79.390625 211.785156 " fillRule="nonzero"/>
                <path class="smoke_2" fill="#012038" d="M 79.390625 258.941406 C 73.882812 261.355469 57.707031 266.054688 49.027344 259.40625 C 35.347656 248.953125 12.695312 258.558594 11.761719 258.941406 C 9.984375 259.746094 9.222656 261.734375 9.984375 263.386719 C 10.535156 264.699219 11.761719 265.503906 13.117188 265.503906 C 13.582031 265.503906 14.007812 265.417969 14.472656 265.207031 C 19.933594 262.792969 36.152344 258.09375 44.917969 264.824219 C 49.621094 268.464844 55.507812 269.691406 61.179688 269.691406 C 71.726562 269.691406 81.507812 265.503906 82.140625 265.25 C 83.878906 264.488281 84.683594 262.539062 83.921875 260.761719 C 83.160156 259.027344 81.125 258.179688 79.390625 258.941406 Z M 79.390625 258.941406 " fillRule="nonzero"/>
            </g>

            <g id="fans_top "transform="translate(0,-90)">
                <g id="fan_group" transform="translate(72,-260)">
                    <path stroke="#012038" strokeWidth="1.5" d="M675.7,648h64.5c1.5,0,2.7,1.2,2.7,2.7
                        v42.5c0,1.5-1.2,2.7-2.7,2.7h-64.5c-1.5,0-2.7-1.2-2.7-2.7v-42.5C673,649.2,674.2,648,675.7,648z"/>
                    <circle stroke="#012038" strokeWidth="1.5" cx="696.8" cy="672.5" r="18.5"/>
                    <g class="helice" style={{transformOrigin: "center",transformBox: "fill-box"}} >
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M697.7,668c0.2-1.1,0.7-1.9,1.6-2.2s2.6-0.5,5.3-0.6c-3.2-3.7-6.2-5.2-9.1-4.5c-2.9,0.7-3.2,3.2-1,7.3H697.7z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M699.4,675c1.1,0.5,1.7,1.1,1.8,2c0.2,0.9,0,2.7-0.6,5.3c4.3-2.3,6.5-4.9,6.5-7.8c0-3-2.3-3.8-6.9-2.7L699.4,675z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M692.5,673.5c-0.9,0.7-1.8,0.9-2.7,0.5c-0.8-0.3-2.3-1.4-4.3-3.2c-0.2,4.9,1,8.1,3.6,9.5s4.5-0.1,5.8-4.7L692.5,673.5z"/>
                        <circle stroke="#012038" strokeWidth="1.5" cx="696.4" cy="671.5" r="3.4"/>
                    </g>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="684" y1="697" x2="684" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="728" y1="697" x2="728" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="661" x2="723" y2="661"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="666" x2="723" y2="666"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="672" x2="723" y2="672"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="677" x2="723" y2="677"/>
                    
                </g>
                <g id="fan_group_2" transform="translate(72,-180)">
                    <path stroke="#012038" strokeWidth="1.5" d="M675.7,648h64.5c1.5,0,2.7,1.2,2.7,2.7
                        v42.5c0,1.5-1.2,2.7-2.7,2.7h-64.5c-1.5,0-2.7-1.2-2.7-2.7v-42.5C673,649.2,674.2,648,675.7,648z"/>
                    <circle stroke="#012038" strokeWidth="1.5" cx="696.8" cy="672.5" r="18.5"/>
                    <g class="helice" style={{transformOrigin: "center",transformBox: "fill-box"}} >
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M697.7,668c0.2-1.1,0.7-1.9,1.6-2.2s2.6-0.5,5.3-0.6c-3.2-3.7-6.2-5.2-9.1-4.5c-2.9,0.7-3.2,3.2-1,7.3H697.7z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M699.4,675c1.1,0.5,1.7,1.1,1.8,2c0.2,0.9,0,2.7-0.6,5.3c4.3-2.3,6.5-4.9,6.5-7.8c0-3-2.3-3.8-6.9-2.7L699.4,675z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M692.5,673.5c-0.9,0.7-1.8,0.9-2.7,0.5c-0.8-0.3-2.3-1.4-4.3-3.2c-0.2,4.9,1,8.1,3.6,9.5s4.5-0.1,5.8-4.7L692.5,673.5z"/>
                        <circle stroke="#012038" strokeWidth="1.5" cx="696.4" cy="671.5" r="3.4"/>
                    </g>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="684" y1="697" x2="684" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="728" y1="697" x2="728" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="661" x2="723" y2="661"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="666" x2="723" y2="666"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="672" x2="723" y2="672"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="677" x2="723" y2="677"/>
                    
               </g>
                
                <text id="text_prod_line_1" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold" fontSize="15"fill="#012038">
                    <tspan x="476" y="395">{prodLine1} kW</tspan>
                </text>

                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                    <tspan x="644" y="395">Prod line #1</tspan>
                </text>

                <line id="lineToEngine_1" x1="740" y1="408" x2="456" y2="408" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                <g id="fluxToEngine_1" transform="translate(740,410) rotate(-180.000000)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="0" y1="2.252386" x2="287" y2="2.252386"  strokeDasharray="3,7.5"></line>
                    <line x1="133" y1="2" x2="135" y2="0" class="fluxToEngine_1"></line>
                    <line x1="133" y1="2" x2="135" y2="3" class="fluxToEngine_1"></line>
                    <line x1="175" y1="2" x2="177" y2="0" class="fluxToEngine_1"></line>
                    <line x1="175" y1="2" x2="177" y2="3" class="fluxToEngine_1"></line>
                </g>

                <text id="text_prod_line_2" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold" fontSize="15"fill="#012038">
                    <tspan x="476" y="475">{prodLine2} kW</tspan>
                </text>

                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                    <tspan x="644" y="475">Prod line #2</tspan>
                </text>

                <line id="lineToEngine_2" x1="740" y1="488" x2="456" y2="488" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                <g id="fluxToEngine_2" transform="translate(740,490) rotate(-180.000000)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="0" y1="2.252386" x2="287" y2="2.252386"  strokeDasharray="3,7.5"></line>
                    <line x1="133" y1="2" x2="135" y2="0" class="fluxToEngine_2"></line>
                    <line x1="133" y1="2" x2="135" y2="3" class="fluxToEngine_2"></line>
                    <line x1="175" y1="2" x2="177" y2="0" class="fluxToEngine_2"></line>
                    <line x1="175" y1="2" x2="177" y2="3" class="fluxToEngine_2"></line>
                </g>

                <line id="lineTopEngine" x1="455" y1="409" x2="455" y2="570" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                <g id="fluxTopEngine" transform="translate(453, 454) rotate(270)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="22" y1="2.252386" x2="-110" y2="2.252386"  strokeDasharray="3.00000011920929,7.5"></line>
                    <line x1="-23" y1="2" x2="-25" y2="0" class="fluxTopEngine"></line>
                    <line x1="-23" y1="2" x2="-25" y2="3" class="fluxTopEngine"></line>

                    <line x1="-73" y1="2" x2="-75" y2="0" class="fluxTopEngine"></line>
                    <line x1="-73" y1="2" x2="-75" y2="3" class="fluxTopEngine"></line>
                </g>

                <g id="boule_1" transform="translate(440,393) scale(0.7)">
                    <ellipse stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134"></line>
                        </g>
                    </g>
                </g>

                <g  id="boule_2" transform="translate(440,473) scale(0.7)">
                    <ellipse stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134"></line>
                        </g>
                    </g>
                </g>
            </g>
            <g id="fans_bottom">
                <g id="fan_group_3" transform="translate(72,-40)">
                    <path stroke="#012038" strokeWidth="1.5" d="M675.7,648h64.5c1.5,0,2.7,1.2,2.7,2.7
                        v42.5c0,1.5-1.2,2.7-2.7,2.7h-64.5c-1.5,0-2.7-1.2-2.7-2.7v-42.5C673,649.2,674.2,648,675.7,648z"/>
                    <circle stroke="#012038" strokeWidth="1.5" cx="696.8" cy="672.5" r="18.5"/>
                    <g class="helice" style={{transformOrigin: "center",transformBox: "fill-box"}} >
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M697.7,668c0.2-1.1,0.7-1.9,1.6-2.2s2.6-0.5,5.3-0.6c-3.2-3.7-6.2-5.2-9.1-4.5c-2.9,0.7-3.2,3.2-1,7.3H697.7z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M699.4,675c1.1,0.5,1.7,1.1,1.8,2c0.2,0.9,0,2.7-0.6,5.3c4.3-2.3,6.5-4.9,6.5-7.8c0-3-2.3-3.8-6.9-2.7L699.4,675z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M692.5,673.5c-0.9,0.7-1.8,0.9-2.7,0.5c-0.8-0.3-2.3-1.4-4.3-3.2c-0.2,4.9,1,8.1,3.6,9.5s4.5-0.1,5.8-4.7L692.5,673.5z"/>
                        <circle stroke="#012038" strokeWidth="1.5" cx="696.4" cy="671.5" r="3.4"/>
                    </g>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="684" y1="697" x2="684" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="728" y1="697" x2="728" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="661" x2="723" y2="661"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="666" x2="723" y2="666"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="672" x2="723" y2="672"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="677" x2="723" y2="677"/>
                    
               </g>
                <g id="fan_group_4" transform="translate(72,40)">
                    <path stroke="#012038" strokeWidth="1.5" d="M675.7,648h64.5c1.5,0,2.7,1.2,2.7,2.7
                        v42.5c0,1.5-1.2,2.7-2.7,2.7h-64.5c-1.5,0-2.7-1.2-2.7-2.7v-42.5C673,649.2,674.2,648,675.7,648z"/>
                    <circle stroke="#012038" strokeWidth="1.5" cx="696.8" cy="672.5" r="18.5"/>
                    <g style={{transformOrigin: "center",transformBox: "fill-box"}} >
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M697.7,668c0.2-1.1,0.7-1.9,1.6-2.2s2.6-0.5,5.3-0.6c-3.2-3.7-6.2-5.2-9.1-4.5c-2.9,0.7-3.2,3.2-1,7.3H697.7z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M699.4,675c1.1,0.5,1.7,1.1,1.8,2c0.2,0.9,0,2.7-0.6,5.3c4.3-2.3,6.5-4.9,6.5-7.8c0-3-2.3-3.8-6.9-2.7L699.4,675z"/>
                        <path stroke="#012038" strokeWidth="1.5" strokeLinejoin="round" d="M692.5,673.5c-0.9,0.7-1.8,0.9-2.7,0.5c-0.8-0.3-2.3-1.4-4.3-3.2c-0.2,4.9,1,8.1,3.6,9.5s4.5-0.1,5.8-4.7L692.5,673.5z"/>
                        <circle stroke="#012038" strokeWidth="1.5" cx="696.4" cy="671.5" r="3.4"/>
                    </g>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="684" y1="697" x2="684" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="728" y1="697" x2="728" y2="701"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="661" x2="723" y2="661"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="666" x2="723" y2="666"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="672" x2="723" y2="672"/>
                    <line stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" x1="736" y1="677" x2="723" y2="677"/>
                    
                </g>

                <text id="text_prod_line_3" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold" fontSize="15"fill="#012038">
                    <tspan x="476" y="617">{prodLine3} kW</tspan>
                </text>

                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                    <tspan x="644" y="617">Prod line #3</tspan>
                </text>

                <line id="lineToEngine_3" x1="740" y1="628" x2="456" y2="628" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                <g id="fluxToEngine_3" transform="translate(740,630) rotate(-180.000000)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="0" y1="2.252386" x2="287" y2="2.252386"  strokeDasharray="3,7.5"></line>
                    <line x1="133" y1="2" x2="135" y2="0" class="fluxToEngine_3"></line>
                    <line x1="133" y1="2" x2="135" y2="3" class="fluxToEngine_3"></line>
                    <line x1="175" y1="2" x2="177" y2="0" class="fluxToEngine_3"></line>
                    <line x1="175" y1="2" x2="177" y2="3" class="fluxToEngine_3"></line>
                </g>

                <text id="text_prod_line_4" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold" fontSize="15"fill="#012038">
                    <tspan x="476" y="695">{prodLine4} kW</tspan>
                </text>

                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                    <tspan x="644" y="695">Prod line #4</tspan>
                </text>
                <line id="lineToEngine_4" x1="740" y1="708" x2="456" y2="708" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                {false?<g id="fluxToEngine_4" transform="translate(740,710) rotate(-180.000000)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="0" y1="2.252386" x2="287" y2="2.252386"  strokeDasharray="3,7.5"></line>
                    <line x1="133" y1="2" x2="135" y2="0" class="fluxToEngine_4"></line>
                    <line x1="133" y1="2" x2="135" y2="3" class="fluxToEngine_4"></line>
                    <line x1="175" y1="2" x2="177" y2="0" class="fluxToEngine_4"></line>
                    <line x1="175" y1="2" x2="177" y2="3" class="fluxToEngine_4"></line>
                </g>:<g></g>}

                <line id="lineBottomEngine" x1="455" y1="707" x2="455" y2="503" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                <g id="fluxBottomEngine" transform="translate(457, 665) rotate(450.000000)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="-135" y1="2.252386" x2="-60" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                    <line x1="-74.7305625" y1="2.252386" x2="-75.2324137" y2="0.750795334" class="fluxBottomEngine"></line>
                    <line x1="-74.7305625" y1="2.252386" x2="-75.2324137" y2="3.75397667" class="fluxBottomEngine"></line>
                    <line x1="27.1916876" y1="2.252386" x2="25.6935388" y2="3.75397667" class="fluxBottomEngine"></line>
                    <line x1="25.6935388" y1="0.750795334" x2="27.1916876" y2="2.252386" class="fluxBottomEngine"></line>
                </g>
                {false?<g id="fluxBottomEngine_sub" transform="translate(457, 665) rotate(450.000000)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                    <line x1="-20" y1="2.252386" x2="20" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                </g>:<g></g>}

                <g  id="boule_3" transform="translate(440,611) scale(0.7)">
                    <ellipse stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134"></line>
                        </g>
                    </g>
                </g>

                <g  id="boule_4" transform="translate(440,690) scale(0.7)">
                    <ellipse stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134"></line>
                        </g>
                    </g>
                </g>
            </g>

            <g id="inputFlux" transform="translate(0,-40)">

            <text id="total_input" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold" fontSize="18" fill="#012038">
                <tspan x="480" y="550">{totalImput} kW</tspan>
            </text>
            <line x1="260" y1="541" x2="460" y2="541" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
            <g id="grid_flux" transform="translate(265, 538)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                <line x1="-9" y1="2.552386" x2="159" y2="2.552386" strokeDasharray="3.00000011920929,7.5"></line>
                <line x1="66.6676221" y1="2.252386" x2="65.1694733" y2="0.750795334" class="grid_flux"></line>
                <line x1="66.6676221" y1="2.252386" x2="65.1694733" y2="3.75397667" class="grid_flux"></line>
                <line x1="108.615789" y1="2.252386" x2="107.11764" y2="0.750795334" class="grid_flux"></line>
                <line x1="108.615789" y1="2.252386" x2="107.11764" y2="3.75397667" class="grid_flux"></line>
            </g>

            <g id="groupInfo">
                <g id="line_1">
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="570" y="520">L1: 68 A / </tspan>
                    </text>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="640" y="520">229V /</tspan>
                    </text>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="695" y="520">Cos 80%</tspan>
                    </text>
                </g>
                <g id="line_1">
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="570" y="550">L2: 47 A / </tspan>
                    </text>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="640" y="550">232V /</tspan>
                    </text>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="695" y="550">Cos 95%</tspan>
                    </text>
                </g>
                <g id="line_1">
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="570" y="580">L3: 68 A / </tspan>
                    </text>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="640" y="580">217V /</tspan>
                    </text>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15"  fill="#012038">
                        <tspan x="695" y="580">Cos 56%</tspan>
                    </text>
                </g>
            </g>



            <line id="line_vertical_group" x1="0" y1="150" x2="0" y2="300" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(256, 840) rotate(180)"></line>
            <g id="flux_vertical_group" transform="translate(254, 730) rotate(-90)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                <line x1="190" y1="2" x2="45" y2="2" strokeDasharray="3,7.5"></line>
                <line x1="36.7046459" y1="2.252386" x2="35.2064971" y2="0.750795334" class="flux_vertical_group"></line>
                <line x1="36.7046459" y1="2.252386" x2="35.2064971" y2="3.75397667" class="flux_vertical_group"></line>
                <line x1="89.1398543" y1="2.252386" x2="87.6417055" y2="3.75397667" class="flux_vertical_group"></line>
                <line x1="87.6417055" y1="0.750795334" x2="89.1398543" y2="2.252386" class="flux_vertical_group"></line>
                <line x1="141.575063" y1="2.252386" x2="140.076914" y2="0.750795334" class="flux_vertical_group"></line>
                <line x1="141.575063" y1="2.252386" x2="140.076914" y2="3.75397667" class="flux_vertical_group"></line>
            </g>

                    <line id="line_horizontal_group" x1="0" y1="0" x2="70" y2="0" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(256, 690) rotate(180)"></line>
                    <g id="flux_horizontal_group" transform="translate(190, 688)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0" y1="2.252386" x2="65" y2="2.252386" strokeDasharray="3,7.5"></line>
                        <line x1="5.24352084" y1="2.252386" x2="3.74537203" y2="0.750795334" class="flux_horizontal_group"></line>
                        <line x1="5.24352084" y1="2.252386" x2="3.74537203" y2="3.75397667" class="flux_horizontal_group"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="0.750795334" class="flux_horizontal_group"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="3.75397667" class="flux_horizontal_group"></line>
                    </g>

                    <line x1="0" y1="430" x2="0" y2="300" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(256, 840) rotate(180)"></line>
                   

                    <line x1="0" y1="300" x2="70" y2="300" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(256, 710) rotate(180)"></line>
                    
                    
                </g>

                <g transform="translate(430,479)">
                    <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g id="Group" transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon id="Path-13" transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g id="Group-3" transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677" id="Line-30"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134" id="Line-30"></line>
                        </g>
                    </g>
                </g>
                
            </g>
        </g>
    </svg>

  );
}
export default Animation;