import React, { useEffect } from "react";
import Card from "components/card/Card.js";

import {Flex,Text,useColorModeValue,Spacer,Icon,Box} from "@chakra-ui/react";
import { MdPowerSettingsNew } from "react-icons/md";

import { Post } from 'utils/queries/index.js';
import CircularSlider from '../advanced-react-circular-slider/src/CircularSlider';
import '../advanced-react-circular-slider/main.css';

function MonitoringAC(props) {
    const { ...rest} = props;
    const {temperature,humidity,power,label,kit_id,myStrom_id,token,status_mystrom,status_clim} = props;
    const [isToggledOnKit, setIsToggledOnKit] = React.useState(false);
    const [isToggledOn, setIsToggledOn] = React.useState(false);
    const [dataIndex, setDataIndex] = React.useState(1);
    const textColor = useColorModeValue("navy.700", "white");

    const redColor = useColorModeValue("red.300", "red.900");
    const greenColor = useColorModeValue("green.300", "green.900");
    const ethBox = useColorModeValue("white", "navy.800");

    var delay
    
    useEffect(() => {
        setDataIndex(temperature-17)
        return () => {}
     }, []);

    useEffect(() => { 
        setIsToggledOn(status_mystrom)
        setIsToggledOnKit(status_clim)
    },[props]);

  
    return (
        <Card
            justifyContent='center'
            align='center'
            direction='column'
            mb='0px'
            border='1px solid'
            borderColor='#0076ba'
            borderRadius='20px'
            {...rest}>
            <Text fontWeight='bold' fontSize='md' me='auto' color={textColor}>
            {label}
            </Text>
            <Flex>
                <Box>
                    <Flex
                        align='center'
                        justify='center'
                        bg={isToggledOnKit?greenColor:redColor}
                        h='50px'
                        w='50px'
                        borderRadius='30px'
                        borderColor={"#1c2329"}
                        cursor={"pointer"}
                        me='7px'
                        onClick={async()=>{
                            await Post.sendToDevice(token,kit_id,"btn",isToggledOnKit?0:1)
                            setIsToggledOnKit(!isToggledOnKit)
                        }}
                    >
                        <Icon color={ethBox} boxSize={9}  as={MdPowerSettingsNew} />
                    </Flex>
                    <Text fontWeight='bold' fontSize='sm' me='auto' color={textColor}>
                        Kit
                    </Text>
                </Box>
                <Spacer />
                <Box>
                    <Flex
                        align='center'
                        justify='center'
                        bg={isToggledOn?greenColor:redColor}
                        h='50px'
                        w='50px'
                        borderRadius='30px'
                        borderColor={"#1c2329"}
                        cursor={"pointer"}
                        me='7px'
                        onClick={async()=>{
                            await Post.sendToDevice(token,myStrom_id,"rly_ms",!isToggledOn?0:1)
                            setIsToggledOn(!isToggledOn)
                        }}
                    >
                        <Icon color={ethBox} boxSize={9}  as={MdPowerSettingsNew} />
                    </Flex>
                    <Text fontWeight='bold' fontSize='sm' me='auto' color={textColor}>
                        MyStrom
                    </Text>
                </Box>

            </Flex>
            {dataIndex!==1?<CircularSlider
                temperature={temperature+" °C"}
                humidity={humidity+"%"}
                power={power+" W"}
                appendToValue=" °C"
                width={300}
                min={17}
                max={30}
                dataIndex={dataIndex}
                step={1}
                labelStep={1}
                onChange={(val)=>{
                    delay=new Date()
                    setTimeout(async () => {
                        if( ((new Date()).getTime()-delay.getTime())>1000 )
                            await Post.sendToDevice(token,kit_id,"tmp",val.value)
                    }, 1000);
                }}
            />:null}
            
        </Card>
    );}
export default MonitoringAC;