import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent
} from '@chakra-ui/react'
import { withTranslation } from 'react-i18next';
import QRCode from "qrcode";
import {Post} from '../../sensors/queries'

const styles = {
  heading3: `text-xl font-semibold text-gray-900 p-4 border-b`,
  heading4: `text-base text-ct-blue-600 font-medium border-b mb-2`,
  modalOverlay: `overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`,
  orderedList: `space-y-1 text-sm list-decimal`,
  buttonGroup: `flex items-center py-6 space-x-2 rounded-b`,
  buttongreen: `text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`,
  buttonGrey: `text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600`,
  inputField: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5`,
};

const TwoFactorAuth= (props) => {
  const {t,isOpen, closeModal,setSuccessMsg,
    setErrorMsg,otpauth_url,
    base32,user_id
  }= props;
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");


  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await Post.requestPost( "verifyOtp", {token,id:user_id});
      if(response.hasOwnProperty("error")){
        if(response.error===200){
          setSuccessMsg("Two-Factor Auth Enabled Successfully")
          closeModal();
        }else setErrorMsg(response.msg)
      }else setErrorMsg(t("error_occurred"))

      setLoading(false);
      //store.setAuthUser(user);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.toString())
    }
  };


  useEffect(() => {
    QRCode.toDataURL(otpauth_url).then(setqrCodeUrl);
  }, []);

  return (
    <Modal isCentered isOpen={isOpen} onClose={closeModal}>
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
      <ModalContent>
        <div
          aria-hidden={true}
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-[#222] bg-opacity-50"
          // onClick={closeModal}
        >
          <div className="relative p-4 w-full max-w-xl h-full md:h-auto left-1/2 -translate-x-1/2">
            <div className="relative bg-white rounded-lg shadow">
              <h3 className={styles.heading3}>Two-Factor Authentication (2FA)</h3>
              {/* Modal body */}
              <div className="p-6 space-y-4">
                <h4 className={styles.heading4}>
                  {t("configuring_Google_Authy")}
                </h4>
                <div className={styles.orderedList}>
                  <li>{t("textProcess_2")} </li>
                  <li>{t("textProcess_3")}</li>
                  <li>{t("textProcess_4")}</li>
                </div>
                <div>
                  <h4 className={styles.heading4}>{t("Scan QR Code")}</h4>
                  <div className="flex justify-center">
                    <img
                      className="block w-64 h-64 object-contain"
                      src={qrcodeUrl}
                      alt="qrcode url"
                    />
                  </div>
                </div>
                <div>
                  <h4 className={styles.heading4}>{t("code_into_app")}</h4>
                  <p className="text-sm">SecretKey: {base32}</p>
                </div>
                <div>
                  <h4 className={styles.heading4}>{t("Verify Code")}</h4>
                  <p className="text-sm">{t("textProcess_5")} </p>
                </div>
                {loading?null:<form>
                  <input
                    onChange={(val)=>setToken(val.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/4 p-2.5"
                    placeholder="Authentication Code"
                  />

                  <div className={styles.buttonGroup}>
                    <button
                      type="button"
                      onClick={closeModal}
                      className={styles.buttonGrey}
                    >
                      {t("Close")}
                    </button>
                    <button type="submit" className={styles.buttongreen}  onClick={verifyOtp}>
                     {t("verify_activate")}
                    </button>
                  </div>
                </form>}
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default withTranslation()(TwoFactorAuth);
