import React from "react";

import { Icon } from "@chakra-ui/react";
import {MdDashboard,MdPerson,MdOutlineSchema} from "react-icons/md";
import {FiUsers} from "react-icons/fi";
import {AiFillControl} from "react-icons/ai";
import {BiChip} from "react-icons/bi";
import {MdSensors} from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa";
import {BsFillFileEarmarkBarGraphFill,BsGearWideConnected} from "react-icons/bs";

import { withTranslation } from 'react-i18next';

// Admin Imports
import MainDashboard from "views/admin/default";
import Monitoring from "views/admin/monitoring_control";
import Billing from "views/admin/billing";
import Profile from "views/admin/profile";
import Configurations from "views/admin/configurations";
import Devices from "views/admin/devices";
import Sensors from "views/admin/sensors";
import Users from "views/admin/users";
import Demo from "views/admin/demo";

export function routes(t){
  return [
    {
      name: t("dashboard"),
      layout: "/admin",
      path: "/dashboard",
      icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
      component: MainDashboard,
      showSelectDevice: true
    },
    {
      name: t("overview"),
      layout: "/admin",
      path: "/overview",
      icon: <Icon as={BsFillFileEarmarkBarGraphFill} width='20px' height='20px' color='inherit' />,
      component: Demo,
    },
    {
      name: t("monitoring_control"),
      layout: "/admin",
      path: "/monitoring_control",
      showSelectDevice: true,
      icon: (<Icon as={AiFillControl} width='20px' height='20px' color='inherit' />),
      component: Monitoring
    },
    {
      name: t("billing"),
      layout: "/admin",
      path: "/billing",
      icon: (<Icon as={FaFileInvoice} width='20px' height='20px' color='inherit' />),
      component: Billing
    },
    {
      name: t("user")+"s",
      layout: "/admin",
      icon: <Icon as={FiUsers} width='20px' height='20px' color='inherit' />,
      path: "/users",
      component: Users,
    },
    {
      name: t("device")+"s",
      layout: "/admin",
      icon: <Icon as={BiChip} width='20px' height='20px' color='inherit' />,
      path: "/devices",
      component: Devices
    },
    {
      name: t("sensor")+"s",
      layout: "/admin",
      icon: <Icon as={MdSensors} width='20px' height='20px' color='inherit' />,
      path: "/sensors",
      component: Sensors,
      showSelectDevice: true
    },
    {
      name: t("profile"),
      layout: "/admin",
      path: "/profile",
      icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
      component: Profile
    },
    {
      name: "Demo",
      layout: "/admin",
      path: "/demo",
      icon: <Icon as={MdOutlineSchema} width='20px' height='20px' color='inherit' />,
      component: Demo,
    },
    {
      name: "Configurations",
      layout: "/admin",
      path: "/configurations",
      icon: <Icon as={BsGearWideConnected} width='20px' height='20px' color='inherit' />,
      component: Configurations,
    }
  ];
}

export default withTranslation()(routes);
