import React from "react";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdOutlineEditNote,
  MdOutlineDelete,MdSettingsSuggest
} from "react-icons/md";
import {BiDuplicate} from "react-icons/bi";



import { withTranslation } from 'react-i18next';

function MainMenu(props) {
  const { ...rest} = props;
  const {t ,onDetele,onModify,onConfig,onDuplicate } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w='37px'
        h='37px'
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
        {...rest}>
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px' />
      </MenuButton>
      <MenuList
        w='150px'
        minW='unset'
        maxW='150px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'>
        <MenuItem
          transition='0.2s linear'
          color={textColor}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{bg: "transparent"}}
          _focus={{bg: "transparent"}}
          onClick={()=>{onModify()}}
          mb='10px'>
          <Flex align='center'>
            <Icon as={MdOutlineEditNote} color={textColor} h='26px' w='26px' me='8px' />
            <Text fontSize='m' fontWeight='400'>{t("modify")}</Text>
          </Flex>
        </MenuItem>
        {props.showConfig?<MenuItem
          transition='0.2s linear'
          color={textColor}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{bg: "transparent"}}
          _focus={{bg: "transparent"}}
          onClick={()=>{onConfig()}}
          mb='10px'>
          <Flex align='center'>
            <Icon as={MdSettingsSuggest} color={textColor} h='26px' w='26px' me='8px' />
            <Text fontSize='m' fontWeight='400'>{t("config")}</Text>
          </Flex>
        </MenuItem>:null}
        {props.onDuplicate?<MenuItem
          transition='0.2s linear'
          color={textColor}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{bg: "transparent"}}
          _focus={{bg: "transparent"}}
          onClick={()=>{onDuplicate()}}
          mb='10px'>
          <Flex align='center'>
            <Icon as={BiDuplicate} color={textColor} h='26px' w='26px' me='8px' />
            <Text fontSize='m' fontWeight='400'>{t("duplicate")}</Text>
          </Flex>
        </MenuItem>:null}
        <MenuItem
          transition='0.2s linear'
          p='0px'
          borderRadius='8px'
          color="red.500"
          _hover={textHover}
          _active={{bg: "transparent"}}
          _focus={{bg: "transparent"}}
          onClick={()=>{onDetele()}}
          mb='10px'>
          <Flex align='center'>
            <Icon as={MdOutlineDelete} h='26px' w='26px' me='8px' />
            <Text fontSize='m' fontWeight='400'>{t("delete")}</Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
export default withTranslation()(MainMenu);
