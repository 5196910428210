import React,{useEffect} from "react";
import { withTranslation } from 'react-i18next';
import {Flex, useColorModeValue,Box,
    Button,NumberInputField,Input,NumberInput
} from "@chakra-ui/react";

function SectionPower(props) {
    var {t,index,data,onAdd,currency,onRemove,updateData,errorMsg,isFirst,isLast } = props;
    const [values, setValues] =  React.useState({});
    var info={}
    const textColor = useColorModeValue("navy.700", "white");

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        info=values
        info[prop]=event.target.value
        updateData(info)
    };

    useEffect(async () => { 
        setValues(data)

    },[])


    return <Flex mt={4} align="center">
        <Box>
            <p style={{"marginLeft":10}}>{t('Start')}</p>
            <NumberInput minW='100px' value={values.min} variant='auth' maxW='100px' size='md' isDisabled={isFirst?false:true}>
                <NumberInputField
                    placeholder={t("kWh")}
                    fontWeight='500'
                    value={values.min}
                    onChange={handleChange("min")}
                />
            </NumberInput>
        </Box>
        <Box ml={15}>
            <p style={{"marginLeft":10}}>{t('End')}</p>
            <NumberInput minW='100px' value={values.max} min={(Number(values.min)+1)} clampValueOnBlur={false} variant='auth' maxW='100px' size='md'>
                <NumberInputField
                    placeholder={t("kWh")}
                    fontWeight='500'
                    disabled={!isLast}
                    value={values.max?values.max:""}
                    onChange={handleChange("max")}
                />
            </NumberInput>
        </Box>
        <Box ml={15}>
            <p style={{"marginLeft":0}}>{t('color_curve')}</p>
            <Input
                minW='90px'
                size='md'
                variant='auth'
                value={values.color?values.color:""}
                type={"color"}
                onChange={handleChange("color")}
            />
        </Box>
        <Box ml={15}>
            <p>{t('Cost per kWh')}</p>
            <NumberInput value={values.cost} minW='90px' variant='auth' maxW='90px' size='md'>
                <NumberInputField
                    placeholder={currency}
                    fontWeight='500'
                    onChange={handleChange("cost")}
                />
            </NumberInput>
        </Box>
        
        {isLast?<Button ml={15} colorScheme='green' size={"sm"}
            onClick={()=>{
                if(Number(values.cost)>0){
                    if(Number(values.max)>Number(values.min)){
                        onAdd(Number(values.max))
                    }else errorMsg("Max value")
                }else errorMsg("Cost Field")
            }}> + </Button>:null}
        {(index>0 && isLast)?<Button ml={2} colorScheme='red' size={"sm"}
            onClick={()=>{
                onRemove(values.id)
            }}> x </Button>:null}
    </Flex>;
}
export default withTranslation()(SectionPower);