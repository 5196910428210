import React from "react";
import { Stack,Button,Text } from "@chakra-ui/react";

export default function AddRemoveBtn(props) {
  const {isVisibleRemove,isVisibleAdd,onPressRemove,onPressAdd } = props;

  return (
    <Stack spacing={2} direction='row'>
        {isVisibleRemove?<Button
            colorScheme={'red'}
            variant={"solid"} 
            border='10px'
            width='1px'
            height='30px'
            borderColor={"black"}
            borderRadius={80}
            onClick={onPressRemove}
        >
            <Text fontSize="sm">X</Text>
        </Button>:null}
        {isVisibleAdd?<Button
            colorScheme={'green'}
            variant={"solid"} 
            border='10px'
            width='1px'
            height='30px'
            borderColor={"black"}
            borderRadius={80}
            onClick={onPressAdd}
        >
            <Text fontSize="md">+</Text>
        </Button>:null}
    </Stack>
  );
}
