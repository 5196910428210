// Chakra imports
import {Box,useColorModeValue} from "@chakra-ui/react";
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
// Custom icons
import React from "react";
import ReactApexChart from "react-apexcharts";
import { Utils} from "utils";
import Constant from "utils/Constant";

function Default(props) {
  const { t,info,value } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("#ffffff", "navy.800");
  var [loading, setLoading] = React.useState(true);
  var [max, setMax] = React.useState(0);
  var [min, setMin] = React.useState(999999);
  var [seuilTitle, setSeuilTitle] = React.useState("");
  var [seuil, setSeuil] = React.useState(0);
  var [type, setType] = React.useState('area');
  var [hideMinAnnotation, setHideMinAnnotation] = React.useState({state:false,color:"#75cb10"});
  var [hideMaxAnnotation, setHideMaxAnnotation] = React.useState({state:false,color:"#75cb10"});
  var [hideThAnnotation, setHideThAnnotation] = React.useState({state:false,color:"#75cb10"});
  
  var [color, setColor] = React.useState(["#8bdac4"]);
  var [dashArray, setDashArray] = React.useState(0);
  var [nbDecimal, setNbDecimal] = React.useState(0);
  var [Y_Win_Lost, setY_Win_Lost] = React.useState([{data:[]}]);
  var [thousandSep, setThousandSep] = React.useState(".");
  var [deciSep, setDeciSep] = React.useState(",");
  
  React.useEffect(() => { 
    if(!isNaN(value))setLoading(false)

    if(info.hasOwnProperty("graph")){
      if(info.graph){
        if(info.graph.hasOwnProperty("cc"))setColor([info.graph.cc])
        if(info.graph.hasOwnProperty("shMin"))setHideMinAnnotation({state:info.graph.shMin,color:info.graph.shMinCol})
        if(info.graph.hasOwnProperty("shMax"))setHideMaxAnnotation({state:info.graph.shMax,color:info.graph.shMaxCol})
        
        if(info.graph.hasOwnProperty("shTh"))setHideThAnnotation({state:info.graph.shTh,color:info.graph.shThCol})
        if(info.graph.hasOwnProperty("th")) setSeuil(Number(info.graph.th).toFixed(Number(info.graph.dd)))
        if(info.graph.hasOwnProperty("th_tt")) setSeuilTitle(info.graph.th_tt)

        if(info.graph.hasOwnProperty("gt")) {
          if(info.graph.gt.value=="win_loss"){
            setType("rangeArea")
            setColor([info.graph.cc,Constant.WIN_LOSS_2_COLOR])

            if(props.data["y"]){
              if(info.graph.shTh){
                let _Y_Win_Lost_min=[],_Y_Win_Lost_max=[],i=0
                for (const element of props.data["y"]) {
                  _Y_Win_Lost_min.push({
                    x:props.data["x"][i],
                    y:[Number(info.graph.th),element<=Number(info.graph.th)?Number(Constant.FAKE_MIN===element?info.graph.th:element):Number(info.graph.th)]}
                  )
                  _Y_Win_Lost_max.push({
                    x:props.data["x"][i],
                    y:[Number(info.graph.th),element>Number(info.graph.th)?Number(element):Number(info.graph.th)]}
                  )
                  i++
                }
                setY_Win_Lost([
                  {data:_Y_Win_Lost_min},
                  {data:_Y_Win_Lost_max}
                ])
              }
            }
          }
          else setType(info.graph.gt.value)
        }
        if(info.graph.hasOwnProperty("dd")) setNbDecimal(info.graph.dd)
        if(info.graph.hasOwnProperty("ts")) setThousandSep(info.graph.ts)
        if(info.graph.hasOwnProperty("ds")) setDeciSep(info.graph.ds)
        if(info.graph.hasOwnProperty("ct"))
          if(info.graph.ct.value==="smooth")  setDashArray(0)
          else setDashArray(2)
      }
    }

    var _max=0,_min=99999
    if(props.data["y"]){
      props.data["y"].forEach(element => {if(element>_max)_max=element});
      props.data["y"].forEach(element => {if(element<_min && element!==Constant.FAKE_MIN)_min=element});
    }

    if(info.type=== "analog_sensor"){
      _max=Utils.getConvertVal(_max,
        info.input_val_min,info.input_val_max,
        info.val_min,info.val_max
      )
      if(_min!==Constant.FAKE_MIN) _min=Utils.getConvertVal(_min,
        info.input_val_min,info.input_val_max,
        info.val_min,info.val_max
      )
    }

    setMax(_max)
    setMin(_min)
  },[props]);

  const getValue=()=>{
    let val=value,unit=''

    if(info.type=== "analog_sensor"){
      val=Utils.getConvertVal(
        value,
        info.input_val_min,
        info.input_val_max,
        info.val_min,
        info.val_max
      )
    }
    try {
      if(Number(val))val=(Number(val).toFixed(nbDecimal).toString().replace('.',deciSep).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep))
    }catch(error) {val=value}
    if(info.hasOwnProperty("unit"))unit=info.unit
    return val+""+unit
  }
  const getMax=()=>{
    if(info.hasOwnProperty("graph")){
      if(info.graph.hasOwnProperty("gph_bound")){
        if(info.graph.gph_bound)return info.val_max?info.val_max:100
        else return (max+(max*.1))
      }return (max+(max*.1))
    }return (max+(max*.1))
  }
  const getMin=()=>{
    if(info.hasOwnProperty("graph")){
      if(info.graph.hasOwnProperty("gph_bound")){
        if(info.graph.gph_bound)return info.val_min?info.val_min:0
        else return (min-(min*.03))
      }return (min-(min*.03))
    }return (min-(min*.03))
  }
  
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Neolec ⚡⚡'
    >
      <Box
        bg={bgColor}
        p="3px"
        borderRadius= "20px">
          <ReactApexChart
            key= {info.title.split("(")[0]}
            options={{
              chart: {
                id:info.title.split("(")[0],
                toolbar: {show: false},
                //group: 'sync',
                //sparkline: {enabled: true},
              },
              plotOptions: type==="rangeArea"?{
                bar: {
                  colors: {
                    ranges: [{
                      from: 50,
                      to: 66,
                      color: '#F15B46'
                    }, {
                      from: 50,
                      to: 0,
                      color: '#FEB019'
                    }]
                  },
                  horizontal: false
                }
              }:{},
              colors:color,
              dataLabels: {enabled: false},
              stroke: {
                width: 2,
                curve:"straight",// 'smooth' straight,
                dashArray
              },
              title: {
                text: getValue(),
                offsetX: 0,
                style: {
                  fontSize: '24px',
                  color:textColor
                }
              },
              subtitle: {
                text: info.title.split("(")[0],
                offsetX: 0,
                style: {
                  fontSize: '14px',
                  color:textColor
                }
              },
              xaxis: {
                type: 'datetime',
                show: true,
                categories:type==="rangeArea"?[]:props.data["x"]?props.data["x"]:[],
                labels: {
                  style: {colors: "#0068b6",fontSize: "9px"},
                  datetimeUTC: false,
                  datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM HH:mm',
                    hour: 'HH:mm'
                  }
                }
              },
              yaxis: {
                tickAmount: 4,
                floating: false,
                axisBorder: {show: false},
                axisTicks: {show: false},
                forceNiceScale: true,
                max:getMax(),
                min:getMin(),
                labels: {
                  style: {
                    colors: '#8e8da4',
                    fontSize:"9px"
                  },
                  formatter: function (value) {
                    return  (Number(value).toFixed(nbDecimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\''))//>999?Math.round(value/1000)+"k":Math.round(value);
                  }
                }
              },
              tooltip: {
                enabled: true,
                fillSeriesColor: false,
                theme: "light",
                x: {
                    show: false,
                    format: 'HH:mm'//'dd MMM, HH:mm',
                },
                y: {
                  title: {formatter: (seriesName) => ""}
                },
              },
              annotations: {
                yaxis: [
                  hideMinAnnotation.state?{
                    y: min,
                    borderColor: hideMinAnnotation.color,
                    label: {
                      borderColor: '#073a61',
                      style: {
                        color: '#fff',
                        background: hideMinAnnotation.color,
                        fontSize:"10px",
                        fontWeight:"bold"
                      },
                      offsetY:15,
                      text:'Min: '+Number(min).toFixed(nbDecimal).toString().replace('.',deciSep).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep)+""+(info.hasOwnProperty("unit")?info.unit:"")
                    }
                  }:{},
                  hideMaxAnnotation.state?{
                    y: max,
                    borderColor: hideMaxAnnotation.color,
                    label: {
                      borderColor: '#073a61',
                      style: {
                        color: '#fff',
                        background: hideMaxAnnotation.color,
                        fontSize:"10px",
                        fontWeight:"bold"
                      },
                      text:'Max: '+Number(max).toFixed(nbDecimal).toString().replace('.',deciSep).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep)+""+(info.hasOwnProperty("unit")?info.unit:"")
                    }
                  }:{},
                  hideThAnnotation.state?{
                    y: seuil,
                    borderColor: hideThAnnotation.color,
                    label: {
                      borderColor: '#fff',
                      offsetX: -205,
                      style: {
                        color: hideThAnnotation.color,
                        fontSize:"10px",
                        fontWeight:"bold"
                      },
                      text: seuilTitle+" "+Number(seuil).toFixed(Number(nbDecimal)).toString().replace('.',deciSep).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep)+""+(info.hasOwnProperty("unit")?info.unit:""),
                    }
                  }:{}
                ]
              },
              grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#f3f3f3', 'transparent'], 
                  opacity: 0.5
                },
              },
              fill: {
                type: 'solid',
                opacity:type==="line"?1:0.2
              },
              legend: {show: false}
            }}
            series={type==="rangeArea"?Y_Win_Lost:[{
              name: info.label,
              data: props.data["y"]?props.data["y"].map(function(x) { return Utils.getConvertVal(
                x,
                info.input_val_min,
                info.input_val_max,
                info.val_min,
                info.val_max
              ) }):[]
            }]}
            type={type}
          />
      </Box>
    </LoadingOverlay>
  );
}

export default withTranslation()(Default);