export default function BoilerDark(props) {
    return(<svg width="204px" height="264px" viewBox="0 0 264 400" transform="translate(-84, -14)" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g id="drop" stroke="none" transform="translate(208, 20) scale(.6)" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M32,12.013156 C23.5507091,23.3648588 18.9498379,31.0271402 18.1973862,35 C16.1140083,46 25.1178619,51.0087576 32,51.0087576 C38.9421789,51.0087576 47.8745727,45.9182866 45.8193653,35 C45.0751685,31.0464552 40.4687134,23.3841738 32,12.013156 Z" id="Path-20" stroke="#012038" stroke-width="1.5" stroke-linejoin="round"></path>
                <path d="M24.0112189,41.0287482 C24.0258372,42.326898 24.3602052,43.3133108 25.0143227,43.9879865 C25.6684403,44.6626622 26.6569258,45 27.9797793,45" id="Path-21-Copy" stroke="#012038" stroke-width="1.5" stroke-linecap="round"></path>
                <text  transform="scale(2.2)" fontFamily="Montserrat-Regular, Montserrat" fontSize="17" fontWeight="normal" fill="#012038">
                    <tspan x="26" y="21">{props.humidity}</tspan>
                </text>
            </g>
            <g id="temperature" stroke="none" transform="translate(100, 20) scale(.6)"  stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M32,5 C34.209139,5 36,6.790861 36,9 L36.0004128,39.8322677 C39.5320019,41.3754752 42,44.8995217 42,49 C42,54.5228475 37.5228475,59 32,59 C26.4771525,59 22,54.5228475 22,49 C22,44.8991333 24.4684657,41.3748076 28.0005908,39.8318293 L28,9 C28,6.790861 29.790861,5 32,5 Z" id="Combined-Shape" stroke="#012038" stroke-width="1.5"></path>
                <circle id="Oval" stroke="#012038" stroke-width="1.5" cx="32" cy="49" r="4"></circle>
                <line x1="32" y1="45" x2="32" y2="15" id="Line-19" stroke="#012038" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
                <line x1="39" y1="24" x2="47" y2="24" id="Line-19" stroke="#012038" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
                <line x1="39" y1="21" x2="43" y2="21" id="Line-19" stroke="#012038" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
                <line x1="39" y1="27" x2="43" y2="27" id="Line-19-Copy" stroke="#012038" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
                <line x1="39" y1="30" x2="47" y2="30" id="Line-19" stroke="#012038" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
                <line x1="39" y1="33" x2="43" y2="33" id="Line-19" stroke="#012038" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
                <text  transform="scale(2.2)" fontFamily="Montserrat-Regular, Montserrat" fontSize="17" fontWeight="normal" fill="#012038">
                    <tspan x="26" y="21">{props.tempExt}</tspan>
                </text>
            </g>
            <g stroke="none" transform="scale(6.3)" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M22.4918031,61 L41.9471939,61 C42.5286426,61 43,60.5286426 43,59.9471939 L43,12.941774 C43.0011465,12.4178551 42.6168815,11.9728154 42.0984147,11.8974196 C37.9460593,11.2991399 34.579921,11 32,11 C29.4214286,11 26.0574625,11.2988269 21.9081016,11.8964808 C21.3878944,11.9733263 21.0037975,12.4219197 21.0084057,12.9477517 L21.4390411,59.9568379 C21.4443329,60.5344997 21.914117,61 22.4918031,61 Z" stroke="#012038" strokeWidth="1.5" strokeLinejoin="round"></path>
                <circle stroke="#012038" strokeWidth="1.5" cx="32" cy="50" r="6"></circle>
                <line x1="19" y1="61" x2="45" y2="61" stroke="#012038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line>
                
                <g  transform="translate(27, 18)" stroke="#012038">
                    <g id="flash" transform="translate(4, 28) scale(.16)" fill="#012038" stroke="#012038" strokeWidth="2">
                        <polygon points="12 0 0 28 8.79126148 28"></polygon>
                        <polygon transform="translate(13.000000, 38.000000) scale(-1, -1) translate(-13.000000, -38.000000) " points="19 24 7 52 15.7912615 52"></polygon>

                        <text  transform="scale(1.2)" fontFamily="Montserrat-Regular, Montserrat" fontSize="17" fontWeight="normal" fill="#012038">
                            <tspan x="-23" y="71">{props.power}</tspan>
                        </text>
                    </g>
                </g>
                <line x1="20.8360656" y1="18" x2="43" y2="18" stroke="#012038" strokeWidth="1.5"></line>
                <g onClick={()=>props.onChange()} class="switchBtn" cursor="pointer" transform="translate(30, 12) scale(.13)">
                            <circle id="Oval" stroke={props.state?"#7AC143":"#C14343"}  fill={props.state?"#7AC143":"#C14343"} cx="18" cy="18" r="18"></circle>
                            <path d="M13.2427261,10.7714697 C10.3055577,12.3857121 8.38165837,15.4371527 8.51103247,18.8720241 C8.69827505,23.8432986 13.1155319,27.7352116 18.3772518,27.5648552 C23.6389717,27.3944988 27.7526483,23.2263835 27.5654058,18.255109 C27.439679,14.917074 25.4067878,12.065685 22.4916731,10.6039869" id="Path" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
                            <line x1="18.1" y1="7.04615385" x2="18.1" y2="15.3538458" id="Line" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></line>
                        </g>
                <text  transform="scale(.2)" fontFamily="Montserrat-Regular, Montserrat" fontSize="17" fontWeight="normal" fill="#012038">
                    <tspan x="80" y="330">{props.title}</tspan>
                </text>
            </g>
        </g>
    </svg>)
}