import React from "react";

import { Icon } from "@chakra-ui/react";
import {MdDashboard,MdPerson,MdOutlineSchema} from "react-icons/md";
import {FiUsers} from "react-icons/fi";
import {AiFillControl} from "react-icons/ai";
import {BiChip} from "react-icons/bi";
import {MdSensors} from "react-icons/md";
import {BsFillFileEarmarkBarGraphFill} from "react-icons/bs";


// Admin Imports
import MainDashboard from "views/admin/default";
import Monitoring from "views/admin/monitoring_control";
import Profile from "views/admin/profile";
import Devices from "views/admin/devices";
import Sensors from "views/admin/sensors";
import Users from "views/admin/users";
import Demo from "views/admin/demo";

export function routesNoTr(){
  return [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    showSelectDevice: true
  },
  {
    name: "Vue d'ensemble",
    layout: "/admin",
    path: "/overview",
    icon: <Icon as={BsFillFileEarmarkBarGraphFill} width='20px' height='20px' color='inherit' />,
    component: Demo,
  },
  {
    name: "Suivi et contrôle",
    layout: "/admin",
    path: "/monitoring_control",
    showSelectDevice: true,
    icon: (
      <Icon
        as={AiFillControl}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Monitoring,
    showSelectDevice: true
  },
  {
    name: "Utilisateurs",
    layout: "/admin",
    icon: <Icon as={FiUsers} width='20px' height='20px' color='inherit' />,
    path: "/users",
    component: Users,
  },
  {
    name: "Appareil",
    layout: "/admin",
    icon: <Icon as={BiChip} width='20px' height='20px' color='inherit' />,
    path: "/devices",
    component: Devices,
    showSelectDevice: true
  },
  {
    name: "Capteurs",
    layout: "/admin",
    icon: <Icon as={MdSensors} width='20px' height='20px' color='inherit' />,
    path: "/sensors",
    component: Sensors,
    showSelectDevice: true
  },
  {
    name: "Profil",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    showSelectDevice: true
  },
  {
    name: "Demo",
    layout: "/admin",
    path: "/demo",
    icon: <Icon as={MdOutlineSchema} width='20px' height='20px' color='inherit' />,
    component: Demo,
  }
];
}

export default routesNoTr;
