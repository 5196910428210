/*!
                                                                                                                                                                                                                                                                                                                                   
=========================================================
* Horizon UI - v1.1.0
=========================================================

*/

import React from "react";
// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// Custom components
import TwoFactorAuth from "./components/TwoFactorAuth";
import Notifications from "./components/Notifications";
import MyInfos from "./components/MyInfos";
// Redux
import { useSelector,useDispatch} from 'react-redux';

import { useHistory } from "react-router-dom";

export default function Profile() {
  const myInfos = useSelector(state => state.user.infos);
  const dispatch = useDispatch();

  let history = useHistory();
  const [secret, setSecret] = React.useState({otpauth_url: "",base32: ""});
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => { 
    document.title = "Profil utilisateur - NeoLec";
    if(myInfos.token){ 
    }else history.push('/')

    return () => {}
  },[]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
       <SimpleGrid columns={{base:1,md:2,xl:2}} gap={"20px"}>
        <MyInfos
          setErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message');}}
          showMsg={(msg)=>{NotificationManager.info(msg, 'Message');}}
          sendOTPDatas={(datas)=>{
            setSecret(datas);
            setOpenModal(true)
          }}
          setSuccessMsg={(msg)=>{
            NotificationManager.info(msg, 'Message');
            let _myInfos=myInfos
            if(_myInfos.otp_enabled)delete _myInfos.otp_enabled
            dispatch({type:'SET_INFOS',payload:_myInfos});
          }}
          
        />
        <Notifications />
      </SimpleGrid>

      {openModal?
        <TwoFactorAuth
          isOpen={openModal} 
          base32={secret.base32}
          otpauth_url={secret.otpauth_url}
          user_id={myInfos._id}
          closeModal={() => setOpenModal(false)}
          setErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message');}}
          setSuccessMsg={(msg)=>{
            NotificationManager.success(msg, 'Message');
            let _myInfos=myInfos
            _myInfos.otp_enabled=true
            dispatch({type:'SET_INFOS',payload:_myInfos});
          }}
        />:null}
      <NotificationContainer/>
    </Box>
  );
}
