import {
  Flex,Button,Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  FormControl,
  FormLabel,Switch,
  Input,Spacer,

  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,

  InputGroup,
  InputLeftAddon,
  Stack,Tooltip
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import TextInput from 'react-autocomplete-input';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import MultiSelectSort from 'components/fields/SortableSelect';
import { DragHandleIcon } from "@chakra-ui/icons";
import Select from 'react-select';

import { withTranslation } from 'react-i18next';
import React from "react";
import * as R from 'ramda';
// Redux
import { useSelector} from 'react-redux';

import LoadingOverlay from 'react-loading-overlay';
import { Scrollbar } from 'react-scrollbars-custom';
import {arrayMoveImmutable} from 'array-move';

import Constant from "utils/Constant";
import { Post } from 'utils/queries';

function ModalConfig(props) {
  const {t,isOpen, onClose,token,showMsg,data,devices}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const cur_device = useSelector(state => state.device.cur_device);
  const [values, setValues] = React.useState({
    _id:"",
    title:'',
    select_devices: [],
    anim_svg: {label:t("nothing"),value:"nothing"},
    sensorsPlotted:[],
    configured_sensors: [],
    section_indicators:[],
    module_programmation:false,
    module_demo:false,
    neo_clim_module:[],
    on_off_module:[],
    boiler_module:[],
    isLoading:false,
  });
  const [dragNdrop, setDragNdrop] = React.useState({
    sensorsPlotted:false,
    grouping_indicator:false
  });
  const [sensorsList, setSensorsList] =  React.useState([]);
  const [sensorsListAll, setSensorsListAll] =  React.useState([]);
  const [deviceLabelList, setDeviceLabelList] =  React.useState([]);
  const [variables, setVariables] = React.useState([]);
  const [linking, setLinking] = React.useState({/*
    SRA:"",   GCP:'',  DHW:'',
    PVP:'',   CCP:'',  SOC:'',
    GPC:'',   CCS:'',  CPB:'',
    WHS: "",  HBT:'',  BVO*/
  });
  
  const animation_list=[
    {label:t("nothing"),value:"nothing"},
    {label:t("main_animation"),value:"main_animation"}
  ]
  var response,_variables
  
  React.useEffect(async () => {  
    await listSensors()
    if(data.hasOwnProperty("dashboard")){
      var _data={
        _id:data._id,
        title:data.title,
        select_devices: [],
        anim_svg: {label:t(data.dashboard.svg),value:data.dashboard.svg},
        sensorsPlotted:[],
        configured_sensors: [],
        section_indicators:[],
        neo_clim_module:[],
        on_off_module:[],
        boiler_module:[],
        isLoading:false
      }
      
      if(data.dashboard.hasOwnProperty("monitoring_24h")){
        _data.configured_sensors=[]
        data.dashboard.monitoring_24h.forEach(monitoring_24h => {
          _data.configured_sensors.push(monitoring_24h)
        });
      }
      if(data.dashboard.hasOwnProperty("svg_linking")){
        setLinking( data.dashboard.svg_linking)
      }
      if(data.dashboard.hasOwnProperty("graphs")){
        _data.sensorsPlotted=[]
        data.dashboard.graphs.forEach(graph => {
          _data.sensorsPlotted.push({
            _id:graph._id,
            title:graph.title,
            stacked:graph.stacked,
            items:graph.items
          })
        });
      }
      if(data.hasOwnProperty("monitoring")){
        _data.section_indicators=[]
        data.monitoring.forEach(monitoring => {
          _data.section_indicators.push({
            id:monitoring.id,
            title:monitoring.title,
            items:monitoring.items
          })
        });
      }

      if(data.hasOwnProperty("controls")){
        if(data.controls.hasOwnProperty("neo_clim_module")){
          _data.neo_clim_module=[]
          data.controls.neo_clim_module.forEach(monitoring => {
            _data.neo_clim_module.push({
              id:monitoring.id,
              title:monitoring.title,
              temperature:monitoring.temperature,
              temperatureExt:monitoring.temperatureExt,
              humidity:monitoring.humidity,
              humidity:monitoring.humidity,
              power:monitoring.power,
              status_clim:monitoring.status_clim,
              status_mystrom:monitoring.status_mystrom
            })
          });
        }
        if(data.controls.hasOwnProperty("on_off_module")){
          _data.on_off_module=[]
          data.controls.on_off_module.forEach(boiler => {
            _data.on_off_module.push({
              id:boiler.id,
              title:boiler.title,
              relay:boiler.relay,
              temp:boiler.temp,
            })
          });
        }
        if(data.controls.hasOwnProperty("boiler_module")){
          _data.boiler_module=[]
          data.controls.boiler_module.forEach(monitoring => {
            _data.boiler_module.push({
              id:monitoring.id,
              title:monitoring.title,
              temperature:monitoring.temperature,
              temperatureExt:monitoring.temperatureExt,
              humidity:monitoring.humidity,
              status:monitoring.status,
              power:monitoring.power
            })
          });
        }
        
        _data.module_programmation=false
        if(data.controls.hasOwnProperty("module_programmation"))
          _data.module_programmation=data.controls.module_programmation
        
        _data.module_demo=false
        if(data.controls.hasOwnProperty("module_demo"))
          _data.module_demo=data.controls.module_demo

          
      }
      
      setValues({ ...values, 
        select_devices: [],
        _id: _data._id,
        title: _data.title,
        anim_svg: _data.anim_svg,
        sensorsPlotted:_data.sensorsPlotted,
        configured_sensors: _data.configured_sensors,
        section_indicators:_data.section_indicators,
        neo_clim_module:_data.neo_clim_module,
        on_off_module:_data.on_off_module,
        boiler_module:_data.boiler_module,
        module_programmation:_data.module_programmation,
        module_demo:_data.module_demo,
        isLoading:false});

      /*setTimeout(() => {
        setValues({ ...values, "select_devices":data.select_devices});
      }, 1000);*/
    }
    return () => {}
  },[cur_device]);

  const listSensors= async ()=>{
    var _sensorsList=[]
    setValues({ ...values,"isLoading":true})
    response=await Post.requestPost("listSensors",{token,device_id:"all"})
    let label=""

    _variables=[]
    response.forEach(element => {
      label=""
      if(element.device_id){
        label=R.filter(o => o.value === element.device_id, devices)[0]
        if(label)if(label.hasOwnProperty("label"))label=label.label
      }

      _sensorsList.push({
        value:element.id,
        device_id:element.device_id,
        unit:element.unit,
        subtitle:element.subtitle,
        title:element.title+" ("+label+")",
        label:element.title,
        _id:element._id,
        type:element.type,
      })

      _variables.push(element.id)

      if(element.formula)_sensorsList[_sensorsList.length-1].formula=element.formula
      if(element.graph)_sensorsList[_sensorsList.length-1].graph=element.graph
      if(element.val_min)_sensorsList[_sensorsList.length-1].val_min=element.val_min
      if(element.val_max)_sensorsList[_sensorsList.length-1].val_max=element.val_max
      if(element.input_val_min)_sensorsList[_sensorsList.length-1].input_val_min=element.input_val_min
      if(element.input_val_max)_sensorsList[_sensorsList.length-1].input_val_max=element.input_val_max
      if(element.diff_index)_sensorsList[_sensorsList.length-1].diff_index=element.diff_index
      if(element.no_integrate)_sensorsList[_sensorsList.length-1].no_integrate=element.no_integrate
    });             
    setVariables(_variables)
    setValues({ ...values,"isLoading":false})

    let regExp,matches,device_label_list=[]
    for (const elt of _sensorsList) {
      regExp = /\(([^)]+)\)/;
      try {
        matches = regExp.exec(elt.title);
        if(!device_label_list.includes(matches[1]))device_label_list.push(matches[1])
      } catch (error) { }
    }
    regExp=device_label_list
    device_label_list=[]
    for (const elt of regExp){
      device_label_list.push({ value:elt, label:elt})
    }
    setDeviceLabelList(device_label_list)
    setSensorsList(_sensorsList)
    setSensorsListAll(_sensorsList)
  }

  const handleSubmit = async (e) => { 
    e.preventDefault();
    let myViews={};

    if(values.title.length>0){

      if(values.configured_sensors.length>0){
        myViews.dashboard={}
        if(values._id.length>0)myViews._id=values._id
        myViews.title=values.title
        myViews.dashboard.svg=values.anim_svg.value
        myViews.dashboard.monitoring_24h=values.configured_sensors
        myViews.dashboard.graphs=values.sensorsPlotted
        
        myViews.monitoring=values.section_indicators

        myViews.controls={}
        if(values.neo_clim_module.length>0)myViews.controls.neo_clim_module=values.neo_clim_module
        if(values.on_off_module.length>0)myViews.controls.on_off_module=values.on_off_module
        if(values.boiler_module.length>0)myViews.controls.boiler_module=values.boiler_module
        myViews.controls.module_programmation=values.module_programmation
        myViews.controls.module_demo=values.module_demo
        
        if(values.anim_svg.value==="main_animation")
          if(Object.keys(linking).length!==0)
            myViews.dashboard.svg_linking=linking
          
        fetch(Constant.SERVER_URL+'/updateUserViews', {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify({token,myViews})
        }).then(response => response.json())
        .then(responseData=>{
          if(responseData.hasOwnProperty("error")){
            if(responseData.error===200){
              showMsg(t("successful_modification"))
              onClose();
              setTimeout(() => {window.location.reload();}, 2000);
            }else  showMsg(responseData.msg,"error")
          }else  showMsg(t("error_occurred"),"error")
          setValues({ ...values,"isLoading":false})
        });
      }else showMsg(t("monitored_over_24_hours"),"error")
    }else showMsg(t("config_title"),"error")
  }


  const sensors_to_be_plotted=()=>{
    const SortableItem = SortableElement((plotter,indx) => {
      plotter=plotter.value
      return <div key={indx}>
        <br/>
        <Flex align="center">
          <DragHandleIcon w='20px' h='32px' color={bgColor} />
          <Box w="100%">
            <Flex direction='row' align="center">
              <Input
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                mb='9px'
                fontWeight='500'
                placeholder={t("title")}
                value={plotter.title}
                onChange={(item)=>{
                  let sensorsPlotted=values.sensorsPlotted
                  for (let i = 0; i < sensorsPlotted.length; i++) {
                    if(sensorsPlotted[i]._id===plotter._id){
                      sensorsPlotted[i].title=item.target.value
                      break
                    }
                  }
                  setValues({...values,sensorsPlotted})
                }}
                size='md'
              />
        
              <Flex align="center">
                <Switch  
                  isChecked={plotter.stacked}
                  onChange={(item)=>{
                    var sensorsPlotted=values.sensorsPlotted
                    for (let i = 0; i < sensorsPlotted.length; i++) {
                      if(sensorsPlotted[i]._id===plotter._id){
                        sensorsPlotted[i].stacked=item.target.checked
                        break
                      }
                    }
                    setValues({...values,sensorsPlotted})
                  }}
                />
                <p>{t("stacked")}</p>
              </Flex>
            </Flex>
            <Flex w="100%" direction='row' align="center">
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                value={plotter.items}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("series_graph")}
                onChange={(sensors_plotted)=>{
                  for (let i = 0; i < sensors_plotted.length; i++) {
                    if(sensors_plotted[i].value){
                      sensors_plotted[i].id=sensors_plotted[i].value
                      delete sensors_plotted[i].value
                    }
                  }
                  var sensorsPlotted=values.sensorsPlotted
                  for (let i = 0; i < sensorsPlotted.length; i++) {
                    if(sensorsPlotted[i]._id===plotter._id){
                      sensorsPlotted[i].items=sensors_plotted
                      break
                    }
                  }
                  setValues({...values,sensorsPlotted})
                }}
              />
              <Spacer />
              <Button colorScheme='red' size={"sm"}
                onClick={()=>{
                  setValues({ ...values,sensorsPlotted:R.filter(o => o._id !== plotter._id, values.sensorsPlotted)  })
                }}> x </Button>
            </Flex>
          </Box>
        </Flex>
      </div>
    });
  
    const SortableList = SortableContainer(({items}) => {
      return (
        <ul>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ul>
      );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
      setValues({ ...values,sensorsPlotted:arrayMoveImmutable(values.sensorsPlotted, oldIndex, newIndex)  })
           
    };
    
    if(dragNdrop.sensorsPlotted)return <SortableList items={values.sensorsPlotted} onSortEnd={onSortEnd} />
    else return<>{values.sensorsPlotted.map((plotter, index) => (
        <div key={index}>
          <br/>
          <Box w="100%">
            <Flex direction='row' align="center">
              <Input
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                mb='9px'
                fontWeight='500'
                placeholder={t("title")}
                value={plotter.title}
                onChange={(item)=>{
                  let sensorsPlotted=values.sensorsPlotted
                  for (let i = 0; i < sensorsPlotted.length; i++) {
                    if(sensorsPlotted[i]._id===plotter._id){
                      sensorsPlotted[i].title=item.target.value
                      break
                    }
                  }
                  setValues({...values,sensorsPlotted})
                }}
                size='md'
              />
        
              <Flex align="center">
                <Switch  
                  isChecked={plotter.stacked}
                  onChange={(item)=>{
                    var sensorsPlotted=values.sensorsPlotted
                    for (let i = 0; i < sensorsPlotted.length; i++) {
                      if(sensorsPlotted[i]._id===plotter._id){
                        sensorsPlotted[i].stacked=item.target.checked
                        break
                      }
                    }
                    setValues({...values,sensorsPlotted})
                  }}
                />
                <p>{t("stacked")}</p>
              </Flex>
            </Flex>
            <Flex w="100%" direction='row' align="center">
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                value={plotter.items}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("series_graph")}
                onChange={(sensors_plotted)=>{
                  for (let i = 0; i < sensors_plotted.length; i++) {
                    if(sensors_plotted[i].value){
                      sensors_plotted[i].id=sensors_plotted[i].value
                      delete sensors_plotted[i].value
                    }
                  }
                  var sensorsPlotted=values.sensorsPlotted
                  for (let i = 0; i < sensorsPlotted.length; i++) {
                    if(sensorsPlotted[i]._id===plotter._id){
                      sensorsPlotted[i].items=sensors_plotted
                      break
                    }
                  }
                  setValues({...values,sensorsPlotted})
                }}
              />
              <Spacer />
              <Button colorScheme='red' size={"sm"}
                onClick={()=>{
                  setValues({ ...values,sensorsPlotted:R.filter(o => o._id !== plotter._id, values.sensorsPlotted)  })
                }}> x </Button>
            </Flex>
          </Box>
        </div>
      ))}</>
  }

  const section_grouping_indicators=()=>{
    const SortableItem = SortableElement((indicator) => {
      indicator=indicator.value
      return <Flex key={indicator.id} direction='row' align="center">
        <DragHandleIcon w='20px' h='32px' color={bgColor} />
        <div>
          <FormControl>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              mb='9px'
              fontWeight='500'
              placeholder={t("section_title")}
              value={indicator.title}
              onChange={(item)=>{
                var section_indicators=values.section_indicators
                for (let i = 0; i < section_indicators.length; i++) {
                  if(section_indicators[i].id===indicator.id){
                    section_indicators[i].title=item.target.value
                    break
                  }
                }
                setValues({...values,section_indicators})
              }}
              size='lg'
            />
            <MultiSelectSort
              ms={{ base: "0px", md: "0px" }}
              options={sensorsList}
              isMulti
              value={indicator.items}
              getOptionLabel ={(option)=>option.title}
              getOptionValue ={(option)=>option.id}
              placeholder={t("indicator_add_ection")}
              onChange={(items)=>{
                for (let i = 0; i < items.length; i++)
                  if(items[i].value){
                    items[i].id=items[i].value
                    delete items[i].value
                  }

                var section_indicators=values.section_indicators
                for (let i = 0; i < section_indicators.length; i++) {
                  if(section_indicators[i].id===indicator.id){
                    section_indicators[i].items=items
                    break
                  }
                }
                setValues({...values,section_indicators})
              }}
            />
          </FormControl>
          <br/><br/>
        </div>
        <Spacer />
        <Button colorScheme='red' size={"sm"}
          onClick={()=>{
            setValues({ ...values,section_indicators:R.filter(o => o.id !== indicator.id, values.section_indicators)  })
          }}> x </Button>
      </Flex>
    });
  
    const SortableList = SortableContainer(({items}) => {
      return (
        <ul>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ul>
      );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
      setValues({ ...values,section_indicators:arrayMoveImmutable(values.section_indicators, oldIndex, newIndex)  })
           
    };

    if(dragNdrop.grouping_indicator)return <SortableList items={values.section_indicators} onSortEnd={onSortEnd} />;
    else return<>{values.section_indicators.map((indicator, index) => (
      <Flex key={indicator.id} direction='row' align="center">
        <div>
          <FormControl>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              mb='9px'
              fontWeight='500'
              placeholder={t("section_title")}
              value={indicator.title}
              onChange={(item)=>{
                var section_indicators=values.section_indicators
                for (let i = 0; i < section_indicators.length; i++) {
                  if(section_indicators[i].id===indicator.id){
                    section_indicators[i].title=item.target.value
                    break
                  }
                }
                setValues({...values,section_indicators})
              }}
              size='lg'
            />
            <MultiSelectSort
              ms={{ base: "0px", md: "0px" }}
              options={sensorsList}
              isMulti
              value={indicator.items}
              getOptionLabel ={(option)=>option.title}
              getOptionValue ={(option)=>option.id}
              placeholder={t("indicator_add_ection")}
              onChange={(items)=>{
                for (let i = 0; i < items.length; i++)
                  if(items[i].value){
                    items[i].id=items[i].value
                    delete items[i].value
                  }

                var section_indicators=values.section_indicators
                for (let i = 0; i < section_indicators.length; i++) {
                  if(section_indicators[i].id===indicator.id){
                    section_indicators[i].items=items
                    break
                  }
                }
                setValues({...values,section_indicators})
              }}
            />
          </FormControl>
          <br/><br/>
        </div>
        <Spacer />
        <Button colorScheme='red' size={"sm"}
          onClick={()=>{
            setValues({ ...values,section_indicators:R.filter(o => o.id !== indicator.id, values.section_indicators)  })
          }}> x </Button>
      </Flex>
    ))}</>
  }

  const section_neo_clim=()=>{
    return values.neo_clim_module.map((indicator) => (
      <Flex key={indicator.id} direction='row' align="center">
        <div>
          <FormControl>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              mb='9px'
              fontWeight='500'
              placeholder={t("section_title")}
              value={indicator.title}
              onChange={(item)=>{
                var neo_clim_module=values.neo_clim_module
                for (let i = 0; i < neo_clim_module.length; i++) {
                  if(neo_clim_module[i].id===indicator.id){
                    neo_clim_module[i].title=item.target.value
                    break
                  }
                }
                setValues({...values,neo_clim_module})
              }}
              size='lg'
            />
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("temperature")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                isMulti
                value={indicator.temperature}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("temperature")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      //if(items[i].subtitle)items[i].subtitle=items[i].subtitle
                      delete items[i].value
                    }

                  var neo_clim_module=values.neo_clim_module
                  for (let i = 0; i < neo_clim_module.length; i++) {
                    if(neo_clim_module[i].id===indicator.id){
                      neo_clim_module[i].temperature=items
                      break
                    }
                  }
                  setValues({...values,neo_clim_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("humidity")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                isMulti
                value={indicator.humidity}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("humidity")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var neo_clim_module=values.neo_clim_module
                  for (let i = 0; i < neo_clim_module.length; i++) {
                    if(neo_clim_module[i].id===indicator.id){
                      neo_clim_module[i].humidity=items
                      break
                    }
                  }
                  setValues({...values,neo_clim_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("power")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                isMulti
                value={indicator.power}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("power")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var neo_clim_module=values.neo_clim_module
                  for (let i = 0; i < neo_clim_module.length; i++) {
                    if(neo_clim_module[i].id===indicator.id){
                      neo_clim_module[i].power=items
                      break
                    }
                  }
                  setValues({...values,neo_clim_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("Status Clim")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                isMulti
                value={indicator.status_clim}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":"Status Clim"})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var neo_clim_module=values.neo_clim_module
                  for (let i = 0; i < neo_clim_module.length; i++) {
                    if(neo_clim_module[i].id===indicator.id){
                      neo_clim_module[i].status_clim=items
                      break
                    }
                  }
                  setValues({...values,neo_clim_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("Status MyStrom")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={sensorsList}
                isMulti
                value={indicator.status_mystrom}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":"Status MyStrom"})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var neo_clim_module=values.neo_clim_module
                  for (let i = 0; i < neo_clim_module.length; i++) {
                    if(neo_clim_module[i].id===indicator.id){
                      neo_clim_module[i].status_mystrom=items
                      break
                    }
                  }
                  setValues({...values,neo_clim_module})
                }}
              />
            </Box>
          </FormControl>
          <br/><br/>
        </div>
        <Spacer />
        <Button colorScheme='red' size={"sm"}
          onClick={()=>{
            setValues({ ...values,neo_clim_module:R.filter(o => o.id !== indicator.id, values.neo_clim_module)  })
          }}> x </Button>
      </Flex>
    ))
  }
  const section_on_off=()=>{
    return values.on_off_module.map((indicator) => (
      <Flex key={indicator.id} direction='row' align="center">
        <div>
          <FormControl>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              mb='9px'
              fontWeight='500'
              placeholder={t("section_title")}
              value={indicator.title}
              onChange={(item)=>{
                var on_off_module=values.on_off_module
                for (let i = 0; i < on_off_module.length; i++) {
                  if(on_off_module[i].id===indicator.id){
                    on_off_module[i].title=item.target.value
                    break
                  }
                }
                setValues({...values,on_off_module})
              }}
              size='lg'
            />
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("relay")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.type === "digital_sensor", sensorsList)}
                isMulti
                value={indicator.relay}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("relay")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      //if(items[i].subtitle)items[i].subtitle=items[i].subtitle
                      delete items[i].value
                    }

                  var on_off_module=values.on_off_module
                  for (let i = 0; i < on_off_module.length; i++) {
                    if(on_off_module[i].id===indicator.id){
                      on_off_module[i].relay=items
                      break
                    }
                  }
                  setValues({...values,on_off_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("Set point sensor")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.type === "analog_sensor", sensorsList)}
                isMulti
                value={indicator.temp}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("setpoint")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var on_off_module=values.on_off_module
                  for (let i = 0; i < on_off_module.length; i++) {
                    if(on_off_module[i].id===indicator.id){
                      on_off_module[i].temp=items
                      break
                    }
                  }
                  setValues({...values,on_off_module})
                }}
              />
            </Box>
          </FormControl>
          <br/><br/>
        </div>
        <Spacer />
        <Button colorScheme='red' size={"sm"}
          onClick={()=>{
            setValues({ ...values,on_off_module:R.filter(o => o.id !== indicator.id, values.on_off_module)  })
          }}> x </Button>
      </Flex>
    ))
  }
  const section_boiler=()=>{
    return values.boiler_module.map((indicator) => (
      <Flex key={indicator.id} direction='row' align="center">
        <div>
          <FormControl>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              mb='9px'
              fontWeight='500'
              placeholder={t("section_title")}
              value={indicator.title}
              onChange={(item)=>{
                var boiler_module=values.boiler_module
                for (let i = 0; i < boiler_module.length; i++) {
                  if(boiler_module[i].id===indicator.id){
                    boiler_module[i].title=item.target.value
                    break
                  }
                }
                setValues({...values,boiler_module})
              }}
              size='lg'
            />
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("water heating temperature")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.unit === "˚C"||o.unit === "°C", sensorsList)}
                isMulti
                value={indicator.temperature}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("temperature")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      //if(items[i].subtitle)items[i].subtitle=items[i].subtitle
                      delete items[i].value
                    }

                  var boiler_module=values.boiler_module
                  for (let i = 0; i < boiler_module.length; i++) {
                    if(boiler_module[i].id===indicator.id){
                      boiler_module[i].temperature=items
                      break
                    }
                  }
                  setValues({...values,boiler_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("ambient temperature")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.unit === "˚C"||o.unit === "°C", sensorsList)}
                isMulti
                value={indicator.temperatureExt}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("temperature")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      //if(items[i].subtitle)items[i].subtitle=items[i].subtitle
                      delete items[i].value
                    }

                  var boiler_module=values.boiler_module
                  for (let i = 0; i < boiler_module.length; i++) {
                    if(boiler_module[i].id===indicator.id){
                      boiler_module[i].temperatureExt=items
                      break
                    }
                  }
                  setValues({...values,boiler_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("humidity")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.unit === "%", sensorsList)}
                isMulti
                value={indicator.humidity}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("humidity")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      //if(items[i].subtitle)items[i].subtitle=items[i].subtitle
                      delete items[i].value
                    }

                  var boiler_module=values.boiler_module
                  for (let i = 0; i < boiler_module.length; i++) {
                    if(boiler_module[i].id===indicator.id){
                      boiler_module[i].humidity=items
                      break
                    }
                  }
                  setValues({...values,boiler_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("Status")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.type === "digital_sensor", sensorsList)}
                isMulti
                value={indicator.status}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("Status")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var boiler_module=values.boiler_module
                  for (let i = 0; i < boiler_module.length; i++) {
                    if(boiler_module[i].id===indicator.id){
                      boiler_module[i].status=items
                      break
                    }
                  }
                  setValues({...values,boiler_module})
                }}
              />
            </Box>
            <Box>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='0px'>
                {t("power")}
              </FormLabel>
              <MultiSelectSort
                ms={{ base: "0px", md: "0px" }}
                options={R.filter(o => o.type === "analog_sensor", sensorsList)}
                isMulti
                value={indicator.power}
                getOptionLabel ={(option)=>option.title}
                getOptionValue ={(option)=>option.id}
                placeholder={t("indicator_param",{"param":t("power")})}
                onChange={(items)=>{
                  for (let i = 0; i < items.length; i++)
                    if(items[i].value){
                      items[i].id=items[i].value
                      delete items[i].value
                    }

                  var boiler_module=values.boiler_module
                  for (let i = 0; i < boiler_module.length; i++) {
                    if(boiler_module[i].id===indicator.id){
                      boiler_module[i].power=items
                      break
                    }
                  }
                  setValues({...values,boiler_module})
                }}
              />
            </Box>
          </FormControl>
          <br/><br/>
        </div>
        <Spacer />
        <Button colorScheme='red' size={"sm"}
          onClick={()=>{
            setValues({ ...values,boiler_module:R.filter(o => o.id !== indicator.id, values.boiler_module)  })
          }}> x </Button>
      </Flex>
    ))
  }
  
  return (
    <Modal blockScrollOnMount={false} isCentered isOpen={isOpen} size={"3xl"} 
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent>
        <ModalHeader>{"Configuration "}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoadingOverlay
            active={values.isLoading}
            spinner
            text={t("loading")}
          >
            {sensorsList.length!==0?<div>
              <Flex>
                <Box>
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='0px'>
                    {t("title")}
                  </FormLabel>
                  <Input
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    mb='9px'
                    fontWeight='500'
                    placeholder={t("config_title")}
                    value={values.title}
                    onChange={(item)=>{setValues({...values,title:item.target.value}) }}
                    size='lg'
                  />
                </Box>
                <Spacer />
                <Box>
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='0px'>
                    {t("device")+"(s)"}
                  </FormLabel>
                  <Select
                    ms={{ base: "0px", md: "0px" }}
                    options={deviceLabelList}
                    isMulti
                    placeholder={t("select_device")}
                    onChange={(devices_select)=>{
                      response=[]
                      for (let i = 0; i < devices_select.length; i++){
                        for (const elt of sensorsListAll) {
                          if(elt.title.includes(devices_select[i].value)) response.push(elt)
                        }
                      }
                      setSensorsList(response)
                      if(response){
                        _variables=[]
                        response.forEach(element => {
                          _variables.push(element.value )
                        });
                        
                        setVariables(_variables)
                      }
                    }}
                  />
                </Box>
              </Flex>
              <br/>
              <Tabs>
                <TabList>
                  <Tab>{t("dashboard")}</Tab>
                  <Tab>{t("monitoring_control")}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Scrollbar style={{ height: 350 }}>
                      <Box>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("svg_animation")}
                        </FormLabel>
                        <Select
                          ms={{ base: "0px", md: "0px" }}
                          options={animation_list}
                          placeholder={t("animation_list")}
                          value={values.anim_svg}
                          onChange={(anim_svg)=>{setValues({ ...values,anim_svg});}}
                        />
                        {values.anim_svg.value==="main_animation"?
                          <Accordion allowToggle>
                            <AccordionItem>
                              <h2>
                                <AccordionButton _expanded={{ bg:"#0064b5", color: 'white' }}>
                                  <Box as="span" flex='1' textAlign='left'>
                                  {t("linking")}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Stack spacing={2} direction='row'>
                                  <Stack>
                                    <Tooltip label='Solar radiation' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          SRA
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.SRA}
                                          onChange={ (text)=>{setLinking({...linking,SRA:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder={'Solar radiation'}
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='PV Production' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          PVP
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.PVP}
                                          onChange={ (text)=>{setLinking({...linking,PVP:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='PV Production'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Home Power Consumption' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          GPC
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.GPC}
                                          onChange={ (text)=>{setLinking({...linking,GPC:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Home Power Consumption'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Outside temperature' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          OTM
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.OTM}
                                          onChange={ (text)=>{setLinking({...linking,OTM:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Outside temperature'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Inside temperature' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          ITM
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.ITM}
                                          onChange={ (text)=>{setLinking({...linking,ITM:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Inside temperature'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Battery voltage' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                        BVO
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.BVO}
                                          onChange={ (text)=>{setLinking({...linking,BVO:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Battery voltage'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    
                                  </Stack>
                                  <Stack>
                                    <Tooltip label='Water Heat Solar' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          WHS
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.WHS}
                                          onChange={ (text)=>{setLinking({...linking,WHS:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Water Heat Solar'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Network consumption' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          GCP
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.GCP}
                                          onChange={ (text)=>{setLinking({...linking,GCP:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Network consumption'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Charge Car Power' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          CCP
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.CCP}
                                          onChange={ (text)=>{setLinking({...linking,CCP:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Charge Car Power'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Charge Car Statut' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          CCS
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.CCS}
                                          onChange={ (text)=>{setLinking({...linking,CCS:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Charge Car Statut'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Heating buffer temperature' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          HBT
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.HBT}
                                          onChange={ (text)=>{setLinking({...linking,HBT:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Heating buffer temperature'
                                        />
                                       </InputGroup>
                                    </Tooltip>
                                  </Stack>
                                  <Stack>
                                    <Tooltip label='Domestic hot water' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          DHW
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.DHW}
                                          onChange={ (text)=>{setLinking({...linking,DHW:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Domestic hot water'
                                        />
                                       </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Bat. State Of Charge' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          SOC
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.SOC}
                                          onChange={ (text)=>{setLinking({...linking,SOC:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Bat. State Of Charge'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Charge power battery' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                          CPB
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.CPB}
                                          onChange={ (text)=>{setLinking({...linking,CPB:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Charge power battery'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='Discharge power battery' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                        DPB
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.DPB}
                                          onChange={ (text)=>{setLinking({...linking,DPB:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='Discharge power battery'
                                        />
                                      </InputGroup>
                                    </Tooltip>
                                    <Tooltip label='PAC Compr. Speed' fontSize='sm'>
                                      <InputGroup size='sm'>
                                        <InputLeftAddon>
                                        PCS
                                        </InputLeftAddon>
                                        <TextInput 
                                          trigger="$"
                                          className={"react-autocomplete-box"}
                                          maxOptions={1000}
                                          value={linking.PCS}
                                          onChange={ (text)=>{setLinking({...linking,PCS:text})}}
                                          Component={"Input"}
                                          options={variables}
                                          style={{ width: "100%" }}
                                          placeholder='PAC Compr. Speed'
                                        />
                                       </InputGroup>
                                    </Tooltip>
                                  </Stack>
                                </Stack>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>:null}
                      </Box>
                      <br/>
                      <Box>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("monitored_over_24_hours")}
                        </FormLabel>
                        <MultiSelectSort
                          ms={{ base: "0px", md: "0px" }}
                          options={sensorsList}
                          isMulti
                          getOptionLabel ={(option)=>option.title}
                          getOptionValue ={(option)=>option.value}
                          value={values.configured_sensors}
                          placeholder={t("configured_sensors")}
                          onChange={(configured_sensors)=>{
                            for (let i = 0; i < configured_sensors.length; i++) 
                              if(configured_sensors[i].value)
                                configured_sensors[i].id=configured_sensors[i].value

                            setValues({ ...values,configured_sensors});
                          }}
                        />
                      </Box>
                      <br/>
                      <Box zIndex={99}>
                        <Flex w="100%" justify="space-between">
                          <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='0px'>
                            {t("sensors_to_be_plotted")}
                          </FormLabel>
                          <Spacer/>
                          <Flex align="flex-end">
                            <Flex align="center">
                              <Switch  
                                isChecked={dragNdrop.sensorsPlotted}
                                onChange={(item)=>{
                                  setDragNdrop({...dragNdrop,sensorsPlotted:item.target.checked})
                                }}
                              />
                              <p>{t("Drag and Drop")} </p>
                            </Flex>
                            <Button colorScheme='blue' size={"sm"}
                              onClick={()=>{
                                var sensorsPlotted=values.sensorsPlotted
                                sensorsPlotted.push({
                                  _id:(new Date()).getTime(),
                                  items:[]
                                })
                                setValues({ ...values,sensorsPlotted})
                              }}> {t("add_graph")} </Button>
                          </Flex>
                        </Flex>
                        {sensors_to_be_plotted()}
                      </Box>
                    </Scrollbar>
                  </TabPanel>
                  <TabPanel>
                    <Scrollbar style={{ height: 350 }} zIndex={99}>
                      <br/>
                      <Flex w="100%" justify="space-between">
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("programmation_module")}
                        </FormLabel>
                        <Switch  
                          isChecked={values.module_programmation}
                          onChange={(item)=>{
                            setValues({...values,module_programmation:item.target.checked})
                          }}
                        />
                      </Flex>
                      <br/>
                      <hr/>
                      <br/>
                      <Flex w="100%" justify="space-between">
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("Module Demo")}
                        </FormLabel>
                        <Switch  
                          isChecked={values.module_demo}
                          onChange={(item)=>{
                            setValues({...values,module_demo:item.target.checked})
                          }}
                        />
                      </Flex><br/>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex='1' textAlign='left'>{t("section_grouping_indicators")}</Box><AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            
                            <Flex w="100%" align="flex-end">
                              <Switch  
                                isChecked={dragNdrop.grouping_indicator}
                                onChange={(item)=>{
                                  setDragNdrop({...dragNdrop,grouping_indicator:item.target.checked})
                                }}
                              />
                              <p>{t("Drag and Drop")} </p>
                            </Flex>
                            {section_grouping_indicators()}
                            <Button colorScheme='blue' size={"sm"}
                            onClick={()=>{
                              var section_indicators=values.section_indicators
                              section_indicators.push({
                                id:(new Date()).getTime(),
                                items:[]
                              })
                              setValues({ ...values,section_indicators})
                            }}> + Section </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex='1' textAlign='left'>{t("neo_clim_module")}</Box><AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {section_neo_clim()}
                            <Button colorScheme='blue' size={"sm"}
                                onClick={()=>{
                                  var neo_clim_module=values.neo_clim_module
                                  neo_clim_module.push({
                                    id:(new Date()).getTime(),
                                    temperature:[],
                                    humidity:[],
                                    power:[],
                                    status_clim:[],
                                    status_mystrom:[]
                                  })
                                  setValues({ ...values,neo_clim_module})
                                }}> + Section </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex='1' textAlign='left'>{t("on off module")}</Box><AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {section_on_off()}
                            <Button colorScheme='blue' size={"sm"}
                                onClick={()=>{
                                  var on_off_module=values.on_off_module
                                  on_off_module.push({
                                    id:(new Date()).getTime(),
                                    relay:[],
                                    temp:[]
                                  })
                                  setValues({ ...values,on_off_module})
                                }}> + Section </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex='1' textAlign='left'>{t("boiler module")}</Box><AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {section_boiler()}
                            <Button colorScheme='blue' size={"sm"}
                                onClick={()=>{
                                  var boiler_module=values.boiler_module
                                  boiler_module.push({
                                    id:(new Date()).getTime(),
                                    temperature:[],
                                    temperatureExt:[],
                                    humidity:[],
                                    status:[],
                                    power:[],
                                  })
                                  setValues({ ...values,boiler_module})
                                }}> + Section </Button>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Scrollbar>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>:null}
          </LoadingOverlay>
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            <Button  
              width='140px' 
              colorScheme='green'  
              onClick={handleSubmit}
            >
              {t("valid")}
            </Button>
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                setValues({...values,
                  select_devices: [],
                  anim_svg: {label:t("nothing"),value:"nothing"},
                  sensorsPlotted:[],
                  configured_sensors: [],
                  section_indicators:[],
                  isLoading:false
                })
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalConfig);