import {useEffect,useState} from "react";
import { Chart } from "react-google-charts";
import Boiler from '../../../monitoring_control/components/Boiler/boiler_dark';
import {Box,Text} from '@chakra-ui/react';

function BoilerDark(props) {
    const max=100,min=0
    const [state, setState] = useState(true);

    useEffect(() => { 
        //setInterval(() => {setTemp(Math.floor(Math.random() * max));}, 4000);
        //setInterval(() => { setPower(Math.floor(Math.random() * 2000));}, 5500);
    },[]);

    return (
        <div style={{height: "300px",marginLeft:30}}>
            <div style={{position: "absolute"}}>
                <Boiler 
                    power={state?"1'800 W":"-- W"}
                    tempExt={"26˚C"}
                    humidity={"60%"}
                    state={state}
                    title={"Chauffe Eau Sanitaire"}
                    onChange={()=>{
                        setState(!state)
                    }}
                />
            </div>
            <div style={{position: "absolute",marginTop: 70}}>
                <Box ml={30}>
                    <Chart
                        chartType="Gauge"
                        data={[["Label", "Value"],["˚C",54]]}
                        options={{
                            width: 70, height: 70,
                            redFrom: max-(max*.1),
                            redTo: max,
                            yellowFrom: max-(max*.25),
                            yellowTo: max-(max*.1),
                            minorTicks: 5,
                            max,
                            min,
                            colors:["#fff","#fff"]
                        }}
                    />
                </Box>
                <div style={{position: "absolute",marginTop:-23,marginLeft:57}}>
                    <Text
                        bg="white"
                        fontSize='10px'
                        fontWeight='700'
                        lineHeight='100%'>
                    {"54"}
                    </Text>
                </div>
            </div>
        </div>
    );}
export default BoilerDark;