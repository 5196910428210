import React, { FC } from "react";
import "./index.css";
import {Icon} from "@chakra-ui/react";
import { IoMdSnow} from "react-icons/io";

const Knob = ({ knobRef, isDragging, knobPosition, knobColor, knobSize, hideKnob, onMouseDown, onMouseUp, children }) => {
  const styles = {
    knob: {
      position: "absolute",
      left: `-${knobSize / 2}px`,
      top: `-${knobSize / 2}px`,
      cursor: "grab",
      zIndex: 4,
    },
    icon: {
      position: "absolute",
      left: `${knobSize / 3.5}px`,
      top: `${knobSize / 1.35}px`,
      zIndex: 5,
    },

    dragging: {
      cursor: "grabbing",
    },

    pause: {
      animationPlayState: "paused",
    },

    animation: {
      transformOrigin: "50% 50%",
      animationTimingFunction: "ease-out",
      animationDuration: "1500ms",
      animationIterationCount: "infinite",
      animationName: "pulse",
    },

    hide: {
      opacity: 0,
    },
  };

  const defaultKnobIcon = () => {
    return <Icon
      as={IoMdSnow}
      color={"white"}
      mt='-56px'
      w='34px'
      h='34px'
      ml='-1px'
    />
  };

  return (
    <div
      style={{
        transform: `translate(${knobPosition.x}px, ${knobPosition.y}px)`,
        ...styles.knob,
        ...(isDragging && styles.dragging),
        ...(hideKnob && styles.hide),
      }}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
    >
      <svg ref={knobRef} width={`${knobSize}px`} height={`${knobSize}px`} viewBox={`0 0 ${knobSize} ${knobSize}`}>
        <circle
          style={{ ...styles.animation, ...(isDragging && styles.pause) }}
          fill={knobColor}
          fillOpacity="0.2"
          stroke="none"
          cx={knobSize / 2}
          cy={knobSize / 2}
          r={knobSize / 2}
        />
        <circle fill={knobColor} stroke="none" cx={knobSize / 2} cy={knobSize / 2} r={(knobSize * 2) / 3 / 2} />
      </svg>
      <div  style={styles.icon}>
        {defaultKnobIcon()}
      </div>
    </div>
  );
};

export default Knob;
