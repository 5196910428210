/*!                                                                                                                                                                                                                                                                                                                           
=========================================================
* Horizon UI - v1.1.0
=========================================================
*/

import React from "react";
// Chakra imports
import {useDisclosure} from "@chakra-ui/hooks";
import { withTranslation } from 'react-i18next';
// Custom components
import Card from "components/card/Card";
import ComplexTable from "../../../components/ComplexTable";
// Redux
import { useSelector} from 'react-redux';

import * as R from 'ramda';
import { Post } from "utils/queries";
import ModalPairing from "./ModalPairing";


function Pairing(props) {
  const {t,showMsg,showErrorMsg,onSubmit}=props;
  const myInfos = useSelector(state => state.user.infos);

  const [devicesList, setDevicesList] = React.useState([]);
  const [sensorsList, setSensorsList] = React.useState([]);
  const [billingList, setBillingList] = React.useState([]);
  const [tableList, setTableList] = React.useState([]);
  const [pairingInfo, setPairingInfo] = React.useState("{}");
  const [tableListAll, setTableListAll] = React.useState([]);
  const {isOpen, onOpen, onClose } = useDisclosure()

  const columnsData = [
    {Header: t("Billing models"), accessor: "model_title"},
    {Header: t("device"),accessor: "device_title"},
    {Header: t("Energy sources"),accessor: "sensor_title"},
    {Header: "Action",accessor: "id"}
  ];
  var response,_sensorsList, items=[];

  const updateSensorsList=async (deviceIdList)=>{
    _sensorsList=[]
    await deviceIdList.forEach(async device => {
      response=await Post.requestPost("listSensors",{token:myInfos.token,device_id:device.value})
      
      response.forEach(async sensor => {
        if(sensor.unit.toLowerCase().includes("w")){
          items=R.filter(o => o.value === sensor.device_id, devicesList)[0]
          if(items)
            _sensorsList.push({value:sensor.device_id+"@!@"+sensor.id,
              label:sensor.title+" ("+items.label+")"})
        }
      })
      setSensorsList(_sensorsList)
    });
  }

  React.useEffect(async() => { 
    if(props.hasOwnProperty("billingList")){
      items=[]
      props.billingList.forEach(element => { items.push({value:element._id,label:element.title}) });
      setBillingList(items)
    }
    if(myInfos.hasOwnProperty("devices")){
      items=[];
      myInfos.devices.forEach(async device => {items.push({value:device.id,label:device.name})});
      updateSensorsList(items)
      setDevicesList(items)
    }

    response=await Post.requestPost("managePairing",{token:myInfos.token,type:"read"})
    if(response.hasOwnProperty("error")){
      if(response.error===200) {
        setTableList(response.items)  
        setTableListAll(response.items) 
      }else if(response.hasOwnProperty("msg"))showErrorMsg(response.msg)
      else showErrorMsg(t("error_occurred"))
    }else showErrorMsg(t("error_occurred"))
    return () => {}
  },[props.billingList]);


  const deleteRow =async (id)=> {
    response=await Post.requestPost("managePairing",{token:myInfos.token,id,type:"delete"})
    if(response.hasOwnProperty("error")){
      if(response.error===200) {
        setTableList(R.filter(o => o.id !== id, tableList))  
        setTableListAll(R.filter(o => o.id !== id, tableListAll)) 
      }else if(response.hasOwnProperty("msg"))showErrorMsg(response.msg)
      else showErrorMsg(t("error_occurred"))
    }else showErrorMsg(t("error_occurred"))
  };

  const editRow =(row)=> {
    setPairingInfo(JSON.stringify(row));
    onOpen()
  }

  return (
    <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <ComplexTable
            title={t("Model")+" - "+t("sensor")}
            columnsData={columnsData}
            tableData={tableList}
            showAdd={sensorsList.length>0 && billingList.length>0}
            onSearch={(text)=>{
              if(text.length===0) setTableListAll(tableListAll)
              else setTableList(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), tableListAll)) 
            }}
            onDetele={(row)=>{ deleteRow(row.id) }}
            onModify={(row)=>{editRow(row) }}
            onOpen={() => {
              //setProfile("{}");
              if(sensorsList.length>0 && billingList.length>0){
                setPairingInfo("{}")
                setTimeout(()=>{
                  onOpen()
                },1000);
              }
              else if(sensorsList.length>0) showErrorMsg("Missing sensors list")
              else if(billingList.length>0) showErrorMsg("Missing billings list")
            }}
          />
          {sensorsList.length>0 && billingList.length>0?<ModalPairing
            isOpen={isOpen} 
            datas={JSON.parse(pairingInfo)}
            onClose={onClose}
            sensorsList={sensorsList}
            billingList={billingList}
            onSubmit={async(billing)=>{
              billing.device_id=billing.sensor.split("@!@")[0]
              billing.sensor_id=billing.sensor.split("@!@")[1]

              billing.model_title =R.filter(o => o.value === billing.model, billingList)[0].label
              billing.device_title=R.filter(o => o.value === billing.device_id, devicesList)[0].label
              billing.sensor_title=R.filter(o => o.value === billing.device_id+"@!@"+billing.sensor_id, sensorsList)[0].label
              billing.sensor_title=billing.sensor_title.split(" (")[0]

              if(R.filter(o => 
                o.model === billing.model &&
                o.device_id === billing.device_id &&
                o.sensor === billing.sensor 
                , tableList)[0]
              ){
                showErrorMsg(t("Existing configuration"));
              }else{
                billing.token=myInfos.token
                billing.type="write"
                response=await Post.requestPost("managePairing",billing)
                if(response.error){
                  if(response.error===200){
                    billing.id=response.id
                    setTableList([...tableList,billing])
                    setTableListAll([...tableListAll,billing])
                    
                    onSubmit(billing)
                    onClose()
                  }else showErrorMsg(response.msg)
                }else showErrorMsg(t("unknown_error"))
              } 
            }}
            showMsg={(msg)=>{showMsg(msg); }}
            showErrorMsg={(msg)=>{showErrorMsg(msg); }}
          />:null}
    </Card>
  );
}
export default withTranslation()(Pairing);
