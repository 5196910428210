import { withTranslation } from 'react-i18next';
// Chakra imports
import {Flex,Box} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
// Custom components
import Card from "components/card/Card.js";

import React from "react";

import 'rsuite/dist/rsuite.min.css';

import AnimationBattery from "./battery";
import AnimationHome from "./home";
import AnimationSolar from "./solar";
import Donut from "./Donut";

function AnimationDetail(props) {
  const { ...rest } = props;
  const {cur_device,type,t} = props;


  React.useEffect(() => {  

    return () => {}
  },[props.data,cur_device]);
  
  const getRequestAnimation=()=>{
    if (type==="battery") {
      return <AnimationBattery/>
    }
    else if (type==="home") {
      return <AnimationHome/>
    }
    else if (type==="solar_panel") {
      return <AnimationSolar/>
    }
  }

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}>
      <Flex justify='center' align='center' w='100%'flexDirection={{ base: "column", lg: "row" }}>
        {getRequestAnimation()}
        <Box w='30%' align='center' >
          {type==="battery"?
          <ReactApexChart
              options={{
                chart: {
                  type: 'radialBar',
                  sparkline: { enabled: true }
                },
                colors:["#75cb10"],
                plotOptions: {
                  radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                      background: "#e7e7e7",
                      strokeWidth: '97%',
                      margin: 5, // margin is in pixels
                      dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                      }
                    },
                    dataLabels: {
                      name: {
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: 'light'
                      },
                      value: {
                        offsetY: -40,
                        fontSize: '25px',
                        fontWeight: 'bold',
                        color: '#75cb10'
                      }
                    }
                  }
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 53, 91]
                  },
                },
                labels: [t("roundcycle_losses")],
              }
            }
            series={[props.data.roundcycleVal]}
            width={'100%'}
            type={'radialBar'}
            height={300}
            />:<Donut 
            title={props.title}
            data={props.data}
          />}
          <ReactApexChart
              options={{
                chart: {
                  type: 'radialBar',
                  sparkline: { enabled: true }
                },
                colors:["#0068b6"],
                plotOptions: {
                  radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                      background: "#e7e7e7",
                      strokeWidth: '97%',
                      margin: 5, // margin is in pixels
                      dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                      }
                    },
                    dataLabels: {
                      name: {
                        color: 'black',
                        fontSize: '15px',
                        fontWeight: 'light'
                      },
                      value: {
                        offsetY: -40,
                        fontSize: '25px',
                        fontWeight: 'bold',
                        color: '#0068b6'
                      }
                    }
                  }
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 53, 91]
                  },
                },
                labels: [props.title2],
              }
            }
            series={[props.data.inner]}
            width={'100%'}
            type={'radialBar'}
            height={300}
          />
        </Box>
      </Flex>
    </Card>
  );
}

export default withTranslation()(AnimationDetail);