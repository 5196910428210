import {
    Box,
    Flex,
    Stat,Text,
    StatLabel,
    StatNumber,
    useColorModeValue,
  } from '@chakra-ui/react';
  
  function StatsCard(props) {
    const { title, cost,consumption, icon,currency } = props;
    return (
        <Stat
            px={{ base: 2, md: 4 }}
            py={'5'}
            shadow={'md'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'white')}
            bgColor={useColorModeValue('white', 'secondary.dark')}
            rounded={'lg'}>
                <Flex justifyContent={'space-between'}>
                    <Box pl={{ base: 2, md: 4 }}>
                    <StatLabel fontWeight={'medium'} isTruncated>
                        {title}
                    </StatLabel>
                    <Flex >
                        <StatNumber fontSize={'2xl'} fontWeight={'medium'} color={"primary.main"}>
                            {Number(cost).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')}
                        </StatNumber>
                        <Text ml={1} mt={2} fontSize={'sm'} fontWeight={'bold'} color={"primary.main"}> {currency?currency.toUpperCase():""}</Text>
                    </Flex>
                    <StatNumber fontSize={'md'} fontWeight={'medium'}>
                        {Number(consumption).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')} kWh
                    </StatNumber>
                    </Box>
                    <Box
                        my={'auto'}
                        color={useColorModeValue('primary.dark', 'gray.200')}
                        alignContent={'center'}>
                        {icon}
                    </Box>
                </Flex>
        </Stat>
    );
  }
  
  export default StatsCard;