import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom';
import './assets/locales/i18n';


// components
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import Logout from "layouts/logout";

//const Error = React.lazy(() => import("./components/pages/error"));

function App () {
  return (
    <Router  basename={'/'}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/"  render={() => <Redirect to="/auth" />} />
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/sleep/:goto`} component={AuthLayout} />
          <Route path={`/dashboard`} component={AdminLayout} />
          <Route path={`/overview`} component={AdminLayout} />
          <Route path={`/monitoring_control`} component={AdminLayout} />
          <Route path={`/billing`} component={AdminLayout} />
          <Route path={`/users`} component={AdminLayout} />
          <Route path={`/devices`} component={AdminLayout} />
          <Route path={`/sensors`} component={AdminLayout} />
          <Route path={`/profile`} component={AdminLayout} />
          <Route path={`/demo`} component={AdminLayout} />
          <Route path={`/configurations`} component={AdminLayout} />
          <Route path={`/logout`} component={Logout} />
          {/*<Route component={Error} />*/}
        </Switch>
      </Suspense>
    </Router>
  );
}
export default App;
