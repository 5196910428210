import {
  Flex,Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,

  FormControl,
  FormLabel,
  FormErrorMessage,
  Spacer,Switch,
  NumberInput,NumberInputField
} from '@chakra-ui/react'
import { withTranslation } from 'react-i18next';
import ReactWeeklyDayPicker from "./WeeklyDayPicker"
import React from "react";
import moment from "moment"; 
import 'moment/locale/fr' 
import TimeRange from "./TimeLine";
import {set} from "date-fns";

import {Post} from 'utils/queries'

function ModalForm(props) {
  const {t,isOpen, onClose,token,addItem,showMsg,data}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const now = new Date()
  const getTodayAtSpecificHour = (hour = 12) => set(now, { hours: hour, minutes: 0, seconds: 0, milliseconds: 0 })

  const selectedStart = getTodayAtSpecificHour(8)
  const selectedEnd = getTodayAtSpecificHour(14)
  const [values, setValues] = React.useState({
    id: "",
    selectedDays:[new Date()],
    consigne:"",
    showSecondLine:true,
    selectedInterval:[selectedStart, selectedEnd],
    isLoading:false
  });
  
  var [errorMsg, setErrorMsg] =  React.useState("");
  var response;

  var [error, setError] =  React.useState(false);

  const resetValues=()=>{
    setValues({...values,
      id: "",
      selectedDays:[new Date()],
      consigne:"",
      showSecondLine:true,
      selectedInterval:[selectedStart, selectedEnd],
      isLoading:false
    })
  }
  React.useEffect(() => { 
    setValues(data)
    return () => {}
  },[data]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();

    //////////
    setValues({ ...values,"isLoading":true})

    let item=values
    item.token=token
    item.hours=[]
    item.hours.push(moment(item.selectedInterval[0]).format("HH:mm"))
    item.hours.push(moment(item.selectedInterval[1]).format("HH:mm"))

    item.days=[]
    for (let i = 0; i < item.selectedDays.length; i++) {
      item.days.push(moment(item.selectedDays[i]).format("dddd"))
    }
    if(values.id!=="")item.id=values.id
    
    console.log(item.hours,item.days);

    response=await Post.requestPost("addProgrammation",item)
    if(response.hasOwnProperty("error")){
      if(response.error===200){
        if(values.id===""){
          item.id=response.id
          showMsg(t("successfully_added"))
        }else{
          item._id=values.id
          showMsg(t("successful_modification"))
        }
        addItem(item)
        ////
        resetValues()
        onClose();
      }
      else setErrorMsg(response.msg)
    }else setErrorMsg(t("error_occurred"))
    setValues({ ...values,"isLoading":false})
  }

  const errorHandler=(err)=>{ setError(err.error)}
  const onChangeCallback=(interval)=>{setValues({...values,selectedInterval:interval})}

  return (

    <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
   
    <ModalContent>
      <ModalHeader>Programmation</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormLabel>{t("forced_recharge_battery")}</FormLabel>
        <ReactWeeklyDayPicker
          daysCount={7}  //How many days will be shown
          startDay={new Date()} // First day as Date Object or 22 June 2016
          selectedDays={values.selectedDays} // Selected days list
          multipleDaySelect={true} //enables multiple day selection
          selectDay={function(day){setValues({...values,selectedDays:day})}}
          unselectDay={function(day){}}
          unselectable={false} // if true allows to unselect a date once it has been selected. Only works when multipleDaySelect={false}
          format={'YYYY-MM-DD'} //format of dates that handled in selectDay and unselectDay functions
          firstLineFormat={'ddd'} // format for the first line of the day button
          secondLineFormat={'DD.MM'} // format for the second line of the day button
          showSecondLine={values.showSecondLine}
          firstLineMobileFormat={'dddd'} // format for the first line of the day button mobile
          secondLineMobileFormat={'MMMM D, Y'} // format for the second line of the day button mobile
          unavailables={{
            //relative:[0,1],  //unavailable dates list relative to today (0:today, 1:tomorrow, -1:yesterday)
            //weekly: [0] //unavailable dates list for each week (0:Sunday, 1:Monday ...)
          }}
          mobilView={window.innerWidth < 1024}  // enables mobil view
          beforeToday={false}   // all dates before today set as unavailable (default:true)
          hiddens={{  // makes dates invisible
            //dates: ['22 July 2017'], //absolute dates list
            //relative: [2], // relative to today (0:today, 1:tomorrow, -1:yesterday)
            //weekly: [1]  //each week (0:Sunday, 1:Monday ...)
          }}
          todayText={""}  // replacing today text (default : - TODAY -)
          unavailableText={""}  // replacing unavailable text (default: unavailable )
        />
        <FormControl display='flex' alignItems='center' mb='2'>
          <p>{t("every_week") }</p>
          <Switch id='periodic' onChange={(value)=>{setValues({...values,showSecondLine:!value.target.checked});}}/>
         
        </FormControl>
        <Flex
          zIndex='2'
          align='center'
          direction='row'
          mt={"6"}
          mb={"4"}
        >
          <FormLabel>{t("soc")}</FormLabel>
          <NumberInput precision={1}>
            <NumberInputField
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={"Consigne"}
              fontWeight='500'
              size='sm'
              value={values.consigne}
              onChange={handleChange('consigne')}
            />
          </NumberInput>
        </Flex>

        {/*<Flex
            zIndex='2'
            direction='row'
            mx={{ base: "auto", lg: "unset" }}
            mb={{ base: "2px", md: "auto" }}>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}>
            {values.selectedInterval?moment(values.selectedInterval[0]).format("HH:mm"):null }
          </FormLabel>
          <Spacer />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}>
            {values.selectedInterval?moment(values.selectedInterval[1]).format("HH:mm"):null}
          </FormLabel>
        </Flex>*/}
        <TimeRange
          error={error}  
          ticksNumber={6}  
          selectedInterval={values.selectedInterval}  
          timelineInterval={[getTodayAtSpecificHour(0), getTodayAtSpecificHour(23)]}  
          onUpdateCallback={errorHandler}  
          onChangeCallback={onChangeCallback}
        />
        <FormControl isInvalid={errorMsg!==""}>
          <FormErrorMessage>{errorMsg}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter bg={bgColor}>
        <Flex w="100%" justify="space-between">
          <Button  
            width='140px' 
            colorScheme='green'  
            isLoading={values.isLoading}
            onClick={handleSubmit}
          >
            {values.id===""?t("add"):t("update")}
          </Button>
          <Button colorScheme='red' variant='outline' 
            onClick={()=>{
              resetValues()
              onClose();
            }}>{t("cancel")}</Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
  );
}

export default withTranslation()(ModalForm);