import React from "react";
import { withTranslation } from 'react-i18next';
import { Chart } from "react-google-charts";
import Card from "components/card/Card.js";
import {Flex,
  Text,NumberInput,NumberInputField,
  useColorModeValue,} from "@chakra-ui/react";

function AnalogInput(props) {
  const {t}=props;
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("secondary.dark", "white");

  var { title,subtitle, current, unit,min,max,isOutput,onAction } = props;
  const options = {
    width: 130, height: 130,
    redFrom: max-(max*.1),
    redTo: max,
    yellowFrom: max-(max*.25),
    yellowTo: max-(max*.1),
    minorTicks: 5,
    max,
    min,
  };

  return (
    <Card>
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex direction='column'>
          <Text
            color={textColorBid}
            fontSize={{ base: "sm"}}
            fontWeight='400'>
            {subtitle}
          </Text>
          <Text
            color={textColor}
            fontSize={{
              base: "xl",
              md: "lg",
              lg: "lg",
              xl: "lg",
              "2xl": "md",
              "3xl": "lg",
            }}
            fontWeight='bold'>
            {title}
          </Text>
        </Flex>
      
        
        <Flex justify='center' 
          w={{ base: "100%", md: "60%", xl: "99%" }}>
          <Chart
            chartType="Gauge"
            data={[["Label", "Value"],[unit, Number(current)]]}
            options={options}
          />
        </Flex>
        {isOutput?<Flex justify='center'  align="row">
          <NumberInput precision={2}>
            <NumberInputField
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={t("enter_send")}
              mb='9px'
              fontWeight='500'
              size='sm'
              onKeyPress={e=> {if (e.key === 'Enter')  onAction(e.target.value);}}
            />
          </NumberInput>
        </Flex>:null}
      </Flex>
    </Card>
  );
}
export default withTranslation()(AnalogInput);