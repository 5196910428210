
import Global from "utils/Constant";

export const listDevices = (token) => {
    return fetch(Global.SERVER_URL+'/listDevices', {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({token})
      })
      .then(response => response.json())
      .then(devicesList=>{
        return devicesList
      });
  };
  
export const getDataLast24h = (token,device_id,views) => {
  return fetch(Global.SERVER_URL+'/getDataLast24h', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token,device_id,views})
    })
    .then(response => response.json())
    .then(message=>{
      if(message.error)
        if(message.error==200)return message.series;
      return []
    });
}
export const getLastState = (token,device_id) => {
  return fetch(Global.SERVER_URL+'/getLastState', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token,"device_id":device_id})
    })
    .then(response => response.json())
    .then(message=>{
      console.log("response3",message);
      if(message){
        if(message.hasOwnProperty("createdAt"))return message;
        else return {error:message.msg}
      }
    });
};
export const getChartDatas = (token,device_id,startDate,endDate) => {
  return fetch(Global.SERVER_URL+'/getChartDatas', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token,device_id,startDate,endDate})
    })
    .then(response => response.json())
    .then(message=>{
      return message;
    });
};
export const calculingOverviewData = (token,device_id,dates) => {
  return fetch(Global.SERVER_URL+'/calculingOverviewData', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({token,device_id,dates})
    })
    .then(response => response.json())
    .then(message=>{
      return message;
    });
};
