import React from "react";
import { Flex,Button,Text } from "@chakra-ui/react";

export default function Day(props) {
  const { day,isPress,onPress } = props;

  return (
    <Button
        colorScheme={isPress?"blue":'green'}
        variant={isPress?"solid":'outline'} /*solid blue*/
        border='1px'
        width='1px'
        height='30px'
        borderColor={isPress?'green:500':"blue:500"}
        borderRadius={80}
        onClick={onPress}
    >
      <Text fontSize="xs">{day}</Text>
    </Button>
  );
}
