import {Box,Checkbox}from '@chakra-ui/react'
import ReactApexChart from "react-apexcharts";
import moment from "moment"; 
import * as R from 'ramda';

// Redux
import { withTranslation } from 'react-i18next';
import React from "react";
var visibility=[]
function BillingGraph(props) {
  const {t,billingGraph}=props;
  const [items,setItems]=React.useState([])
  const [visibilityChartConsmpt,setVisibilityChartConsmpt]=React.useState("[]")
  // Chakra color mode

  React.useEffect(async () => { 
    graphDatas()
    return () => {}
  },[props]);

  const graphDatas=async()=>{

    let series=[],series_consumption=[],colors=[],_serie,_serie_consumption
    let charts=[],found

    visibility=[]
    for await(const data of billingGraph) {
      series=[]; colors=[]; _serie={}; series_consumption=[]

      //////Cost////////
      for await(const serie of data.series) {
        colors.push(serie.color)
        _serie={name: serie.title, type:"area", data:[]}
        for (let i = 0; i < serie.x.length; i++)
          _serie.data.push({x: serie.x[i], y: serie.y_cost[i]})

        found=false
        for (let j = 0; j < series.length; j++)
          if(series[j].name===_serie.name){
            found=true
            for (let k = 0; k < _serie.data.length; k++) 
              series[j].data.push(_serie.data[k]);
            series[j].data=R.sort(function(a, b) { return (new Date(a.x)).getTime() - (new Date(b.x)).getTime(); },  series_consumption[j].data);
          }

        if(!found){
          _serie.data=R.sort(function(a, b) { return (new Date(a.x)).getTime() - (new Date(b.x)).getTime(); },  _serie.data);
          series.push(_serie)
        }
      }


      //////Consumption////////
      for await(const serie of data.series) {
        _serie_consumption={name:serie.title, type:"area", data:[] }
        for (let i = 0; i < serie.x.length; i++)
          _serie_consumption.data.push({x:serie.x[i], y:serie.y_consumption[i]})

        found=false
        for (let j = 0; j < series_consumption.length; j++)
          if(series_consumption[j].name===_serie_consumption.name){
            found=true
            for (let k = 0; k < _serie_consumption.data.length; k++) 
              series_consumption[j].data.push(_serie_consumption.data[k]);
            series_consumption[j].data=R.sort(function(a, b) { return (new Date(a.x)).getTime() - (new Date(b.x)).getTime(); },  series_consumption[j].data);
          }

        if(!found){
          _serie_consumption.data=R.sort(function(a, b) { return (new Date(a.x)).getTime() - (new Date(b.x)).getTime(); },  _serie_consumption.data);
          series_consumption.push(_serie_consumption)
        }
      }

      visibility.push(false)
      charts.push({series,series_consumption,colors,currency:data.currency,title:data.billing_title+" / "+data.sensors_title})
    }
    setItems(charts)
    setVisibilityChartConsmpt(JSON.stringify(visibility))
  }


  return (
    <Box>
        {items.map((info,index)=>
          {
            console.log("info_"+index,info);
            return (<Box key= {index}>
              <ReactApexChart
                key= {index+"_1"}
                options={{
                  chart: {
                    id: index+"_1",
                    zoom: {enabled: false},
                    toolbar: {
                      show: true,
                      offsetX: 0,
                      offsetY: 0,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        customIcons: []
                      },
                      export: {
                        csv: {
                          filename:  "NEOLEC Cost "+info.title,
                          columnDelimiter: ',',
                          headerCategory: t("datetime"),
                          dateFormatter(timestamp) {
                            return moment(timestamp).format("DD/MM/YYYY HH:mm")
                          }
                        },
                        png: { filename: "neolec"}
                      }
                    }
                  },
                  stroke: {
                    width: 2,
                    curve:"smooth",// 'smooth' straight
                  },
                  colors:info.colors,
                  fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.45,
                        opacityTo: 0.05,
                        stops: [20, 100, 100, 100]
                      },
                  },
                  title: {text:info.title},
                  yaxis: {
                    labels: {
                      style: { fontSize:"10px"},
                      formatter: function (value) {
                        return Number(value).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')+" "+info.currency;
                      }
                    },
                    axisBorder: {show:false},
                    axisTicks: { show: false}
                  },
                  xaxis: {
                    type: 'datetime',
                    labels: {
                      style: {colors:"#000",fontSize: "9px"},
                      datetimeUTC: false,
                      datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM \'yy',
                        day: 'dd MMM HH:00',
                        hour: 'HH:mm'
                      }
                    }
                  },
                  tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: "light",
                    x: {show: false,format: 'dd MMM, HH:mm'},
                    y: {title: {formatter: (seriesName) => ""},}
                  },
                  dataLabels: {enabled: false },
                  grid: {
                    borderColor: '#e7e7e7',
                    row: {
                      colors: ['#f3f3f3', 'transparent'], 
                      opacity: 0.5
                    },
                  },
                  fill:{type:'solid',opacity:1},
                }}
                series={info.series}
                type={"area"}
                width={'100%'}
                height={200}
              />
              <Checkbox 
                ml={5}
                mt={-3}
                size='sm' 
                color='secondaryGray.600'
                isChecked={JSON.parse(visibilityChartConsmpt)[index]}
                onChange={async (state)=>{
                  visibility=await JSON.parse(visibilityChartConsmpt)
                  visibility[index]=await !visibility[index]
                  setVisibilityChartConsmpt(JSON.stringify(visibility))
                }}
              >{t("Show consumption graph")}</Checkbox>
              {JSON.parse(visibilityChartConsmpt)[index]===true?<ReactApexChart
                key= {index+"_2"}
                options={{
                  chart: {
                    id: index+"_2",
                    zoom: {enabled: false},
                    toolbar: {
                      show: true,
                      offsetX: 0,
                      offsetY: 0,
                      tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        customIcons: []
                      },
                      export: {
                        csv: {
                          filename:  "NEOLEC consumption "+info.title,
                          columnDelimiter: ',',
                          headerCategory: t("datetime"),
                          dateFormatter(timestamp) {
                            return moment(timestamp).format("DD/MM/YYYY HH:mm")
                          }
                        },
                        png: { filename: "neolec"}
                      }
                    }
                  },
                  stroke: {width: 2, curve:"smooth",},
                  colors:info.colors,
                  fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.45,
                        opacityTo: 0.05,
                        stops: [20, 100, 100, 100]
                      },
                  },
                  title: {text:t("comsumption")},
                  yaxis: {
                    labels: {
                      style: { fontSize:"10px"},
                      formatter: function (value) {
                        return Number(value).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'')+" kWh";
                      }
                    },
                    axisBorder: {show:false},
                    axisTicks: { show: false}
                  },
                  xaxis: {
                    type: 'datetime',
                    labels: {
                      style: {colors:"#000",fontSize: "9px"},
                      datetimeUTC: false,
                      datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM \'yy',
                        day: 'dd MMM HH:00',
                        hour: 'HH:mm'
                      }
                    }
                  },
                  tooltip: {
                    enabled: true,
                    fillSeriesColor: false,
                    theme: "light",
                    x: {show: false,format: 'dd MMM, HH:mm'},
                    y: {title: {formatter: (seriesName) => ""},},
                  },
                  dataLabels: {enabled: false },
                  grid: {
                    borderColor: '#e7e7e7',
                    row: {
                      colors: ['#f3f3f3', 'transparent'], 
                      opacity: 0.5
                    },
                  },
                  fill:{type:'solid',opacity:1},
                }}
                series={info.series_consumption}
                type={"area"}
                width={'100%'}
                height={200}
              />:null}
            </Box>)}
        )}
    </Box>
  );
}

export default withTranslation()(BillingGraph);