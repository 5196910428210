import React,{useEffect} from "react";
import { withTranslation } from 'react-i18next';
import {useDisclosure} from "@chakra-ui/hooks";
import * as R from 'ramda';
import moment from "moment"; 
import 'moment/locale/fr' 
import TimeRange from "views/admin/monitoring_control/components/TimeLine";
import {set} from "date-fns";
import {Flex,Box,
    ButtonGroup,Button,
    NumberInputField,
    Wrap,WrapItem,NumberInput,IconButton
} from "@chakra-ui/react";
import {BsCheckLg} from "react-icons/bs";
import {AiOutlineCloseCircle} from "react-icons/ai";
import ModalTitle from "./ModalTitle";
import WeekLine from "components/WeekLine";
import AddRemoveBtn from "./AddRemoveBtn";

function PlaningLine(props) {
    var {t, onUpdateProgram,program,placeholder,currency,
        isVisibleRemove,isVisibleAdd,weekPress,
        onPressRemove,onPressAdd
    } = props;
    var _program=[]
    const now = new Date()
    const getTodayAtSpecificHour = (hour = 12,end=false) => set(now, { hours: hour, minutes: !end?0:59, seconds: !end?0:59, milliseconds: !end?0:59 })

    const selectedStart = getTodayAtSpecificHour(22)
    const selectedEnd = getTodayAtSpecificHour(23,true)
    
    const [error, setError] =  React.useState(false);
    const [values, setValues] = React.useState({
      id: "",
      title:"",
      cost:"",
      color:"#"+Math.floor(Math.random()*16777215).toString(16),
      selectedInterval:[selectedStart, selectedEnd],

      cost_sale:"",
      color_sale:"#"+Math.floor(Math.random()*16777215).toString(16),
      selectedInterval_sale:[selectedStart, selectedEnd],
      isLoading:false
    });
    const [listRange, setListRange] =  React.useState([]);
    
    const { isOpen, onOpen, onClose } = useDisclosure()


    useEffect(async () => { 
        _program=program
        if(program.hasOwnProperty("listRange")){
            for (let i = 0; i < _program.listRange.length; i++) {
                _program.listRange[i].start=getTodayAtSpecificHour(moment(_program.listRange[i].start).format("HH"))
                _program.listRange[i].end=getTodayAtSpecificHour(moment(_program.listRange[i].end).format("HH"))
            }
            setListRange(_program.listRange)
        }
    },[])

    const errorHandler=(err)=>{ 
        setError(err.error)
        if(err.time) setValues({...values,selectedInterval:err.time})
    }
    const onChangeCallback=(interval)=>{setValues({...values,selectedInterval:interval})}
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: Number(event.target.value)>100?100:Number(event.target.value) });
    };

    const handleSubmit = async (e) => { 
        e.preventDefault();
        let item={
            id:(new Date()).getTime(),
            title:moment(values.selectedInterval[0]).format("HH:mm")+"-"+moment(values.selectedInterval[1]).format("HH:mm")+", "+values.cost+""+currency,
            start:values.selectedInterval[0],
            end:values.selectedInterval[1],
            cost:values.cost,
            color:"#"+Math.floor(Math.random()*16777215).toString(16)
        }
        let _listRange=[...listRange,item]
        setListRange(_listRange)

        _program=program
        _program.listRange=_listRange
        setError(true)
        onUpdateProgram(_program)
    }

    return <Box mt={5}>
            <Flex justifyContent={"space-between"} mb={3}>
                {program.weekSelect?<WeekLine
                    weekSelect={program.weekSelect}
                    weekPress={weekPress}
                />:null}
                <AddRemoveBtn
                    isVisibleRemove={isVisibleRemove}
                    isVisibleAdd={isVisibleAdd}
                    onPressRemove={onPressRemove}
                    onPressAdd={onPressAdd}
                />
            </Flex>
            <Flex w={"100%"}
                justifyContent={"center"}
                align={"center"}
            >
                <Flex w={"100%"}
                    justifyContent={"flex-start"}
                    align={"center"}
                    mb='9px'
                    pl="30px"
                >
                    <TimeRange
                        error={error}  
                        ticksNumber={6}  
                        selectedInterval={values.selectedInterval}  
                        disabledIntervals={listRange}  
                        timelineInterval={[getTodayAtSpecificHour(0), getTodayAtSpecificHour(23,true)]}  
                        onUpdateCallback={errorHandler}  
                        onChangeCallback={onChangeCallback}
                    />
                </Flex>
                <Flex
                    justifyContent={"flex-start"}
                    align={"center"}
                    mb='9px'
                >
                    <Box ml={15} style={{"marginTop":-20}}>
                        <p>{placeholder}</p>
                        <NumberInput minW='90px' variant='auth' maxW='90px' size='lg'>
                            <NumberInputField
                                placeholder={currency}
                                fontWeight='500'
                                value={values.cost}
                                onChange={handleChange('cost')}
                            />
                        </NumberInput>
                    </Box>
                    {!error && Number(values.cost)?<IconButton
                        colorScheme='green'
                        ml={2}
                        onClick={handleSubmit}
                        aria-label={values.id===""?t("add"):t("update")}
                        icon={<BsCheckLg/>}
                    />:null}
                </Flex>
            </Flex>
            <Wrap mt={2} mb={1}>
                {
                    listRange.map((range)=>{
                        return(<WrapItem key={range.id} ><ButtonGroup size='sm' isAttached variant='outline'>
                            <Button 
                                colorScheme="teal"
                                color={range.color}
                                onClick={()=>{
                                    setValues({...values,
                                        title:range.title,
                                        color:range.color,
                                        cost:range.cost,
                                        id:range.id
                                    })
                                    onOpen()
                                }}
                            >{range.title}</Button>
                            <IconButton
                                colorScheme="red"
                                icon={<AiOutlineCloseCircle />}
                                onClick={()=>{
                                    let _listRange=R.filter(o => o.id !== range.id, listRange)
                                    setListRange(_listRange)
                                    _program=program
                                    _program.listRange=_listRange
                                    onUpdateProgram(_program)
                                }}
                            />
                        </ButtonGroup></WrapItem>)
                    })
                }
            </Wrap>
            
            <ModalTitle
                isOpen={isOpen} 
                onClose={onClose}
                data={values}
                getInfos={(_title,_color,_cost)=>{
                    var _listRange=listRange
                    for (let i = 0; i < _listRange.length; i++) {
                        if( _listRange[i].id===values.id){
                            _listRange[i].title=_title
                            _listRange[i].color=_color
                            _listRange[i].cost=_cost
                            break
                        }
                    }
                    setListRange(_listRange)
                    _program=program
                    _program.listRange=_listRange
                    onUpdateProgram(_program)
                }}
            />
        </Box>;
}
export default withTranslation()(PlaningLine);