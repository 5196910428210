import { useEffect ,useState} from "react";
// Chakra imports
import {Button,
  Modal,
  ModalOverlay,
  ModalContent} from "@chakra-ui/react";
import { Post } from "utils/queries";
import { withTranslation } from 'react-i18next';
const styles = {
  inputField: `form-control block w-full px-2 py-2 text-sm text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`,
};


const Validate2fa = (props) => {
  const {t,setErrorMsg,isOpen,setValidationOK,closeModal,user_id }= props;

  const [token, setToken] = useState("");
  const [loading, setLoading] = useState("");
  

  const validate2fa = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await Post.requestPost("validateOtp", {token,id:user_id});
      if(response.hasOwnProperty("error")){
        if(response.error===200){
          setValidationOK()
          closeModal();
        }else setErrorMsg(response.msg)
      }else setErrorMsg(t("error_occurred"))
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.toString());
    }
  };

  useEffect(() => {}, []);

  return (
    <Modal isCentered isOpen={isOpen} onClose={closeModal}>
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
      <ModalContent>
        <section className="grid place-items-center0 rounded-2xl">
          <div className="w-full rounded-2xl ">
            <h1 className="text-2xl lg:text-2xl text-center font-[600] text-ct-blue-600 rounded-3xl mb-1">
              {t("Welcome Back")}
            </h1>
            <h2 className="text-lg text-center mb-1 text-[#142149]">
              {t("Verify Authentication Code")}
            </h2>
            <form
              className="max-w-md w-full mx-auto overflow-hidden shadow-lg bg-ct-dark-200 rounded-2xl p-8 space-y-2"
            >
              <h2 className="text-center text-xl font-semibold text-[#142149]">
                {t("2fa")}
              </h2>
              <p className="text-center text-sm mb-3">
                {t("textProcess_1")}
              </p>
              <input
                onChange={(val)=>setToken(val.target.value)}
                className={styles.inputField}
                placeholder={t("Authentication Code")}
              />

              <Button
                isLoading={loading}
                colorScheme={'green'}
                onClick={validate2fa}
              >
                {t("Authenticate")}
              </Button>
              <span className="block text-center">
              </span>
            </form>
          </div>
        </section>
      </ModalContent>
    </Modal>
  );
};
export default withTranslation()(Validate2fa);
