import {
  Flex,Button,
  Modal,Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,

  Icon,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,Switch
} from '@chakra-ui/react'
import Select from 'react-select';
import {Buffer} from 'buffer'

import { withTranslation } from 'react-i18next';
import React from "react";
import * as R from 'ramda';

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import {validateEmail,validatePassword,validateField} from "utils/Validators";
import Constant from "utils/Constant";

function ModalForm(props) {
  const {t,isOpen, onClose,token,users,setUsers,showMsg,devices,profiles,data,isAdmin}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const [values, setValues] = React.useState({
    idUser: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    role:{value:'user',label:t("user")},
    isReadOnly:false,
    myDevicesList:[]
  });
  var [errorEmail, setErrorEmail] =  React.useState("");
  var [errorFirstname, setErrorFirstname] =  React.useState("");
  var [errorPwd, setErrorPwd] =  React.useState("");
  var [errorMsg, setErrorMsg] =  React.useState("");
  
  const [show, setShow] = React.useState(false);
  
  React.useEffect(() => {  
    console.log("data",data);
    setValues(data)
    return () => {}
  },[]);


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();

    let error,noFound=true,pwd;
    
    error= await validateEmail(values.email)
    if(error.email)setErrorEmail("")
    else {
      setErrorEmail(error);
      noFound=false;
    }
    ///////
    if(values.idUser===""){
      error= await validatePassword(values.password)
      if(error.password)setErrorPwd("")
      else{
        setErrorPwd(error);
        noFound=false;
      }
    }
    //////////
    error= await validateField(values.firstname)
    if(error.field)setErrorFirstname("")
    else{
      setErrorFirstname(error);
      noFound=false;
    }
    
    if(noFound) {
      setValues({ ...values,"isLoading":true})
      var _user={ 
        email:values.email, 
        firstname:values.firstname,
        lastname:values.lastname,
        token,
        phone:values.phone, 
        profile:values.profile, 
        role:values.role.value, 
        lastVisit:new Date(),
        isReadOnly:values.isReadOnly
      }
      if(values.idUser===""){
        pwd=values.password
        _user.password= Buffer.from(pwd).toString('base64')
      }else{
        pwd=values.password
        if(pwd.length!==0)_user.password= Buffer.from(pwd).toString('base64')
      }
      
      if(values.idUser!==""){
        _user.idUser=values.idUser
      }

      if(values.myDevicesList.length!==0){
        _user.devices=[]
        for (let i = 0; i < values.myDevicesList.length; i++) {
          _user.devices.push({ 
            date: new Date(),
            name: values.myDevicesList[i].label,
            id: values.myDevicesList[i].value,
            "lng":t("names"),
          })
        }
      }
      fetch(Constant.SERVER_URL+'/addUser', {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(_user)
      }).then(response => response.json())
      .then(responseData=>{
        if(responseData.hasOwnProperty("error")){
          if(responseData.error===200){
            var userList=users
            if(values.idUser===""){
              _user._id=responseData.id
              showMsg(t("successfully_added"))
            }else{
              userList=R.filter(o => o.email !== values.email, users)
              _user._id=values.idUser
              showMsg(t("successful_modification"))
             //setTimeout(() => {window.location.reload();}, 1500);

            }
            userList.unshift(_user)
            setUsers(userList)
            //////
            setValues({...values,
              firstname: "",
              lastname: "",
              idUser: "",
              phone: "",
              email: "",
              password: "",
              role:{value:'user',label:t("user")},
              showPassword: false,
              isReadOnly:false,
              isLoading:false,
              myDevicesList:[]
            })
            onClose();
          }
          else if(responseData.error===402){
            setErrorMsg(t("email_used"))
          }else  setErrorMsg(responseData.msg)
        }else  setErrorMsg(t("error_occurred"))
        setValues({ ...values,"isLoading":false})
      });
    }
  }

  return (

    <Modal isCentered isOpen={isOpen} 
      onClose={onClose}
      closeOnOverlayClick={false}
    >
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
   
    <ModalContent>
      <ModalHeader>{t("fill_user_info")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}>
          <FormControl isRequired isInvalid={errorFirstname!==""}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("firstname")}
            </FormLabel>
            <FormErrorMessage>{errorFirstname}</FormErrorMessage>
            <Input
              id="firstname"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder='John'
              mb='9px'
              fontWeight='500'
              value={values.firstname}
              onChange={handleChange('firstname')}
              size='lg'
              isRequired={true}
            />
          </FormControl>
          <FormControl ml='10px' >
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("lastname")}
            </FormLabel>
            <Input
              id="lastname"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder='Doe'
              mb='9px'
              fontWeight='500'
              value={values.lastname}
              onChange={handleChange('lastname')}
              size='lg'
            />
          </FormControl>
        </Flex>
        <Flex
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}>
          <FormControl isRequired isInvalid={errorEmail!==""}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("email")}
            </FormLabel>
            <FormErrorMessage>{errorEmail}</FormErrorMessage>
            <Input
              id="email"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='user@neolec.ch'
              mb='9px'
              fontWeight='500'
              value={values.email}
              onChange={handleChange('email')}
              size='lg'
              isRequired={true}
            />
          </FormControl>
          <FormControl ml='10px'>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("phone")}
            </FormLabel>
            <Input
              id="phone"
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='tel'
              placeholder='+41 xx xxx xx xx'
              mb='9px'
              fontWeight='500'
              value={values.phone}
              onChange={handleChange('phone')}
              size='lg'
            />
          </FormControl>
        </Flex>
        <Flex
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("role")}
            </FormLabel>
            <Select 
              ms={{ base: "0px", md: "0px" }}
              defaultValue={values.role}
              isDisabled={!isAdmin}
              onChange={(itm)=>{setValues({ ...values, "role": itm });}}
              options={[
                {value:'user',label:t("user")},
                {value:'admin',label:t("admin")}
              ]}
              placeholder={t("role")}
            />
          </FormControl>
          <FormControl ml='10px' isRequired isInvalid={errorPwd!==""}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("password")}
            </FormLabel>
            <FormErrorMessage>{errorPwd}</FormErrorMessage>
            <InputGroup size='md'>
              <Input
                id="password"
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder='**********'
                type={show? 'text' : 'password'}
                mb='9px'
                fontWeight='500'
                value={values.password}
                onChange={handleChange('password')}
                size='lg'
                isRequired={true}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={()=>{setShow(!show)}}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>

        <Box
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("user_profile")}
            </FormLabel>
            <Select
              ms={{ base: "0px", md: "0px" }}
              options={profiles}
              isDisabled={!isAdmin}
              placeholder={t("user_profile")}
              onChange={(profile)=>{
                setValues({ ...values, "profile":{value:profile._id,label:profile.title}});
              }}
              value={values.profile}
            />
          </FormControl>
        </Box>
        <Box
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}
          mt={{ base: "15px", md: "15px" }}
          >
          <FormControl>
            <Select
              isMulti
              isDisabled={!isAdmin}
              ms={{ base: "0px", md: "0px" }}
              options={devices}
              placeholder={t("device_list")}
              onChange={(_myDevicesList)=>{
                setValues({ ...values, "myDevicesList": _myDevicesList });
              }}
              defaultValue={values.myDevicesList}
            />

          </FormControl>
        </Box>
        <br/>
        {isAdmin?<Flex align="center">
          <Switch id='isReadOnly' 
            isChecked={values.isReadOnly}
            onChange={(val)=>{setValues({ ...values, 'isReadOnly': val.target.checked})}}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            htmlFor='isReadOnly'
            mb='0px'> {t("Read only")}
          </FormLabel>
        </Flex>:null}

        <FormControl isInvalid={errorMsg!==""}>
          <FormErrorMessage>{errorMsg}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter bg={bgColor}>
        <Flex w="100%" justify="space-between">
          <Button  
            width='140px' 
            colorScheme='green'  
            isLoading={values.isLoading}
            onClick={handleSubmit}
          >
            {values.idUser===""?t("add"):t("update")}
          </Button>
          <Button colorScheme='red' variant='outline' 
            onClick={()=>{
              setValues({...values,
                idUser: "",
                firstname: "",
                lastname: "",
                phone: "",
                email: "",
                password: "",
                isReadOnly:false,
                role:{value:'user',label:t("user")},
                myDevicesList:[]
              })
              onClose();
            }}>{t("cancel")}</Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
  );
}

export default withTranslation()(ModalForm);