import React, { FC } from "react";
import {Flex,Text,useColorModeValue,Icon} from "@chakra-ui/react";

const Labels = ({
  labelColor,
  secondaryLabelColor,
  labelFontFamily,
  labelFontSize,
  valueFontSize,
  appendToValue,
  hideLabelValue,
  selected
}) => {
  const styles = {
    labels: {
      position: "absolute",
      top: "0px",
      left: "-10px",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      userSelect: "none",
      color: labelColor,
      fontFamily: labelFontFamily,
      zIndex: 1,
    },

    value: {
      fontSize: `${valueFontSize}`,
      position: "relative",
    },

    appended: {
      position: "absolute",
      right: "0",
      top: "17px",
      transform: "translate(100%, 0)",
      color:"#75cb10",
      fontSize: "1.5rem"
    },

    secondaryText: {
      color: secondaryLabelColor,
      fontSize: labelFontSize,
      lineHeight:"22px"
    },

    hide: {
      display: "none",
    },

    mainLabel: {
      fontFamily: "Fraction",
      display: "flex",
      alignItems: "center",
      color:"#75cb10",
      lineHeight:"90px"
    }
  };

  const menuColor = useColorModeValue("gray.400", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
    <div style={{ ...styles.labels, ...(hideLabelValue && styles.hide) }}>
       <div style={styles.value}>
        <code>
          <span style={styles.mainLabel}>{selected?.value}</span>
          {selected?.value !== undefined && <span style={styles.appended}>{appendToValue}</span>}
        </code>
      </div>
    </div>
  );
};

export default Labels;
