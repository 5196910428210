import {
  Flex,Button,Box,
  Modal,IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,Checkbox,
  ModalCloseButton,
  Accordion, AccordionItem,
  AccordionButton, AccordionPanel, AccordionIcon,
  useColorModeValue,Select,Input
} from '@chakra-ui/react'
import {AiOutlineDelete} from "react-icons/ai";
import {BiDuplicate} from "react-icons/bi";
import PlaningLine from '../PlaningLine';
import SectionPower from '../SectionPower';
import PeriodLine from './PeriodLine';

// Redux
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React from "react";
import moment from "moment"; 
import * as R from 'ramda';

import { Scrollbar } from 'react-scrollbars-custom';

function ModalBillingConfig(props) {
  const {t,isOpen, onClose,onSubmit,showErrorMsg,datas}=props;
  // Chakra color mode
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const myInfos = useSelector(state => state.user.infos);

  const [values, setValues] = React.useState({isLoading:false});
  const [profile, setProfile] = React.useState(JSON.stringify({currency:"chf"}));

  const [section_hour, setSection_hour] = React.useState({
    title:"Billing Hour",
    currency:"chf",
    listRange:[]
  })
  var _profile
  

  React.useEffect(async () => { 
    if(datas.hasOwnProperty("title"))
      setProfile(JSON.stringify(datas))
    else setProfile(JSON.stringify({currency:"chf"}))
    return () => {}
  },[props]);


  const handleSubmit = async (e) => { 
    e.preventDefault();
    let error_found=false;
    
    if("typekBilling"==="hourly"){
      let section_hour_=section_hour
      if(section_hour_.hasOwnProperty("title")){
        delete section_hour_.cost
        delete section_hour_.time
        if(section_hour_.listRange.length===0){
          error_found=true
          showErrorMsg(t("Invalid field")+": "+t("Time slot"))
        }
        /**lastTimeRange=new Date(values.section_hour[i].time[1]).getTime()
            myViews=myInfos
            if(!myViews.hasOwnProperty("billing"))myViews.billing={}
            myViews.billing.section_hour=values.section_hour
            if(i===(values.section_hour.length-1)){
              onSubmit(myViews.billing)
            } */
      }else {
        error_found=true
        showErrorMsg(t("Invalid field")+": "+t("title"))
      }
      if(!error_found){
        section_hour_.type="hourly"
        onSubmit(section_hour_)
      }
    }
    else if("typeBiilling"==="power"){
      /*if(section_power.hasOwnProperty("title")){
        if(section_power.listRange.length===0){
          error_found=true
          showErrorMsg(t("Invalid field")+": "+t("Time slot"))
        }
      }else {
        error_found=true
        showErrorMsg(t("Invalid field")+": "+t("title"))
      }
      if(!error_found){
        let section_power_=section_power
        section_power_.type="power"
        onSubmit(section_power_)
      }*/
    }
    onSubmit(profile)
  }
  const addPeriod=async()=>{
    _profile=JSON.parse(profile)
    _profile.periods=[]
    if(JSON.parse(profile).hasOwnProperty("periods")){
      _profile.periods=JSON.parse(profile).periods
    }
    _profile.periods.push({
      id:(new Date()).getTime(),
      type:'hourly',
      hourly:[{
        id:(new Date()).getTime(),
        weekSelect:[false,false,false,false,false,false,false]
      }],
      hourly_sale:[{
        id:(new Date()).getTime(),
        weekSelect:[false,false,false,false,false,false,false]
      }],
      power:[{id:0,min:0,color:"#"+Math.floor(Math.random()*16777215).toString(16)}]
    })
    setProfile(JSON.stringify(_profile))
  }
  const duplicatePeriod=async(period)=>{
    _profile=JSON.parse(profile)
    _profile.periods=[]
    if(JSON.parse(profile).hasOwnProperty("periods")){
      _profile.periods=JSON.parse(profile).periods
    }
    period.id=(new Date()).getTime()
    _profile.periods.push(period)
    setProfile(JSON.stringify(_profile))
  }
  const deletePeriod=(id)=>{
    _profile=JSON.parse(profile)
    if(_profile.hasOwnProperty("periods")){
      for (let i = 0; i < _profile.periods.length; i++) {
        if(_profile.periods[i].id===id){
           _profile.periods.splice(i, 1)
          break
        }
      }
    }
    setProfile(JSON.stringify(_profile))
  }
  const getRangePane=(period)=>{
    if(period.type==="hourly"){
      return(<Scrollbar style={{height: 250}}>
        <Box flex='1' justify="center">
          {period.hourly?period.hourly.map((item,index)=>{
            return(
              <PlaningLine
                key={index}
                currency={JSON.parse(profile).currency}
                placeholder={t("Cost per kWh")}
                isVisibleRemove={index!==0}
                isVisibleAdd={index==(period.hourly.length-1)}
                program={item}
                onUpdateProgram={(item)=>{
                  _profile=JSON.parse(profile)
                  for(let i = 0; i < _profile.periods.length; i++) {
                    if(_profile.periods[i].id===period.id){
                      for (let j = 0; j < _profile.periods[i].hourly.length; j++) {
                        if(_profile.periods[i].hourly[j].id===item.id){
                          if(item.hasOwnProperty("weekSelect"))_profile.periods[i].hourly[j].weekSelect=item.weekSelect
                          if(item.hasOwnProperty("listRange"))_profile.periods[i].hourly[j].listRange=item.listRange
                        }
                      }
                      break
                    }
                  }
                  setProfile(JSON.stringify(_profile))
                }}
                onPressRemove={()=>{
                  _profile=JSON.parse(profile)
                  for(let i = 0; i < _profile.periods.length; i++) {
                    if(_profile.periods[i].id===period.id){
                      _profile.periods[i].hourly=R.filter(o => o.id!==item.id, _profile.periods[i].hourly)
                      break
                    }
                  }
                  setProfile(JSON.stringify(_profile))
                }}
                onPressAdd={()=>{
                  _profile=JSON.parse(profile)
                  for(let i = 0; i < _profile.periods.length; i++) {
                    if(_profile.periods[i].id===period.id){
                      _profile.periods[i].hourly.push({
                        id:(new Date()).getTime(),
                        weekSelect:[false,false,false,false,false,false,false]
                      })
                      break
                    }
                  }
                  setProfile(JSON.stringify(_profile))
                }}
                weekPress={(idx)=>{
                  _profile=JSON.parse(profile)
                  for(let i = 0; i < _profile.periods.length; i++) {
                    if(_profile.periods[i].id===period.id){
                      for (let j = 0; j < _profile.periods[i].hourly.length; j++) {
                        if(_profile.periods[i].hourly[j].id===item.id){
                          _profile.periods[i].hourly[j].weekSelect[idx]=!_profile.periods[i].hourly[j].weekSelect[idx]
                        }
                        
                      }
                      break
                    }
                  }
                  setProfile(JSON.stringify(_profile))
                }}
                errorMsg={(text)=>{showErrorMsg(text)}}
              />)
          }):null}
          <Flex w={"100%"}
                justifyContent={"space-between"}
                align={"center"}
                mt={period.isSell?3:0}
            >
                <Checkbox 
                    ml={2}
                    size='sm' 
                    color='secondaryGray.600'
                    isChecked={period.isSell}
                    onChange={(state)=>{
                      _profile=JSON.parse(profile)
                      for(let i = 0; i < _profile.periods.length; i++) {
                        if(_profile.periods[i].id===period.id){
                          _profile.periods[i].isSell=state.target.checked
                          break
                        }
                      }
                      setProfile(JSON.stringify(_profile))
                    }}
                >{t("For sale")}</Checkbox>
            </Flex>
          {period.isSell?<Box mt='9px'>
            {period.hourly_sale?period.hourly_sale.map((item,index)=>{
              return(
                <PlaningLine
                  key={index}
                  currency={JSON.parse(profile).currency}
                  placeholder={t("Cost per kWh")}
                  isVisibleRemove={index!==0}
                  isVisibleAdd={index==(period.hourly_sale.length-1)}
                  program={item}
                  onUpdateProgram={(item)=>{
                    _profile=JSON.parse(profile)
                    for(let i = 0; i < _profile.periods.length; i++) {
                      if(_profile.periods[i].id===period.id){
                        for (let j = 0; j < _profile.periods[i].hourly_sale.length; j++) {
                          if(_profile.periods[i].hourly_sale[j].id===item.id){
                            if(item.hasOwnProperty("weekSelect"))_profile.periods[i].hourly_sale[j].weekSelect=item.weekSelect
                            if(item.hasOwnProperty("listRange"))_profile.periods[i].hourly_sale[j].listRange=item.listRange
                          }
                        }
                        break
                      }
                    }
                    setProfile(JSON.stringify(_profile))
                  }}
                  onPressRemove={()=>{
                    _profile=JSON.parse(profile)
                    for(let i = 0; i < _profile.periods.length; i++) {
                      if(_profile.periods[i].id===period.id){
                        _profile.periods[i].hourly_sale=R.filter(o => o.id!==item.id, _profile.periods[i].hourly_sale)
                        break
                      }
                    }
                    setProfile(JSON.stringify(_profile))
                  }}
                  onPressAdd={()=>{
                    _profile=JSON.parse(profile)
                    for(let i = 0; i < _profile.periods.length; i++) {
                      if(_profile.periods[i].id===period.id){
                        _profile.periods[i].hourly_sale.push({
                          id:(new Date()).getTime(),
                          weekSelect:[false,false,false,false,false,false,false]
                        })
                        break
                      }
                    }
                    setProfile(JSON.stringify(_profile))
                  }}
                  weekPress={(idx)=>{
                    _profile=JSON.parse(profile)
                    for(let i = 0; i < _profile.periods.length; i++) {
                      if(_profile.periods[i].id===period.id){
                        for (let j = 0; j < _profile.periods[i].hourly_sale.length; j++) {
                          if(_profile.periods[i].hourly_sale[j].id===item.id){
                            _profile.periods[i].hourly_sale[j].weekSelect[idx]=!_profile.periods[i].hourly_sale[j].weekSelect[idx]
                          }
                          
                        }
                        break
                      }
                    }
                    setProfile(JSON.stringify(_profile))
                  }}
                  errorMsg={(text)=>{showErrorMsg(text)}}
                />)
            }):null}
            </Box>:null}
        </Box>
       </Scrollbar>)
    }
    else if(period.type==="power"){
      return(<Scrollbar style={{height: 250}}>
        <Box flex='1' justify="center">
          <SectionPower
            currency={JSON.parse(profile).currency}
            program={period.power}
            onUpdateProgram={(item)=>{
              _profile=JSON.parse(profile)
              for(let i = 0; i < _profile.periods.length; i++) {
                if(_profile.periods[i].id===period.id){
                  _profile.periods[i].power=item
                  break
                }
              }
              setProfile(JSON.stringify(_profile))
            }}
            errorMsg={(text)=>{showErrorMsg(text)}}
          />
        </Box>
       </Scrollbar>)
    }
    return (<Box/>)
  }

  return (
    <Modal  closeOnOverlayClick={false} blockScrollOnMount={false} isCentered isOpen={isOpen} 
      size={"3xl"} 
      onClose={()=>{
        setSection_hour({listRange:[]})
        onClose()
      }}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent>
        <ModalHeader>{t("Billing models")}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Box pb={4}>
            <Flex w={"100%"}
                justifyContent={"center"}
                align={"center"}
                mb='9px'
            >
              <Input
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  placeholder={t("title")}
                  fontWeight='500'
                  defaultValue={JSON.parse(profile).title}
                  onChange={(val)=>{
                      _profile=JSON.parse(profile)
                      _profile.title=val.target.value
                      setProfile(JSON.stringify(_profile))
                  }}
                  size='lg'
              />
              <Select ml={15} variant='auth' 
                  w={150}
                  defaultValue={JSON.parse(profile).currency}
                  onChange={(val)=>{
                    _profile=JSON.parse(profile)
                    _profile.currency=val.target.value
                    setProfile(JSON.stringify(_profile))
                  }}
              >
                  <option value='chf'>CHF</option>
                  <option value='eur'>EUR</option>
                  <option value='usd'>USD</option>
                  <option value='xaf'>XAF</option>
              </Select>
            </Flex>
            {myInfos.role==="admin"?<Flex w={"100%"}>
              <Checkbox 
                ml={2}
                size='sm' 
                color='secondaryGray.600'
                isChecked={JSON.parse(profile).isVisibleAll}
                onChange={(state)=>{
                  _profile=JSON.parse(profile)
                  _profile.isVisibleAll=state.target.checked
                  setProfile(JSON.stringify(_profile))
                }}
              >{t("Visible to all users")}</Checkbox>
            </Flex>:null}
            <Flex w={"100%"}
                justifyContent={"flex-end"}
                align={"center"}
                mb='14px'
                mt='4px'
            >
              <Button  
                width='140px' 
                size="sm"
                colorScheme='blue'  
                onClick={addPeriod}
              >{t("+ Add a period")}
              </Button>
            </Flex>
            <Accordion defaultIndex={[]} allowMultiple allowToggle={false}>
              {JSON.parse(profile).hasOwnProperty("periods")?
                JSON.parse(profile).periods.map((period,idx)=>{
                  return<AccordionItem key={idx}>
                    <h2>
                      <AccordionButton bg="white" disabled={true}>
                        <Box flex='1' textAlign='left'>{period.hasOwnProperty("startDate")?
                          moment(period.startDate).format('MMM YYYY')+" - "+moment(period.endDate).format('MMM YYYY'):
                          t("Period to set")}
                        </Box>
                        <Flex>
                          <IconButton
                            colorScheme='blue'
                            variant='link'
                            fontSize='20px'
                            icon={<BiDuplicate/>}
                            onClick={()=>{duplicatePeriod(period)}}
                          />
                          <IconButton
                            colorScheme='red'
                            variant='link'
                            fontSize='20px'
                            icon={<AiOutlineDelete/>}
                            onClick={()=>deletePeriod(period.id)}
                          />
                          <AccordionIcon />
                        </Flex>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <PeriodLine
                        onChange={(startDate,endDate,type)=>{
                          _profile=JSON.parse(profile)
                          for(let i = 0; i < _profile.periods.length; i++) {
                            if(_profile.periods[i].id===period.id){
                              _profile.periods[i].startDate=startDate
                              _profile.periods[i].endDate=endDate
                              _profile.periods[i].type=type

                              if(_profile.periods[i].hasOwnProperty(type)){

                              }else{}
                              break
                            }
                          }
                          setProfile(JSON.stringify(_profile))
                        }}
                        period={period}
                      />
                      {getRangePane(period)}
                    </AccordionPanel>
                  </AccordionItem>
                })
              :null}
            </Accordion>
          </Box>
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            <Button  
              width='140px' 
              colorScheme='green'  
              onClick={handleSubmit}
            >{JSON.parse(profile).hasOwnProperty("id")?t("modify"):t("valid")}
            </Button>
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                setValues({...values,isLoading:false})
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalBillingConfig);