import {
  Flex,Button,Grid,GridItem,
  Modal,Box,Icon,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,

  FormControl,
  FormLabel,Switch,
  FormErrorMessage,
  Input,Tooltip,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Select from 'react-select';
import {FaExclamationCircle} from "react-icons/fa";
import TextInput from 'react-autocomplete-input';
import '../../../../assets/css/react-autocomplete-input.css';

import { withTranslation } from 'react-i18next';
import React from "react";
// Redux
import { useSelector} from 'react-redux';

import {validateField} from "utils/Validators";
import {Post} from '../queries'
import TabCommands from './TabCommands';
var mexp = require('../../../../utils/math-expression-evaluator');


function ModalForm(props) {
  const {t,isOpen, onClose,token,role,addItem,showMsg,showErrorMsg,devices,data,sensorsList}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("secondary.dark","navy.800");
  const cur_device = useSelector(state => state.device.cur_device);

  const [values, setValues] = React.useState({
    full_id: "",
    id: "",
    title:"",
    subtitle:"",
    formula:"",
    unit:"",
    input_val_min:0,
    input_val_max:100,
    val_min:0,
    val_max:100,
    device:{value:cur_device.value,label:cur_device.label},
    type:{value:'analog_sensor',label:t("analog_sensor")},
    isOutput:false
  });
  const [graph, setGraph] = React.useState({
    ds: ".",
    ts: "'",
    dd: 2,
    cc:"#75cb10",
    th:10,
    th_tt:"",
    shTh:false,
    shMin:false,
    shMinCol:"#04d909",//29c036
    shMax:false,
    shMaxCol:"#fd2c2b",//cd272d
    shThCol:"#c01010",
    ct:{value:'smooth',label:t("smooth")},
    gt:{value:'area',label:t("area")},
    gph_bound:false,
    gph_ct:{value:'smooth',label:t("smooth")},
    gph_gt:{value:'area',label:t("area")},
    gph_rvs:{value:false,label:t("no")}
  })
  const [forecast, setForecast] = React.useState({
    src_id: "",
    formula: "",
  })
  
  const [commands, setCommands] = React.useState({protocol:"undefined"});
  
  var [errorID, setErrorID] =  React.useState("");
  var [errorDeviceID, setErrorDeviceID] =  React.useState("");
  var [errorTitle, setErrorTitle] =  React.useState("");
  var [errorType, setErrorType] =  React.useState("");
  var [errorMsg, setErrorMsg] =  React.useState("");
  const [variables, setVariables] = React.useState([]);
  var response;

  const parseExpresion=(expression)=>{
    if(expression){
      var text=expression, _var,_value
      if(expression)
        if(expression.includes("$")){
          var newTxt = text.split('$');
          for (var i = 1; i < newTxt.length; i++) {
            _var=newTxt[i].split(' ')[0];
            sensorsList.forEach(element => {
              if(element.id===_var){
                _value=element.value?element.value:"0"
                /*if (_value==="0" && element.formula!=="") {
                  _value=mexp.eval(element.formula)
                }*/
                text=text.replace('$'+_var,''+_value).split("=")[0]
              }
            });
          }
        }

      try{ return [text+" = "+mexp.eval(text),true,mexp.eval(text)]}
      catch(e){return [text+" = "+e.message,false,0] }
    }
    return ["Empty",false,0]
  }

  React.useEffect(() => {  
    setErrorMsg("");

    if(data.full_id!==""){
      setValues({ ...values, 
        full_id: data.full_id, 
        id: data.id,
        title: data.title,
        subtitle:data.subtitle,
        unit:data.unit,
        val_min:data.val_min,
        val_max:data.val_max,
        input_val_min:data.input_val_min,
        no_integrate:data.no_integrate,
        diff_index:data.diff_index,
        formula:data.formula,
        input_val_max:data.input_val_max,
        device:{value:data.device_id,label:data.device_label},
        type:{value:data.type,label:t(data.type)},
        isOutput:data.isOutput,
        isLoading:false
      });
      if(data.graph.hasOwnProperty("dd")){
        setGraph({...graph,
          ds: data.graph.ds,
          ts: data.graph.ts,
          dd: data.graph.dd,
          cc:data.graph.cc,
          th:data.graph.th,
          shMin:data.graph.shMin,
          shMinCol:data.graph.shMinCol,
          shMax:data.graph.shMax,
          shMaxCol:data.graph.shMaxCol,
          th_tt:data.graph.th_tt,
          shTh:data.graph.shTh,
          shThCol:data.graph.shThCol,
          gph_bound:data.graph.gph_bound,
          ct:data.graph.ct,
          gt:data.graph.gt,
          gph_ct:data.graph.gph_ct?data.graph.gph_ct:{value:'smooth',label:t("smooth")},
          gph_gt:data.graph.gph_gt?data.graph.gph_gt:{value:'line',label:t("line")},
          gph_rvs:data.graph.gph_rvs?data.graph.gph_rvs:{value:false,label:t("no")}
        })
      }else 
        setGraph({...graph,
          ds: ".",
          ts: "'",
          dd: "2",
          cc:"#75cb10",
          shMin:false,
          shMinCol:"#04d909",
          shMax:false,
          shMaxCol:"#fd2c2b",
          shTh:false,
          th_tt:"",
          shThCol:"#c01010",
          th:50,
          ct:{value:'smooth',label:t("smooth")},
          gt:{value:'area',label:t("area")},
          gph_bound:false,
          gph_ct:{value:'smooth',label:t("smooth")},
          gph_gt:{value:'area',label:t("area")},
          gph_rvs:{value:false,label:t("no")}
        })
      if(data.hasOwnProperty("forecast")){
        if(data.forecast.hasOwnProperty("src_id")){
          setForecast({...forecast,src_id:data.forecast.src_id,formula:data.forecast.formula})
        }else setForecast({...forecast,src_id: "",formula: ""})
      }
      if(data.commands)setCommands(data.commands)
      
    }else{
      setValues({ ...values, 
        full_id: "", 
        id: "",
        title: "",
        subtitle:"",
        formula:"",
        unit:"",
        val_min:0,
        val_max:100,
        input_val_min:0,
        input_val_max:100,
        type:{value:'analog_sensor',label:t("analog_sensor")},
        no_integrate:false,
        diff_index:false,
        isOutput:false,
        isLoading:false
      });
      setGraph({...graph,
        ds: ".",
        ts: ",",
        dd: "2",
        cc:"#75cb10",
        th:"10",
        sl:true,
        ct:{value:'smooth',label:t("smooth")},
        gt:{value:'area',label:t("area")},
        gph_ct:{value:'smooth',label:t("smooth")},
        gph_gt:{value:'area',label:t("area")},
        gph_rvs:{value:false,label:t("no")}
      })
      setForecast({...forecast,src_id: "",formula: ""})
    }

    if(sensorsList){
      var _variables=[]
      sensorsList.forEach(element => { _variables.push(element.id)});
      setVariables(_variables)
    }
    return () => {}
  },[data]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeChart = (prop) => (event) => {
    setGraph({ ...graph, [prop]: event.target.value });
  };
  const handleChangeForecast = (prop) => (event) => {
    setForecast({ ...forecast, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();

    let error,noFound=true;
    //////////
    error= await validateField(values.device.value)
    if(error.field)setErrorDeviceID("")
    else{setErrorDeviceID(error);  noFound=false;}
    
    error= await validateField(values.type.value)
    if(error.field)setErrorType("")
    else{setErrorType(error); noFound=false;}
    
    error= await validateField(values.id)
    if(error.field)setErrorID("")
    else{setErrorID(error); noFound=false;}
    
    error= await validateField(values.title)
    if(error.field)setErrorTitle("")
    else{setErrorTitle(error); noFound=false;}

    if(commands)
      if(commands.list)
      for (const elt of commands.list) {
        if(elt.title && !elt.command)showErrorMsg("Fill command to "+elt.title)
        else if(!elt.title && elt.command)showErrorMsg("Fill title to "+elt.command)
        else if(!elt.title && !elt.command)showErrorMsg("Fill title and command")

        if(elt.title && elt.command)noFound=true
        else{
          noFound=false
          break;
        }
      }
    
    if(noFound) {
      setValues({ ...values,"isLoading":true})

      var _sensor={ 
        id:values.id, 
        title:values.title,
        subtitle:values.subtitle,
        unit:values.unit,
        type:values.type.value,
        isOutput:values.isOutput,
        val_min:Number(values.val_min),
        val_max:Number(values.val_max),
        input_val_min:Number(values.input_val_min),
        input_val_max:Number(values.input_val_max),
        device_id:values.device.value,
        graph,
        token,
        date_msg:new Date()
      }

      if(values.hasOwnProperty("no_integrate"))_sensor.no_integrate=values.no_integrate
      if(values.hasOwnProperty("diff_index"))_sensor.diff_index=values.diff_index

      if(values.full_id!=="")_sensor.full_id=values.full_id
      if(commands)if(commands.protocol!=="undefined")_sensor.commands=commands
      if(forecast.src_id!=="")_sensor.forecast=forecast
      else delete _sensor.forecast

      if(values.type.value==="calculated_analog_sensor")
        _sensor.formula=values.formula
      
      //send data after reconnection 
      response=await Post.requestPost("addSensor",_sensor)
      if(response.hasOwnProperty("error")){
        if(response.error===200){
          _sensor._id=response.id
          if(values.full_id==="")  showMsg(t("successfully_added"))
          else{
            _sensor._id=values.full_id
            _sensor.full_id=values.full_id
            showMsg(t("successful_modification"))
          }
          addItem(_sensor)
          ////
          setValues({...values,
            full_id: "",
            id: "",
            title:"",
            subtitle:"",
            formula:"",
            unit:"",
            input_val_min:0,
            input_val_max:100,
            val_min:0,
            val_max:100,
            type:{value:'analog_sensor',label:t("analog_sensor")},
            no_integrate:false,
            diff_index:false,
            isOutput:false,
            isLoading:false
          })
          setGraph({...graph,
            ds: ".",
            ts: "'",
            dd: "2",
            cc:"#75cb10",
            th:"10",
            sl:true,
            ct:{value:'smooth',label:t("smooth")},
            gt:{value:'area',label:t("area")}
          })
          setForecast({...forecast,src_id: "", formula: ""})
          onClose();
        }
        else if(response.error===402) showErrorMsg(t("identifier_already_used"))
        else if(response.hasOwnProperty("msg"))showErrorMsg(response.msg)
        else showErrorMsg(t("error_occurred"))
      }else showErrorMsg(t("error_occurred"))
      setValues({ ...values,"isLoading":false})
    }
  }

  return (

    <Modal isCentered 
      isOpen={isOpen}
      size={"xl"} 
      onClose={onClose}
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    
      <ModalContent maxW="60%" maxH="660px" >
        <ModalHeader>{t("sensor_details")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>{t("general_infos")}</Tab>
              <Tab>{t("graphique_representation")}</Tab>
              {role==="admin"?<Tab>{t("forecast")}</Tab>:null}
              {role==="admin"?<Tab>{t("command")}</Tab>:null}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <FormControl isRequired  isInvalid={errorDeviceID!==""}>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("device")}
                    </FormLabel>
                    <Select
                      defaultValue={values.device}
                      onChange={(itm)=>{setValues({...values,"device":itm });}}
                      options={devices}
                      placeholder={t("device")}
                    />
                    <FormErrorMessage>{errorDeviceID}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired  isInvalid={errorType!==""}>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {"Type"}
                    </FormLabel>
                    <Select 
                      ms={{ base: "0px", md: "0px" }}
                      defaultValue={values.type}
                      onChange={(itm)=>{setValues({...values,"type":itm});}}
                      options={[
                        {value:'analog_sensor',label:t("analog_sensor")},
                        {value:'calculated_analog_sensor',label:t("calculated_analog_sensor")},
                        {value:'digital_sensor',label:t("digital_sensor")}
                      ]}
                      placeholder={"Type"}
                    />
                    <FormErrorMessage>{errorType}</FormErrorMessage>

                    {values.type.value==="analog_sensor"?
                    <FormControl align="center">
                      <Flex align="center">
                        <Switch id='isOutput' 
                          isChecked={values.isOutput}
                          onChange={(val)=>{setValues({ ...values, 'isOutput': val.target.checked})}}
                        />
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          htmlFor='isOutput'
                          mb='0px'> {t("isOutput")}
                        </FormLabel>
                      </Flex>
                    </FormControl>
                    :null}
                  </FormControl>
                </Flex>
                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb={{ base: "2px", md: "auto" }}>
                  <FormControl isRequired  isInvalid={errorID!==""}>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("id")}
                    </FormLabel>
                    <Input
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      placeholder={t("id")}
                      mb='9px'
                      fontWeight='500'
                      value={values.id}
                      onChange={handleChange('id')}
                      size='lg'
                    />
                    <FormErrorMessage>{errorID}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={errorTitle!==""} ml='10px' >
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("title")}
                    </FormLabel>
                    <Input
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      placeholder={t("title")}
                      mb='9px'
                      fontWeight='500'
                      value={values.title}
                      onChange={handleChange('title')}
                      size='lg'
                    />
                    <FormErrorMessage>{errorTitle}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb={{ base: "2px", md: "auto" }}>
                  <FormControl>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("subtitle")}
                    </FormLabel>
                    <Input
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      placeholder={t("subtitle")}
                      mb='9px'
                      fontWeight='500'
                      value={values.subtitle}
                      onChange={handleChange('subtitle')}
                      size='lg'
                    />
                  </FormControl>
                  {values.type.value!=="digital_sensor"?<FormControl  ml='10px' >
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("unit")}
                    </FormLabel>
                    <Input
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      placeholder={t("unit")}
                      mb='9px'
                      fontWeight='500'
                      value={values.unit}
                      onChange={handleChange('unit')}
                      size='lg'
                    />
                  </FormControl>:null}
                </Flex>
                
                {values.type.value==="analog_sensor"?<div>
                  <Flex
                    zIndex='2'
                    direction='row'
                    mx={{ base: "auto", lg: "unset" }}
                    mb={{ base: "2px", md: "auto" }}>
                      <FormControl>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("input_val_min")}
                        </FormLabel>
                        <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder={t("input_val_min")}
                          mb='9px'
                          fontWeight='500'
                          value={values.input_val_min}
                          onChange={handleChange('input_val_min')}
                          size='lg'
                        />
                      </FormControl>
                      <FormControl  ml='10px'>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("input_val_max")}
                        </FormLabel>
                        <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder={t("input_val_max")}
                          mb='9px'
                          fontWeight='500'
                          value={values.input_val_max}
                          onChange={handleChange('input_val_max')}
                          size='lg'
                        />
                      </FormControl>
                  </Flex>
                  <Flex
                    zIndex='2'
                    direction='row'
                    mx={{ base: "auto", lg: "unset" }}
                    mb={{ base: "2px", md: "auto" }}>
                      <FormControl>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("val_min")}
                        </FormLabel>
                        <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder={t("val_min")}
                          mb='9px'
                          fontWeight='500'
                          value={values.val_min}
                          onChange={handleChange('val_min')}
                          size='lg'
                        />
                      </FormControl>
                      <FormControl  ml='10px'>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("val_max")}
                        </FormLabel>
                        <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder={t("val_max")}
                          mb='9px'
                          fontWeight='500'
                          value={values.val_max}
                          onChange={handleChange('val_max')}
                          size='lg'
                        />
                      </FormControl>
                  </Flex>
                </div>:null}
                {
                  values.type.value==="calculated_analog_sensor"?<div>
                    <FormControl>
                      <Flex
                        zIndex='2'
                        direction='row'
                        mx={{ base: "auto", lg: "unset" }}
                        mb={{ base: "2px", md: "auto" }}>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='0px'>
                          {t("formula")}
                        </FormLabel>
                        <Tooltip label='Supported symbols /\n
                                  ---------------------------------
                                  +    -     /     *
                                  Mod     (     )     &     Sigma
                                  Pi n pi e
                                  C P ! log
                                  ln pow ^ root
                                  sin cos tan
                                  asin acos atan
                                  sinh cosh tanh
                                  asinh acosh atanh'>
                          <Icon as={FaExclamationCircle} width='20px' height='20px' color='inherit' />
                        </Tooltip>
                      </Flex>
                      <TextInput 
                        trigger="$"
                        className={"react-autocomplete-box"}
                        maxOptions={1000}
                        value={values.formula}
                        onChange={ (formula)=>{
                          setValues({...values,formula})
                        }}
                        options={variables}
                        style={{ width: "100%" }}
                        aria-label={t("formula")}
                        placeholder={t("see_list_available")}
                      />
                      <b>{parseExpresion(values.formula)[0]}</b>
                    </FormControl>
                    <Flex
                      zIndex='2'
                      direction='row'
                      mx={{ base: "auto", lg: "unset" }}
                      mb={{ base: "2px", md: "auto" }}>
                        <FormControl>
                          <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='0px'>
                            {t("val_min")}
                          </FormLabel>
                          <Input
                            variant='auth'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            placeholder={t("val_min")}
                            mb='9px'
                            fontWeight='500'
                            value={values.val_min}
                            onChange={handleChange('val_min')}
                            size='lg'
                          />
                        </FormControl>
                        <FormControl  ml='10px'>
                          <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='0px'>
                            {t("val_max")}
                          </FormLabel>
                          <Input
                            variant='auth'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            placeholder={t("val_max")}
                            mb='9px'
                            fontWeight='500'
                            value={values.val_max}
                            onChange={handleChange('val_max')}
                            size='lg'
                          />
                        </FormControl>
                    </Flex>
                  </div>:null
                }
                <FormControl isInvalid={errorMsg!==""}>
                  <FormErrorMessage>{errorMsg}</FormErrorMessage>
                </FormControl>
              </TabPanel>
              <TabPanel>
                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='0px'>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='0px'>
                        {t("decimal_separator")}
                      </FormLabel>
                      <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          mb='9px'
                          fontWeight='500'
                          value={graph.ds}
                          onChange={handleChangeChart('ds')}
                          size='lg'
                        />
                    </FormControl>
                    
                    <FormControl ml='10px'>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='0px'>
                        {t("thousand_separator")}
                      </FormLabel>
                      <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          mb='9px'
                          fontWeight='500'
                          value={graph.ts}
                          onChange={handleChangeChart('ts')}
                          size='lg'
                        />
                    </FormControl>

                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("decimal_digits")}
                    </FormLabel>
                    <Input
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        mb='9px'
                        fontWeight='500'
                        type={"number"}
                        value={graph.dd}
                        onChange={handleChangeChart('dd')}
                        size='lg'
                      />
                  </FormControl>
                </Flex>

                <Flex
                  mx={{ base: "auto", lg: "unset" }}
                  mb='15px'>
                  <Flex
                    zIndex='2'
                    direction='row'
                    mx={{ base: "auto", lg: "unset" }}>
                    <Switch id='no_integrate' 
                      isChecked={values.no_integrate}
                      onChange={(val)=>{setValues({ ...values, 'no_integrate': val.target.checked})}}
                    />
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      htmlFor='no_integrate'
                      mb='0px'>
                      {t("no_integrate")}
                    </FormLabel>
                  </Flex>
                  <Flex
                    zIndex='2'
                    direction='row'
                    mx={{ base: "auto", lg: "unset" }}>
                    <Switch id='diff_index' 
                      isChecked={values.diff_index}
                      onChange={(val)=>{setValues({ ...values, 'diff_index': val.target.checked})}}
                    />
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      htmlFor='diff_index'
                      mb='0px'>
                      {t("diff_index")}
                    </FormLabel>
                  </Flex>
                </Flex>

                <strong>Monitoring 24</strong>
                <hr/>
                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <Switch id='shTh' 
                    isChecked={graph.shTh}
                    onChange={(val)=>{setGraph({ ...graph, 'shTh': val.target.checked})}}
                  />
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    htmlFor='shTh'
                    mb='0px'>
                    {t("show_threshold",{val:t("threshold")})}
                  </FormLabel>
                </Flex>
                {graph.shTh?<Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("threshold")+" ("+t("title")+")"}
                    </FormLabel>
                    <Input
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        mb='9px'
                        fontWeight='500'
                        value={graph.th_tt}
                        onChange={handleChangeChart('th_tt')}
                        size='lg'
                      />
                  </FormControl>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("threshold")+" ("+t("value")+")"}
                    </FormLabel>
                    <Input
                        variant='auth'
                        fontSize='sm'
                        type={"number"}
                        ms={{ base: "0px", md: "0px" }}
                        mb='9px'
                        fontWeight='500'
                        value={graph.th}
                        onChange={handleChangeChart('th')}
                        size='lg'
                      />
                  </FormControl>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("color")}
                    </FormLabel>
                    <Input
                      type={"color"}
                      value={graph.shThCol}
                      onChange={handleChangeChart('shThCol')}
                    />
                  </FormControl>
                </Flex>:null}

                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <FormControl>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("color_curve")}
                    </FormLabel>
                    <Input
                        variant='auth'
                        fontSize='sm'
                        type={"color"}
                        ms={{ base: "0px", md: "0px" }}
                        mb='9px'
                        fontWeight='500'
                        value={graph.cc}
                        onChange={handleChangeChart('cc')}
                        size='lg'
                      />
                  </FormControl>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("curve_type")}
                    </FormLabel>
                    <Select 
                        ms={{ base: "0px", md: "0px" }}
                        value={graph.ct}
                        onChange={(itm)=>{setGraph({...graph,ct:itm});}}
                        options={[
                          {value:'dashed',label:t("dashed")},
                          {value:'smooth',label:t("smooth")}
                        ]}
                      />
                  </FormControl>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("graph_type")}
                    </FormLabel>
                    <Select 
                        ms={{ base: "0px", md: "0px" }}
                        defaultValue={graph.gt}
                        onChange={(itm)=>{setGraph({...graph,gt:itm});}}
                        options={[
                          {value:'line',label:t("line")},
                          {value:'area',label:t("area")},
                          {value:'win_loss',label:t("Win Loss")},
                          {value:'bar',label:t("bar")}
                        ]}
                      />
                  </FormControl>
                </Flex>

                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <FormControl>
                    <Flex align="center">
                      <Switch id='shMin' 
                        isChecked={graph.shMin}
                        onChange={(val)=>{setGraph({ ...graph, 'shMin': val.target.checked})}}
                      />
                      {graph.shMin?<Box ml='10px'>
                        <p>Mimimum</p>
                        <Input
                          type={"color"}
                          value={graph.shMinCol}
                          onChange={handleChangeChart('shMinCol')}
                        />
                      </Box>:<FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        htmlFor='shMax'
                        mb='0px'>
                        {t("show_threshold",{val:t("minimum")})}
                      </FormLabel>
                      }
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <Flex align="center">
                      <Switch id='shMax' 
                        isChecked={graph.shMax}
                        onChange={(val)=>{setGraph({ ...graph, 'shMax': val.target.checked})}}
                      />
                      {graph.shMax?<Box ml='10px'>
                        <p>Maximum</p>
                        <Input
                          type={"color"}
                          value={graph.shMaxCol}
                          onChange={handleChangeChart('shMaxCol')}
                        />
                      </Box>:<FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        htmlFor='shMax'
                        mb='0px'>
                        {t("show_threshold",{val:t("maximum")})}
                      </FormLabel>
                      }
                    </Flex>
                  </FormControl>
                </Flex>

                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <FormControl>
                    <Flex align="center">
                      <Switch 
                        isChecked={graph.gph_bound}
                        onChange={(val)=>{setGraph({ ...graph, 'gph_bound': val.target.checked})}}
                      />
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='0px'>
                        {t("bound_graph_limit",{min:values.val_min,max:values.val_max})}
                      </FormLabel>
                    </Flex>
                  </FormControl>
                </Flex>

                <strong>{t("graph")}</strong>
                <hr/>
                <Flex
                  zIndex='2'
                  direction='row'
                  mx={{ base: "auto", lg: "unset" }}
                  mb='10px'>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("reverse")}
                    </FormLabel>
                    <Select 
                        ms={{ base: "0px", md: "0px" }}
                        defaultValue={graph.gph_rvs}
                        onChange={(itm)=>{setGraph({...graph,gph_rvs:itm});}}
                        options={[
                          {value:false,label:t("no")},
                          {value:true,label:t("yes")}
                        ]}
                      />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("curve_type")}
                    </FormLabel>
                    <Select 
                        ms={{ base: "0px", md: "0px" }}
                        value={graph.gph_ct}
                        onChange={(itm)=>{setGraph({...graph,gph_ct:itm});}}
                        options={[
                          {value:'dashed',label:t("dashed")},
                          {value:'smooth',label:t("smooth")}
                        ]}
                      />
                  </FormControl>
                  <FormControl ml='10px'>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='0px'>
                      {t("graph_type")}
                    </FormLabel>
                    <Select 
                        ms={{ base: "0px", md: "0px" }}
                        defaultValue={graph.gph_gt}
                        onChange={(itm)=>{setGraph({...graph,gph_gt:itm});}}
                        options={[
                          {value:'line',label:t("line")},
                          {value:'area',label:t("area")},
                          {value:'win_loss',label:t("Win Loss")},
                          {value:'bar',label:t("bar")}
                        ]}
                      />
                  </FormControl>
                </Flex>
                <br/>
              </TabPanel>
              {role==="admin"?<TabPanel>
                <Grid
                  templateColumns='repeat(4, 1fr)'
                  gap={3}
                  mb='10px'
                >
                  <GridItem colSpan={1}>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='0px'>
                        {t("src_id")}
                      </FormLabel>
                      <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          mb='9px'
                          fontWeight='500'
                          value={forecast.src_id}
                          onChange={handleChangeForecast('src_id')}
                          size='lg'
                        />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl ml='10px'>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='0px'>
                        {t("formula")}
                      </FormLabel>
                      <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          mb='9px'
                          fontWeight='500'
                          value={forecast.formula}
                          onChange={handleChangeForecast('formula')}
                          size='lg'
                        />
                    </FormControl>
                  </GridItem>
                </Grid>
                <br/>
                <br/>
                <br/>
              
              </TabPanel>:null}
              {role==="admin"?<TabPanel>
                <TabCommands 
                  data={data}
                  onTypeCommand={(_command)=>{
                    setCommands(_command)
                  }}
                /><br/><br/>
              </TabPanel>:null}
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter bg={bgColor}>
          <Flex w="100%" justify="space-between">
            <Button  
              width='140px' 
              colorScheme='green'  
              isLoading={values.isLoading}
              onClick={handleSubmit}
            >
              {values.full_id===""?t("add"):t("update")}
            </Button>
            <Button colorScheme='red' variant='outline' 
              onClick={()=>{
                setValues({...values,
                  full_id: "",
                  id: "",
                  title:"",
                  subtitle:"",
                  formula:"",
                  unit:"",
                  input_val_min:0,
                  input_val_max:100,
                  no_integrate:false,
                  diff_index:false,
                  val_min:0,
                  val_max:100,
                  type:{value:'analog_sensor',label:t("analog_sensor")},
                  isOutput:false
                })
                onClose();
              }}>{t("cancel")}</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default withTranslation()(ModalForm);