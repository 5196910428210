/*!
=====================================================
* Horizon UI - v1.1.0
=========================================================

*/

// Chakra importsm
import { Flex,Box,Text,Tab,
  Tabs, TabList, TabPanels,TabPanel
} from '@chakra-ui/react'
// Assets
import React from "react";
// Custom components
import Card from "components/card/Card.js";
// Redux
import { useSelector} from 'react-redux';

import { withTranslation } from 'react-i18next';

import 'rsuite/dist/rsuite.min.css';
import DateRangePicker from 'rsuite/DateRangePicker';
import AnimationDetail from "./components/animation/detail";
import AnimationDetail2 from "./components/animation/detail/index2";

import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer} from 'react-notifications';
//import {PDFDownloadLink} from '@react-pdf/renderer';
import ExportPDF from './components/exportPDF';


function Overview(props) {
  const {t}=props;
  //const myInfos = useSelector(state => state.user.infos);
  const cur_device = useSelector(state => state.device.cur_device);
  var [isLoading, setIsLoading] = React.useState(false);


  React.useEffect(() => {  
    (async function() {
      document.title = t("overview")+" - NeoLec";
      
    })();
    return () => {}
    
  },[cur_device]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={t("loading")}
    >
      <Card  pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex justify='space-between' ps='0px' pe='20px' pt='5px' align='center' w='100%' zIndex='0'>
            <Box>
              <Text fontSize='lg'>{t("period")}</Text>
              <DateRangePicker 
                isoWeek={true}
                placeholder={t("current_month")}
                onOk={(value) => { props.onRefresh(value)}}
                oneTap showOneCalendar hoverRange="month" ranges={[]}
              />
            </Box>
            {/*<PDFDownloadLink document={<ExportPDF />} fileName="neolec_report.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : t("export_PDF"))}
          </PDFDownloadLink>*/}
        </Flex>
        
        <Box height='30px'/>

        <Tabs isManual variant='enclosed-colored'  colorScheme='green'>
          <TabList>
            <Tab>{t("home")}</Tab>
            <Tab>{t("home")} 2</Tab>
            <Tab>{t("photovoltaic_panel")}</Tab>
            <Tab>{t("photovoltaic_panel")} 2</Tab>
            <Tab>{t("battery")}</Tab>
            <Tab>{t("battery")} 2</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AnimationDetail
                type={"home"}
                title={t("consumption_break_down")}
                title2={t("self_consumption_rate")}
                cur_device={null} 
                onRefresh={(date)=>{
                  //updateChart(currentDeviceID,date[0],date[1])
                }}
                data={{
                  innerSub:55,
                  outnerSub_2:55,
                  inner:45,
                  outner:15,
                  outnerSub_1:30
                }}
              />
            </TabPanel>
            <TabPanel>
              <AnimationDetail2
                type={"home"}
                title={t("consumption_break_down")}
                title2={t("self_consumption_rate")}
                cur_device={null} 
                onRefresh={(date)=>{
                  //updateChart(currentDeviceID,date[0],date[1])
                }}
                data={{
                  innerSub:55,
                  outnerSub_2:55,
                  inner:45,
                  outner:15,
                  outnerSub_1:30
                }}
              />
            </TabPanel>
            <TabPanel>
              <AnimationDetail
                type={"solar_panel"}
                cur_device={null} 
                title={t("production_break_down")}
                title2={t("self_consumption_rate")}
                data={{
                  innerSub:60,
                  outnerSub_2:60,
                  inner:40,
                  outner:15,
                  outnerSub_1:25
                }}
                onRefresh={(date)=>{
                  //updateChart(currentDeviceID,date[0],date[1])
                }}
              />
            </TabPanel>
            <TabPanel>
              <AnimationDetail2
                type={"solar_panel"}
                cur_device={null} 
                title={t("production_break_down")}
                title2={t("self_consumption_rate")}
                data={{
                  innerSub:60,
                  outnerSub_2:60,
                  inner:40,
                  outner:15,
                  outnerSub_1:25
                }}
                onRefresh={(date)=>{
                  //updateChart(currentDeviceID,date[0],date[1])
                }}
              />
            </TabPanel>
            <TabPanel>
              <AnimationDetail
                type={"battery"}
                title={t("roundcycle_losses")+": 7%"}
                title2={"SOH:50"}
                cur_device={null} 
                onRefresh={(date)=>{
                  //updateChart(currentDeviceID,date[0],date[1])
                }}
                data={{
                  innerSub:50,
                  outnerSub_2:50,
                  inner:50,
                  outner:20,
                  outnerSub_1:30,
                  roundcycleVal:7
                }}
              />
            </TabPanel>
            <TabPanel>
              <AnimationDetail2
                type={"battery"}
                title={t("roundcycle_losses")+": 7%"}
                title2={"SOH:50"}
                cur_device={null} 
                onRefresh={(date)=>{
                  //updateChart(currentDeviceID,date[0],date[1])
                }}
                data={{
                  innerSub:50,
                  outnerSub_2:50,
                  inner:50,
                  outner:20,
                  outnerSub_1:30,
                  roundcycleVal:7
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
      <NotificationContainer/>
    </LoadingOverlay>
  );
}

export default withTranslation()(Overview);