import {useState,useEffect} from "react";
import anime from "animejs";
import "./styles.css"

function Animation(props) {
  const [airconditioner, setAirconditioner] = useState(0);
  const [battery, setBattery] = useState(0);
  const [accumulator, setaccumulator] = useState(0);
  const [car, setCar] = useState(0);
  const [plug, setPlug] = useState(0);

  const max = 100;
  const fluxElectSpeed=5000;

  const animationSun=() => {
    anime({
      targets: "#the_SUN",
      rotate: 360, 
      easing: 'linear',
      loop: true,
      duration: 8000
    });
    anime({
      targets: "#sun_only",
      translateX: 700, 
      easing: 'linear',
      duration: 10
    });

  }  
  const animationSunFlux=() => {
    anime({
      targets: '#fluxCenter',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 6000,
      loop: true
    });
    anime({
      targets: '.fluxCenter',
      easing: 'easeInOutSine',
      translateX:[-20,25],
      duration: 6000,
      loop: true
    });
    anime({
      targets: '#fluxLeft',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 6000,
      loop: true
    });
    anime({
      targets: '.fluxLeft',
      easing: 'easeInOutSine',
      translateX:40,
      duration: 6000,
      loop: true
    });
    anime({
      targets: '#fluxRight',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 5000,
      loop: true
    });
    anime({
      targets: '.fluxRight',
      easing: 'easeInOutSine',
      translateX:40,
      duration: 4000,
      loop: true
    });
    
    anime({
        targets: '.fluxDown',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: 5000,
        loop: true
    });
    anime({
      targets: '.fluxDownSub',
      easing: 'easeInOutSine',
      translateX:[-13,22],
      duration: 6000,
      loop: true
    });
  }
  const animationSmoke=() => {
    anime({
        targets: '#smoke_1',
        strokeWidth: .2,
        easing: 'linear',
        duration: 3700,
        loop: true
      });
    anime({
        targets: '#smoke_2',
        strokeWidth: .1,
        easing: 'linear',
        duration: 4500,
        loop: true
    });
    anime({
        targets: '#smoke_3',
        strokeWidth: .3,
        easing: 'linear',
        duration: 2500,
        loop: true
    });
    //////////
    anime({
        targets: '#smoke_fan_1',
        strokeWidth: .1,
        easing: 'linear',
        duration: 2700,
        loop: true
      });
    anime({
        targets: '#smoke_fan_2',
        strokeWidth: .1,
        easing: 'linear',
        duration: 5000,
        loop: true
    });
    anime({
        targets: '#smoke_fan_3',
        strokeWidth: .1,
        easing: 'linear',
        duration: 4000,
        loop: true
    });
  }
  const animation=() => {
  }
  const animHelice=() => {
    anime({
      targets: "#helice",
      rotate: 360, 
      easing: 'linear',
      loop: true,
      duration: 3000
    });

    anime({
        targets: "#group_helice",
        translateX: 20.974083, 
        translateY: 49.552492, 
        easing: 'linear',
        duration: 10
      });
  }
  const animationElecFlux=() => {
    anime({
      targets: '#borne_charge_flux',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: fluxElectSpeed,
      loop: true
    });
    anime({
      targets: '.borne_charge_flux',
      easing: 'easeInOutSine',
      translateX:[-20,25],
      duration: fluxElectSpeed,
      loop: true
    });
    
    anime({
      targets: '#panel_solar_flux',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: 6000,
      loop: true
    });
    anime({
      targets: '.panel_solar_flux',
      easing: 'easeInOutSine',
      translateX:[-17,25],
      duration: 6000,
      loop: true
    });

    anime({
        targets: '#grid_flux',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: fluxElectSpeed,
        loop: true
      });
    anime({
        targets: '.grid_flux',
        easing: 'linear',
        translateX:[-65,45],
        duration: fluxElectSpeed,
        loop: true
    });

    anime({
        targets: '#bat_rad_flux',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: fluxElectSpeed,
        loop: true
      });
    anime({
        targets: '.bat_rad_flux',
        easing: 'linear',
        translateX:[-15,15],
        duration: fluxElectSpeed,
        loop: true
    });

    
  }
  const animationWaterFlux=() => {
    anime({
        targets: '.water_flux',
        easing: 'linear',
        translateY:-40,
        duration: 5000,
        loop: true
    });
    
    anime({
        targets: '.thermo_flux',
        easing: 'linear',
        translateY:-28,
        duration: 5000,
        loop: true
    });

    anime({
        targets: '.radia_flux',
        easing: 'linear',
        translateX:-40,
        duration: 6000,
        loop: true
    });
    anime({
        targets: '.waterHotBall_flux',
        easing: 'linear',
        translateY:40,
        duration: 5500,
        loop: true
    });

    
    
  }
  const animationVerticalFlux=() => {
    anime({
      targets: '#vertical_fan',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: fluxElectSpeed,
      loop: true
    });
    anime({
      targets: '.vertical_fan',
      easing: 'linear',
      translateX:[-27,20],
      duration: 8000,
      loop: true
    });

    anime({
        targets: '#horizontal_fan',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: fluxElectSpeed,
        loop: true
      });
    anime({
        targets: '.horizontal_fan',
        easing: 'linear',
        translateX:[-35,1],
        duration: fluxElectSpeed,
        loop: true
    });
    
    anime({
        targets: '#vertical_bat',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: fluxElectSpeed,
        loop: true
    });
    anime({
        targets: '.vertical_bat',
        easing: 'linear',
        translateX:[-20,10],
        duration: fluxElectSpeed,
        loop: true
    });

    /*anime({
        targets: '#horizontal_bat',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: 6000,
        loop: true
    });*/
    anime({
        targets: '.horizontal_bat',
        easing: 'linear',
        translateX:[-0,26],
        duration: fluxElectSpeed,
        loop: true
    });
     
  }
  const animationCarPipe=() => {
    anime({
      targets: '#leftCarPipe',
      strokeDashoffset: 100,
      direction: 'reverse',
      easing: 'linear',
      duration: fluxElectSpeed,
      loop: true
    });
    anime({
      targets: '.leftCarPipe',
      easing: 'linear',
      translateX:[-15,18],
      duration: fluxElectSpeed,
      loop: true
    });
     
    anime({
        targets: '#centerCarPipe',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: fluxElectSpeed,
        loop: true
      });
      anime({
        targets: '.centerCarPipe',
        easing: 'linear',
        translateX:[-30,35],
        duration: fluxElectSpeed,
        loop: true
      });

      anime({
        targets: '#rightCarPipe',
        strokeDashoffset: 100,
        direction: 'reverse',
        easing: 'linear',
        duration: fluxElectSpeed,
        loop: true
      });
      anime({
        targets: '.rightCarPipe',
        easing: 'linear',
        translateX:[-7,10],
        duration: fluxElectSpeed,
        loop: true
      });
  }

  let _car,_battery;
  
  useEffect(() => { 
    animation();
    animHelice(); 
    animationSun();
    animationSmoke();
    animationCarPipe();
    animationSunFlux();
    animationElecFlux();
    animationWaterFlux();
    animationVerticalFlux();

    setInterval(() => {
        _car=Math.floor(Math.random() * max)
        anime({
            targets: '#borne_charge_indi',
            easing: 'linear',
            fill: _car<60?"#ec0707":"#19ec07",
            stroke: "#000",
            duration: 400
        });
        setCar(_car);

        _battery=Math.floor(Math.random() * max)
        anime({
            targets: '.bat_indi',
            easing: 'linear',
            fill: _battery<60?"#ec0707":"#19ec07",
            stroke: "#000",
            duration: 400
        });
        setBattery(_battery);

        setAirconditioner(Math.floor(Math.random() * max));
        setaccumulator(Math.floor(Math.random() * max));
        setPlug(Math.floor(Math.random() * max));


  }, 4000);
  },[]);
  
  return (
    <svg version="1.1" id="idstation" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1010 910" >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="illustration" transform="translate(0.000000, 7.000000)">
            <g transform="translate(704.11234, 370.892895) scale(0.4)" >
                <path fill="#fff" stroke="none" d="M0 2C16.4598 -1.91875 22.358 11.5199 28 25L29 25C29 19.3892 26.8369 8.09964 30.0278 3.3179C32.7461 -0.755692 41.3812 0.321587 42.6821 5.05864C45.3292 14.6979 43 28.0023 43 38C43 41.3894 43.9536 46.7129 41.9722 49.6821C34.6399 60.6703 24.3897 43.9812 21.5756 39C18.8159 34.115 16.1578 29.1827 14 24L13 24C13 29.911 17.3086 47.2177 11.6821 50.9722C8.71079 52.955 3.40753 52 0 52L0 63L6 63L4 67C7.04018 70.6566 3.78661 72.1401 0 72C3.702 76.2365 15.6272 74 21 74C41.6154 74 65.618 77.4492 85.8873 73.8519C91.7001 72.8202 88.7137 65.2698 97 65L96 74L101 72L99 65L103 67L107 65L103 74C119.807 74 141.902 77.7584 158 73C157.236 67.1809 159.229 65.189 165 65L165 74L202 74L216 72C210.805 70.7203 209.387 64.0009 216 65L216 51C209.074 51.8753 201.234 53.4442 195.001 49.2971C182.261 40.8212 183.525 22.5127 197 15.4421C203.223 12.1771 209.395 13.4787 216 14L216 0L144 0L144 52L130 52L130 0L33 0C25.8197 0 4.77184 -3.4608 0 2z"/>
                <path fill="#0169b7" stroke="none" d="M130 0L130 52L144 52L144 0L130 0M0 52C3.35721 52 9.61069 53.1787 12.3966 50.9722C17.3153 47.0765 13.0006 29.6743 13 24L14 24C16.104 31.3037 22.14 46.6234 28.6096 50.9722C31.2309 52.7342 39.8453 53.0821 41.9722 50.3966C44.1751 47.6153 43 41.3281 43 38C43 27.4596 44.6214 15.4445 42.8519 5.05864C42.0151 0.147614 31.7817 -1.10899 29.6034 3.3179C26.969 8.67169 29 19.0782 29 25C26.4965 20.7147 24.1739 16.3176 21.7191 12C19.2764 7.70346 4.01354 -8.29987 0.317901 4.14815C-3.71213 17.7225 0 37.8028 0 52z"/>
                <path fill="#75cb10" stroke="none" d="M99 53C100.255 42.8383 90.8633 34.6076 93.5733 25C97.4537 11.243 115.908 12.0295 120.142 25C122.797 33.1334 113.945 45.2036 113 54C135.583 44.5331 130.676 7.68383 104 11.3048C83.2075 14.1271 76.3302 47.6097 99 53z"/>
                <path fill="#0169b7" stroke="none" d="M82 39L61 39C66.7122 37.3116 74.4585 39.967 79.6821 37.3966C86.823 33.8828 79.1457 18.8574 74.9066 15.9946C57.4643 4.21522 38.0493 27.6067 48.7276 43.9961C53.3397 51.0749 74.4891 57.6031 80.9722 49.2724C82.8896 46.8086 82 41.9305 82 39z"/>
                <path fill="#75cb10" stroke="none" d="M169 38C162.132 42.5324 156.891 37.3878 150.318 39.7732C146.63 41.1114 147.427 48.6094 150.318 50.3472C155.508 53.4678 167.534 52.9953 173 50.8519C191.862 43.4544 184.561 13.079 165 13.0802C156.731 13.0808 139.982 29.0743 149.742 36.9722C153.51 40.0211 164.318 38 169 38z"/>
                <path fill="#0169b7" stroke="none" d="M216 51L215 39C211.379 39.6719 207.381 40.7204 204.109 38.3966C199.004 34.7708 200.115 27.716 206.015 25.6181C208.823 24.6196 212.15 25.6401 215 26L216 14C209.218 13.1429 202.365 12.0979 196 15.4421C183.5 22.0092 182.163 41.3505 194.004 49.2971C200.188 53.447 209.087 52.2834 216 51z"/>
                <path fill="#fff" stroke="none" d="M100 48L113 48C115.098 40.8688 121.638 34.8519 120.797 27C119.36 13.5874 100.448 9.5843 94.3179 22.0039C89.7481 31.2631 97.5635 39.5424 100 48M215 23C212.139 26.929 207.023 23.6744 203.228 26.6034C191.161 35.9178 210.121 39.6918 216 40L215 23M60 27L68 27C65.6377 23.3733 62.7934 24.1584 60 27M162 27L170 27C167.145 24.0871 164.536 23.5008 162 27z"/>
                <path fill="#75cb10" stroke="none" d="M99 27C96.0264 35.122 102.43 45.2424 111.981 40.6674C116.728 38.394 118.458 32.6257 120 28C114.726 28.0151 109.844 28.0066 107 33L99 27z"/>
                <path fill="#0169b7" stroke="none" d="M104.109 46.5872C96.674 48.767 101.598 62.1675 107.715 59.9174C115.668 56.9915 113.732 43.7659 104.109 46.5872z"/>
                <path fill="#0165b7" stroke="none" d="M100 48L101 49L100 48z"/>
                <path fill="#57a879" stroke="none" d="M99 49L99 54L100 54L99 49M113 49L113 52L114 52L113 49M112 52L113 53L112 52z"/>
                <path fill="#0169b7" stroke="none" d="M0 63C0.0511558 65.236 -0.693124 70.5564 1.62346 71.9028C5.18733 73.9741 7.32451 69.2133 4 67L6 63L0 63M41 63C38.9014 67.2804 39.1459 72.5922 45 72L43 70L45 67L45 66L41 63M215 73L216 72C215.058 58.7578 206.663 68.4468 215 73M9 65L10 73L11 73L12 68L13 68L14 73L15 73L16 68L17 68L18 73C23.6098 65.5541 14.3515 65.0006 9 65M29 73C33.0445 58.798 14.6093 71.3892 29 73M32 65L33 73L38 65L32 65M54.4444 66.0463C50.5995 68.6129 54.7646 74.3698 58.3997 71.777C61.8408 69.3226 58.206 63.5354 54.4444 66.0463M62 72L66 72L66 68L67 68L68 73C73.2273 66.0619 62.3406 61.6289 62 72M74.3071 66.0463C70.7319 68.3551 73.858 73.7772 77.5671 71.7122C81.4549 69.5477 78.3097 63.4615 74.3071 66.0463M82 65L83 73L84 73L88 67L82 65M89 74C95.9316 73.9178 96.9765 71.636 97 65C89.7743 65.0856 89.4808 67.4794 89 74M99 65L99 74C104.1 73.1701 105.646 69.6845 107 65L103 67L99 65M115 65L116 73L117 73L118 68L119 68L120 73L121 73L122 68L123 68L124 73C129.61 65.5541 120.351 65.0006 115 65M129 65C128.435 70.9905 130.01 72.5647 136 72C135.712 66.7963 134.223 65.1737 129 65M138 65L139 73L140 73L141 68L142 68L145 73C146.696 66.7738 143.873 65.07 138 65M148 66C148.373 70.6698 149.162 72.3087 154 73C155.531 67.6235 153.805 64.4542 148 66M157 74L165 74L165 65C158.024 65.0831 157.4 67.501 157 74M169.444 66.0463C165.599 68.6129 169.765 74.3698 173.4 71.777C176.841 69.3226 173.206 63.5354 169.444 66.0463M177 72L184 73L185 68L186 68L187 73C192.996 65.0419 177.371 60.6875 177 72M192.607 66.0463C189.456 68.397 193.097 73.6885 196.486 71.7122C200.505 69.3685 196.247 63.3318 192.607 66.0463M201 65L202 73L203 73L204 68L205 68L205 72L209 72C208.678 66.1964 206.584 65.0665 201 65z"/>
                <path fill="#fff" stroke="none" d="M0 68L1 69L0 68M92 68L92 70L94 68L92 68M160 68L162 70L162 68L160 68M181.333 68.6667L181.667 69.3333L181.333 68.6667M215.333 68.6667L215.667 69.3333L215.333 68.6667M2 69L3 70L2 69z"/>
            </g>
                
                <g id="maison" transform="translate(0.000000, 283.800636)" stroke="#012038">
                    <line x1="0.959751582" y1="356.627784" x2="940.299056" y2="358.129374" id="Line-2" strokeWidth="4.8" strokeLinecap="round" strokeDasharray="4.49999988079071"></line>
                    <line x1="941.797205" y1="358.129374" x2="941.797205" y2="599.885472" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <line x1="279.615431" y1="358.129374" x2="279.615431" y2="599.885472" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <line x1="552.877774" y1="358.129374" x2="552.278514" y2="512.793213" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <line x1="279.615431" y1="599.885472" x2="941.797205" y2="599.885472" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <line x1="279.615431" y1="197.459173" x2="552.278514" y2="197.459173" id="Line-2" strokeWidth="1.8" strokeLinecap="square"></line>
                    <line x1="553.776663" y1="173.433722" x2="941.797205" y2="173.433722" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <line x1="279.615431" y1="358.129374" x2="279.615431" y2="197.459173" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <path d="M514.824794,156.916225 L747.03786,0.750795334 M553.776663,131.389183 L553.027589,355.308819 M979.250926,156.916225 L747.03786,0.750795334 M941.797205,132.890774 L941.797205,357.378579" id="Combined-Shape" strokeWidth="1.8" strokeLinecap="round"></path>
                </g>
                <g id="icon/sun" transform="translate(326.807118, 518.048780)" stroke="#012038">
                    <path d="M35.0880844,91.1334411 C22.6052023,92.0156467 14.7221181,93.1895732 11.4388316,94.6552204 C8.39044619,96.0160086 5.97040992,97.8159365 4.17872274,100.055004 C3.83720889,100.479393 3.65181826,101.007895 3.65173773,101.55263 L3.65173773,106.220251 C3.65173773,107.545735 4.72625433,108.620251 6.05173773,108.620251 L19.1074931,108.620251 L19.1074931,108.620251 C19.1074931,102.691264 21.5419849,99.7267706 26.4109685,99.7267706 C31.2799522,99.7267706 33.714444,102.691264 33.714444,108.620251 L83.0129033,108.620251 C83.0129033,102.691264 85.4473952,99.7267706 90.3163788,99.7267706 C95.1853624,99.7267706 97.6198542,102.691264 97.6198542,108.620251 L111.754843,104.269884 L111.754843,91.0734247 L95.957844,83.5513885 C76.4431427,79.9678506 62.3047549,79.5644432 53.5426807,82.3411661 C45.3186097,84.9473944 39.647731,87.6716988 36.5300444,90.5140791 L36.530096,90.5141358 C36.1323704,90.8762492 35.6245327,91.0942624 35.0880665,91.1331947 Z" id="Path-11" strokeWidth="1.8" strokeLinejoin="round"></path>
                    <ellipse id="Oval" strokeWidth="1.8" cx="90.3163788" cy="107.047025" rx="7.30347546" ry="7.32025451"></ellipse>
                    <ellipse id="Oval" strokeWidth="1.8" cx="26.4109685" cy="107.047025" rx="7.30347546" ry="7.32025451"></ellipse>
                    <g id="Group" transform="translate(61.102477, 86.916325)" fill="#012038" strokeLinejoin="round" strokeWidth="1.2">
                        <polygon id="Path-13" points="3.65173773 0 0 7.32025451 2.67528177 7.32025451"></polygon>
                        <polygon id="Path-13" transform="translate(3.651738, 10.980382) scale(-1, -1) translate(-3.651738, -10.980382) " points="5.47760659 7.32025451 1.82586886 14.640509 4.50115063 14.640509"></polygon>
                    </g>
                </g>
                <g id="icon/sun-copy-2" transform="translate(427.183088, 540.572641)" stroke="#012038">
                    <rect id="Rectangle" strokeWidth="1.8" x="46.4426132" y="16.5174973" width="25.4685298" height="72.0763521" rx="2.4"></rect>
                    <path d="M17.9777857,27.9807671 L18.7950755,33.4438164 C18.9708991,34.619082 19.9803161,35.4887204 21.1686608,35.4887204 L26.7175082,35.4887204 C27.896995,35.4887204 28.9016573,34.6316664 29.0875226,33.4669161 L29.9629762,27.9807671 L29.9629762,27.9807671 L17.9777857,27.9807671 Z" id="Path-8" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></path>
                    <line x1="26.9666786" y1="24.776246" x2="26.9666786" y2="27.7794274" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                    <g id="Group" transform="translate(55.431506, 60.063627)" fill="#012038" strokeLinejoin="round" strokeWidth="1.2">
                        <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                        <polygon id="Path-13" transform="translate(5.243521, 14.265111) scale(-1, -1) translate(-5.243521, -14.265111) " points="7.49074406 9.00954401 2.99629762 19.5206787 6.28895211 19.5206787"></polygon>
                    </g>
                    <path d="M23.970381,48.3914616 C26.0651649,45.960286 29.73418,45.6875866 32.1653556,47.7823705 C33.4464467,48.8862024 34.1831195,50.4933566 34.1831195,52.1844034 L34.1831195,62.9434239 C34.1831195,66.3287896 36.9275007,69.0731707 40.3128663,69.0731707 C43.698232,69.0731707 46.4426132,66.3287896 46.4426132,62.9434239 L46.4426132,36.038176 L46.4426132,36.038176" id="Path-17" strokeWidth="1.8" transform="translate(35.206497, 52.555673) scale(-1, 1) translate(-35.206497, -52.555673) "></path>
                    <rect id="borne_charge_indi" strokeWidth="1.8" x="50.9370596" y="21.0222694" width="16.4796369" height="7.50795334" rx="1.2"></rect>
                    <rect id="Rectangle" strokeWidth="1.8" transform="translate(59.176878, 90.846235) scale(1, -1) translate(-59.176878, -90.846235) " x="43.4463155" y="88.5938494" width="31.461125" height="4.504772" rx="2.252386"></rect>
                    <line x1="20.9740834" y1="24.776246" x2="20.9740834" y2="27.7794274" id="Line-2" strokeWidth="1.8" strokeLinecap="round"></line>
                </g>
                <g transform="translate(864.146244, 525.556734)" stroke="#012038">
                    <path d="M28.4648274,22.52386 C28.4648274,17.0202265 35.0014062,14.2684097 48.0745637,14.2684097 C61.1477212,14.2684097 67.6842999,17.0202265 67.6842999,22.52386 L67.6842999,84.7170745 C67.9606203,87.4639179 61.4240415,88.8373396 48.0745637,88.8373396 C34.7250859,88.8373396 28.1885071,87.4639179 28.4648274,84.7170745 L28.4648274,22.52386 Z" id="Path-14" strokeWidth="1.8" fill="#FFFFFF"></path>
                    <ellipse id="Oval" strokeWidth="1.8" cx="47.940762" cy="29.281018" rx="8.23981846" ry="8.25874867"></ellipse>
                    <line x1="47.940762" y1="29.281018" x2="50.9370596" y2="26.2778367" id="Line" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></line>
                    <path d="M47.9387056,58.5620361 C44.7996378,62.9331985 43.090329,65.8836877 42.8107793,67.4135037 C42.0367659,71.6492371 45.381864,73.5779427 47.9387056,73.5779427 C50.5178535,73.5779427 53.836403,71.617772 53.0728554,67.4135037 C52.7963725,65.8911253 51.0849893,62.9406361 47.9387056,58.5620361 Z" id="Path-20" strokeWidth="1.8" strokeLinejoin="round"></path>
                    <path d="M45.6935388,69.0731707 C45.6990572,69.5640209 45.8252826,69.9369986 46.0722148,70.1921037 C46.319147,70.4472088 46.6923046,70.5747614 47.1916876,70.5747614" id="Path-21-Copy" strokeWidth="1.2" strokeLinecap="round"></path>
                    <path d="M33.0941072,88.4347133 C33.0941072,89.4391903 33.0941072,90.4436672 33.0941072,91.4481442 C33.0941072,92.1108859 33.6313655,92.6481442 34.2941072,92.6481442 L39.3848513,92.6481442 C40.047593,92.6481442 40.5848513,92.1108859 40.5848513,91.4481442 C40.5848513,90.7342878 40.5848513,90.0204315 40.5848513,89.3065751 C40.5848513,88.616953 40.5848513,88.8373396 37.0291703,88.6357727 C33.4734892,88.4342058 33.0941072,88.2169351 33.0941072,88.4347133 Z" id="Path-35" strokeWidth="1.8"></path>
                    <path d="M55.5513579,88.6845749 C55.5513579,89.6890518 55.5513579,90.6935288 55.5513579,91.6980058 C55.5513579,92.3607475 56.0886162,92.8980058 56.7513579,92.8980058 L61.842102,92.8980058 C62.5048437,92.8980058 63.042102,92.3607475 63.042102,91.6980058 C63.042102,90.9841494 63.042102,90.270293 63.042102,89.5564367 C63.042102,88.8668146 63.042102,89.0872012 59.486421,88.8856343 C55.9307399,88.6840674 55.5513579,88.4667967 55.5513579,88.6845749 Z" id="Path-35" strokeWidth="1.8" transform="translate(59.296730, 90.745928) scale(-1, 1) translate(-59.296730, -90.745928) "></path>
                </g>
                <g transform="translate(632.429476, 540.572641)" stroke="#012038" strokeWidth="1.8">
                    <g>
                        <g transform="translate(11.985190, 81.085896)" fill="#FFFFFF">
                            <rect id="Rectangle" x="0" y="0" width="70.4129941" height="6.00636267" rx="1.2"></rect>
                        </g>
                        <rect id="Rectangle" fill="#FFFFFF" x="11.9851905" y="39.0413574" width="70.4129941" height="6.00636267" rx="1.2"></rect>
                        <rect id="Rectangle-Copy-2" fill="#FFFFFF" x="49.4389108" y="30.0318134" width="10.4870417" height="63.0668081" rx="5.24352084"></rect>
                        <rect id="Rectangle" fill="#FFFFFF" x="16.4796369" y="30.0318134" width="10.4870417" height="63.0668081" rx="5.24352084"></rect>
                        <rect id="Rectangle-Copy" fill="#FFFFFF" x="32.9592739" y="30.0318134" width="10.4870417" height="63.0668081" rx="5.24352084"></rect>
                        <rect id="Rectangle-Copy-3" fill="#FFFFFF" x="65.9185477" y="30.0318134" width="10.4870417" height="63.0668081" rx="5.24352084"></rect>
                        <polygon id="Path-34" strokeLinejoin="round" points="82.3981846 36.038176 82.3981846 48.0509014 88.3907799 45.0967076 88.3907799 39.6255278"></polygon>
                        <path id="smoke_1" d="M43.3998287,6.00636267 C41.5889131,6.95003324 40.6180747,8.12177952 40.4873135,9.52160153 C40.2911717,11.6213345 40.8942043,12.1732502 41.697397,13.6576416 C42.5005897,15.1420329 43.6973251,16.1014801 43.3998287,18.6531944 C43.2014978,20.3543372 42.2306594,21.6445591 40.4873135,22.52386" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path id="smoke_3" d="M50.8905728,6.00636267 C49.0796571,6.95003324 48.1088187,8.12177952 47.9780576,9.52160153 C47.7819158,11.6213345 48.3849483,12.1732502 49.1881411,13.6576416 C49.9913338,15.1420329 51.1880692,16.1014801 50.8905728,18.6531944 C50.6922419,20.3543372 49.7214034,21.6445591 47.9780576,22.52386" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path id="smoke_3" d="M58.3813168,6.00636267 C56.5704012,6.95003324 55.5995628,8.12177952 55.4688016,9.52160153 C55.2726599,11.6213345 55.8756924,12.1732502 56.6788851,13.6576416 C57.4820779,15.1420329 58.6788132,16.1014801 58.3813168,18.6531944 C58.1829859,20.3543372 57.2121475,21.6445591 55.4688016,22.52386" strokeLinecap="round" strokeLinejoin="round"></path>
                    </g>
                </g>
                <g transform="translate(503.588678, 219.232238)" stroke="#012038" strokeWidth="2.079">
                    <g >
                        <path d="M14.5567723,18.7638954 L3.60983836,77.4737971 C3.32921991,78.9787922 4.32177327,80.426318 5.82676833,80.7069364 C5.9917477,80.7376981 6.15917979,80.7534141 6.32700187,80.7538906 L89.7091643,80.9906556 C91.2400914,80.9950027 92.4846771,79.7574651 92.4890242,78.2265379 C92.4894724,78.0686781 92.4764352,77.9110686 92.4500546,77.755428 L82.4587672,18.8087613 C82.2328001,17.4755985 81.0779256,16.5 79.7257481,16.5 L17.2818069,16.5 C15.9468272,16.5 14.8014726,17.4515339 14.5567723,18.7638954 Z" id="Path-19" strokeLinecap="round" strokeLinejoin="round"></path>
                        <line x1="38.55" y1="17.8125" x2="30" y2="79.5" id="Line-16" strokeLinecap="square"></line>
                        <line x1="59.7857143" y1="17.8125" x2="69.8571429" y2="79.28125" id="Line-16" strokeLinecap="square"></line>
                        <line x1="13.0714286" y1="37.3125" x2="83.3571429" y2="37.3125" id="Line-16" strokeLinecap="square"></line>
                        <line x1="6.79245283" y1="60.75" x2="89.2075472" y2="60.75" id="Line-16" strokeLinecap="square"></line>
                        <line x1="76.5" y1="93" x2="22.5" y2="93" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="37.5" y1="81" x2="37.5" y2="93" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="61.5" y1="81" x2="61.5" y2="93" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                    </g>
                </g>
                <g id="icon/sun-copy" transform="translate(852.588678, 231.232238)">
                    <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.8" fill="#FFFFFF" x="46.5" y="30.0222825" width="4.5" height="57" rx="2.25"></rect>
                    <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.8" fill="#FFFFFF" x="61.5" y="30.0222825" width="4.5" height="57" rx="2.25"></rect>
                    <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.8" fill="#FFFFFF" x="76.5" y="30.0222825" width="4.5" height="57" rx="2.25"></rect>
                    <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.8" fill="#FFFFFF" x="31.5" y="30.0222825" width="4.5" height="57" rx="2.25"></rect>
                    <rect id="Rectangle-Copy-3" stroke="#012038" strokeWidth="1.8" fill="#FFFFFF" x="16.5" y="30.0222825" width="4.5" height="57" rx="2.25"></rect>
                    <path d="M8.61086145,5.10816995 C5.87028715,7.55108597 4.5,12.3483627 4.5,19.5 C4.5,26.6516373 5.87028715,31.448914 8.61086145,33.8918301 C9.04926275,34.2825353 9.61571647,34.4988963 10.2029516,34.5 L87.8222135,34.5 C88.5614216,34.4990357 89.2589445,34.1574854 89.7133301,33.5744216 C91.9044434,30.7580824 93,26.0666086 93,19.5 C93,12.9333914 91.9044434,8.24191758 89.7133301,5.42557845 C89.2589445,4.84251463 88.5614216,4.50096434 87.8222135,4.5 L10.2029516,4.5 C9.61571647,4.50110367 9.04926275,4.71746475 8.61086145,5.10816995 Z" id="Path-36" stroke="#012038" strokeWidth="1.8"></path>
                    <path d="M11.4543747,86.2439932 C10.8075741,86.9224829 10.4841738,87.9244851 10.4841738,89.25 C10.4841738,90.5755149 10.8075741,91.5775171 11.4543747,92.2560068 C11.9069518,92.7307544 12.5341113,92.9995905 13.1900161,93 L84.2323678,93 C84.8921744,92.9999156 85.5228444,92.728197 85.976112,92.2487253 C86.6481532,91.5378269 86.9841738,90.5382518 86.9841738,89.25 C86.9841738,87.9617482 86.6481532,86.9621731 85.976112,86.2512747 C85.5228444,85.771803 84.8921744,85.5000844 84.2323678,85.5 L13.1900161,85.5 C12.5341113,85.5004095 11.9069518,85.7692456 11.4543747,86.2439932 Z" id="Path-36" stroke="#012038" strokeWidth="1.8"></path>
                    <line x1="18.75" y1="33.75" x2="18.75" y2="38.25" id="Line-24" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="square"></line>
                    <line x1="48.75" y1="33.75" x2="48.75" y2="38.25" id="Line-24" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="square"></line>
                    <line x1="33.75" y1="33.75" x2="33.75" y2="38.25" id="Line-24" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="square"></line>
                    <line x1="63.75" y1="33.75" x2="63.75" y2="38.25" id="Line-24" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="square"></line>
                    <line x1="78.75" y1="33.75" x2="78.75" y2="38.25" id="Line-24" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="square"></line>
                    <line x1="16.5" y1="87.75" x2="81.75" y2="87.75" id="Line-23" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="square"></line>
                    <path d="M47.9979411,9 C44.8549945,13.366532 43.1435736,16.3138957 42.8636785,17.8420911 C42.0887086,22.0733375 45.4379401,24 47.9979411,24 C50.5802759,24 53.902926,22.0419057 53.1384349,17.8420911 C52.8616104,16.3213254 51.1481125,13.3739617 47.9979411,9 Z" id="Path-20" stroke="#012038" strokeWidth="1.8" strokeLinejoin="round"></path>
                    <path d="M45.75,19.5 C45.7555253,19.9903302 45.8819066,20.3629128 46.129144,20.6177477 C46.3763813,20.8725826 46.75,21 47.25,21" id="Path-21-Copy" stroke="#012038" strokeWidth="1.2" strokeLinecap="round"></path>
                </g>
                <g transform="translate(29.050042, 371.321103)" stroke="#012038" strokeWidth="1.8">
                    <g id="Group">
                        <path d="M44.7983254,212.678053 L12.3988585,259.288062 L40.8028448,166.550144 L53.205626,75.9512563 L70.5107744,4.65591276 C70.6670992,4.01187152 71.3159241,3.61649921 71.9599653,3.772824 C72.390269,3.87726937 72.7280871,4.21019378 72.8387999,4.63892767 L91.2539313,75.9512563 L91.2539313,75.9512563 L103.57604,167.487169 L130.882901,259.288062 L100.31951,212.678053 L44.7983254,212.678053 Z" id="Path-32" strokeLinejoin="round"></path>
                        <polygon id="Path-33" strokeLinejoin="round" points="8.23981846 79.5314955 58.5063544 54.7552494 85.1408364 54.7552494 131.837095 79.5314955"></polygon>
                        <polygon id="Path-33" strokeLinejoin="round" points="8.23981846 141.472111 48.3152992 116.695864 96.3673747 116.695864 131.837095 141.472111"></polygon>
                        <line x1="53.6245523" y1="80.6672833" x2="96.2560611" y2="115.945069" id="Line" strokeLinecap="square"></line>
                        <line x1="48.3152992" y1="115.945069" x2="91.7616147" y2="79.9068931" id="Line-2" strokeLinecap="square"></line>
                        <line x1="27.3412158" y1="212.046872" x2="102.248656" y2="168.500743" id="Line-3" strokeLinecap="square"></line>
                        <line x1="115.731996" y1="210.545281" x2="40.8245551" y2="166.999152" id="Line-4" strokeLinecap="square"></line>
                        <line x1="42.3227039" y1="166.999152" x2="102.248656" y2="166.999152" id="Line-5" strokeLinecap="square"></line>
                        <line x1="27.3412158" y1="212.647508" x2="115.731996" y2="212.647508" id="Line-6" strokeLinecap="square"></line>
                        <line x1="8.23981846" y1="79.5314955" x2="8.23981846" y2="87.7902441" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="2.05995462" y1="88.3533406" x2="14.4196823" y2="88.3533406" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="125.657232" y1="88.3533406" x2="138.016959" y2="88.3533406" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="131.837095" y1="79.5314955" x2="131.837095" y2="87.7902441" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="8.23981846" y1="141.472111" x2="8.23981846" y2="149.730859" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="2.05995462" y1="150.293956" x2="14.4196823" y2="150.293956" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="125.657232" y1="150.293956" x2="138.016959" y2="150.293956" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="131.837095" y1="141.472111" x2="131.837095" y2="149.730859" id="Line" strokeLinecap="round" strokeLinejoin="round"></line>
                    </g>
                </g>
                <text  fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="843.668458" y="507.531283">{accumulator}°C</tspan>
                </text>
                <text id="21,8°C" fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="754.797802" y="588.617179">21,8°C</tspan>
                </text>
                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="724.834826" y="764.303287">{airconditioner}W</tspan>
                </text>
                <text id="158W/m2" fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="715.845933" y="114.114528">158W/m2</tspan>
                </text>
                <text id="5’824W" fontFamily="Montserrat-Bold, Montserrat" fontSize="27" fontWeight="bold" fill="#012038">
                    <tspan x="697.868148" y="357.869035">5’824W</tspan>
                </text>
                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="371.271707" y="516.540827">{plug}W</tspan>
                </text>
                <text id="1’580W" fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="530.075481" y="336.349947">1’580W</tspan>
                </text>
                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="381.758748" y="584.112407">{car}%</tspan>
                </text>
                <g transform="translate(443.182850, 767.312831)" stroke="#012038">
                    <path d="M15.0533813,41.3298829 L80.8281427,41.3298829 C82.1536261,41.3298829 83.2281427,42.4043995 83.2281427,43.7298829 L83.2281427,70.3910079 C83.2281427,71.7164913 82.1536261,72.7910079 80.8281427,72.7910079 L15.0533813,72.7910079 C13.7278979,72.7910079 12.6533813,71.7164913 12.6533813,70.3910079 L12.6533813,43.7298829 C12.6533813,42.4043995 13.7278979,41.3298829 15.0533813,41.3298829 Z" id="Rectangle" strokeWidth="1.8" transform="translate(47.940762, 57.060445) rotate(-90.000000) translate(-47.940762, -57.060445) "></path>
                    <g id="Group" transform="translate(43.446316, 43.546129)" fill="#012038" strokeLinejoin="round" strokeWidth="0.72">
                        <polygon class="bat_indi" points="5.6771955 0 0 12.9367811 4.1591425 12.9367811"></polygon>
                        <polygon class="bat_indi" transform="translate(6.150295, 17.557060) scale(-1, -1) translate(-6.150295, -17.557060) " points="8.98889287 11.0886695 3.31169737 24.0254507 7.47083988 24.0254507"></polygon>
                    </g>
                    <path d="M47.6374504,10.5317858 L49.7422224,10.5317858 C50.4049641,10.5317858 50.9422224,11.0690441 50.9422224,11.7317858 L50.9422224,27.3095716 C50.9422224,27.9723133 50.4049641,28.5095716 49.7422224,28.5095716 L47.6374504,28.5095716 C46.9747087,28.5095716 46.4374504,27.9723133 46.4374504,27.3095716 L46.4374504,11.7317858 C46.4374504,11.0690441 46.9747087,10.5317858 47.6374504,10.5317858 Z" id="Rectangle" strokeWidth="1.8" transform="translate(48.689836, 19.520679) scale(-1, 1) rotate(90.000000) translate(-48.689836, -19.520679) "></path>
                </g>
                <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                    <tspan x="479.138421" y="764.303287">{battery}%</tspan>
                </text>
                <g id="Group" transform="translate(702.362594, 764.309650)" stroke="#012038" strokeWidth="1.8">
                    <g id="icon/sun">
                        <rect id="Rectangle" x="10.4870417" y="36.038176" width="76.4055894" height="52.5556734" rx="2.4"></rect>
                        <ellipse id="Oval" cx="36.7046459" cy="62.3160127" rx="20.225009" ry="20.271474"></ellipse>
                        <g id="group_helice">
                            <g id="helice"  style={{transformOrigin: "center",transformBox: "fill-box"}} transform="translate(20.974083, 49.552492)">
                                <path d="M16.7091208,8.25467926 C16.9374629,7.00903543 17.5122852,6.19563096 18.4335877,5.81446584 C19.3548901,5.43330071 21.2884718,5.20891778 24.2343328,5.14131704 C20.7300064,1.0712512 17.4087186,-0.582298233 14.2704694,0.180668724 C11.1322201,0.94363568 10.7579691,3.63497253 13.1477163,8.25467926 L16.7091208,8.25467926 Z" id="Path-16" strokeLinejoin="round"></path>
                                <path d="M21.1972365,21.7584325 C21.4267024,20.5153844 22.0031871,19.7034606 22.9266906,19.322661 C23.8501941,18.9418614 25.7881059,18.7169931 28.7404262,18.6480561 C25.2305012,14.588612 21.9027999,12.9403334 18.7573223,13.7032203 C15.6118448,14.4661072 15.2354253,17.1517757 17.628064,21.7602259 L21.1972365,21.7584325 Z" id="Path-16" strokeLinejoin="round" transform="translate(22.408974, 17.641207) rotate(103.000000) translate(-22.408974, -17.641207) "></path>
                                <ellipse id="Oval" cx="15.3434604" cy="12.7635207" rx="3.74537203" ry="3.75397667"></ellipse>
                                <path d="M6.22397031,20.2648617 C6.45113226,19.0208075 7.02563882,18.2089289 7.94748999,17.8292259 C8.86934117,17.4495229 10.8047325,17.2275949 13.753664,17.1634419 C10.2409398,13.0936996 6.91421252,11.4381072 3.7734821,12.1966646 C0.632751682,12.955222 0.261177708,15.6432607 2.65876018,20.2607807 L6.22397031,20.2648617 Z" id="Path-16" strokeLinejoin="round" transform="translate(7.427762, 16.141382) rotate(223.000000) translate(-7.427762, -16.141382) "></path>
                            </g>
                        </g>
                        <line x1="23.2213066" y1="88.9692471" x2="23.2213066" y2="93.8494168" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="71.1620686" y1="89.3446448" x2="71.1620686" y2="93.8494168" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="79.401887" y1="49.552492" x2="65.9185477" y2="49.552492" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="79.401887" y1="55.5588547" x2="65.9185477" y2="55.5588547" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="79.401887" y1="61.5652174" x2="65.9185477" y2="61.5652174" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="79.401887" y1="67.5715801" x2="65.9185477" y2="67.5715801" id="Line-13" strokeLinecap="round" strokeLinejoin="round"></line>
                        <path d="M43.3998287,13.514316 C41.5889131,14.4579866 40.6180747,15.6297329 40.4873135,17.0295549 C40.2911717,19.1292879 40.8942043,19.6812036 41.697397,21.1655949 C42.5005897,22.6499863 43.6973251,23.6094335 43.3998287,26.1611477 C43.2014978,27.8622906 42.2306594,29.1525125 40.4873135,30.0318134" id="smoke_fan_1" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M50.8905728,13.514316 C49.0796571,14.4579866 48.1088187,15.6297329 47.9780576,17.0295549 C47.7819158,19.1292879 48.3849483,19.6812036 49.1881411,21.1655949 C49.9913338,22.6499863 51.1880692,23.6094335 50.8905728,26.1611477 C50.6922419,27.8622906 49.7214034,29.1525125 47.9780576,30.0318134" id="smoke_fan_2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M58.3813168,13.514316 C56.5704012,14.4579866 55.5995628,15.6297329 55.4688016,17.0295549 C55.2726599,19.1292879 55.8756924,19.6812036 56.6788851,21.1655949 C57.4820779,22.6499863 58.6788132,23.6094335 58.3813168,26.1611477 C58.1829859,27.8622906 57.2121475,29.1525125 55.4688016,30.0318134" id="smoke_fan_3" strokeLinecap="round" strokeLinejoin="round"></path>
                    </g>
                </g>
                <text id="135W" fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038"><tspan x="494.119909" y="692.226935">135W</tspan> </text>
                <g id="sun_zone">
                    <g id="sun_only">
                        <g id="the_SUN" style={{transformOrigin: "center",transformBox: "fill-box"}} stroke="#012038" strokeWidth="2.16">
                            <g>
                                <ellipse id="circle_Sun" cx="47.940762" cy="48.0509014" rx="17.9777857" ry="18.019088"></ellipse>
                                <g transform="translate(48.485196, 46.876414) rotate(-45.000000) translate(-48.485196, -46.876414) translate(8.033591, 45.186530)" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="68.3200067" y1="0.812124084" x2="80.9032083" y2="0.989784717"></line>
                                    <line x1="6.54286086e-13" y1="2.47881389" x2="12.58445" y2="2.47881389"></line>
                                </g>
                                <g id="Group" transform="translate(47.571548, 47.670168) rotate(-135.000000) translate(-47.571548, -47.670168) translate(6.532604, 46.344500)" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="69.4934377" y1="1.75038236" x2="82.0778877" y2="1.75038236"></line>
                                    <line x1="2.34107665e-12" y1="0.900954401" x2="12.58445" y2="0.900954401"></line>
                                </g>
                                <line x1="8.98889287" y1="47.300106" x2="20.9740834" y2="47.300106" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="74.9074406" y1="47.300106" x2="86.8926311" y2="47.300106" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="47.940762" y1="87.0922587" x2="47.940762" y2="75.0795334" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="47.940762" y1="21.0222694" x2="47.940762" y2="9.00954401" strokeLinecap="round" strokeLinejoin="round"></line>
                            </g>
                        </g>
                    </g>
                    
                    <line x1="902.5" y1="180.5" x2="553.5" y2="180.5" id="lineHori" stroke="#F9DE33" strokeWidth="6.75" fill="#012038" strokeLinecap="round"></line>
                    <line x1="553.25" y1="216.687876" x2="553.25" y2="181.233651" id="lineVertiL" stroke="#F9DE33" strokeWidth="6.75" fill="#012038" strokeLinecap="round"></line>
                    <line x1="902.36546" y1="219.983033" x2="902.36546" y2="180.941676" id="lineVertiR" stroke="#F9DE33" strokeWidth="6.75" fill="#012038" strokeLinecap="round"></line>
                    <line x1="747.5" y1="178.75" x2="747.5" y2="136.25" id="lineVertiC" stroke="#F9DE33" strokeWidth="6.75" fill="#012038" strokeLinecap="round"></line>


                    <g id="fluxCenter" transform="translate(747.752386, 155.662248) rotate(90.000000) translate(-747.752386, -155.662248) translate(729.774600, 153.409862)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="35.2064971" y2="2.252386" id="fluxCenter_1" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="fluxCenter"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="fluxCenter"></line>
                    </g>
                    <g id="fluxLeft" transform="translate(652.923683, 180.941676) rotate(180.000000) translate(-652.923683, -180.941676) translate(561.536606, 178.689290)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="182.025081" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="24.7194554" y1="2.252386" x2="23.2213066" y2="0.750795334" class="fluxLeft"></line>
                        <line x1="24.7194554" y1="2.252386" x2="23.2213066" y2="3.75397667" class="fluxLeft"></line>
                        <line x1="56.1805804" y1="2.252386" x2="54.6824316" y2="0.750795334" class="fluxLeft"></line>
                        <line x1="56.1805804" y1="2.252386" x2="54.6824316" y2="3.75397667" class="fluxLeft"></line>
                        <line x1="98.1287472" y1="2.252386" x2="96.6305984" y2="0.750795334" class="fluxLeft"></line>
                        <line x1="98.1287472" y1="2.252386" x2="96.6305984" y2="3.75397667" class="fluxLeft"></line>
                        <line x1="149.065807" y1="0.750795334" x2="150.563956" y2="2.252386" class="fluxLeft"></line>
                        <line x1="149.065807" y1="3.75397667" x2="150.563956" y2="2.252386" class="fluxLeft"></line>
                    </g>
                    <g class="fluxDown" transform="translate(553.252386, 198.209968) rotate(90.000000) translate(-553.252386, -198.209968) translate(535.274600, 195.957582)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="35.2064971" y2="2.252386"  strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="14.2324137" y1="2.252386" x2="12.7342649" y2="0.750795334" class="fluxDownSub"></line>
                        <line x1="14.2324137" y1="2.252386" x2="12.7342649" y2="3.75397667" class="fluxDownSub"></line>
                    </g>
                    <g id="fluxRight" transform="translate(749.554282, 178.689290)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <path d="M0,2.252386 L152.15251,2.252386 L152.811179,2.252386" strokeDasharray="3.00000011920929,7.5"></path>
                        <line x1="23.970381" y1="2.252386" x2="22.4722322" y2="0.750795334" class="fluxRight"></line>
                        <line x1="23.970381" y1="2.252386" x2="22.4722322" y2="3.75397667" class="fluxRight"></line>
                        <line x1="65.9185477" y1="2.252386" x2="64.4203989" y2="0.750795334" class="fluxRight"></line>
                        <line x1="65.9185477" y1="2.252386" x2="64.4203989" y2="3.75397667" class="fluxRight"></line>
                        <line x1="107.866714" y1="2.252386" x2="106.368566" y2="0.750795334" class="fluxRight"></line>
                        <line x1="107.866714" y1="2.252386" x2="106.368566" y2="3.75397667" class="fluxRight"></line>
                    </g>
                    <g class="fluxDown" transform="translate(902.365460, 202.714740) rotate(90.000000) translate(-902.365460, -202.714740) translate(884.387675, 200.462354)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="35.2064971" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="14.2324137" y1="2.252386" x2="12.7342649" y2="0.750795334" class="fluxDownSub"></line>
                        <line x1="14.2324137" y1="2.252386" x2="12.7342649" y2="3.75397667" class="fluxDownSub"></line>
                    </g>
                    
                </g>
                <g id="elecZone">
                    <polyline stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" points="490.374537 546.579003 490.374537 517.856401 490.374537 495.52492"></polyline>
                    <g id="borne_charge_flux" transform="translate(490.374537, 524.055143) rotate(90.000000) translate(-490.374537, -524.055143) translate(465.655082, 521.802757)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="48.6898364" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="26.2176042" y1="2.252386" x2="24.7194554" y2="0.750795334" class="borne_charge_flux"></line>
                        <line x1="26.2176042" y1="2.252386" x2="24.7194554" y2="3.75397667" class="borne_charge_flux"></line>
                    </g>
                    <polyline stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" points="553.293085 388.911983 553.293085 366.94764 553.293085 349.870626"></polyline>
                    <g id="panel_solar_flux" transform="translate(553.293085, 369.391304) rotate(90.000000) translate(-553.293085, -369.391304) translate(534.566225, 367.138918)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="-9.82831062e-12" y1="2.252386" x2="37.4537203" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="24.7194554" y1="2.252386" x2="23.2213066" y2="0.750795334" class="panel_solar_flux"></line>
                        <line x1="24.7194554" y1="2.252386" x2="23.2213066" y2="3.75397667" class="panel_solar_flux"></line>
                    </g>
                    <line x1="99.3576974" y1="640.42842" x2="261.157769" y2="640.42842" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <g id="grid_flux" transform="translate(101.604921, 638.176034)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="155.058402" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="66.6676221" y1="2.252386" x2="65.1694733" y2="0.750795334" class="grid_flux"></line>
                        <line x1="66.6676221" y1="2.252386" x2="65.1694733" y2="3.75397667" class="grid_flux"></line>
                        <line x1="108.615789" y1="2.252386" x2="107.11764" y2="0.750795334" class="grid_flux"></line>
                        <line x1="108.615789" y1="2.252386" x2="107.11764" y2="3.75397667" class="grid_flux"></line>
                    </g>
                    <text fontFamily="Montserrat-Regular, Montserrat" fontSize="15" fontWeight="normal" fill="#012038">
                        <tspan x="81.758748" y="670">{car*8}W</tspan>
                    </text>

                    <line x1="573.269021" y1="822.120891" x2="692.119073" y2="822.120892" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(632.694047, 822.120891) rotate(180.000000) translate(-632.694047, -822.120891) "></line>
                    <g id="bat_rad_flux" transform="translate(583.750000, 819.868505)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0" y1="2.13149523" x2="105" y2="2.13149523" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="13.9764421" y1="2.252386" x2="12.4782933" y2="0.750795334" class="bat_rad_flux"></line>
                        <line x1="13.9764421" y1="2.252386" x2="12.4782933" y2="3.75397667" class="bat_rad_flux"></line>
                        <line x1="55.9246089" y1="2.252386" x2="54.4264601" y2="0.750795334" class="bat_rad_flux"></line>
                        <line x1="55.9246089" y1="2.252386" x2="54.4264601" y2="3.75397667" class="bat_rad_flux"></line>
                        <line x1="97.872775" y1="2.252386" x2="96.3746268" y2="0.750795334" class="bat_rad_flux"></line>
                        <line x1="97.8727756" y1="2.252386" x2="96.3746268" y2="3.75397667" class="bat_rad_flux"></line>
                    </g>
                </g>

                <g transform="translate(533.071778, 388.911983)">
                    <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g id="Group" transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon id="Path-13" transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g id="Group-3" transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677" id="Line-30"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134" id="Line-30"></line>
                        </g>
                    </g>
                </g>
                <g transform="translate(533.071778, 684.725345)">
                    <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g id="Group" transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon id="Path-13" transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g id="Group-3" transform="translate(13.483339, 4.451144)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="0.428042518" y1="0.5" x2="5.56455273" y2="0.5" id="Line-30"></line>
                        </g>
                    </g>
                </g>
                <g transform="translate(531.573630, 797.344645)">
                    <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g id="Group" transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon id="Path-13" transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g id="Group-3" transform="translate(13.483339, 4.451144)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="0.428042518" y1="0.5" x2="5.56455273" y2="0.5" id="Line-30"></line>
                        </g>
                    </g>
                </g>
                <g id="Group-3-Copy-3" transform="translate(663.410725, 433.959703)">
                    <ellipse id="Oval" stroke="#E99952" strokeWidth="1.5" fill="#E99952" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g id="Group" transform="translate(9.487042, 10.511135)" stroke="#FFFFFF" strokeWidth="0.5">
                        <g>
                            <path d="M12,1.875 C12.8284271,1.875 13.5,2.54657288 13.5,3.375 L13.4999708,14.93702 C14.824415,15.5156808 15.75,16.8372494 15.75,18.375 C15.75,20.4460678 14.0710678,22.125 12,22.125 C9.92893219,22.125 8.25,20.4460678 8.25,18.375 C8.25,16.8376334 9.17512285,15.5163408 10.4990372,14.9374536 L10.5,3.375 C10.5,2.54657288 11.1715729,1.875 12,1.875 Z" id="Combined-Shape"></path>
                            <circle id="Oval" cx="12" cy="18.375" r="1.5"></circle>
                            <line x1="12" y1="16.875" x2="12" y2="5.625" id="Line-19" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="14.625" y1="9" x2="17.625" y2="9" id="Line-19" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="14.625" y1="7.875" x2="16.125" y2="7.875" id="Line-19" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="14.625" y1="10.125" x2="16.125" y2="10.125" id="Line-19-Copy" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="14.625" y1="11.25" x2="17.625" y2="11.25" id="Line-19" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="14.625" y1="12.375" x2="16.125" y2="12.375" id="Line-19" strokeLinecap="round" strokeLinejoin="round"></line>
                        </g>
                    </g>
                </g>

                <g id="waterZone">
                    
                    <ellipse class="waterHotBall_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="902" cy="490" rx="6" ry="7"></ellipse>
                    <ellipse class="waterHotBall_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="902" cy="445" rx="6" ry="7"></ellipse>
                    <ellipse class="waterHotBall_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="902" cy="400" rx="6" ry="7"></ellipse>
                    <ellipse class="waterHotBall_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="902" cy="354" rx="6" ry="7"></ellipse>
                    
                    <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="816" cy="595" rx="6" ry="7"></ellipse>
                    <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="816" cy="555" rx="6" ry="7"></ellipse>
                    <ellipse class="water_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="816" cy="505" rx="6" ry="7"></ellipse>

                    <ellipse class="radia_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="856" cy="603" rx="7" ry="6"></ellipse>
                    <ellipse class="radia_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="816" cy="603" rx="7" ry="6"></ellipse>
                    <ellipse class="radia_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="776" cy="603" rx="7" ry="6"></ellipse>
                   
                    <ellipse class="thermo_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="684" cy="528" rx="6" ry="7"></ellipse>
                    <ellipse class="thermo_flux" stroke="#E99952" strokeWidth="1" fill="#E99952" cx="684" cy="502" rx="6" ry="7"></ellipse>
                    

                    <line x1="902.5" y1="349.5" x2="902.36546" y2="529.31071" stroke="#E99952" strokeWidth="6.75" strokeLinecap="round"></line>
                    <line x1="877.788842" y1="537.569459" x2="753.156817" y2="537.569459" id="Line-27" stroke="#E99952" strokeWidth="6.75" strokeLinecap="round" transform="translate(815.472829, 537.569459) scale(-1, -1) rotate(90.000000) translate(-815.472829, -537.569459) "></line>
                   
                    <line x1="684.384808" y1="473.00106" x2="684.384808" y2="533.064687"  stroke="#E99952" strokeWidth="6.75" strokeLinecap="round"></line>
                    <line x1="860.417294" y1="602.888653" x2="739.06724" y2="602.888653" stroke="#E99952" strokeWidth="6.75" strokeLinecap="round" transform="translate(799.742267, 602.888653) scale(-1, 1) translate(-799.742267, -602.888653)"></line>
                </g>
                <g id="verticalZone">
                    <line x1="902.861758" y1="653.942736" x2="902.861758" y2="822.120891" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(902.861758, 738.031813) rotate(180.000000) translate(-902.861758, -738.031813) "></line>
                    <g id="vertical_fan" transform="translate(902.861758, 737.281018) rotate(-90.000000) translate(-902.861758, -737.281018) translate(821.961722, 735.028632)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0" y1="2.252386" x2="161" y2="2.252386" strokeDasharray="3,7.5"></line>
                        <line x1="20.7046459" y1="2.252386" x2="20.2064971" y2="0.750795334" class="vertical_fan"></line>
                        <line x1="20.7046459" y1="2.252386" x2="20.2064971" y2="3.75397667" class="vertical_fan"></line>
                        <line x1="67.1398543" y1="2.252386" x2="67.6417055" y2="3.75397667" class="vertical_fan"></line>
                        <line x1="67.6417055" y1="0.750795334" x2="67.1398543" y2="2.252386" class="vertical_fan"></line>
                        <line x1="121.575063" y1="2.252386" x2="120.076914" y2="0.750795334" class="vertical_fan"></line>
                        <line x1="121.575063" y1="2.252386" x2="120.076914" y2="3.75397667" class="vertical_fan"></line>
                    </g>

                    <line x1="811.5" y1="822.25" x2="902.5" y2="822.25" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(857.000000, 822.250000) rotate(180.000000) translate(-857.000000, -822.250000) "></line>
                    <g id="horizontal_fan" transform="translate(813.225606, 819.868505)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="89.1398543" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="0.750795334" class="horizontal_fan"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="3.75397667" class="horizontal_fan"></line>
                        <line x1="89.1398543" y1="2.252386" x2="87.6417055" y2="0.750795334" class="horizontal_fan"></line>
                        <line x1="89.1398543" y1="2.252386" x2="87.6417055" y2="3.75397667" class="horizontal_fan"></line>
                    </g>
                

                    <line x1="491.872686" y1="706.498409" x2="535.319002" y2="706.498409" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(513.595844, 706.498409) rotate(180.000000) translate(-513.595844, -706.498409) "></line>
                    <line x1="477.607575" y1="720.763521" x2="513.137797" y2="720.763521" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round" transform="translate(491.872686, 720.763521) rotate(90.000000) translate(-491.872686, -720.763521) "></line>
                    <g id="vertical_bat" transform="translate(491.872686, 726.019088) rotate(-270.000000) translate(-491.872686, -726.019088) translate(475.393049, 723.766702)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="32.9592739" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="vertical_bat"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="vertical_bat"></line>
                    </g>
                    <g id="horizontal_bat" transform="translate(512.097695, 706.498409) rotate(-180.000000) translate(-512.097695, -706.498409) translate(493.370835, 704.246023)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="4.00251532e-11" y1="2.252386" x2="37.4537203" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="14.2324137" y1="2.252386" x2="12.7342649" y2="0.750795334" class="horizontal_bat"></line>
                        <line x1="14.2324137" y1="2.252386" x2="12.7342649" y2="3.75397667" class="horizontal_bat"></line>
                    </g>


                    <line x1="339.061507" y1="530.812301" x2="455.917115" y2="530.812301" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <line x1="455.917115" y1="547.329799" x2="455.917115" y2="530.812301" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <line x1="339.061507" y1="592.377519" x2="339.061507" y2="530.812301" stroke="#8BDAC4" strokeWidth="6.75" strokeLinecap="round"></line>
                    <g id="centerCarPipe" transform="translate(398.238385, 530.812301) rotate(-180.000000) translate(-398.238385, -530.812301) translate(344.305028, 528.559915)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="107.11764" y2="2.252386"  strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="35.2064971" y1="2.252386" x2="33.7083483" y2="0.750795334" class="centerCarPipe"></line>
                        <line x1="35.2064971" y1="2.252386" x2="33.7083483" y2="3.75397667" class="centerCarPipe"></line>
                        <line x1="77.1546638" y1="2.252386" x2="75.656515" y2="0.750795334" class="centerCarPipe"></line>
                        <line x1="77.1546638" y1="2.252386" x2="75.656515" y2="3.75397667" class="centerCarPipe"></line>
                    </g>
                    <g id="rightCarPipe" transform="translate(455.917115, 538.320255) rotate(270.000000) translate(-455.917115, -538.320255) translate(447.677296, 536.067869)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="15.7305625" y2="2.252386"  strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="5.24352084" y1="2.252386" x2="3.74537203" y2="0.750795334" class="rightCarPipe"></line>
                        <line x1="5.24352084" y1="2.252386" x2="3.74537203" y2="3.75397667" class="rightCarPipe"></line>
                    </g>
                    <g id="leftCarPipe" transform="translate(339.061507, 563.096501) rotate(450.000000) translate(-339.061507, -563.096501) translate(307.600382, 560.844115)" stroke="#012038" strokeLinecap="round" strokeWidth="1.5">
                        <line x1="0.749074406" y1="2.252386" x2="62.1731757" y2="2.252386" strokeDasharray="3.00000011920929,7.5"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="0.750795334" class="leftCarPipe"></line>
                        <line x1="15.7305625" y1="2.252386" x2="14.2324137" y2="3.75397667" class="leftCarPipe"></line>
                        <line x1="47.1916876" y1="2.252386" x2="45.6935388" y2="3.75397667" class="leftCarPipe"></line>
                        <line x1="45.6935388" y1="0.750795334" x2="47.1916876" y2="2.252386" class="leftCarPipe"></line>
                    </g>
                </g>
                <g transform="translate(853.675624, 539.071050)" stroke="#012038">
                    <path d="M28.4648274,22.52386 C28.4648274,17.0202265 35.0014062,14.2684097 48.0745637,14.2684097 C61.1477212,14.2684097 67.6842999,17.0202265 67.6842999,22.52386 L67.6842999,84.7170745 C67.9606203,87.4639179 61.4240415,88.8373396 48.0745637,88.8373396 C34.7250859,88.8373396 28.1885071,87.4639179 28.4648274,84.7170745 L28.4648274,22.52386 Z" id="Path-14" strokeWidth="1.8" fill="#FFFFFF"></path>
                    <ellipse id="Oval" strokeWidth="1.8" cx="47.940762" cy="29.281018" rx="8.23981846" ry="8.25874867"></ellipse>
                    <line x1="47.940762" y1="29.281018" x2="50.9370596" y2="26.2778367" id="Line" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"></line>
                    <path d="M47.9387056,58.5620361 C44.7996378,62.9331985 43.090329,65.8836877 42.8107793,67.4135037 C42.0367659,71.6492371 45.381864,73.5779427 47.9387056,73.5779427 C50.5178535,73.5779427 53.836403,71.617772 53.0728554,67.4135037 C52.7963725,65.8911253 51.0849893,62.9406361 47.9387056,58.5620361 Z" id="Path-20" strokeWidth="1.8" strokeLinejoin="round"></path>
                    <path d="M45.6935388,69.0731707 C45.6990572,69.5640209 45.8252826,69.9369986 46.0722148,70.1921037 C46.319147,70.4472088 46.6923046,70.5747614 47.1916876,70.5747614" id="Path-21-Copy" strokeWidth="1.2" strokeLinecap="round"></path>
                    <path d="M33.0941072,88.4347133 C33.0941072,89.4391903 33.0941072,90.4436672 33.0941072,91.4481442 C33.0941072,92.1108859 33.6313655,92.6481442 34.2941072,92.6481442 L39.3848513,92.6481442 C40.047593,92.6481442 40.5848513,92.1108859 40.5848513,91.4481442 C40.5848513,90.7342878 40.5848513,90.0204315 40.5848513,89.3065751 C40.5848513,88.616953 40.5848513,88.8373396 37.0291703,88.6357727 C33.4734892,88.4342058 33.0941072,88.2169351 33.0941072,88.4347133 Z" id="Path-35" strokeWidth="1.8"></path>
                    <path d="M55.5513579,88.6845749 C55.5513579,89.6890518 55.5513579,90.6935288 55.5513579,91.6980058 C55.5513579,92.3607475 56.0886162,92.8980058 56.7513579,92.8980058 L61.842102,92.8980058 C62.5048437,92.8980058 63.042102,92.3607475 63.042102,91.6980058 C63.042102,90.9841494 63.042102,90.270293 63.042102,89.5564367 C63.042102,88.8668146 63.042102,89.0872012 59.486421,88.8856343 C55.9307399,88.6840674 55.5513579,88.4667967 55.5513579,88.6845749 Z" id="Path-35" strokeWidth="1.8" transform="translate(59.296730, 90.745928) scale(-1, 1) translate(-59.296730, -90.745928) "></path>
                </g>
                <g id="goutte" transform="translate(792.251523, 433.959703)">
                    <ellipse id="Oval" stroke="#E99952" strokeWidth="1.5" fill="#E99952" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g id="Group" transform="translate(10.487042, 9.511135)" stroke="#FFFFFF" strokeWidth="0.5">
                        <g id="icon/sun">
                            <path d="M12,4.50493348 C8.83151592,8.76182205 7.1061892,11.6351776 6.82401982,13.125 C6.04275312,17.25 9.4191982,19.1282841 12,19.1282841 C14.6033171,19.1282841 17.9529648,17.2193575 17.182262,13.125 C16.9031882,11.6424207 15.1757675,8.76906518 12,4.50493348 Z" id="Path-20" strokeLinejoin="round"></path>
                            <path d="M9.0042071,15.3857806 C9.00968896,15.8725868 9.13507693,16.2424916 9.38037103,16.4954949 C9.62566512,16.7484983 9.99634719,16.875 10.4924172,16.875" id="Path-21-Copy" strokeLinecap="round"></path>
                        </g>
                    </g>
                </g>
                
                <g transform="translate(467.153231, 457.985154)">
                    <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g id="Group" transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon id="Path-13" transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g id="Group-3" transform="translate(13.483339, 4.451144)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="0.428042518" y1="0.5" x2="5.56455273" y2="0.5" id="Line-30"></line>
                        </g>
                    </g>
                </g>
                <g transform="translate(260.408695, 617.153765)">
                    <ellipse id="Oval" stroke="#8BDAC4" strokeWidth="1.5" fill="#8BDAC4" cx="22.4722322" cy="22.52386" rx="22.4722322" ry="22.52386"></ellipse>
                    <g transform="translate(11.985190, 10.511135)" stroke="#FFFFFF">
                        <g id="Group" transform="translate(4.494446, 2.252386)" fill="#FFFFFF" strokeLinejoin="round" strokeWidth="0.6">
                            <polygon id="Path-13" points="4.49444643 0 0 10.5111347 3.29265448 10.5111347"></polygon>
                            <polygon id="Path-13" transform="translate(4.868984, 14.265111) scale(-1, -1) translate(-4.868984, -14.265111) " points="7.11620686 9.00954401 2.62176042 19.5206787 5.9144149 19.5206787"></polygon>
                        </g>
                        <g id="Group-3" transform="translate(13.483339, 1.876988)" strokeLinecap="round" strokeWidth="0.5">
                            <line x1="2.99629762" y1="0.429025905" x2="2.99629762" y2="5.57733677" id="Line-30"></line>
                            <line x1="0.428042518" y1="3.00318134" x2="5.56455273" y2="3.00318134" id="Line-30"></line>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

  );
}
export default Animation;