import { withTranslation } from 'react-i18next';
// Chakra imports
import {Box,Flex,Text,useColorModeValue } from "@chakra-ui/react";

import React from "react";
import { TwoLayerDonutChart } from "./TwoLayerDonutChart";

function Donut(props) {
  const textColor = useColorModeValue("navy.800","#ffffff");
  React.useEffect(() => {  

    return () => {} 
  },[]);

  return (
      <Flex justify="flex-end" zIndex='0' >
        <Box align={"center"} justify={"center"}>
          <TwoLayerDonutChart data={props.data}/>
          <Text mt={-15} fontSize='md' fontWeight={"bold"} color={textColor}>{props.title}</Text>
        </Box>
      </Flex>
  );
}

export default withTranslation()(Donut);