import * as React from "react";
import * as d3 from "d3";


const createArc = d3.arc().innerRadius(0).outerRadius(54*2.25);
const createArc2 =d3.arc().innerRadius(0).outerRadius(63*3.1);

export const TwoLayerDonutChart = (props) => {
  const height = 280;
  const width = 280;
  let dataInner =[props.data.innerSub,props.data.inner];
  if(props.data.inner>props.data.innerSub)dataInner =[0,0]

  let pie = d3.pie()(dataInner);

  return (
    <svg height={height} width={width}>
      <g transform={`translate(${width / 2},${height / 2})`}>
        <Slice pie={pie} data={props.data}/>
      </g>
    </svg>
  );
};

const Slice = props => {
  let { pie } = props;

  let interpolateInner = d3.interpolateRgb("#8BDAC4", "#0068b6");{/*vert claire / bleu */}

  return pie.map((slice, index) => {
    let sliceColor = interpolateInner(index / (pie.length - 1));
    return (
        <g key={index}>
            <Path
                radius={100}
                interpolate={interpolateInner}
                slice={slice}
                data={props.data}
                sliceColor={sliceColor}
            />
            <text 
                transform={`translate(${createArc.centroid(slice)})`}
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="15"
                fontWeight="bold"
                fill="white">
                {slice.value+"%"}
            </text>
        </g>
    );
  });
};

class Path extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isHovered: false };
    }

    onMouseOver = () => {this.setState({isHovered: true});};
    onMouseOut = () => {this.setState({isHovered: false});};


    render() {
        let { radius, slice, sliceColor} = this.props;


        var dataOuter = [
            this.props.data.outner,
            this.props.data.outnerSub_1,
            this.props.data.outnerSub_2
        ]
        /*if(this.props.data.outnerSub_1>this.props.data.outnerSub_2)
            dataOujter = [
                this.props.data.outner,
                this.props.data.outnerSub_2,
                this.props.data.outnerSub_1
            ]*/

        const outerRadius = this.state.isHovered ? radius * .9 : radius* .8;
        const innerRadius = radius * 0.4;

        const arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .padAngle(0.0002)
        .cornerRadius(2);

        let outterPie = d3.pie();

        const arc2 = d3.arc()
        .innerRadius(outerRadius * 1.0)
        .outerRadius(outerRadius * 1.4)
        .padAngle(0.0005)
        .cornerRadius(0);


        return (<g> 
            <path
                d={arc(slice)}
                fill={sliceColor}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
            />
            <g>
                <path d={arc2(outterPie(dataOuter)[0])} fill={"#E99952"} />{/*orange*/}
                <text 
                    transform={`translate(${(createArc2.centroid(outterPie(dataOuter)[0]))})`}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontSize="13"
                    fontWeight="bold"
                    fill="black">
                    {outterPie(dataOuter)[0].value+"%"}
                </text>
            </g>
            <g>
                <path d={arc2(outterPie(dataOuter)[1])} fill={"#F9DE33"} />{/*jaune*/}
                <text 
                    transform={`translate(${(createArc2.centroid(outterPie(dataOuter)[1]))})`}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontSize="13"
                    fontWeight="bold"
                    fill="black">
                    {outterPie(dataOuter)[1].value+"%"}
                </text>
            </g>
            <g>
                <path d={arc2(outterPie(dataOuter)[2])} fill={"#8BDAC4"} />{/*vert claire*/}
                {this.props.data.inner>this.props.data.innerSub?<text 
                    transform={`translate(${(createArc2.centroid(outterPie(dataOuter)[2]))})`}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontSize="13"
                    fontWeight="bold"
                    fill="black">
                    {outterPie(dataOuter)[2].value+"%"}
                </text>:null}
            </g>
            
            {this.state.isHovered && (<circle r={innerRadius * .1} fill={sliceColor} />)}
        </g>);
    }
}
