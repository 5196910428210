import React,{useEffect} from "react";
import { withTranslation } from 'react-i18next';
import {useDisclosure} from "@chakra-ui/hooks";
import * as R from 'ramda';
import ReactApexChart from "react-apexcharts";
import moment from "moment"; 
import 'moment/locale/fr' 
import TimeRange from "../TimeLine";
import {set} from "date-fns";
import {Flex, Text, useColorModeValue,Box,
    ButtonGroup,Button,FormLabel,
    Spacer,NumberInputField,Switch,
    Stack,NumberInput,IconButton
} from "@chakra-ui/react";
import {BsCheckLg} from "react-icons/bs";
import {AiOutlineCloseCircle} from "react-icons/ai";
import ModalTitle from "../ModalTitle";

function PlaningLine(props) {
    var { title,t, onUpdateProgramList,programList,weeklyForecasts,errorMsg } = props;
    var _programList=[], _selectedInterval={},_listRange={},_setpoint={},_error={}
    const now = new Date()
    const getTodayAtSpecificHour = (hour = 12,end=false) => set(now, { hours: hour, minutes: !end?0:59, seconds: !end?0:59, milliseconds: !end?0:59 })

    const selectedStart = getTodayAtSpecificHour(22)
    const selectedEnd = getTodayAtSpecificHour(23,true)
    
    var [curSensorId, setCurSensorId] =  React.useState("");
    var [error, setError] =  React.useState(false);
    const [values, setValues] = React.useState({
      id: "",
      isManual:true,
      setpoint:"",
      title:"",
      selectedInterval:[selectedStart, selectedEnd],
      isLoading:false
    });
    var [listRange, setListRange] =  React.useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure()

    const textColor = useColorModeValue("navy.700", "white");

    useEffect(async () => { 
        if(programList.hasOwnProperty(title)){
            _programList=programList
            for (let i = 0; i < _programList.length; i++) {
                _programList[i].start=getTodayAtSpecificHour(moment(_programList[i].start).format("HH"))
                _programList[i].end=getTodayAtSpecificHour(moment(_programList[i].end).format("HH"))
            }
            setListRange(_programList[title])
        }

        if(props.hasOwnProperty("mainSensors")){
            props.mainSensors.forEach(sensor => {
                _listRange[sensor._id]=[]
                _setpoint[sensor._id]=""
                _error[sensor._id]=false
                _selectedInterval[sensor._id]=[selectedStart, selectedEnd]
            });

            if(programList.hasOwnProperty(title)){
                for (const sensor_id in programList[title]) {
                    if(!_listRange.hasOwnProperty(sensor_id))_listRange[sensor_id]=[]

                    _listRange[sensor_id]=programList[title][sensor_id]
                    for (let i = 0; i < _listRange[sensor_id].length; i++) {
                        _listRange[sensor_id][i].start= getTodayAtSpecificHour(moment(_listRange[sensor_id][i].start).format("HH"))
                        _listRange[sensor_id][i].end= getTodayAtSpecificHour(moment(_listRange[sensor_id][i].end).format("HH"))
                    }
                }
            }
            setError(_error)
            setListRange(_listRange)
            setValues({...values,setpoint:_setpoint,selectedInterval:_selectedInterval})
        }
    },[])

    const errorHandler=(err)=>{ 
        setError(err.error)
        if(err.time) setValues({...values,selectedInterval:err.time})
    }
    const onChangeCallback=(interval)=>{setValues({...values,selectedInterval:interval})}
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: Number(event.target.value)>100?100:Number(event.target.value) });
    };

    const handleSubmit = async (e) => { 
        e.preventDefault();
        let item={
            id:(new Date()).getTime(),
            title:moment(values.selectedInterval[0]).format("HH:mm")+" - "+moment(values.selectedInterval[1]).format("HH:mm")+", "+values.setpoint+"%",
            start:values.selectedInterval[0],
            end:values.selectedInterval[1],
            setpoint:values.setpoint
        }
        let _listRange=[...listRange,item]
        setListRange(_listRange)

        _programList=programList
        _programList[title]=_listRange
        setError(true)

        onUpdateProgramList(_programList)
    }

    const bateryView=()=>{
        return <Box mt={5}>
            <Flex justify='space-between'>
                <Text
                    color={textColor}
                    fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                    }}
                    fontWeight='bold'>
                    {t(title)}
                </Text>
                <Flex w={"85%"}>
                    <Flex 
                        direction='row'
                        w={"85%"}
                        justifyContent={"center"}
                    >
                        <Flex
                            zIndex='2'
                            direction='column'
                            mt="25px"
                            mx={{ base: "auto", lg: "unset" }}
                            mb={{ base: "2px", md: "auto" }}>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}>
                                {values.selectedInterval?moment(values.selectedInterval[0]).format("HH:mm"):null }
                            </FormLabel>
                            <Spacer />
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}>
                                {values.selectedInterval?moment(values.selectedInterval[1]).format("HH:mm"):null}
                            </FormLabel>
                        </Flex>
                        <Box w={"100%"}>
                            <Flex 
                                w={"15%"}
                                display='flex'
                                alignItems='center'
                            >
                                <Switch
                                    isChecked={values.isManual}
                                    onChange={(val)=>{setValues({ ...values, 'isManual': val.target.checked})}}
                                />
                                <p>{values.isManual?t("Manual"):t("Automatic") }</p>
                            </Flex>
                            <TimeRange
                                error={error}  
                                ticksNumber={6}  
                                disabled={values.isManual}
                                selectedInterval={values.selectedInterval}  
                                disabledIntervals={listRange}  
                                timelineInterval={[getTodayAtSpecificHour(0), getTodayAtSpecificHour(23,true)]}  
                                onUpdateCallback={errorHandler}  
                                onChangeCallback={onChangeCallback}
                            />
                        </Box>
                    </Flex>
                    {values.isManual?<Flex 
                        direction='row'
                        align={"center"}
                        ml={25}
                        w={"15%"}
                    >
                        <NumberInput  min={0} max={100}>
                            <NumberInputField
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                mb={"3px"}
                                placeholder={"Charge %"}
                                fontWeight='500'
                                size='md'
                                value={values.setpoint}
                                onChange={handleChange('setpoint')}
                            />
                        </NumberInput>
                        {!error && Number(values.setpoint)?<IconButton
                            colorScheme='green'
                            ml={2}
                            onClick={handleSubmit}
                            aria-label={values.id===""?t("add"):t("update")}
                            icon={<BsCheckLg/>}
                        />:null}
                    </Flex>:null}
                </Flex>
            </Flex>
            <Stack direction='row' justify='flex-end' mt={2} mb={1}>
                {
                    listRange.map((range)=>{
                        return(<ButtonGroup key={range.id} size='sm' isAttached variant='outline'>
                            <Button 
                                colorScheme="teal"
                                onClick={()=>{
                                    if(values.isManual){
                                        setValues({...values,
                                            title:range.title,
                                            id:range.id
                                        })
                                        onOpen()
                                    }
                                }}
                            >{range.title}</Button>
                            {values.isManual?<IconButton
                                colorScheme="red"
                                icon={<AiOutlineCloseCircle />}
                                onClick={()=>{
                                    setListRange(R.filter(o => o.id !== range.id, listRange))
                                }}
                            />:null}
                        </ButtonGroup>)
                    })
                }
            </Stack>

            {values.isManual?null:<ReactApexChart
                key= {0}
                options={{
                    chart: {
                        id:9,
                        toolbar: {show: false},
                    },
                    colors:["#77cb10"],
                    dataLabels: {enabled: false},
                    stroke: {
                        width: 2,
                        curve:"smooth",// 'smooth' straight,
                    },
                    title: {
                        text: "Prévisions (dni)",
                        offsetX: 0,
                        style: {
                            fontSize: '14px',
                            color:textColor
                        }
                    },
                    xaxis: {
                        type: 'datetime',
                        categories:weeklyForecasts.hasOwnProperty("x")?weeklyForecasts.x:[],
                        show: true,
                        labels: {
                            style: {colors: "#0068b6",fontSize: "9px"},
                            datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM HH:mm',
                            hour: 'HH:mm'
                            }
                        }
                    },
                    yaxis: {
                        tickAmount: 4,
                        floating: false,
                        axisBorder: {show: false},
                        axisTicks: {show: false},
                        labels: {
                            style: {
                            colors: '#8e8da4',
                            fontSize:"9px"
                            },
                            formatter: function (value) {
                                return   Number(value)>999?Math.round(value/1000)+"k":Math.round(value);
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                        fillSeriesColor: false,
                        theme: "light",
                        x: {
                            show: false,
                            format: 'HH:mm'//'dd MMM, HH:mm',
                                
                        },
                        y: { title: { formatter: (seriesName) => "" },},
                    },
                    annotations: {
                        yaxis: []
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], 
                            opacity: 0.5
                    },
                    },
                    fill: {
                        type: 'solid',
                        opacity:0.4
                    },
                    legend: {show: false}
                }}
                series={[{
                    data:weeklyForecasts.hasOwnProperty("y")?weeklyForecasts.y:[]
                }]}
                height={160}
                type="area"
            />}
            <hr/>
            <ModalTitle
                isOpen={isOpen} 
                onClose={onClose}
                label={values.title}
                getTitle={(val)=>{
                    var _listRange=listRange
                    for (let i = 0; i < _listRange.length; i++) {
                    if( _listRange[i].id===values.id){
                        _listRange[i].title=val
                        break
                    }
                    }
                    setListRange(listRange)
                }}
            />
        </Box>
    }
    const OnOffView=()=>{
        return <Box mt={5}>
            <Box justify='space-between'>
                <Text
                    color={textColor}
                    fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                    }}
                    fontWeight='bold'>
                    {t(title)}
                </Text>
                
                {props.mainSensors.map((sensor,index)=>{
                    return(<Box key={index}>
                        <Flex 
                            align={"center"}
                        >
                            <Flex w={"10%"}/>
                            <Flex 
                                direction='row'
                                w={"85%"}
                                justifyContent={"center"}
                            >
                                <Flex
                                    zIndex='2'
                                    direction='column'
                                    mt="40px"
                                    mx={{ base: "auto", lg: "unset" }}
                                    mb={{ base: "2px", md: "auto" }}>
                                    <FormLabel
                                        display='flex'
                                        fontSize='xs'
                                        fontWeight='500'
                                        color={textColor}>
                                        {values.selectedInterval[sensor._id]?moment(values.selectedInterval[sensor._id][0]).format("HH:mm"):null }
                                    </FormLabel>
                                    <Spacer />
                                    <FormLabel
                                        display='flex'
                                        fontSize='xs'
                                        fontWeight='500'
                                        color={textColor}>
                                        {values.selectedInterval[sensor._id]?moment(values.selectedInterval[sensor._id][1]).format("HH:mm"):null}
                                    </FormLabel>
                                </Flex>
                                <Box w={"100%"}>
                                    <FormLabel
                                        display='flex'
                                        fontSize='sm'
                                        fontWeight='500'
                                        color={textColor}>
                                        {sensor.label}
                                    </FormLabel>
                                    <TimeRange
                                        error={error[sensor._id]}  
                                        ticksNumber={6}  
                                        selectedInterval={values.selectedInterval[sensor._id]}  
                                        disabledIntervals={listRange[sensor._id]}  
                                        timelineInterval={[getTodayAtSpecificHour(0), getTodayAtSpecificHour(23,true)]}  
                                        onUpdateCallback={(err)=>{ 
                                            _error=error
                                            _error[sensor._id]=err.error
                                            setError(_error)
                                            _selectedInterval=values.selectedInterval
                                            _selectedInterval[sensor._id]=err.time
                                            if(err.time) setValues({...values,selectedInterval:_selectedInterval})}
                                        }  
                                        onChangeCallback={(interval)=>{
                                            _selectedInterval=values.selectedInterval
                                            _selectedInterval[sensor._id]=interval
                                            setValues({...values,selectedInterval:_selectedInterval})}
                                        }
                                    />
                                </Box>
                            </Flex>

                            {props.secondSensors[index]?
                            <Flex 
                                direction='row'
                                align={"center"}
                                ml={25}
                                w={"15%"}
                            >
                                <NumberInput  min={0} max={100}>
                                    <NumberInputField
                                        fontSize='sm'
                                        ms={{ base: "0px", md: "0px" }}
                                        mb={"3px"}
                                        placeholder={sensor.unit}
                                        fontWeight='500'
                                        size='md'
                                        value={values.setpoint[sensor._id]}
                                        onChange={(val)=>{
                                            _setpoint=values.setpoint
                                            _setpoint[sensor._id]=val.target.value
                                            setValues({...values,setpoint:_setpoint})}
                                        }
                                    />
                                </NumberInput>
                                {!error[sensor._id] && Number(values.setpoint[sensor._id])?<IconButton
                                    colorScheme='green'
                                    ml={2}
                                    onClick={()=>{
                                        _listRange=listRange
                                        if(!listRange.hasOwnProperty(sensor._id)) _listRange[sensor._id]=[]
                                        if(!error[sensor._id]){
                                            _listRange[sensor._id].push({
                                                id:(new Date()).getTime(),
                                                device_id:sensor.device_id,
                                                sensor_id:sensor.id,
                                                title:moment(values.selectedInterval[sensor._id][0]).format("HH:mm")+" - "+moment(values.selectedInterval[sensor._id][1]).format("HH:mm")+", "+values.setpoint[sensor._id]+""+sensor.unit,
                                                start:values.selectedInterval[sensor._id][0],
                                                start_hour:moment(values.selectedInterval[sensor._id][0]).format("HH:mm"),
                                                end:values.selectedInterval[sensor._id][1],
                                                end_hour:moment(values.selectedInterval[sensor._id][1]).format("HH:mm"),
                                                setpoint:values.setpoint[sensor._id],
                                                setpoint_id:props.secondSensors[index]._id
                                            })
                                            setListRange(_listRange)
                                            setTimeout(() => {
                                                setListRange(_listRange)
                                                _programList=programList
                                                _programList[title]=_listRange
                                                onUpdateProgramList(_programList)
                                            }, 500);
                                        }else errorMsg("Unknow error, please refresh the page")
                                
                                    }}
                                    aria-label={values.id===""?t("add"):t("update")}
                                    icon={<BsCheckLg/>}
                                />:null}
                            </Flex>:
                            !error[sensor._id]?<IconButton
                                colorScheme='green'
                                ml={2}
                                mt={6}
                                onClick={()=>{
                                    _listRange=listRange
                                    if(!listRange.hasOwnProperty(sensor._id)) _listRange[sensor._id]=[]
                                    if(!error[sensor._id]){
                                        _listRange[sensor._id].push({
                                            id:(new Date()).getTime(),
                                            device_id:sensor.device_id,
                                            sensor_id:sensor.id,
                                            title:moment(values.selectedInterval[sensor._id][0]).format("HH:mm")+" - "+moment(values.selectedInterval[sensor._id][1]).format("HH:mm"),
                                            start:values.selectedInterval[sensor._id][0],
                                            start_hour:moment(values.selectedInterval[sensor._id][0]).format("HH:mm"),
                                            end:values.selectedInterval[sensor._id][1],
                                            end_hour:moment(values.selectedInterval[sensor._id][1]).format("HH:mm")
                                        })
                                        setListRange(_listRange)
                                        setValues({...values,selectedInterval:values.selectedInterval})
                                        setTimeout(() => {
                                            setListRange(_listRange)
                                            _programList=programList
                                            _programList[title]=_listRange
                                            onUpdateProgramList(_programList)
                                        },500);
                                    }else errorMsg("Unknow error, please refresh the page")
                                }}
                                aria-label={values.id===""?t("add"):t("update")}
                                icon={<BsCheckLg/>}
                            />:null}
                        </Flex>
                        {listRange.hasOwnProperty(sensor._id)?<Stack direction='row' justify='flex-end' mt={2} mb={1}>
                            {
                                listRange[sensor._id].map((range,index)=>{
                                    return(<ButtonGroup key={index} size='sm' isAttached variant='outline'>
                                        <Button 
                                            colorScheme="teal"
                                            onClick={()=>{
                                                if(values.isManual){
                                                    setValues({...values,
                                                        title:range.title,
                                                        id:range.id
                                                    })
                                                    setCurSensorId(sensor._id)
                                                    onOpen()
                                                }
                                            }}
                                        >{range.title}</Button>
                                        <IconButton
                                            colorScheme="red"
                                            icon={<AiOutlineCloseCircle />}
                                            onClick={()=>{
                                                setValues({...values,selectedInterval:values.selectedInterval})
                                                _listRange=listRange
                                                _listRange[sensor._id]=R.filter(o => o.id !== range.id, _listRange[sensor._id])
                                                
                                                _programList=programList
                                                _programList[title]=_listRange
                                                onUpdateProgramList(_programList)
                                                setListRange(_listRange)
                                            }}
                                        />
                                    </ButtonGroup>)
                                })
                            }
                        </Stack>:null}
                    </Box>)
                })}
                
            </Box>
            <hr/>
            <ModalTitle
                isOpen={isOpen} 
                onClose={onClose}
                label={values.title}
                getTitle={(val)=>{
                    var _listRange=listRange
                    if(props.hasOwnProperty("weeklyForecasts")){
                        for (let i = 0; i < _listRange.length; i++) {
                            if( _listRange[i].id===values.id){
                                _listRange[i].title=val
                                break
                            }
                        }
                    }
                    else if(props.hasOwnProperty("mainSensors")){
                        for (let i = 0; i < _listRange[curSensorId].length; i++) {
                            if( _listRange[curSensorId][i].id===values.id){
                                _listRange[curSensorId][i].title=val
                                break
                            }
                        }
                    }
                    setCurSensorId(-1)
                    setListRange(_listRange)
                }}
            />
        </Box>
    }

    return (
        props.weeklyForecasts?bateryView():
        props.mainSensors?OnOffView():
        null
    );
}
export default withTranslation()(PlaningLine);