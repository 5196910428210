/*!                                                                                                                                                                                                                                                                                                                                  
=========================================================
* Horizon UI - v1.1.0
=========================================================
*/

import React from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Text,Button,
  useColorModeValue,
  SimpleGrid,

  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";
// Redux
import { useSelector,useDispatch } from 'react-redux';

import {useDisclosure} from "@chakra-ui/hooks";
import { withTranslation } from 'react-i18next';
import { Post } from 'utils/queries/index.js';
import { Utils } from 'utils';
import LoadingOverlay from 'react-loading-overlay';
import * as R from 'ramda';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// Custom components
import Card from "components/card/Card.js";
import ModalForm from './components/ModalForm';
import MonitoringAC from "./components/monitoringAC";
import Boiler from "./components/Boiler";

import DemoTab from '../demo/components/DemoTab'
// components
import ComponentDigital from "./components/ComponentDigital";
import AnalogInput from "./components/AnalogInput";
import DigitalInput from "./components/DigitalInput";

import PlaningLine from "./components/PlaningLine";

var mexp = require('../../../utils/math-expression-evaluator');
const week=["Mon","Tue","Wed","Thu","Fri","Sat","Sun"]
LoadingOverlay.propTypes = undefined 

function MonitoringControl(props) {
  const {t}=props;
  const myInfos = useSelector(state => state.user.infos);
  const cur_device = useSelector(state => state.device.cur_device);
  const dispatch = useDispatch();

  let history = useHistory(),response;

  const [sensors,setSensors]=React.useState([])
  const [isLoading, setIsLoading] = React.useState(true);
  const [neoClimModule, setNeoClimModule] = React.useState([]);
  const [programList, setProgramList] = React.useState({});
  const [programON_OFFList, setProgramON_OFFList] = React.useState({});
  const [weeklyForecasts, setWeeklyForecasts] = React.useState({});
  const [boilerModule, setBoilerModule] = React.useState([]);
  const [mainSensors, setMainSensors] = React.useState([]);
  const [secondSensors, setSecondSensors] = React.useState([]);
  const [monitoring_load, setMonitoringLoad] = React.useState(true);
  
  const [values, setValues] = React.useState({
    idDevice: cur_device.value,
  });
  const { isOpen, onOpen, onClose } = useDisclosure()

  var intervalId,neo_clim_module,boiler_module;

  React.useEffect( () => {  
    (async function() {
      document.title = t("monitoring_control")+" - NeoLec";
      if(cur_device.value){
        if(myInfos.token){
          manageListDevice(cur_device.value)
              
          intervalId = setInterval(async() => {
            reloadSensor()
            reloadClimData()
            reloadBoilerData()
          }, 20*1000);
        
          if(myInfos.myViews.hasOwnProperty("controls")){
            if(myInfos.myViews.controls.hasOwnProperty("module_programmation")){
              response=await Post.requestPost("getWeeklyForecasts",{
                token:myInfos.token,
                device_id:cur_device.value
              })
              setWeeklyForecasts(response);
            }
            if(myInfos.myViews.controls.hasOwnProperty("on_off_module")){
              let _mainSensors=[]
              let _secondSensors=[]
              myInfos.myViews.controls.on_off_module.forEach(on_off_module => {
                  
                if(on_off_module.hasOwnProperty("relay"))
                  on_off_module.relay.forEach(relay => _mainSensors.push(relay));
                
                if(on_off_module.hasOwnProperty("temp"))
                  on_off_module.temp.forEach(temp => _secondSensors.push(temp));
              });
              setMainSensors(_mainSensors)
              setSecondSensors(_secondSensors)
            }
            if(myInfos.myViews.controls.hasOwnProperty("boiler_module"))
              if(Array.isArray(myInfos.myViews.controls.boiler_module))setBoilerModule(myInfos.myViews.controls.boiler_module)

            if(myInfos.myViews.controls.hasOwnProperty("on_off_module_config"))
              setProgramON_OFFList(myInfos.myViews.controls.on_off_module_config)
          }
        }
        else history.push('/')
      }
      setMonitoringLoad(true)
      setTimeout(() => {setMonitoringLoad(false)}, 3000);
    })();
    return () => {clearInterval(intervalId);};
  },[cur_device]);
    
  const manageListDevice= async ()=>{
    reloadSensor()
    reloadClimData()
    reloadBoilerData()
    setIsLoading(false)
  }

  const reloadSensor = async() => {
    if(myInfos.role==="admin"){
      response=await Post.listSensors(myInfos.token,cur_device.value)
      if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
      else setSensors(response)
    }else{
      if(myInfos.hasOwnProperty("myViews")){
        if(myInfos.myViews.hasOwnProperty("monitoring")){
          response=await Post.requestPost("listSensors",{
            token:myInfos.token,
            monitoring:myInfos.myViews.monitoring
          })
          setSensors(response.result)
        }else{
          response=await Post.listSensors(myInfos.token,cur_device.value)
          if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
          else setSensors(response)
        }
      }else{
        response=await Post.listSensors(myInfos.token,cur_device.value)
        if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
        else setSensors(response)
      }
    }
  };

  const reloadClimData = async() => {
    if(myInfos.hasOwnProperty("myViews")){
      if(myInfos.myViews.hasOwnProperty("controls")){
        if(myInfos.myViews.controls.hasOwnProperty("neo_clim_module")){
          neo_clim_module=myInfos.myViews.controls.neo_clim_module;
          for (let i = 0; i < neo_clim_module.length; i++) {
            if(neo_clim_module[i].hasOwnProperty("humidity")){
              for (let j = 0; j < neo_clim_module[i].humidity.length; j++) {
                neo_clim_module[i].humidity[j]={
                  "device_id": neo_clim_module[i].humidity[j].device_id,
                  "id":neo_clim_module[i].humidity[j].id,
                }
              }
            }
            if(neo_clim_module[i].hasOwnProperty("temperature")){
              for (let j = 0; j < neo_clim_module[i].temperature.length; j++) {
                neo_clim_module[i].temperature[j]={
                  "device_id": neo_clim_module[i].temperature[j].device_id,
                  "id":neo_clim_module[i].temperature[j].id,
                }
              }
            }
            if(neo_clim_module[i].hasOwnProperty("power")){
              for (let j = 0; j < neo_clim_module[i].power.length; j++) {
                neo_clim_module[i].power[j]={
                  "device_id": neo_clim_module[i].power[j].device_id,
                  "id":neo_clim_module[i].power[j].id,
                }
              }
            }
            if(neo_clim_module[i].hasOwnProperty("status_mystrom")){
              for (let j = 0; j < neo_clim_module[i].status_mystrom.length; j++) {
                neo_clim_module[i].status_mystrom[j]={
                  "device_id": neo_clim_module[i].status_mystrom[j].device_id,
                  "id":neo_clim_module[i].status_mystrom[j].id,
                }
              }
            }
            if(neo_clim_module[i].hasOwnProperty("status_clim")){
              for (let j = 0; j < neo_clim_module[i].status_clim.length; j++) {
                neo_clim_module[i].status_clim[j]={
                  "device_id": neo_clim_module[i].status_clim[j].device_id,
                  "id":neo_clim_module[i].status_clim[j].id,
                }
              }
            }
          }
          response=await Post.requestPost("listClimData",{
            token:myInfos.token,
            neo_clim_module
          })
          if(response.hasOwnProperty("error"))
            if (response.error===200) setNeoClimModule(response.neo_clim_module)
        }
      }
    }
  };
  const reloadBoilerData = async() => {
    if(myInfos.hasOwnProperty("myViews")){
      if(myInfos.myViews.hasOwnProperty("controls")){
        if(myInfos.myViews.controls.hasOwnProperty("boiler_module")){
          boiler_module=myInfos.myViews.controls.boiler_module;
          for (let i = 0; i < boiler_module.length; i++) {
            if(boiler_module[i].hasOwnProperty("status")){
              for (let j = 0; j < boiler_module[i].status.length; j++) {
                boiler_module[i].status[j]={
                  "device_id": boiler_module[i].status[j].device_id,
                  "id":boiler_module[i].status[j].id,
                }
              }
            }
            if(boiler_module[i].hasOwnProperty("temperature")){
              for (let j = 0; j < boiler_module[i].temperature.length; j++) {
                boiler_module[i].temperature[j]={
                  "device_id": boiler_module[i].temperature[j].device_id,
                  "id":boiler_module[i].temperature[j].id,
                  "ds" : ".",//boiler_module[i].temperature[j].graph.ds,
                  "ts" : ",",//boiler_module[i].temperature[j].graph.ts,
                  "dd" : 1,//boiler_module[i].temperature[j].graph.dd
                }
              }
            }
            if(boiler_module[i].hasOwnProperty("temperatureExt")){
              for (let j = 0; j < boiler_module[i].temperatureExt.length; j++) {
                boiler_module[i].temperatureExt[j]={
                  "device_id": boiler_module[i].temperatureExt[j].device_id,
                  "id":boiler_module[i].temperatureExt[j].id,
                  "ds" : ".",//boiler_module[i].temperatureExt[j].graph.ds,
                  "ts" : ",",//boiler_module[i].temperatureExt[j].graph.ts,
                  "dd" : 1,//boiler_module[i].temperatureExt[j].graph.dd
                }
              }
            }
            if(boiler_module[i].hasOwnProperty("humidity")){
              for (let j = 0; j < boiler_module[i].humidity.length; j++) {
                boiler_module[i].humidity[j]={
                  "device_id": boiler_module[i].humidity[j].device_id,
                  "id":boiler_module[i].humidity[j].id,
                  "ds" : ".",//boiler_module[i].humidity[j].graph.ds,
                  "ts" : ",",//boiler_module[i].humidity[j].graph.ts,
                  "dd" : 0,//boiler_module[i].humidity[j].graph.dd
                }
              }
            }
            if(boiler_module[i].hasOwnProperty("power")){
              for (let j = 0; j < boiler_module[i].power.length; j++) {
                boiler_module[i].power[j]={
                  "device_id": boiler_module[i].power[j].device_id,
                  "id":boiler_module[i].power[j].id,
                  "ds" : ".",//boiler_module[i].humidity[j].graph.ds,
                  "ts" : ",",//boiler_module[i].humidity[j].graph.ts,
                  "dd" : 0,//boiler_module[i].humidity[j].graph.dd
                }
              }
            }
          }
          response=await Post.requestPost("listBoilerData",{
            token:myInfos.token,
            boiler_module
          })
          if(response.hasOwnProperty("error"))
            if (response.error===200)
              if(response.hasOwnProperty("boiler_module")){
                if(Array.isArray(response.boiler_module))setBoilerModule(response.boiler_module)
                setTimeout(() => {
                  if(Array.isArray(response.boiler_module))setBoilerModule(response.boiler_module)
                }, 1000);
              }
        }
      }
    }
  };

  const getProporValue=(_sensor)=>{
    if(_sensor.type==="analog_sensor"){
      _sensor.value=Utils.getConvertVal(
        _sensor.value,
        _sensor.input_val_min,
        _sensor.input_val_max,
        _sensor.val_min,
        _sensor.val_max
      )
    }else if(_sensor.type==="calculated_analog_sensor"){
      if(_sensor.hasOwnProperty("formula")) _sensor.value=parseExpresion(_sensor.formula)[2]
      else _sensor.value=Number(_sensor.val_min)
    }
    
    if(_sensor.hasOwnProperty("graph"))
    _sensor.value= Number(_sensor.value).toFixed(_sensor.graph.dd).toString().replace('.',_sensor.graph.ds)
    return _sensor.value;
  }

  const parseExpresion=(expression)=>{
    var text=expression, _var,_value
    if(expression)
      if(expression.includes("$")){
        var newTxt = text.split('$');
        for (var i = 1; i < newTxt.length; i++) {
          _var=newTxt[i].split(' ')[0];
          sensors.forEach(element => {
            if(element.id===_var){
              _value=element.value?Number(element.value):0
              if (Number(_value)===0 && element.formula) {
                //_value=mexp.eval(element.formula)
              }
              text=text.replace('$'+_var,''+_value)
            }
          });
        }
      }

    try{ return [text+" = "+mexp.eval(text),true,mexp.eval(text)]}
    catch(e){return [text+" = "+e.message,false,0] }
  }

  const showWidget=(items,type="follow")=>{
    if(type=="follow"){
      return items.map((item) => (
        item.type==="analog_sensor"?<AnalogInput
          key={item.title}
          title={item.title}
          subtitle={item.subtitle}
          current={item.value?getProporValue(item):item.val_min}
          id={item.id}
          unit={item.unit}
          isOutput={item.isOutput}
          min={item.val_min}
          max={item.val_max}
          onAction={async(val,cmd)=>{
            response=await Post.sendToDevice(myInfos.token,cur_device.value,item.id,val,cmd)
            if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
            else reloadSensor()
          }}
        />:item.type==="digital_sensor"?<DigitalInput
            title={item.title}
            subtitle={item.hasOwnProperty("value")?Number(item.value)===0?t("open"):t("close"):t("close")}
            current={item.hasOwnProperty("value")?Number(item.value):0}
            key={item.id}
            id={item.id}
            onAction={async(val,cmd)=>{
              response=await Post.sendToDevice(myInfos.token,cur_device.value,item.id,val,cmd)
              if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
              else reloadSensor()
            }}
        />:item.type==="calculated_analog_sensor"?<AnalogInput
            key={item.title}
            title={item.title}
            subtitle={item.subtitle}
            current={item.value?getProporValue(item):item.val_min}
            id={item.id}
            unit={item.unit}
            min={item.val_min}
            max={item.val_max}
          />:null
      ))
    }
    else if(type=="neo_clim"){
      if (items.temperature) {
        return items.temperature.map((item,index) => (
          <MonitoringAC 
            key={index}
            token={myInfos.token}
            kit_id={items.temperature[index]?items.temperature[index].device_id:0}
            myStrom_id={items.power[index]?items.power[index].device_id:0}
            label={items.temperature[index].title}
            status_clim={items.status_clim[index]?items.status_clim[index].value:0}
            status_mystrom={items.status_mystrom[index]?items.status_mystrom[index].value:0}
            temperature={items.temperature[index]?items.temperature[index].value?items.temperature[index].value:0:0}
            humidity={items.humidity[index]?items.humidity[index].value:0}
            power={items.power[index]?items.power[index].value:0}
          />
        ))
      }
    }
    else if(type=="boiler"){
      if (items.status) {
        return items.status.map((item,index) => (
            <Boiler
              key={index}
              temp={items.hasOwnProperty("temperature")?items.temperature[index]?items.temperature[index].hasOwnProperty("value")?Number(items.temperature[index].value).toFixed(items.temperature[index].dd).toString().replace('.',items.temperature[index].ds).replace(/\B(?=(\d{3})+(?!\d))/g, items.temperature[index].ts):"--˚C":"--˚C":"--˚C"}
              tempExt={items.hasOwnProperty("temperatureExt")?items.temperatureExt[index]?items.temperatureExt[index].hasOwnProperty("value")?Number(items.temperatureExt[index].value).toFixed(items.temperatureExt[index].dd).toString().replace('.',items.temperatureExt[index].ds).replace(/\B(?=(\d{3})+(?!\d))/g, items.temperatureExt[index].ts)+"˚C":"--˚C":"--˚C":"--˚C"}
              humidity={items.hasOwnProperty("humidity")?items.humidity[index]?items.humidity[index].hasOwnProperty("value")? Number(items.humidity[index].value).toFixed(items.humidity[index].dd).toString().replace('.',items.humidity[index].ds).replace(/\B(?=(\d{3})+(?!\d))/g, items.humidity[index].ts)+"%":"--%":"--%":"--%"}
              power={items.hasOwnProperty("power")?items.power[index]?items.power[index].hasOwnProperty("value")?items.power[index].value+" W":"-- W":"-- W":"-- W"}
              status={item.hasOwnProperty("value")?item.value===0?false:true:false}
              title={item.title}
              onChange={async (val,cmd)=>{
                response=await Post.sendToDevice(myInfos.token,item.device_id,item.id,val?0:1,cmd)
                if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
              }}
            />
        ))
      }
    }
    return(<div/>)
  }

  const showSection=()=>{
    if(myInfos.hasOwnProperty("myViews")){
      if(myInfos.myViews.hasOwnProperty("monitoring")){
        return sensors.map((monitoring) => (
          <Flex direction='column' key={monitoring.title}>
            <Flex
              mt='26px'
              mb='16px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                {monitoring.title}
              </Text>
            </Flex>
            <SimpleGrid columns={{ base: 2, md: 3 }}gap='20px'>
              {showWidget(monitoring.items)}
            </SimpleGrid>
          </Flex>
        ))
      }
    }
  }

  const showProgrammationTable=()=>{
    if(myInfos.myViews.hasOwnProperty("controls"))
      if(myInfos.myViews.controls.hasOwnProperty("module_programmation"))
        if(myInfos.myViews.controls.module_programmation)
          return (<AccordionItem>
            <h2>
              <AccordionButton bg="white">
                <Box flex='1' textAlign='left'>{t("programmation_module")}</Box><AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel bg="white">
              <SimpleGrid mb='20px'>
                <PlaningLine
                  title={"Mon"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Mon_x,y:weeklyForecasts.dni.Mon_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
                <PlaningLine
                  title={"Tue"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Tue_x,y:weeklyForecasts.dni.Tue_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
                <PlaningLine
                  title={"Wed"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Wed_x,y:weeklyForecasts.dni.Wed_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
                <PlaningLine
                  title={"Thursday"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Thursday_x,y:weeklyForecasts.dni.Thursday_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
                <PlaningLine
                  title={"Fri"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Fri_x,y:weeklyForecasts.dni.Fri_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
                <PlaningLine
                  title={"Sat"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Sat_x,y:weeklyForecasts.dni.Sat_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
                <PlaningLine
                  title={"Sun"}
                  programList={programList}
                  weeklyForecasts={weeklyForecasts.dni?{x:weeklyForecasts.dni.Sun_x,y:weeklyForecasts.dni.Sun_y}:{x:[],y:[]}}
                  onUpdateProgramList={(list)=>setProgramList(list)}
                  errorMsg={(text)=>errorMsg(text)}
                />
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>)
  }
  const showModuleDemo=()=>{
    if(myInfos.myViews.hasOwnProperty("controls"))
      if(myInfos.myViews.controls.hasOwnProperty("module_demo"))
        if(myInfos.myViews.controls.module_demo)
          return (<AccordionItem>
            <h2>
              <AccordionButton bg="white">
                <Box flex='1' textAlign='left'>{t("Module Demo")}</Box><AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel bg="white">
              <SimpleGrid mb='20px'>
                <DemoTab/>
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>)
  }

  const onUpdateProgramONOFFList=async (list)=>{
    setProgramON_OFFList(list)
    myInfos.myViews.controls.on_off_module_config=list
    dispatch({type:'SET_INFOS',payload:myInfos});
    
    response=await Post.requestPost("updateProgramONOFF",{
      profile_id:myInfos.myViews._id,
      parameter:"on_off_module",
      token:myInfos.token,
      list
    })
    if(response.hasOwnProperty("error"))
      if(response.error===200){
        successMsg(t("successful_modification"))
      }else errorMsg(response.msg)
  }

  const showModuleOnOff=()=>{
    if(myInfos.myViews.hasOwnProperty("controls"))
      if(myInfos.myViews.controls.hasOwnProperty("on_off_module")){
        return (<AccordionItem>
         <h2>
           <AccordionButton bg="white">
             <Box flex='1' textAlign='left'>{t("on off module")}</Box><AccordionIcon />
           </AccordionButton>
         </h2>
         <AccordionPanel bg="white">
           <SimpleGrid mb='20px'>
             {week.map((day)=>(
               <PlaningLine
                 key={day}
                 title={day}
                 mainSensors={mainSensors}
                 secondSensors={secondSensors}
                 programList={myInfos.myViews.controls.hasOwnProperty("on_off_module_config")?myInfos.myViews.controls.on_off_module_config:{}}
                 onUpdateProgramList={(list)=>onUpdateProgramONOFFList(list)}
                 errorMsg={(text)=>errorMsg(text)}
               />
             ))}
           </SimpleGrid>
         </AccordionPanel>
        </AccordionItem>)
      }
  }

  const showNeoClim=()=>{
    if(myInfos.myViews.hasOwnProperty("controls"))
      if(myInfos.myViews.controls.hasOwnProperty("neo_clim_module"))
        return (<AccordionItem>
          <h2>
            <AccordionButton bg="white">
              <Box flex='1' textAlign='left'>{t("neo_clim_module")}</Box><AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} bg="white">
            {neoClimModule.map((monitoring) => (
              <Flex direction='column' key={monitoring.id}>
                <Flex
                  mt='26px'
                  mb='16px'
                  justifyContent='space-between'
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "start", md: "center" }}>
                  <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                    {monitoring.title}
                  </Text>
                </Flex>
                <SimpleGrid columns={{ base: 2, md: 3 }} gap='20px'>
                  {showWidget(monitoring,"neo_clim")}
                </SimpleGrid>
              </Flex>
              ))
            }
          </AccordionPanel>
        </AccordionItem>)
  }

  const showBoilerModule=()=>{
        if(boilerModule.length>0)
          return (<AccordionItem>
            <h2>
              <AccordionButton bg="white">
                <Box flex='1' textAlign='left'>{t("boiler module")}</Box><AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} bg="white">
              {boilerModule.map((monitoring) => (
                <Flex direction='column' key={monitoring.id}>
                  <Flex
                    mt='26px'
                    mb='16px'
                    justifyContent='space-between'
                    direction={{ base: "column", md: "row" }}
                    align={{ base: "start", md: "center" }}>
                    <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                      {monitoring.title}
                    </Text>
                  </Flex>
                  <Flex gap='20px'>
                    {showWidget(monitoring,"boiler")}
                  </Flex>
                </Flex>
                ))
              }
            </AccordionPanel>
          </AccordionItem>)
  }


  const errorMsg =(text)=> {NotificationManager.error(text, 'Message');}
  const successMsg =(text)=> {NotificationManager.success(text, 'Message');}

  const customView=()=>{
    return(<Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Grid
        mb='20px'
        mt='30px'
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Flex direction='column'>
              <Accordion defaultIndex={[]} allowMultiple allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton bg="white">
                      <Box flex='1' textAlign='left'>{t("monitoring")}</Box>{monitoring_load?<Button isLoading colorScheme='white' variant='ghost'></Button>:<AccordionIcon />}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {showSection()}
                  </AccordionPanel>
                </AccordionItem>
                {showProgrammationTable()}
                {showModuleOnOff()}
                {showNeoClim()}
                {showBoilerModule()}
                {showModuleDemo()}
              </Accordion>
          </Flex>
        </Flex>
      </Grid> 
    </Box>)
  }

  const defaultView=()=>{
    return(<Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Flex direction='column'>
            <Flex
              mt='26px'
              mb='16px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                {cur_device.value==="70cf8db8"?"Bourjon siège social":t("analog_input")}
              </Text>
            </Flex>
            <SimpleGrid columns={{ base: 2, md: 3 }} gap='20px'>
              {R.filter(o => o.type==="analog_sensor", sensors).map((sensor) => (
                <AnalogInput
                  key={sensor.title}
                  title={sensor.title}
                  subtitle={sensor.subtitle}
                  current={sensor.value?getProporValue(sensor):sensor.val_min}
                  id={sensor.id}
                  unit={sensor.unit}
                  isOutput={sensor.isOutput}
                  min={sensor.val_min}
                  max={sensor.val_max}
                  onAction={async(val,cmd)=>{
                    response=await Post.sendToDevice(myInfos.token,cur_device.value,sensor.id,val,cmd)
                    if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
                    else reloadSensor()
                  }}
                />
              ))}
            </SimpleGrid>
          </Flex>

          <Flex direction='column'>
            <Flex
              mt='26px'
              mb='16px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                {cur_device.value==="70cf8db8"?"Puissance totale":t("calculated_analog_sensor")}
              </Text>
            </Flex>
            <SimpleGrid columns={{ base: 2, md: 3 }}gap='20px'>
              
              {R.filter(o => o.type?o.type.includes("calculated_analog_sensor"):null , sensors).map((sensor) => (
                <AnalogInput
                  key={sensor.title}
                  title={sensor.title}
                  subtitle={sensor.subtitle}
                  current={sensor.formula?getProporValue(sensor):sensor.val_min}
                  id={sensor.id}
                  unit={sensor.unit}
                  min={sensor.val_min}
                  max={sensor.val_max}
                />
              ))}
            </SimpleGrid>
          </Flex>
        </Flex>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <ComponentDigital
              title={t("digital_input")}
              items={R.filter(o => o.type === "digital_sensor", sensors).map((sensor) => (
                <DigitalInput
                  key={sensor.id}
                  title={sensor.title}
                  subtitle={Number(sensor.value)===0?t("open"):t("close")}
                  current={Number(sensor.value)}
                  id={sensor.id}
                  onAction={async(val,cmd)=>{
                    response=await Post.sendToDevice(myInfos.token,cur_device.value,sensor.id,val,cmd)
                    if(response.hasOwnProperty("error"))NotificationManager.error(response.error, 'Message');
                    else reloadSensor()
                  }}
                />
              ))}
            />
          </Card>
          <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
            </Flex>

          </Card>
        </Flex>
      </Grid> 
    </Box>)
  }
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Neolec ⚡⚡'
    >
      {myInfos.role==="admin"?defaultView():myInfos.hasOwnProperty("myViews")?customView():null}
      <ModalForm
        isOpen={isOpen} 
        onClose={onClose}
        token={myInfos.token}
        data={values}
        addItem={(itm)=>{ 
        }}
        showMsg={(msg)=>{NotificationManager.success(msg, 'Message'); }}
      />
      <NotificationContainer/>
    </LoadingOverlay>
  );
  }
export default withTranslation()(MonitoringControl);
